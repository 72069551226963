import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  @media (max-width: 1280px) {
  }
  @media (max-width: 767px) {
    height: 100%;
    /* background: #000; */
    min-height: 170.2vw;
  }

  .ant-steps {
    background: transparent;
    padding: 20px;
    max-width: 700px;
    width: 100%;
    @media (max-width: 767px) {
      padding: 1.333vw;
    }
  }
  .ant-steps-item-icon .ant-steps-icon {
    font-family: 'OutfitMedium';
  }
  .ant-steps-item-title {
    font-family: 'OutfitRegular';
    font-size: 18px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border: none;
    margin-right: 0;
  }
  .ant-btn {
    border-radius: 16px;
    min-height: 50px;
    min-width: 100px;
  }
  @media (max-width: 767px) {
    .ant-steps-item-container {
      display: flex;
    }
    .ant-steps {
      display: -webkit-box;
    }
    .ant-steps-item {
      width: 31vw;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: #fff;
    }
  }
`
export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;
`
