import styled from 'styled-components'
import Button from 'components/Button'

export const Invite = styled(Button)`
  background-color: #1bb691;
`
export const Create = styled(Button)``
export const Container = styled.div`
  width: 100%;
`
export const Wrapper = styled.div`
  width: 100%;
`
export const LeftSection = styled.div`
  flex: 10;
  width: 100%;
  @media (max-width: 1440px) {
    gap: 8px;
  }
`
export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 34px;
  width: 100%;
  max-width: 600px;
  padding: 10px;
`
export const LeftTopSection = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  padding: 14px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
    margin-left: 4.8vw;
  }
`
export const LeftBottomSection = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
`
export const WelcomeSection = styled.div`
  font-family: 'OutfitLight';
  font-size: 42px;
  color: #0a0c17;
  @media (max-width: 1440px) {
    font-size: 31px;
  }
`
export const WelcomeText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  color: #737b7d;
`
export const Box = styled.div`
  display: flex;
  background: #e2eaff;
  border-radius: 28px;
  width: 100%;
  justify-content: space-between;
  padding: 15px 20px;
`
export const ButtonBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 330px;
  align-items: center;
  gap: 13px;
`
export const WelcomeBox = styled.div`
  width: 100%;
`
export const DashbordContentSection = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
    margin-left: 4.8vw;
  }
`
export const ContentWrapper = styled.div`
  margin: 10px;
`
export const ContainerSecond = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
  @media (max-width: 1440px) {
    margin-bottom: 15px;
  }
`
export const MoneyDetails = styled.div`
  font-family: 'OutfitRegular';
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #0a0c17;
`
export const MoneyDetails2 = styled.div`
  font-family: 'OutfitMedium';
  font-size: 16px;
  font-weight: 10;
  line-height: 21px;
`

export const RightWalletSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`
export const TextArea = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 30px;
  color: #0a0c17;
`
export const ImageSection = styled.img``

export const SecondImageSection = styled.img``
export const WalletBox = styled.div`
  display: flex;
  gap: 20px;
`
export const LeftPlusBox = styled.div`
  max-width: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f8faff;
  border: 1px dashed #bebebe;
  box-sizing: border-box;
  border-radius: 18px;
  cursor: pointer;
  padding: 20px;
`
export const BottomBoxSection = styled.div`
  display: flex;
  padding: 0 23px 23px 23px;
`
export const PlusSection = styled.img`
  height: 16px;
`

export const ViewAllBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 7px;
  cursor: pointer;
`
export const ViewAllText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2677e9;
  :hover {
    opacity: 0.5;
  }
`
export const ViewImage = styled.img`
  height: 16px;
  margin-top: 8px;
  :hover {
    opacity: 0.5;
  }
`
export const RightBenificiaries = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  margin-top: 21px;
  padding: 15px;
  padding-bottom: 1px;
`
export const BenificiariesTextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 30px;
  display: flex;
  color: #0a0c17;
`
export const PluseImageBox = styled.img`
  height: 28px;
  cursor: pointer;
`
export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BenificiariescardSection = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
`
export const SectionRight = styled.div`
  flex: 1;
  width: 100%;
  max-width: 570px;
  min-width: 445px;
  padding-right: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    min-width: 90.667vw !important;
    margin-left: 5.6vw;
  }

  @media (max-width: 1440px) {
    max-width: 400px;
    min-width: 370px;
    gap: 8px;
  }
`
export const ViewAllBoxs = styled.div`
  display: flex;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  padding: 26px;

  @media (max-width: 1440px) {
    padding: 17px;
  }
`
export const WelcomeBoldSection = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 80px;
  color: #0a0c17;
`
export const LeftImage = styled.div``
export const RightImage = styled.div``

export const Plus = styled.div`
  background: rgba(0, 159, 66, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 15px;
  color: #009f42;
  max-width: 80px;
`
export const ImageWrapper = styled.img`
  height: 50px;
`
export const Minus = styled.div`
  background: rgba(255, 56, 56, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 15px;
  color: #ff3535;
  max-width: 80px;
`
export const IncContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CryptoLogo = styled.img`
  height: 200px;
  width: 196px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 80px;
  text-align: center;
  @media (max-width: 1440px) {
    padding: 20px 3px 0px 3px;
  }
`
export const DefinitionSection = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 2px 37px 26px 10px;
  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 26px 10px 25px 10px;
  }
`
export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
export const NoAssetsAvalable = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 37px 37px 37px 10px;
  @media (max-width: 1440px) {
    padding: 65px 66px 60px 66px;
  }
`
export const ReadMore = styled.div`
  cursor: pointer;
  color: #4473f5;
`
export const MessageContainer = styled.div<{ showFull?: boolean }>`
  display: ${(props) => (props?.showFull ? '' : 'flex')};
`

export const MessageText = styled.div`
  font-family: 'OutfitLight';
  font-size: 23px;
  color: #0a0c17;
`
