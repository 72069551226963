import React, { useState, useContext } from 'react'
import data from 'components/BeneficaryMobileSidebar/data'
import SidebarCard from 'components/Cards/SideBar'
import Modal from 'components/Modal'
import { UserContext } from 'context/user'
import CommingSoon from 'components/Cards/CommingSoon'
import HeaderLogo from 'assets/images/mobileheader-logo.png'
import CrossLogo from 'assets/images/grayx-logo.png'

import {
  Container,
  Wrapper,
  GrantorView,
  ButtonContainers,
  ViewSelectOption,
  GrantorSection,
  NameText,
  GrantorAdress,
  Dropaheader,
  XLogo,
  LogoSection,
  BurgerHeader,
} from 'styles/components/FiduciaryMobileSidebar'

interface IHeaderProps {
  showModal(value: boolean, type: string): void
}

export const BeneficaryMobileSidebar = ({ showModal }: IHeaderProps) => {
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <BurgerHeader>
        <Dropaheader>
          <XLogo src={CrossLogo} onClick={() => showModal(false, 'option')} />
          <LogoSection src={HeaderLogo} />
        </Dropaheader>
        <GrantorView>
          <ButtonContainers>
            <ViewSelectOption>
              <GrantorSection>
                <NameText>Beneficiarie View</NameText>
                <GrantorAdress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                  user?.userWalletId?.length - 4,
                )}`}</GrantorAdress>
              </GrantorSection>
            </ViewSelectOption>
          </ButtonContainers>
        </GrantorView>
      </BurgerHeader>
      <Wrapper>
        {data?.map((item) => (
          <SidebarCard
            key={item.id}
            image={item?.icon}
            title={item?.title}
            path={item?.path}
            showModal={(value: boolean) => setIsOpen(value)}
          />
        ))}
      </Wrapper>
      <Modal isOpen={isOpen}>
        <CommingSoon showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Container>
  )
}

export default BeneficaryMobileSidebar
