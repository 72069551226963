import { ReactElement, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import usePost from 'hooks/usePost'
import { Checkbox as AntdCheckbox } from 'antd'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import { FiduciaryMyPlanRoute, BeneficarydashboardRoute, DashboardRoute } from 'constants/routes'
import Button from 'components/Button'
import Logo from 'assets/images/logo-black.png'
import Watermark from 'assets/images/watermark-image.png'
import DefaultImage from 'assets/images/crypto-currencies.png'

import {
  Container,
  Wrapper,
  WelcomeTextSection,
  TopTextSection,
  ContainerTextSection,
  PrimaryWalletSection,
  PrimaryWalletContainer,
  HeaderWrapper,
  LogoSection,
  TopSection,
  TextSection,
  TableContainer,
  ButtonSection,
  Defination,
} from 'styles/views/welcome/WelcomeSection/WelcomeCrypto'
import { CryptoLogo } from 'styles/views/dashboard/MyPlan'

interface ITokenData {
  name: string
  token_address: string
  checked?: boolean
}

const WelcomeCrypto = (): ReactElement => {
  const navigate = useNavigate()
  const { mutateAsync, isLoading, error, isSuccess, isError } = usePost()
  const Web3Api = useMoralisWeb3Api()
  const { Moralis } = useMoralis()
  const { user } = useContext(UserContext)
  const role = localStorage.getItem('signRole')
  const { setLoader } = useContext(LoaderContext)
  const [uniqueTokens, setUniqueTokens] = useState<ITokenData[]>([])
  const linkedAddresses = user?.userWallet
  const [isOpens, setIsOpens] = useState(false)
  const fetchTokenBalance = async () => {
    const tokens: any[] = []

    for (let iterator = 0; iterator < linkedAddresses.length; iterator++) {
      const tokenbalances = await Web3Api.account.getTokenBalances({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: linkedAddresses[iterator].walletId, //this holds internally linked address of MM
      })
      tokens.push(...tokenbalances)
    }

    const uniqueTokens = [
      ...tokens
        .reduce((map, current) => {
          const { token_address } = current
          const grouped = map.get(token_address)
          if (!grouped) {
            map.set(token_address, { ...current })
          } else {
            map.set(token_address, {
              ...grouped,
              balance: (Number(grouped.balance) + Number(current.balance)).toLocaleString('fullwide', {
                useGrouping: false,
              }),
            })
          }

          return map
        }, new Map())
        .values(),
    ]
    setUniqueTokens(uniqueTokens.map((obj) => ({ ...obj, checked: false })))
  }

  useEffect(() => {
    fetchTokenBalance()
  }, [])

  const roleNavigation = () => {
    if (role === 'Fiduciary') {
      navigate(FiduciaryMyPlanRoute.path)
    } else if (role === 'Beneficiary') {
      navigate(BeneficarydashboardRoute.path)
    } else navigate(DashboardRoute.path)
  }

  const onSubmit = async () => {
    const data = uniqueTokens?.filter((item) => item.checked === true)

    const maxLength = data?.length - 1

    const finalData: any = []

    for (let i = 0; i < data?.length; i++) {
      const elementData = data[i]

      const payload = {
        yourAssets: elementData.name,
        tokenAddress: elementData.token_address,
        walletId: user?.userWalletId,
      }
      finalData.push(payload)

      if (i === maxLength) {
        try {
          await mutateAsync({
            url: 'assetcollection/userAsset',
            payload: { userAsset: finalData, isAuthorised: false },
            token: true,
          })

          localStorage.removeItem('_welcomeFlag')
          roleNavigation()
        } catch (error: any) {
          return { error: error.response.data.message }
        }
      }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (isError) {
      setIsOpens(true)
    }
  }, [isError, error])

  return (
    <Container>
      <HeaderWrapper>
        <LogoSection src={Logo} />
      </HeaderWrapper>
      <Wrapper>
        <WelcomeTextSection>
          <ContainerTextSection>
            <TopTextSection>Welcome to CryptoPlan</TopTextSection>
          </ContainerTextSection>
        </WelcomeTextSection>
        <PrimaryWalletSection>
          <PrimaryWalletContainer>
            <TopSection>
              <TextSection>Choose your Assets</TextSection>
            </TopSection>
            <TableContainer>
              {uniqueTokens?.length ? (
                <table>
                  <tr>
                    {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>Logo</th> : ''}
                    <th>Your Assets</th>
                    <th>Balance</th>
                    <th>Total Amount</th>
                  </tr>
                  <tbody>
                    {uniqueTokens.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                            <td>
                              <CryptoLogo className="minified" src={item?.logo === null ? DefaultImage : item.logo} />
                            </td>
                          ) : (
                            ''
                          )}
                          <td>{item.name}</td>
                          <td>{Moralis.Units.FromWei(item.balance, 18)}</td>
                          <td>{'NA'}</td>
                          <th>
                            <AntdCheckbox
                              checked={item?.checked}
                              onChange={(e) => {
                                const newArray = [...uniqueTokens]
                                newArray[index].checked = e?.target?.checked
                                setUniqueTokens(newArray)
                              }}
                            />
                          </th>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <>
                  <CryptoLogo src={Watermark} />
                  <Defination>No Assets</Defination>
                </>
              )}
              <ButtonSection>
                {uniqueTokens?.length ? (
                  <Button label="Add Wallet " variant="contained" type="submit" onClick={() => onSubmit()} />
                ) : (
                  <Button
                    label="Skip"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      localStorage.removeItem('_welcomeFlag')
                      roleNavigation()
                    }}
                  />
                )}
              </ButtonSection>
            </TableContainer>
          </PrimaryWalletContainer>
        </PrimaryWalletSection>
      </Wrapper>
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} success={isSuccess} error={error} />
      </Modal>
    </Container>
  )
}

export default WelcomeCrypto
