import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'components/Modal'
import data from 'components/MobileBurgerDrop/data'
import OptionWallet from 'components/Cards/OptionWallet'
import HeaderLogo from 'assets/images/logo.png'
import { OurSolutionRoutes, TimerRoutes, WelcomePlanRoute } from 'constants/routes'
import {
  LogoSection,
  DropDownSection,
  Dropaheader,
  MobileNavmenu,
  MobileButton,
  LoginMobile,
  RegisterButton,
  MenuItem,
  SigninButton,
} from 'styles/views/landing/Header'
interface IHeaderProps {
  showModal(value: boolean, type: string): void
  scrollView(refName: string): void
  setIsUpdatePlan: () => void
}
const MobileBurgerDrop: React.FC<IHeaderProps> = ({ showModal, scrollView, setIsUpdatePlan }: IHeaderProps) => {
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)
  const wrapperRef = useRef<any>()
  const navigate = useNavigate()
  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsUpdatePlan()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const handleRegister = () => {
    setIsUpdatePlan()
    setIsUpdatePlanOpen(true)
  }

  return (
    <DropDownSection ref={wrapperRef}>
      <Dropaheader>
        <LogoSection src={HeaderLogo} />
      </Dropaheader>
      <MobileNavmenu>
        {data?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setIsUpdatePlan()
              if (item?.title == 'Our Solution') navigate(OurSolutionRoutes.path)
              else scrollView(item.title)
              showModal(false, 'option')
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </MobileNavmenu>
      <MobileButton>
        <SigninButton>
          <LoginMobile
            label="Sign In"
            variant="outline"
            onClick={() => {
              process.env.REACT_APP_COMING_SOON === 'false'
                ? navigate(TimerRoutes.path)
                : navigate(WelcomePlanRoute.path)
            }}
          />
        </SigninButton>
        <RegisterButton
          label="Register"
          variant="contained"
          onClick={() =>
            process.env.REACT_APP_COMING_SOON === 'false' ? navigate(TimerRoutes.path) : handleRegister()
          }
        />
      </MobileButton>
      <Modal isOpen={isUpdatePlanOpen}>
        <OptionWallet showModal={(value: boolean) => setUpdatePlanModal(value)} />
      </Modal>
    </DropDownSection>
  )
}

export default MobileBurgerDrop
