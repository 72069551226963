import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  align-items: flex-start;
  width: 100%;
`
export const Wrapper = styled.div`
  display: grid;
  text-align: left;
  padding: 2px 47px;
  justify-content: center;
  gap: 0px;
  @media (max-width: 767px) {
    height: 60.333vw;
    overflow-y: scroll;
    padding: 2px 30px;
  }
`
export const HeaddigText = styled.div`
  font-family: 'OutfitRegular';
  padding: 24px 10px;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.3px;
  &.Chosed {
    background: #4473f5;
  }
  &.not {
    background: #fff;
  }
  @media (max-width: 1280px) {
    padding: 7px 10px;
  }
`

export const PlanCard = styled.div<{ planCheck?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 355px;
  background: #fff;
  min-height: 153px;
  border-radius: 20px;
  padding: 14px 16px 18px 24px;
  text-align: left;
  border: 1px solid #e0e0e0;

  pointer-events: ${(props) => props.planCheck && 'none'};

  cursor: pointer;
  &.Chosed {
    background: #4473f5;
  }
  &.not {
    background: #fff;
  }
  @media (max-width: 767px) {
    width: 100%;
    min-width: 74vw;
    min-height: 35.333vw;
    padding: 2.133vw;
  }
  @media (max-width: 1280px) {
    min-height: 143px;
  }
`
export const PlanContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
export const HeadText = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'OutfitMedium';
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  color: #000;
  &.Chosed {
    color: #fff;
  }
  &.not {
    color: #000;
  }
`
export const MidTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const MidText = styled.div`
  font-family: 'OutfitRegular';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  &.Chosed {
    color: #fff;
  }
  &.not {
    color: #000;
  }
`
export const PlanWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const PlanBWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const Plan = styled.div`
  font-family: 'OutfitRegular';
  font-size: 22px;
  line-height: 28px;
  color: #000;
  &.Chosed {
    color: #fff;
  }
  &.not {
    color: #000;
  }
`
export const PlanB = styled.div`
  font-family: 'OutfitRegular';
  font-size: 22px;
  line-height: 28px;
  color: #000;
  &.Chosed {
    color: #fff;
  }
  &.not {
    color: #000;
  }
`
export const PlanDuration = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 20px;
  color: #000;
  &.Chosed {
    color: #fff;
    opacity: 0.6;
  }
  &.not {
    color: #000;
  }
`
export const Billed = styled.div`
  &.Chosed {
    color: #fff;
    width: max-content;
    padding: 4px 8px;
    background: #50c6be;
    border-radius: 50px;
  }
  &.not {
    color: #000;
  }
`
export const Yearly = styled.div`
  text-align: left;
  &.Chosed {
    color: #fff;
    opacity: 0.6;
  }
  &.not {
    color: #000;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  text-align: end;
  .sc-gsnTZi {
    max-width: 140px;
  }
`
export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
  .Back-button {
    background: #fff;
    color: #000;
    border-color: #e0e0e0;
    height: 56px;
    margin: 14px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    @media (max-width: 1366px) {
      margin: 14px;
    }
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin-left: 1.333vw;
      margin-top: 1.333vw;
      margin-bottom: 10px;
    }
  }
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: center;
  gap: 10px;
  @media (max-width: 767px) {
    max-width: 76.8vw;
  }
`
