interface IData {
  panel: string
  label: string
  link: string
  description: string
}

const data: IData[] = [
  {
    panel: 'What is CryptoPlan and what is it doing?',
    label:
      'Cryptoplan is a hybrid web2 and web3 application that helps users create a smart contract to automate a generational plan.',
    link: 'Learn more',
    description:
      'Cryptoplan is a hybrid web2 and web3 application that helps users create a smart contract to automate a generational plan for their digital assets. Cryptoplan makes sure the future of your digital assets is secured for future generations. ',
  },
  {
    panel: 'Why do I need a Cryptoplan?',
    label:
      'Without a Cryptoplan you expose your assets to counterparty risk or malicious actors within your circle as you must fully trust your lawyer or fiduciary to carry out your plans.',
    link: 'Learn more',
    description:
      'Without a Cryptoplan you expose your assets to counterparty risk or malicious actors within your circle as you must fully trust your lawyer or fiduciary to carry out your plans. With cryptoplan you have the power to create a trustless contract in minutes that will disburse your assets as you wish while always owning your keys.',
  },
  {
    panel: 'Does Cryptoplan have access to my assets?',
    label:
      'NO, cryptoplan never has access to your wallets, private keys, or your seed phrases. We rely on our trustless Web3 contract combined with your appointed network.',
    link: 'Learn more',
    description:
      'NO, cryptoplan never has access to your wallets, private keys, or your seed phrases. We rely on our trustless Web3 contract combined with your appointed network. Privacy and Security of the utmost importance to our founders and our mission. You remain in control of your portfolio always.',
  },

  {
    panel: 'Can I update or make changes to my Cryptoplan?',
    label:
      'You are always in control of your Cryptoplan. If you wish to add or remove addresses from your Cryptoplan that is fully up to you. ',
    link: 'Learn more',
    description:
      'You are always in control of your Cryptoplan. If you wish to add or remove addresses from your Cryptoplan that is fully up to you. Although be aware there may be some cost depending on the Cryptoplan tier you joined. For example, if you add a new baby to your family, we can help you make a wallet and add that child to your cryptoplan.',
  },
  {
    panel: 'What is a Smart Contract?',
    label:
      'A smart contract is a self-executing contract with the terms of the contract between parties being directly written into lines of code.',
    link: 'Learn more',
    description:
      'A smart contract is a self-executing contract with the terms of the contract between parties being directly written into lines of code. The code and agreements exist across a distributed, decentralized blockchain network. The code controls the execution, and transactions are trackable and irreversible.',
  },
  {
    panel: 'What is a MetaMask Wallet?',
    label:
      'MetaMask is a popular and established browser extension that functions as a  digital wallet and gateway to blockchain applications.',
    link: 'Learn more',
    description:
      'MetaMask is a popular and established browser extension that functions as a  digital wallet and gateway to blockchain applications. MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets. MetaMask provides an essential utility for blockchain newcomers, token traders, crypto gamers, and developers',
  },
  {
    panel: 'What is a Wallet address?',
    label:
      'Also known as your "public key." Your wallet address is unique. It’s the address people will use when sending you cryptocurrency, NFTs, or digital assets.',
    link: 'Learn more',
    description:
      'Also known as your "public key." Your wallet address is unique. It’s the address people will use when sending you cryptocurrency, NFTs, or digital assets. Your NFTs and cryptocurrency do not live in your wallet—they exist on the blockchain under your wallet address. Your software or hardware wallet only contains the key to that address. On Ethereum, your address starts with a "0x". You can also use an ENS domain.',
  },
  {
    panel: 'What is a Private key and Seed phrase? ',
    label:
      'A private key is like a password — a string of letters and numbers — that allows you to access and manage your crypto funds. Private Keys are used to sign transactions and also to prove ownership of assets.',
    link: 'Learn more',
    description:
      'A private key is like a password — a string of letters and numbers — that allows you to access and manage your crypto funds. Private Keys are used to sign transactions and also to prove ownership of assets. Seed phrase is a list of words (usually ranging from 12-24 words) that can be used to recover your crypto, should you forget your password or lose access to your wallet.',
  },
  {
    panel: 'What is Ethereum?',
    label:
      'At its core, Ethereum is a decentralized global software platform powered by blockchain technology. It is most commonly known for its native cryptocurrency, ether (ETH).',
    link: 'Learn more',
    description:
      'At its core, Ethereum is a decentralized global software platform powered by blockchain technology. It is most commonly known for its native cryptocurrency, ether (ETH).Ethereum can be used by anyone to create any secured digital technology. It has a token designed to pay for work supporting the blockchain, but participants can also use it to pay for tangible goods and services if accepted.',
  },
  {
    panel: 'How do I purchase Ethereum (ETH)?',
    label:
      'Ether is the native currency of the Ethereum network, and it’s commonly abbreviated to ETH, which is its ticker symbol.',
    link: 'Learn more',
    description:
      'Ether is the native currency of the Ethereum network, and it’s commonly abbreviated to ETH, which is its ticker symbol.You need ETH to pay for some of your interactions with the blockchain and to pay for the items you buy. You can buy by depositing from an exchange like  Coinbase, using your credit card, or directly in your wallet extension.',
  },
  {
    panel: 'What is the Gas fee?',
    label:
      'Think of gas fees as Ethereum blockchain transaction costs. Cryptoplan has no say in setting gas fees – they are determined by supply and demand across the network (blockchain).',
    link: 'Learn more',
    description:
      'Think of gas fees as Ethereum blockchain transaction costs. Cryptoplan has no say in setting gas fees – they are determined by supply and demand across the network (blockchain).When you are creating or updating a Will, you will have to pay gas fees. The gas fee amount will vary depending upon the activity on the blockchain.',
  },
  {
    panel: 'What happens when the transaction fails?',
    label:
      'When a transaction fails on the Ethereum network, you are still charged.  Transactions fail when there isn’t enough gas to pay for a transaction or when a smart contract rejects a transaction.',
    link: 'Learn more',
    description:
      'When a transaction fails on the Ethereum network, you are still charged.  Transactions fail when there isn’t enough gas to pay for a transaction or when a smart contract rejects a transaction. The Cryptoplan Team is not accountable for the lost gas fee in a failed transaction.You are still charged for failed transactions because miners need to confirm transactions to the chain whether they succeed or fail. That means you will still have to pay for that regardless of whether your transaction goes through.',
  },
]

export default data
