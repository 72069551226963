import { SVGProps } from 'react'

const KeyFeatureTree = (props: SVGProps<SVGSVGElement>) => (
  <svg width={36} height={37} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M33.32 27.62v-4.45a6.702 6.702 0 0 0-6.702-6.703h-3.83a2.872 2.872 0 0 1-2.872-2.872v-4.45a4.586 4.586 0 0 0 1.335-7.417 4.595 4.595 0 0 0-7.845 3.25 4.59 4.59 0 0 0 2.681 4.167v4.45a2.872 2.872 0 0 1-2.872 2.872h-3.83a6.701 6.701 0 0 0-6.7 6.703v4.45a4.588 4.588 0 0 0 1.914 8.762 4.59 4.59 0 0 0 1.914-8.762v-4.45a2.873 2.873 0 0 1 2.873-2.873h3.829a6.632 6.632 0 0 0 2.872-.67v7.993a4.588 4.588 0 0 0 1.915 8.762 4.59 4.59 0 0 0 1.915-8.762v-7.993c.873.417 1.84.67 2.871.67h3.83a2.872 2.872 0 0 1 2.872 2.872v4.45a4.585 4.585 0 0 0-1.335 7.417A4.595 4.595 0 0 0 36 31.786a4.588 4.588 0 0 0-2.68-4.166ZM18.001 2.326a2.65 2.65 0 1 1 0 5.3 2.65 2.65 0 0 1 0-5.3ZM4.599 34.439a2.651 2.651 0 1 1 0-5.303 2.651 2.651 0 0 1 0 5.303Zm13.403 0a2.652 2.652 0 1 1 0-5.303 2.652 2.652 0 0 1 0 5.303Zm13.403 0a2.651 2.651 0 1 1 0-5.303 2.651 2.651 0 0 1 0 5.303Z"
      fill="#0E65F3"
      className="icon"
    />
  </svg>
)

export default KeyFeatureTree
