import { SVGProps } from 'react'

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity={0.3} cx={28} cy={28} r={20} fill="#00299C" />
    <rect x={26} y={24} width={4} height={14} rx={2} fill="#3D42DF" />
    <rect x={26} y={18} width={4} height={4} rx={2} fill="#3D42DF" />
  </svg>
)

export default InfoIcon
