import React, { useState } from 'react'
import Modal from 'components/Modal'
import SignUpCard from 'components/Cards/SignUp'
import AddWalletModal from 'components/Modal/AddWalletModal'
import data from 'views/landing/SignUp/data'
import CancelSign from 'assets/images/vector-cancel.png'
import {
  Container,
  Wrapper,
  PrimaryWalletSection,
  CardSection,
  ImageSection,
  CancelSection,
  TextSection,
} from 'styles/views/landing/SignIn'

interface IModalProps {
  showModal: (value: boolean) => void
  hidePreviousModal?: () => void
}

const LoginConnectWallet: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <Wrapper>
        <ImageSection>
          <TextSection>Connect Wallet</TextSection>
          <CancelSection
            src={CancelSign}
            onClick={() => {
              showModal(false)
            }}
          />
        </ImageSection>
        <PrimaryWalletSection>
          <CardSection onClick={() => setOpen(true)}>
            {data?.map((item) => (
              <SignUpCard key={item.id} image={item?.image} title={item?.title} />
            ))}
          </CardSection>
        </PrimaryWalletSection>
      </Wrapper>

      <Modal isOpen={open}>
        <AddWalletModal showModal={(value: boolean) => setOpen(value)} />
      </Modal>
    </Container>
  )
}

export default LoginConnectWallet
