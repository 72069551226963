import React from 'react'
import MobileUpgradeSteper from 'views/dashboard/MyPlan/MobileUpgradeSteper'
import NavBar from 'components/NavBar'
import { MainContainer, Container } from 'styles/views/dashboard/MyPlan/UpgradePlan'

const MobileUpgradePlan = () => (
  <MainContainer>
    <Container>
      <NavBar />
      <MobileUpgradeSteper />
    </Container>
  </MainContainer>
)
export default MobileUpgradePlan
