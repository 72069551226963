import MyPlan from 'assets/svg/MyPlan'
import Portfolio from 'assets/svg/Portfolio'
import Setting from 'assets/svg/Setting'

interface IData {
  id: number
  icon: any
  title: string
  path?: string
}

const data: IData[] = [
  {
    id: 0,
    icon: <MyPlan />,
    title: 'My Plan',
    path: '/fiduciarydashboard',
  },
  {
    id: 1,
    icon: <Portfolio />,
    title: 'My Crypto Assets ',
    path: '/fiduciarycryptoassetsRoute',
  },

  {
    id: 3,
    icon: <Setting />,
    title: 'Settings',
    path: '/fiduciarysetting',
  },
]
export default data
