import React, { useEffect, useContext } from 'react'
import usePost from 'hooks/usePost'
import { LoaderContext } from 'context/loader'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  Wrapper,
  DiscardButton,
  SaveButton,
  RightButtonBox,
} from 'styles/components/Cards/Warning'

export interface IModalProps {
  onNo: () => void
  showModal: (value: boolean) => void
  encryptedShards: any
  hidePreviousModal: (value: boolean) => void
}

interface IElementProps {
  emailId: string
  userWalletId: string
  item: string
}

const ConfirmationModal = ({ onNo, showModal, encryptedShards, hidePreviousModal }: IModalProps) => {
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { mutateAsync, isLoading } = usePost()
  const { mutateAsync: shartSaveAsync, isLoading: shartSaveLoading } = usePost()
  const { setLoader } = useContext(LoaderContext)
  const beneficiaryData = beneficiariesData?.data?.beneficary

  const handleYes = async () => {
    const emailArray: Array<string> = []
    const userWalletId: Array<string> = []

    beneficiaryData.forEach((element: IElementProps) => {
      emailArray.push(element.emailId)
      userWalletId.push(element.userWalletId)
    })
    const payloadForOtp = {
      email: emailArray,
      userwalletId: userWalletId,
    }

    const payloadshartSave = {
      shartsDetails: encryptedShards,
    }

    try {
      await mutateAsync({
        url: 'auth/shareshartsconvert',
        payload: payloadForOtp,
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }

    try {
      await shartSaveAsync({
        url: 'auth/shartSave',
        payload: payloadshartSave,
        token: true,
      })
      toast('Shards have been generated')

      setTimeout(() => {
        showModal(false)
        hidePreviousModal(false)
      }, 2000)
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading || shartSaveLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, shartSaveLoading])

  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} onClick={() => showModal(false)} />
        </Container>
        <TextSection>Please confirm that encryption key has been shared with the fiduciary?</TextSection>
        <RightButtonBox>
          <DiscardButton label={'Yes'} variant="contained" type="button" onClick={handleYes} />
          <SaveButton label={'No'} variant="contained" type="submit" onClick={onNo} />
        </RightButtonBox>
      </Wrapper>
      <ToastContainer />
    </MainContainer>
  )
}

export default ConfirmationModal
