import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import CrossLogo from 'assets/images/grayx-logo.png'
import Watermark from 'assets/images/watermark-image.png'

import {
  Container,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  ImageSection,
  LeftButton,
  RightButton,
  CryptoLogo,
} from 'styles/components/Cards/OptionWallet'

export interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

const OptionWallet: React.FC<IModalProps> = ({ showModal }) => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Container>
        <HeadingSection>Do you have Wallet?</HeadingSection>
        <ImageSection src={CrossLogo} onClick={() => showModal(false, 'option')} />
      </Container>
      <TextSection>
        <Defination>
          <CryptoLogo src={Watermark} />
        </Defination>
        <ButtonSection>
          <LeftButton>
            <Button
              label="No, I don’t Have"
              variant="outline"
              type="button"
              onClick={() => {
                showModal(false, 'option')
                showModal(true, 'wallet')
              }}
            />
          </LeftButton>
          <RightButton>
            <Button
              label="Yes, I have"
              variant="contained"
              type="button"
              onClick={() => {
                navigate('/selectwallet')
              }}
            />
          </RightButton>
        </ButtonSection>
      </TextSection>
    </Wrapper>
  )
}

export default OptionWallet
