import React from 'react'
import EmailCopy from 'assets/images/copy-email.png'
import { MainContainer, Container, EmailInformation, Email, EmailCoppyImage } from 'styles/components/CoppyWalletId'

const CoppyWalletId = () => (
  <MainContainer>
    <Container>
      <EmailInformation>
        <Email>wallet028nde339</Email>
        <EmailCoppyImage src={EmailCopy} />
      </EmailInformation>
    </Container>
  </MainContainer>
)
export default CoppyWalletId
