import SettingAcknowledgement from 'views/dashboard/MyPlan/SettingAcknowledgement'

const SettingsAcknowledgement = () => {
  return (
    <div>
      <SettingAcknowledgement
        next={function (): void {
          throw new Error('Function not implemented.')
        }}
      />
    </div>
  )
}

export default SettingsAcknowledgement
