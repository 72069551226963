import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'antd'
import Modal from 'components/Modal'
import AddBenifiduciaryMobile from 'views/dashboard/MyPlan/AddBeniFiduciaryMobile'
import RightLogo from 'assets/images/RightMobile-Logo.png'
import RedPluse from 'assets/images/red-pluse.png'
import More from 'assets/svg/More'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import { TempBeneficiaryDataContext } from 'context/tempBeneficiary'
import { LoaderContext } from 'context/loader'

import {
  MainContainer,
  Container,
  AssignBenificiare,
  LeftSection,
  DeletImage,
  BenificiareText,
  TotalPersentage,
  RightSection,
  ButtonContainer,
  TextArea,
  AddMore,
  WrappSection,
  TextSectionRed,
} from 'styles/views/dashboard/MyPlan/MobileDisplayBenificiaires'

export interface IModalProps {
  next: () => void
  text?: string
}
export interface IData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

const ContainerModal = ({ item, id, setTotalPercentage }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const { tempBen, setTempBen } = useContext(TempBeneficiaryDataContext)

  const deleteBeneficiary = async () => {
    setTotalPercentage((prev: any) => prev - parseInt(item.amount))
    const newBeneficiary = [...tempBen]
    newBeneficiary.splice(id, 1)
    setTempBen(newBeneficiary)
  }

  return (
    <div>
      <Container>
        <AssignBenificiare>
          <DeletImage src={RedPluse} onClick={deleteBeneficiary} />
          <LeftSection>
            <BenificiareText>
              {item.appointAs != '' ? item.appointAs : id == 0 ? 'fiduciary' : 'beneficiary'}
            </BenificiareText>
            <TotalPersentage>{item.amount > 0 ? item.amount : 0}%</TotalPersentage>
          </LeftSection>
          <RightSection src={RightLogo} onClick={() => setIsOpen(true)} />
        </AssignBenificiare>
      </Container>
      <Modal isOpen={isOpen}>
        <AddBenifiduciaryMobile
          updateable="true"
          showModal={(value: boolean) => setIsOpen(value)}
          setTotalPercentage={setTotalPercentage}
          indexx={id}
          item={item}
        />
      </Modal>
    </div>
  )
}

const MobileDisplayBenificiaires: React.FC<IModalProps> = ({ next }) => {
  const { tempBen, setTempBen } = useContext(TempBeneficiaryDataContext)
  const { setLoader } = useContext(LoaderContext)
  const [sumPercentage, setTotalPercentage] = useState(0)
  const [fidAllocated, setFidAllocated] = useState(0)
  const {
    data: beneficiaryStatus,
    isLoading: linkLoading,
    refetch,
  } = useGet('get-status', '/get/getStatus', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    refetch()
  }, [beneficiaryStatus])

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  useEffect(() => {
    if (beneficiaryStatus?.users?.length) {
      setTempBen(beneficiaryStatus?.users)
      const fedLength = beneficiaryStatus?.users?.filter((item: any) => item.appointAs == 'Fiduciary')
      setFidAllocated(fedLength.length)
    }

    let temp = 0
    beneficiaryStatus?.users?.forEach((item: any) => {
      if (typeof item?.amount == 'string') {
        temp += parseInt(item?.amount) || 0
      } else {
        temp += item?.amount || 0
      }
    })
    setTotalPercentage(temp)
  }, [beneficiaryStatus])

  useEffect(() => {
    const fedLength = tempBen?.filter((item: any) => item.appointAs === 'Fiduciary')
    setFidAllocated(fedLength.length)
  }, [tempBen])

  const addBeneficiary = () => {
    setTempBen((prev: any) => [...prev, { displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '0' }])
  }

  const { mutateAsync: updatAsync, isLoading } = usePost()

  const sendBeneficiaryData = async () => {
    try {
      const updateData = await updatAsync({
        url: 'check/changeStatus',
        payload: tempBen,
        token: true,
      })
      next()
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <MainContainer>
      {tempBen?.map((item: any, id: any) => (
        <ContainerModal
          item={item}
          key={id}
          tempBen={tempBen}
          setTempBen={setTempBen}
          id={id}
          setTotalPercentage={setTotalPercentage}
        />
      ))}
      <ButtonContainer>
        <TextArea>
          {sumPercentage != 100 && (
            <AddMore onClick={addBeneficiary}>
              <More />
              <span>Add More</span>
            </AddMore>
          )}
          <AddMore onClick={addBeneficiary}>
            <span>{sumPercentage} %</span>
          </AddMore>
        </TextArea>
        <WrappSection>
          <Button
            disabled={sumPercentage != 100 || fidAllocated != 1}
            type="primary"
            htmlType="submit"
            className="next-button"
            onClick={sendBeneficiaryData}
          >
            Next
          </Button>
        </WrappSection>
        <div>{sumPercentage > 100 && <TextSectionRed>{'(Total cannot be more than 100%)'}</TextSectionRed>}</div>
        <div>{sumPercentage < 100 && <TextSectionRed>{'(Total cannot be less than 100%)'}</TextSectionRed>}</div>
        <div>{fidAllocated != 1 && <TextSectionRed>{'(Please select one fiduciary )'}</TextSectionRed>}</div>
      </ButtonContainer>
    </MainContainer>
  )
}

export default MobileDisplayBenificiaires
