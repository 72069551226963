import React, { useState } from 'react'
import Heading from 'components/Heading'
import SubHeading from 'components/SubHeading'
import SubscriberRoadMap from 'views/landing/SubscriberRoadMap'
import BenificiaryRoadMap from 'views/landing/BeneficiariesRoadMap'
import {
  Container,
  Wrapper,
  RightSubSection,
  LeftSection,
  HeadOption,
  RightSection,
} from 'styles/views/landing/HowWeWork'
import { AnimatePresence, motion } from 'framer-motion'
interface IWorkProps {
  work: React.RefObject<HTMLDivElement>
}

const HowWeWork: React.FC<IWorkProps> = ({ work }: IWorkProps) => {
  const [subscriberRoadmap, setSubscriberRoadmap] = useState(true)
  const [beneficiaryRoadmap, setBenificiaryRoadmap] = useState(false)

  const [animation, setAnimation] = useState([true, false])

  return (
    <Container ref={work}>
      <Wrapper>
        <LeftSection>
          <Heading label="How It Works" />
          <SubHeading label="Create your Cryptoplan on our smart contract engine with only a few clicks" />
        </LeftSection>
        <RightSection>
          <RightSubSection>
            <HeadOption
              active={subscriberRoadmap}
              onClick={() => {
                // subscriberController.start({ opacity: 1, translateX: '0px' })
                setSubscriberRoadmap(true)
                setBenificiaryRoadmap(false)
                setAnimation([true, false])
              }}
            >
              For Subscriber
            </HeadOption>

            <HeadOption
              active={beneficiaryRoadmap}
              onClick={() => {
                setSubscriberRoadmap(false)
                setBenificiaryRoadmap(true)
                // controls.start({ translateX: '0vw' })
                setAnimation([false, true])
              }}
            >
              For Beneficiary
            </HeadOption>
          </RightSubSection>
        </RightSection>
      </Wrapper>
      {/* <AnimatePresence> */}
      {/* {subscriberRoadmap ? ( */}
      <AnimatePresence>
        {animation[0] && (
          <motion.div
            initial={{ translateX: '-5vw' }}
            animate={{ translateX: '0vw', opacity: 1 }}
            exit={{ translateX: '-5vw', opacity: 0 }}
          >
            <SubscriberRoadMap />
          </motion.div>
        )}
      </AnimatePresence>

      {/* ) : ( */}
      <AnimatePresence>
        {animation[1] && (
          <motion.div
            initial={{ translateX: '5vw' }}
            animate={{ translateX: '0vw', opacity: 1 }}
            exit={{ translateX: '5vw', opacity: 0 }}
          >
            <BenificiaryRoadMap />
          </motion.div>
        )}
      </AnimatePresence>
      {/* )} */}
      {/* </AnimatePresence> */}
    </Container>
  )
}

export default HowWeWork
