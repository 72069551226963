import React, { useState, useRef, useEffect } from 'react'

import { useAnimation, useMotionValue, useSpring } from 'framer-motion'

import Header from 'views/landing/Header'

import SignIn from 'views/landing/SignIn'

import PlanFuture from 'views/landing/PlanFuture'

import KeyFeature from 'views/landing/KeyFeature'

import ConnectWallet from 'views/landing/ConnectWallet'

import HowWeWork from 'views/landing/HowWeWork'

import Beneficiaries from 'views/landing/Beneficiaries'

import FAQ from 'views/landing/FAQ'

import ContactUs from 'views/landing/ContactUs'

import Footer from 'views/landing/Footer'

import Modal from 'components/Modal'

import OptionWallet from 'components/Cards/OptionWallet'

import CreateWallet from 'components/Cards/CreateWallet'

import MobileHowWeWork from 'views/landing/MobileHowWeWork'
import { Container, FirstContainer, LastContainer, RoadMap, MobileMap } from 'styles/pages/landing'
import { useLocation } from 'react-router-dom'

let lastScrollTop = 0

const LandingPage: React.FC = () => {
  const home = useRef<HTMLDivElement>(null)

  const about = useRef<HTMLDivElement>(null)

  const work = useRef<HTMLDivElement>(null)

  const workRef = useRef<HTMLDivElement>(null)

  const faq = useRef<HTMLDivElement>(null)

  const contact = useRef<HTMLDivElement>(null)

  const [signInModalIsOpen, setSignInModal] = useState(false)

  const [optionWalletModalIsOpen, setOptionWalletModal] = useState(false)
  const [createWalletModalIsOpen, setCreateWalletModal] = useState(false)

  function setModal(value: boolean, type: string) {
    if (type == 'signIn') {
      setSignInModal(value)
    } else if (type == 'option') {
      setOptionWalletModal(value)
    } else if (type == 'wallet') {
      setCreateWalletModal(value)
    }
  }

  function scrollView(refName: string) {
    switch (refName) {
      case 'Home':
        return home?.current?.scrollIntoView({ behavior: 'smooth' })

      case 'Features':
        return about?.current?.scrollIntoView({ behavior: 'smooth' })

      case 'How It Works':
        return work?.current?.scrollIntoView({ behavior: 'smooth' })

      case 'How it Works':
        return workRef?.current?.scrollIntoView({ behavior: 'smooth' })

      case 'FAQs':
        return faq?.current?.scrollIntoView({ behavior: 'smooth' })

      case 'Contact Us':
        return contact?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const directionControls = useAnimation()

  const headerCollapse = useMotionValue(0)

  const desktopY = useSpring(useMotionValue(0), {
    velocity: 0,

    mass: 0.1,
  })

  const initiateScrollEvents = (scrollDir: number) => {
    if (scrollDir > 0 && headerCollapse?.get() !== 0) {
      headerCollapse.set(0)

      directionControls.start({ y: 0 })
    } else if (scrollDir < 0 && headerCollapse?.get() !== -68) {
      headerCollapse.set(-68)

      directionControls.start({ y: -160 })
    }
  }

  const scrollEvents = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    const dir = lastScrollTop - scrollTop

    if (dir !== 0) initiateScrollEvents(dir)

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

    if (y.get() !== -document.documentElement.scrollTop) y.set(-document.documentElement.scrollTop)
  }

  const navigate = useLocation()
  useEffect(() => {
    if (navigate.pathname === '/') {
      window.document.body.style.backgroundColor = ''
    }

    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    if (('onpagehide' in window) as any) {
      window.addEventListener('pagehide', scrollToTop)
    } else {
      window.addEventListener('unload', scrollToTop)
    }

    return () => {
      if (('onpagehide' in window) as any) {
        window.removeEventListener('pagehide', scrollToTop)
      } else {
        window.removeEventListener('unload', scrollToTop)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollEvents)

    return () => {
      window.removeEventListener('scroll', scrollEvents)
    }
  })

  const y = desktopY

  return (
    <>
      <Container>
        <FirstContainer>
          <Header
            showModal={(value: boolean, type: string) => setModal(value, type)}
            scrollView={(refName: string) => scrollView(refName)}
            controls={directionControls}
          />

          <PlanFuture homeRef={home} />
        </FirstContainer>

        <KeyFeature aboutRef={about} />

        <ConnectWallet />

        <Beneficiaries />

        <RoadMap>
          <HowWeWork work={work} />
        </RoadMap>

        <MobileMap>
          <MobileHowWeWork workRef={workRef} />
        </MobileMap>

        <FAQ faqRef={faq} />

        <LastContainer>
          <ContactUs contactRef={contact} />
        </LastContainer>

        <Footer scrollView={(refName: string) => scrollView(refName)} />

        <Modal isOpen={signInModalIsOpen}>
          <SignIn showModal={(value: boolean, type: string) => setModal(value, type)} />
        </Modal>

        <Modal isOpen={optionWalletModalIsOpen}>
          <OptionWallet showModal={(value: boolean, type: string) => setModal(value, type)} />
        </Modal>

        <Modal isOpen={createWalletModalIsOpen}>
          <CreateWallet showModal={(value: boolean, type: string) => setModal(value, type)} />
        </Modal>
      </Container>
    </>
  )
}

export default LandingPage
