import { useState, useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoaderContext } from 'context/loader'
import SignupWallet from 'assets/images/signup-wallet.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import EmailEdit from 'assets/images/edit1.png'
import usePatch from 'hooks/usePatch'
import useGet from 'hooks/useGet'
import NumberFormat from 'react-number-format'
import More from 'assets/svg/More'
import AddModal from 'components/AddEmailModal'
import Modal from 'components/Modal'
import LoginConnectWallet from 'views/landing/LoginConnectWallet'
import TextInput from 'components/FormElements/TextInput'
import { UserContext } from 'context/user'
import { userSchema } from 'utils/schema/updateBenficiary'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  MainContainer,
  HeadText,
  TextArea,
  ButtonBox,
  LabelText,
  EmailText,
  WalletWrapper,
  WalletLogoSection,
  SubDetailContainer,
  Form,
  LabelContainer,
  AddEmail,
  SubWalletWrapper,
  Wallet,
  WalletAddress,
  BottomButtonBox,
  LeftButtonBox,
  RightButtonBox,
  DiscardButton,
  SaveButton,
  Error,
  SecondaryImg,
  SecndEmailLabel,
  EmailLabelContainer,
  CopySection,
  LabelTextPhone,
  PhoneContainer,
} from 'styles/views/dashboard/Settings/MyDeatils'

interface IData {
  userName: string
  phoneNo?: string
}

const MyDetails = () => {
  const notify = () => toast('Save successfully!')
  const { user, fetchUser } = useContext(UserContext)

  const [isOpen, setisOpen] = useState(false)
  const [isOpenWallet, setIsOpenWallet] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const walletInfo = localStorage?.getItem('walletconnect') || ''

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IData>({
    resolver: yupResolver(userSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  useEffect(() => {
    fetchUser()
  }, [])

  const { mutateAsync: updatAsync, isLoading } = usePatch()

  const formData = async (values: IData) => {
    try {
      const updateData = await updatAsync({
        url: 'fiduciaryUpdate/fiduciaryUpdate',
        payload: {
          displayName: values.userName,
          phoneNumber: values.phoneNo !== '' ? values?.phoneNo : '',
        },
        token: true,
      })
      fetchUser()
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const { data: getlinkup, refetch: linkupFetch } = useGet('get-linkup', 'getlinkup/getuserLink', false, {
    enabled: true,
    token: true,
  })

  const fiduciaryName =
    getlinkup?.linkupFiducary && getlinkup.linkupFiducary.length > 0 ? getlinkup.linkupFiducary[0].displayName : ''

  const fiduciaryNumber =
    getlinkup?.linkupFiducary && getlinkup.linkupFiducary.length > 0 ? getlinkup.linkupFiducary[0].phoneNumber : ''

  useEffect(() => {
    setValue('userName', fiduciaryName)
    setValue('phoneNo', fiduciaryNumber)
  }, [getlinkup])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    linkupFetch()
  }, [getlinkup])

  return (
    <MainContainer>
      <div>
        <HeadText>Primary Wallet</HeadText>
        <TextArea></TextArea>
        <ButtonBox>
          <WalletWrapper>
            {walletInfo ? (
              <WalletLogoSection src={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet} />
            ) : (
              <WalletLogoSection src={SignupWallet} alt="MetaMask" />
            )}
            <SubWalletWrapper>
              {walletInfo ? <Wallet>{JSON.parse(walletInfo)?.peerMeta?.name}</Wallet> : <Wallet>Metamask</Wallet>}
              <WalletAddress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                user?.userWalletId?.length - 4,
              )}`}</WalletAddress>
            </SubWalletWrapper>
          </WalletWrapper>
        </ButtonBox>
      </div>
      <div>
        <HeadText>Profile</HeadText>
        <TextArea></TextArea>
      </div>
      <Form onSubmit={handleSubmit(formData)}>
        <EmailLabelContainer>
          <LabelText>Email ID </LabelText>
          <EmailText>{user?.emailId}</EmailText>
        </EmailLabelContainer>
        <SubDetailContainer>
          {user?.emailId2 ? (
            <SecndEmailLabel>
              <LabelText>Secondary Email ID</LabelText>
              <CopySection>
                {user?.emailId2}
                <SecondaryImg src={EmailEdit} onClick={() => setisOpen(true)} />
              </CopySection>
            </SecndEmailLabel>
          ) : (
            <LabelContainer>
              <LabelText>Secondary Email ID</LabelText>
              <AddEmail onClick={() => setisOpen(true)}>
                <More />
                Add New Email
              </AddEmail>
            </LabelContainer>
          )}
        </SubDetailContainer>
        <SubDetailContainer>
          <LabelContainer>
            <LabelText>Display Name</LabelText>
          </LabelContainer>
          <LabelContainer>
            <TextInput name="userName" className="text" placeholder="Enter Username" control={control} />
            <Error>{errors?.userName?.message}</Error>
          </LabelContainer>
        </SubDetailContainer>
        <SubDetailContainer>
          <LabelContainer>
            <LabelTextPhone>Phone Number (Optional)</LabelTextPhone>
          </LabelContainer>
          <PhoneContainer>
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  className="text11"
                  format="+1 (###) ###-####"
                  placeholder="Enter Phone Number"
                />
              )}
              control={control}
              name="phoneNo"
            />

            <Error>{errors?.phoneNo?.message}</Error>
          </PhoneContainer>
        </SubDetailContainer>
        <BottomButtonBox>
          <LeftButtonBox></LeftButtonBox>
          <RightButtonBox>
            <DiscardButton label={'Discard'} variant="contained" type="button" />
            <SaveButton label={'Save'} variant="contained" type="submit" onClick={notify}></SaveButton>
          </RightButtonBox>
        </BottomButtonBox>
      </Form>
      <Modal isOpen={isOpen}>
        <AddModal
          user={user}
          showModal={(value: boolean) => setisOpen(value)}
          showAnotherModal={(value: boolean) => value}
        />
      </Modal>
      <Modal isOpen={isOpenWallet}>
        <LoginConnectWallet showModal={(value: boolean) => setIsOpenWallet(value)} />
      </Modal>
      <ToastContainer />
    </MainContainer>
  )
}

export default MyDetails
