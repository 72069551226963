import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import Button from 'components/Button'
import 'react-multi-email/style.css'
import { ReactMultiEmail } from 'react-multi-email'
import CopyLink from 'assets/images/copylink-logo.png'
import Instagram from 'assets/images/instagram-logo.png'
import Whatsapp from 'assets/images/whatsapp-logo.png'
import Telegram from 'assets/images/telegram-logo.png'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import Close from 'assets/images/close-gray.png'
import { LoaderContext } from 'context/loader'
import Linkedin from 'assets/svg/Linkedin'
import Twitter from 'assets/svg/Twitter'
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import {
  MainContainer,
  Wrapper,
  TopSection,
  TextSection,
  CloseImage,
  SocialPletforms,
  SocialIcons,
  InviteEmail,
  InviteTo,
  ButtonSection,
  InstaText,
  CopyText,
  CopyLinkSection,
  Instalink,
  InstagramLogo,
  CoppyLinkLogo,
  EmailTag,
  WhatsappLogo,
  TelegramLogo,
  TelegramText,
  TextWhatsapp,
  TextLinkedin,
} from 'styles/components/Cards/InviteFriends'
import CommingSoon from 'components/Cards/CommingSoon'
export interface IModalProps {
  showModal: (value: boolean, type?: string) => void
}
interface IData {
  emailId: string[]
}

const InviteFriends: React.FC<IModalProps> = ({ showModal }) => {
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }
  const { setValue, register, handleSubmit } = useForm<IData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { mutateAsync, isError, error, isLoading } = usePost()
  const { setLoader } = useContext(LoaderContext)

  const onSubmit = async (values: IData) => {
    try {
      await mutateAsync({
        url: '/invite/link',
        payload: values,
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  const { data: userLink, isLoading: linkLoading } = useGet('get-link', '/social/link', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  const [copied, setCopied] = useState(false)

  const copy = () => {
    const el = document.createElement('input')
    el.value = window.location.href
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setCopied(true)
  }

  return (
    <MainContainer>
      <Wrapper>
        <TopSection>
          <TextSection>Invite Friends</TextSection>
          <CloseImage
            src={Close}
            onClick={() => {
              showModal(false, 'signIn')
            }}
          />
        </TopSection>
        <SocialPletforms>Invite on Social platforms</SocialPletforms>

        <SocialIcons>
          {/* <LinkedinShareButton url={userLink?.link}> */}
          <div
            onClick={() => {
              setInviteModal(true)
            }}
          >
            <Linkedin />
            <TextLinkedin>LinkedIn</TextLinkedin>
          </div>
          {/* </LinkedinShareButton> */}

          <TwitterShareButton url={userLink?.link}>
            <Twitter />
            <div>Twitter</div>
          </TwitterShareButton>

          <WhatsappShareButton url={userLink?.link}>
            <WhatsappLogo src={Whatsapp} />
            <TextWhatsapp>Whatsapp</TextWhatsapp>
          </WhatsappShareButton>

          <TelegramShareButton url={userLink?.link}>
            <TelegramLogo src={Telegram} />
            <TelegramText>Telegram</TelegramText>
          </TelegramShareButton>

          <Instalink>
            {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"> */}
            <InstagramLogo
              src={Instagram}
              onClick={() => {
                setInviteModal(true)
              }}
            />
            {/* </a> */}

            <InstaText>Instagram</InstaText>
          </Instalink>

          <CopyLinkSection>
            <CoppyLinkLogo
              src={CopyLink}
              onClick={() => {
                navigator.clipboard.writeText(` ${userLink?.link}`)
                copy()
              }}
            />
            <CopyText> {!copied ? 'Copy link' : 'Copied!'}</CopyText>
          </CopyLinkSection>
        </SocialIcons>
        <InviteEmail>Invite on Email </InviteEmail>

        <InviteTo></InviteTo>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ReactMultiEmail
              className="multi_select"
              placeholder="Input your Email Address"
              {...register('emailId')}
              onChange={(emails: string[]) => {
                setValue('emailId', emails)
              }}
              getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                return (
                  <EmailTag key={index}>
                    {email}
                    <span onClick={() => removeEmail(index)}>×</span>
                  </EmailTag>
                )
              }}
            />
          </div>

          <ButtonSection>
            <Button label="Cancel " variant="outline" type="button" onClick={() => showModal(false)} />
            <Button label="Invite " variant="contained" type="submit" />
          </ButtonSection>
        </form>
      </Wrapper>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} error={error} success={false} />
      </Modal>
      <Modal isOpen={isCreateInviteOpen}>
        <CommingSoon showModal={(value: boolean) => setInviteModal(value)} />
      </Modal>
    </MainContainer>
  )
}

export default InviteFriends
