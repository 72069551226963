import React from 'react'

interface IBeneficiaryDataContext {
  beneficiariesData: any
  setBeneficiariesData: any
}

const defaultState = {
  beneficiariesData: [],
  setBeneficiariesData: () => null,
}

const BeneficiaryDataContext = React.createContext<IBeneficiaryDataContext>(defaultState)

export { BeneficiaryDataContext }
