import React from 'react'
import PropTypes from 'prop-types'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'

import LoaderPaypal from 'components/PaypalComponent/LoaderPaypal'

const PaypalButton = ({ onApprove }: any) => {
  const [{ isResolved }] = usePayPalScriptReducer()

  if (!isResolved) return <LoaderPaypal />

  return <PayPalButtons onApprove={onApprove} />
}

PaypalButton.propTypes = {
  createOrder: PropTypes.func,
  createSubscription: PropTypes.func,
  onApprove: PropTypes.func.isRequired,
}

export default PaypalButton
