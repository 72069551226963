import React from 'react'
import KeyFeature from 'assets/images/Front2.png'
import { useNavigate } from 'react-router-dom'
import {
  Wrapper,
  Container,
  Head,
  Body,
  Dashboardpic,
  HeadText,
  Planfuture,
  HeadingSection,
  ButtonContainer,
  GetStarted,
  MobileDashboardpic,
  VEdioContainer,
} from 'styles/views/landing/PlanFuture'

interface IHomeProps {
  homeRef: React.RefObject<HTMLDivElement>
}

export const PlanFuture: React.FC<IHomeProps> = ({ homeRef }: IHomeProps) => {
  const navigate = useNavigate()

  return (
    <Container ref={homeRef}>
      <Wrapper>
        <Head>
          <HeadingSection>
            <Planfuture>
              Easy. Secure.
              <br />
              Crypto Estate Planning.
            </Planfuture>
            <HeadText>
              Cryptoplan automates and protects digital assets, resolving the drawbacks of traditional estate planning.
            </HeadText>
            <ButtonContainer>
              <GetStarted
                label="Get Started"
                variant="contained"
                onClick={() => {
                  process.env.REACT_APP_COMING_SOON === 'false' ? navigate('/timer') : navigate('/')
                }}
              />
            </ButtonContainer>
          </HeadingSection>
        </Head>
        <Body>
          <Dashboardpic src={KeyFeature} />
          <VEdioContainer>
            <video className="reactPlayer" loop muted playsInline width="100%" height="100%" controls autoPlay>
              <source src="video1.mp4" type="video/mp4" />
            </video>
          </VEdioContainer>
          <MobileDashboardpic src={KeyFeature} />
        </Body>
      </Wrapper>
    </Container>
  )
}

export default PlanFuture
