import { useState, useEffect, useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import 'tippy.js/dist/tippy.css'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { UpdataDataContext } from 'context/updateBeneficiary'
import usePost from 'hooks/usePost'
import { UserContext } from 'context/user'
import Button from 'components/Button'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import CopyImage from 'assets/images/copy-image.png'
import ConfirmImage from 'assets/images/confirm-tick.png'
import CrossLogo from 'assets/images/grayx-logo.png'
import {
  MainContainer,
  Container,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  ContractDetails,
  Text,
  Para,
  Copyimages,
  ConfirmedImage,
  GreenCheck,
} from 'styles/components/ContractCreatedModal'
import useGet from 'hooks/useGet'

interface IModalProps {
  showModal: (value: boolean) => void
  hideAnotherModal: () => void
  contractDetails?: string
}

const CongratsModal: React.FC<IModalProps> = ({ showModal, contractDetails }: IModalProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const { updateBeneficiariesData } = useContext(UpdataDataContext)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const [isOpen, setIsOpen] = useState(false)
  const [errorModal, seterrorModal] = useState<string | ''>('')
  const beneficiaryData = beneficiariesData?.data?.beneficary

  const { mutateAsync } = usePost()
  const { user } = useContext(UserContext)

  const { data: beneficiaryStatus, refetch } = useGet('get-status', '/get/getStatus', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    refetch()
  }, [])

  const updateBeneficiaryData = window.innerWidth <= 768 ? beneficiaryStatus?.users : updateBeneficiariesData

  const mailData = beneficiariesData?.data?.paymentTransition ? updateBeneficiaryData : beneficiaryData

  const notifyCollectionRole = async () => {
    const payload = mailData.map((item: any) => {
      return {
        email: item?.emailId,
        message: `${user?.userName} has assigned you as ${item?.appointAs} ${item?.userWalletId}`,
        userWalletId: item?.userWalletId,
        username: item?.displayName,
        granterWalletIds: user?.userWalletId,
        role: item?.appointAs,
      }
    })
    try {
      const resp = await mutateAsync({ url: 'notifyCollection/role', payload: payload, token: true })
      if (resp.success) {
        window.location.reload()
      }
    } catch (error: any) {
      seterrorModal(error.response.data.message)
      setIsOpen(true)
      return { error: errorModal }
    }
  }
  const handleCopy = () => {
    setIsCopied(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (isCopied) setIsCopied(false)
    }, 10000)
  }, [isCopied])

  return (
    <>
      <MainContainer>
        <Wrapper>
          <Container>
            <img
              src={CrossLogo}
              alt="Close"
              onClick={() => {
                showModal(false)
              }}
            />
          </Container>
          {location.pathname === '/settings' ? (
            <TextSection>
              <>
                <Defination>You have successfully deleted your cryptoplan.</Defination>
                <HeadingSection>Your contract has been deleted!</HeadingSection>
              </>
              <ContractDetails>
                <Text>Contract ID </Text>
                <Para>{contractDetails}</Para>
                <ConfirmedImage src={ConfirmImage} alt="Confirmed" />
                <CopyToClipboard text={String(contractDetails)} onCopy={handleCopy}>
                  <div data-tip data-for="from">
                    <ReactTooltip id="from" place="top" effect="solid">
                      {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                    </ReactTooltip>
                    <img
                      src={CopyImage}
                      alt="Copy"
                      onClick={() => {
                        setIsCopied(true)
                      }}
                    />
                  </div>
                </CopyToClipboard>
              </ContractDetails>
              <ButtonSection>
                <Button label="Okay" variant="contained" type="button" onClick={notifyCollectionRole} />
              </ButtonSection>
            </TextSection>
          ) : (
            <TextSection>
              {beneficiariesData?.data?.paymentTransition ? (
                <>
                  <Defination>You have successfully updated your cryptoplan.</Defination>
                  <HeadingSection>Your contract has been updated!</HeadingSection>
                </>
              ) : (
                <>
                  <Defination>You have successfully created your cryptoplan.</Defination>
                  <HeadingSection>Your contract has been created!</HeadingSection>
                </>
              )}
              <ContractDetails>
                <Text>Contract ID </Text>
                <Para>{contractDetails}</Para>
                <Copyimages>
                  <GreenCheck src={ConfirmImage} alt="Confirmed" />
                  <CopyToClipboard text={String(contractDetails)} onCopy={handleCopy}>
                    <div data-tip data-for="from">
                      <ReactTooltip id="from" place="top" effect="solid">
                        {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                      </ReactTooltip>
                      <img
                        src={CopyImage}
                        alt="Copy"
                        onClick={() => {
                          setIsCopied(true)
                        }}
                      />
                    </div>
                  </CopyToClipboard>
                </Copyimages>
              </ContractDetails>
              <ButtonSection>
                <Button label="Okay" variant="contained" type="button" onClick={notifyCollectionRole} />
              </ButtonSection>
            </TextSection>
          )}
        </Wrapper>
        <Modal isOpen={isOpen}>
          <ErrorModal showModal={(value: boolean) => setIsOpen(value)} errorModal={errorModal} success={false} />
        </Modal>
      </MainContainer>
    </>
  )
}

export default CongratsModal
