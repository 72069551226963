import styled from 'styled-components'
export const WalletTableInfo = styled.div`
  width: 100%;

  background: #ffffff;
  border: 1px dashed #e7e7e7;
  box-sizing: border-box;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 23px 14px 30px;
  cursor: pointer;
  gap: 35px;
  @media (max-width: 1500px) {
    max-width: 500px;
    gap: 13px;
  }
`
export const NameSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const NameInfo = styled.div`
  font-family: 'OutfitMedium';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0a0c17;
`
export const WalletInfo = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const InfoWaleet = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0a0c17;
`
export const PercentageSection = styled.div`
  border: 2px solid rgba(0, 159, 66, 0.22);
  box-sizing: border-box;
  border-radius: 50px;
  max-height: 34px;
  color: #009f42;
  padding: 4px 10px;
`
