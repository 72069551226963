const primary = {
  dark: '#000',
  light: '#fff',
}
const secondary = {
  main: '#4473F5',
  light: '#1BB691',
}
const info = {
  dark: '#000',
  light: '#fff',
  extra_light: '#e5e5e5',
  gray: '#969696',
}
export const theme = {
  primary,
  secondary,
  info,
}
