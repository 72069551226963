import { ReactElement, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import { LoaderContext } from 'context/loader'
import { UniqueTokensContext } from 'context/uniqueTokens'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { BeneficiaryContext } from 'context/beneficiaries'
import { UserContext } from 'context/user'
import usePatch from 'hooks/usePatch'
import usePost from 'hooks/usePost'
import moment from 'moment'
import InfoModal from 'components/InfoModal'
import MobileCreatePlan from 'views/dashboard/MyPlan/MobileCreatePlan'
import InviteFriends from 'components/Cards/InviteFriends'
import BenificiariesCards from 'components/Cards/DashboardBeneficiaries'
import Modal from 'components/Modal'
import UpdateCrypto from 'views/dashboard/Beneficiaries/UpdateCrypto'
import useGet from 'hooks/useGet'
import MyCryptoPlan from 'views/dashboard/MyPlan/CreatePlan'
import MoneySection from 'assets/images/money-image.png'
import WalletSection from 'assets/images/dashboard-wallet.png'
import VectorArrow from 'assets/images/vector-arrow.png'
import Watermark from 'assets/images/watermark-image.png'
import DefaultImage from 'assets/images/crypto-currencies.png'
import { ethers } from 'ethers'
import {
  Invite,
  Create,
  Container,
  Wrapper,
  LeftSection,
  LeftTopSection,
  LeftBottomSection,
  WelcomeSection,
  WelcomeText,
  Box,
  ButtonBox,
  WelcomeBox,
  DashbordContentSection,
  ContentWrapper,
  ContainerSecond,
  BalanceSection,
  BalanceDetails,
  WalletDetails,
  BalanceImageSection,
  WalletImageSection,
  Details,
  MoneyDetails,
  MainBalanceSection,
  TableContainer,
  ViewAllBox,
  ViewAllText,
  ViewImage,
  RightBenificiaries,
  BenificiariesTextSection,
  RightContainer,
  BenificiariescardSection,
  SectionRight,
  ViewAllBoxs,
  NoBeneficiaries,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  BeneficiarieImage,
  MoneyDetails2,
  MoneyDetails3,
  NoneBeneficiaries,
  BalanceContainer,
  DesktopView,
  MobileView,
  PortfolioTextSection,
} from 'styles/views/dashboard/MyPlan'
import InfoModal2 from 'components/InfoModal2'
import Wallets from './Wallets'

export interface IBeneficiaryData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

interface ITokenData {
  name: string
  balance: string
  token_address: string
}

interface ITokenPriceData {
  usdPrice: number
}

export const MyPlan = (): ReactElement => {
  const { setLoader } = useContext(LoaderContext)
  const { beneficiary } = useContext(BeneficiaryContext)
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)
  const [screenView, setScreenView] = useState('laptop')
  const { user } = useContext(UserContext)
  const { beneficiariesData, setBeneficiariesData } = useContext(BeneficiaryDataContext)
  const { setUniqueTokensContext } = useContext(UniqueTokensContext)
  const [isCreatePlanOpen, setIsCreatePlanOpen] = useState(false)
  const [isCreateMobilePlanOpen, setIsCreatemobilePlanOpen] = useState(false)
  const [talDifference, setTalDifference] = useState<any>([])
  const [totalAssetValue, setTotalAssetValue] = useState<any>()
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const [, setIsOpen] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const [isInfo2Open, setIsInfo2Open] = useState(false)
  const wrapped_native_currency_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''
  const navigate = useNavigate()
  const Web3Api = useMoralisWeb3Api()
  const [uniqueTokens, setUniqueTokens] = useState<ITokenData[]>([])
  const [walletNativeBalance, setWalletNativeBalance] = useState<string>('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState<ITokenPriceData[]>([])
  const [assetTokenPrice, setAssetTokenPrice] = useState<ITokenPriceData[]>([])
  const [lastSyncedAt, setLastSyncedAt] = useState<string>('')
  const { isSuccess, isError, error } = usePost()
  const [updatedLength, setUpdatedLength] = useState<number>()
  const { data: updateData, isSuccess: updatSuccess, isError: updateIsError, error: updateError } = usePatch()
  const { Moralis } = useMoralis()
  const currentUser = Moralis.User.current() || undefined
  const linkedAddresses = user?.userWallet
  const isUserActive: any = currentUser?.attributes.isActive

  const {
    data: beneficiaryData,
    isLoading,
    refetch,
  } = useGet('get-beneficiary', 'getbeneficiary/getbeneficiary', false, {
    enabled: true,
    token: true,
  })
  setBeneficiariesData(beneficiaryData)

  const setPlanModal = (value: boolean) => {
    setIsCreatePlanOpen(value)
  }
  const setMobilePlanModal = (value: boolean) => {
    setIsCreatemobilePlanOpen(value)
  }
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }

  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }

  const fetchNativeBalance = async () => {
    let totalBalance = 0
    const element = user?.userWallet
    for (let i = 0; i < user?.userWallet.length; i++) {
      const { balance } = await Web3Api.account.getNativeBalance({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: element[i]?.walletId,
      })
      totalBalance = totalBalance + Number(balance)
    }

    setWalletNativeBalance(Moralis.Units.FromWei(totalBalance.toString(), 18))
  }

  const getWidth = () => {
    const selectedQueryData = window.document.querySelector('#root')
    const xAxisCoordinate = selectedQueryData?.getBoundingClientRect()
    if (xAxisCoordinate) xAxisCoordinate?.width <= 767 ? setScreenView('mobile') : setScreenView('laptop')
  }
  const fetchAssetPriceBalance = async () => {
    const tokens: any[] = []

    for (let iterator = 0; iterator < linkedAddresses.length; iterator++) {
      const tokenbalances: any[] = await Web3Api.account.getTokenBalances({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: linkedAddresses[iterator].walletId, //this holds internally linked address of MM
      })
      tokens.push(...tokenbalances)
      setUpdatedLength(Number(tokens.length))
    }

    const uniqueToken = [
      ...tokens
        .reduce((map, current) => {
          const { token_address } = current
          const grouped = map.get(token_address)
          if (!grouped) {
            map.set(token_address, { ...current })
          } else {
            map.set(token_address, {
              ...grouped,
              balance: (Number(grouped.balance) + Number(current.balance)).toLocaleString('fullwide', {
                useGrouping: false,
              }),
            })
          }

          return map
        }, new Map())
        .values(),
    ]

    setUniqueTokens(uniqueToken)

    //Get asset token price
    const getAssetsPrice: any = []
    let totalassetsvalue: any = 0

    for (let iterator = 0; iterator < uniqueToken.length; iterator++) {
      const assetPrice = await Web3Api.token.getTokenPrice({
        address: uniqueToken[iterator]?.token_address,
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      })
      const assetPriceUSD: any = assetPrice?.usdPrice || 0
      totalassetsvalue =
        totalassetsvalue + assetPriceUSD * Number(ethers.utils.formatEther(uniqueToken[iterator].balance))
      getAssetsPrice?.push(assetPriceUSD)
    }

    setTotalAssetValue(totalassetsvalue)

    if (getAssetsPrice != undefined) setAssetTokenPrice(getAssetsPrice)
  }

  const fetchTokenPrice = async () => {
    const price = await Web3Api.token.getTokenPrice({
      address: wrapped_native_currency_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    const nativePriceUSD: any = price.usdPrice
    setNativeTokenPrice(nativePriceUSD)
    const lastSync = new Date().toLocaleTimeString()
    setLastSyncedAt(lastSync)
  }

  const checkUser = async () => {
    if (isUserActive === false) {
      setIsInfo2Open(true)
    }
  }

  const { data: getAsset, refetch: getAssetRefetch } = useGet('get-Asset', 'asset/getAsset', false, {
    enabled: false,
    token: true,
  })

  let accountAssets: any = []
  getAsset?.user?.userWallet.forEach((element: any) => {
    accountAssets = [...accountAssets, ...element?.assests]
  })

  accountAssets = accountAssets.filter(
    (value: any, index: any, self: any[]) => index === self.findIndex((t) => t.yourAssets === value?.yourAssets),
  )

  const updatingTally = async () => {
    const assetArray: any = []
    const assetOfCurrentUser = getAsset?.user?.userWallet || ''
    let counter = 0
    for (let iterator = 0; iterator < assetOfCurrentUser.length; iterator++) {
      const counterStop = (assetOfCurrentUser[iterator]?.assests).length
      assetOfCurrentUser[iterator].assests.forEach(async (element: any) => {
        const currentAssetData = await changeInPrice(element.tokenAddress).catch(() => {
          new Error('Error in fetching token information')
        })

        assetArray.push(currentAssetData)
        counter = counter + 1

        if (currentAssetData != 'undefined' && counter === counterStop) {
          setTalDifference(assetArray)
        }
      })
    }
  }

  // Getting the Price Change in 24H
  async function changeInPrice(address: string) {
    //Getting block from date
    const date = await Web3Api.native.getDateToBlock({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      date: moment().subtract(1, 'days').toString(),
    })

    //Getting the price with particular Block Number
    const price24hAgo = await Web3Api.token.getTokenPrice({
      address: address,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toBlock: date.block,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    //Getting the current price
    const currentPrice = await Web3Api.token.getTokenPrice({
      address: address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    const netChangedData = (((currentPrice?.usdPrice - price24hAgo?.usdPrice) / price24hAgo?.usdPrice) * 100).toFixed(2)
    return netChangedData
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && user?.userWalletId) {
      fetchTokenPrice()
      const priceInterval = setInterval(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
          fetchTokenPrice()
          fetchAssetPriceBalance()
        }
      }, 15000)
      return () => clearInterval(priceInterval)
    }
  }, [user])

  useEffect(() => {
    fetchAssetPriceBalance()
  }, [])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (updatSuccess) {
      setIsOpen(true)
    }
  }, [updateData, updatSuccess])

  useEffect(() => {
    if (updateIsError) {
      setIsOpen(true)
    }
  }, [updateIsError, updateError])

  useEffect(() => {
    refetch()
  }, [beneficiary])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && getAsset) {
      updatingTally()
      const priceInterval = setInterval(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') updatingTally()
      }, 25000)
      return () => clearInterval(priceInterval)
    }
  }, [getAsset])

  useEffect(() => {
    getAssetRefetch()
  }, [])

  useEffect(() => {
    checkUser()
    getWidth()
  }, [])

  useEffect(() => {
    if (user?.userWalletId) fetchNativeBalance()
  }, [user])
  useEffect(() => {
    if (uniqueTokens) {
      setUniqueTokensContext(uniqueTokens)
      accountAssets.map((element: any) => {
        const newData = uniqueTokens.find((data) => data.name === element.yourAssets)
        element.newData = newData
      })
    }
  }, [uniqueTokens, accountAssets])

  const newShow: string[] = []

  accountAssets.forEach((item: any) => {
    item?.newData !== undefined &&
      newShow.push(
        item?.newData?.balance
          ? item?.newData?.decimals == 6
            ? Moralis.Units.FromWei(item?.newData?.balance, 6)
            : Moralis.Units.FromWei(item?.newData?.balance, 18)
          : 'Loading...',
      )
  })

  const show = newShow.some((item: string) => item === '0')

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <ContainerSecond>
            <LeftSection>
              <LeftTopSection>
                <Box>
                  <WelcomeBox>
                    <WelcomeSection>Welcome {user?.userName}!</WelcomeSection>
                    <WelcomeText>Let’s Create Your Cryptoplan.</WelcomeText>
                  </WelcomeBox>
                  {screenView == 'laptop' && (
                    <ButtonBox>
                      <Invite label="Invite Friends" variant="contained" onClick={() => setInviteModal(true)} />
                      {beneficiariesData?.data?.paymentTransition === true ? (
                        <Create
                          label="Update My Cryptoplan"
                          variant="contained"
                          onClick={() => setUpdatePlanModal(true)}
                        />
                      ) : (
                        <Create label="Create My Cryptoplan" variant="contained" onClick={() => setPlanModal(true)} />
                      )}
                    </ButtonBox>
                  )}
                  {screenView == 'mobile' && (
                    <ButtonBox>
                      <Invite label="Invite Friends" variant="contained" onClick={() => setInviteModal(true)} />
                      <Create
                        label={
                          beneficiariesData?.data?.paymentTransition === true
                            ? 'Update My Cryptoplan'
                            : 'Create My Cryptoplan'
                        }
                        variant="contained"
                        onClick={() => setMobilePlanModal(true)}
                      />
                    </ButtonBox>
                  )}
                </Box>
              </LeftTopSection>
              <LeftBottomSection>
                <DashbordContentSection>
                  <RightContainer>
                    <PortfolioTextSection>Portfolio</PortfolioTextSection>
                  </RightContainer>
                  <BalanceContainer>
                    <BalanceSection>
                      <BalanceDetails>
                        <BalanceImageSection src={MoneySection} />
                        <MainBalanceSection>
                          <Details>Assets worth(≈)</Details>
                          {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                            <MoneyDetails>
                              {isNaN(Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                                ? '↺'
                                : (Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                                    ?.toLocaleString()
                                    .substring(0, 9)}
                            </MoneyDetails>
                          ) : (
                            <MoneyDetails>
                              {isNaN(Number(walletNativeBalance))
                                ? '↺'
                                : Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
                            </MoneyDetails>
                          )}

                          <Details>🔁 {lastSyncedAt}</Details>
                        </MainBalanceSection>
                      </BalanceDetails>
                      <WalletDetails>
                        <WalletImageSection src={WalletSection} />
                        <MainBalanceSection>
                          <Details>Wallets Connected</Details>
                          <MoneyDetails>{linkedAddresses.length}</MoneyDetails>
                        </MainBalanceSection>{' '}
                        <MainBalanceSection></MainBalanceSection>{' '}
                      </WalletDetails>
                      <BalanceDetails></BalanceDetails>
                      <WalletDetails>
                        <MainBalanceSection>
                          <Details>Addresses Linked</Details>
                          {linkedAddresses?.length > 1 ? (
                            <MoneyDetails2>
                              {linkedAddresses
                                .map((data: any, index: number) => {
                                  return (
                                    <h4 key={index}>
                                      {data?.walletId.toString().substring(0, 10)}
                                      ..${data.walletId.toString()?.substring(user?.userWalletId?.length - 4)}
                                    </h4>
                                  )
                                })
                                .splice(1)}
                            </MoneyDetails2>
                          ) : (
                            <MoneyDetails3>{'No accounts are linked'}</MoneyDetails3>
                          )}
                        </MainBalanceSection>
                      </WalletDetails>
                    </BalanceSection>
                  </BalanceContainer>

                  <TableContainer>
                    <table>
                      <tr>
                        <th></th>
                        {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>Logo</th> : ''}
                        <th>Name</th>
                        <th>24 H</th>
                        <th>Price in $ (DEX)</th>
                        <th>Token Balance</th>
                        <th>Value in $ (≈)</th>
                      </tr>
                      {!show && accountAssets.length ? (
                        accountAssets.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td className="image"></td>
                              {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                                <td>
                                  <CryptoLogo
                                    className="minified"
                                    src={item?.newData?.logo === null ? DefaultImage : item?.newData?.logo}
                                  />
                                </td>
                              ) : (
                                ''
                              )}
                              <td>{item?.yourAssets}</td>
                              {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                                <td>
                                  <div>
                                    {talDifference.length == 0 || talDifference[index] === undefined
                                      ? '↺'
                                      : talDifference[index] + '%'}
                                  </div>
                                </td>
                              ) : (
                                <td>{'NA'}</td>
                              )}
                              {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                                <td>
                                  {Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4).length == 0 ||
                                  isNaN(Number(assetTokenPrice[index]))
                                    ? '↺'
                                    : Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4) || 'NA'}
                                </td>
                              ) : (
                                <td>{'NA'}</td>
                              )}

                              <td>
                                {updatedLength != 0
                                  ? item?.newData?.balance
                                    ? item?.newData?.decimals == 6
                                      ? Moralis.Units.FromWei(item?.newData?.balance, 6)
                                      : Moralis.Units.FromWei(item?.newData?.balance, 18)
                                    : 'Loading...'
                                  : '0'}
                              </td>
                              {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                                <td>
                                  {item?.newData?.balance
                                    ? (Number(assetTokenPrice[index]) * Number(item?.newData?.balance))
                                        ?.toLocaleString()
                                        .substring(0, 4).length == 0 ||
                                      isNaN(Number(assetTokenPrice[index]) * Number(item?.newData?.balance))
                                      ? '↺'
                                      : (Number(assetTokenPrice[index]) * Number(item?.newData?.balance))
                                          ?.toLocaleString()
                                          .substring(0, 4) || 'NA'
                                    : ''}
                                </td>
                              ) : (
                                <td>{'NA'}</td>
                              )}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className="center" colSpan={5}>
                            <NoBeneficiaries>
                              <BeneficiarieImage>
                                <CryptoLogo src={Watermark} />
                              </BeneficiarieImage>
                              <TextSextion>
                                <HeadingSection>No Assets Available</HeadingSection>
                              </TextSextion>
                            </NoBeneficiaries>
                          </td>
                        </tr>
                      )}
                    </table>
                    {!show && accountAssets.length ? (
                      <>
                        <DesktopView>
                          <ViewAllBox onClick={() => navigate('/portfolio')}>
                            <ViewAllText>View Detailed Portfolio</ViewAllText>
                            <ViewImage src={VectorArrow} />
                          </ViewAllBox>
                        </DesktopView>

                        <MobileView>
                          <ViewAllBox onClick={() => navigate('/mobileportfolio')}>
                            <ViewAllText>View Detailed Portfolio</ViewAllText>
                            <ViewImage src={VectorArrow} />
                          </ViewAllBox>
                        </MobileView>
                      </>
                    ) : (
                      ''
                    )}
                  </TableContainer>
                </DashbordContentSection>
              </LeftBottomSection>
            </LeftSection>
            <SectionRight>
              <Wallets />
              <RightBenificiaries>
                <RightContainer>
                  <BenificiariesTextSection>Beneficiaries</BenificiariesTextSection>
                </RightContainer>
                {beneficiariesData?.data?.beneficary.length && beneficiariesData?.data?.paymentTransition ? (
                  <BenificiariescardSection>
                    {beneficiariesData?.data?.beneficary?.slice(0, 4)?.map((item: any, index: number) => {
                      return (
                        <BenificiariesCards
                          key={index}
                          title={item?.displayName}
                          subtitle={`${item?.userWalletId?.substring(0, 5)}..${item?.userWalletId?.substring(
                            item?.userWalletId?.length - 4,
                          )}`}
                          subtext={item?.appointAs}
                          subheading={item?.amount}
                        />
                      )
                    })}
                    <>
                      <DesktopView>
                        <ViewAllBoxs
                          onClick={() => {
                            navigate('/beneficiaries')
                          }}
                        >
                          <ViewAllText>View Beneficiaries</ViewAllText>
                          <ViewImage src={VectorArrow} />
                        </ViewAllBoxs>
                      </DesktopView>
                      <MobileView>
                        <ViewAllBoxs
                          onClick={() => {
                            navigate('/mobilebeneficiaries')
                          }}
                        >
                          <ViewAllText>View Beneficiaries</ViewAllText>
                          <ViewImage src={VectorArrow} />
                        </ViewAllBoxs>
                      </MobileView>
                    </>
                  </BenificiariescardSection>
                ) : (
                  <NoneBeneficiaries>
                    <BeneficiarieImage>
                      <CryptoLogo src={Watermark} />
                    </BeneficiarieImage>
                    <TextSextion>
                      <HeadingSection>No Beneficiaries Added</HeadingSection>
                      <DefinitionSection></DefinitionSection>
                    </TextSextion>
                  </NoneBeneficiaries>
                )}
              </RightBenificiaries>
            </SectionRight>
          </ContainerSecond>
        </ContentWrapper>
      </Wrapper>
      <Modal isOpen={isCreatePlanOpen}>
        <MyCryptoPlan showModal={(value: boolean) => setPlanModal(value)} />
      </Modal>
      <Modal isOpen={isCreateMobilePlanOpen}>
        <MobileCreatePlan showModal={(value: boolean) => setMobilePlanModal(value)} />
      </Modal>
      <Modal isOpen={isCreateInviteOpen}>
        <InviteFriends showModal={(value: boolean) => setInviteModal(value)} />
      </Modal>
      <Modal isOpen={isInfoOpen}>
        <InfoModal showModal={(value: boolean) => setIsInfoOpen(value)} success={isSuccess} info={error} />
      </Modal>
      <Modal isOpen={isInfo2Open}>
        <InfoModal2 showModal={(value: boolean) => setIsInfo2Open(value)} success={isSuccess} info={error} />
      </Modal>
      <Modal isOpen={isUpdatePlanOpen}>
        <UpdateCrypto showModal={(value: boolean) => setUpdatePlanModal(value)} />
      </Modal>
    </Container>
  )
}

export default MyPlan
