import FiduciaryNavBar from 'components/FiduciaryNavBar'
import FiduciarySidebar from 'components/FiduciarySidebar'
import Watermark from 'assets/images/watermark-image.png'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import { useState, useEffect, useContext } from 'react'
import {
  Wrapper,
  MainContainer,
  BalanceSection,
  TableContainer,
  DashbordContentSection,
  LeftBottomSection,
  FilterSection,
  RightSectionFilter,
  Section,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  NoDataContainer,
} from 'styles/views/dashboard/FiduciaryCryptoAssets'
import { ethers } from 'ethers'
import { LoaderContext } from 'context/loader'

interface ITokenData {
  token_address: string
  name: string
  symbol: string
  logo?: string | undefined
  thumbnail?: string | undefined
  decimals: number
  balance: string
  tokenPrice?: string | number
}

const FiduciaryCryptoAssets = () => {
  const { setLoader } = useContext(LoaderContext)
  const [tokenData, settokenData] = useState<Array<ITokenData>>([])
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const currentUser = Moralis.User.current()?.attributes?.accounts[0] || undefined

  const getAllTokensInWallet = async () => {
    const tokenData: ITokenData[] = await Moralis.Web3API.account.getTokenBalances({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: currentUser || '',
    })
    if (tokenData.length === 0) {
      setLoader(false)
    }

    if (tokenData.length > 0) {
      for (let i = 0; i < tokenData.length; i++) {
        try {
          const tokenPrice = await Web3Api.token.getTokenPrice({
            address: tokenData[i].token_address,
            chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
          })
          tokenData[i].tokenPrice = tokenPrice?.usdPrice.toPrecision(2)
        } catch {
          tokenData[i].tokenPrice = 'null'
        }
      }

      setLoader(false)
      settokenData(tokenData)
    }
  }

  useEffect(() => {
    setLoader(true)
    getAllTokensInWallet()
  }, [])

  return (
    <Wrapper>
      <FiduciaryNavBar />
      <Section>
        <FiduciarySidebar />
        <MainContainer>
          <LeftBottomSection>
            <DashbordContentSection>
              <BalanceSection>My Crypto Assets </BalanceSection>
              <FilterSection>
                <RightSectionFilter></RightSectionFilter>
              </FilterSection>
              <TableContainer>
                <table id="table-4">
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Value</th>
                  </tr>
                  {tokenData.length ? (
                    tokenData.map((val: ITokenData, key: number) => {
                      return (
                        <tr key={key}>
                          <th>{val.name}</th>
                          {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>{val?.tokenPrice}</th> : <th>{'NA'}</th>}
                          <th>{ethers.utils.formatEther(val.balance)}</th>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className="center" colSpan={5}>
                        <NoDataContainer>
                          <NoBeneficiaries>
                            <BeneficiarieImage>
                              <CryptoLogo src={Watermark} />
                            </BeneficiarieImage>
                            <TextSextion>
                              <HeadingSection>No Assets Received</HeadingSection>
                              <DefinitionSection></DefinitionSection>
                            </TextSextion>
                          </NoBeneficiaries>
                        </NoDataContainer>
                      </td>
                    </tr>
                  )}
                </table>
              </TableContainer>
            </DashbordContentSection>
          </LeftBottomSection>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default FiduciaryCryptoAssets
