import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={15} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.381 7.737 7.756 2.112a1.242 1.242 0 0 0-.881-.362H2.5c-.688 0-1.25.563-1.25 1.25v4.375c0 .344.137.656.369.887l5.625 5.625c.225.226.537.363.881.363s.656-.137.881-.369l4.375-4.375c.232-.225.369-.537.369-.881s-.144-.662-.369-.888Zm-5.256 5.27L2.5 7.374V3h4.375v-.006L12.5 8.619l-4.375 4.387Z"
      fill="#1BB691"
    />
    <path d="M4.063 5.5a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z" fill="#1BB691" />
  </svg>
)

export default SvgComponent
