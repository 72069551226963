import styled from 'styled-components'
import Button from 'components/Button'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 517px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  padding: 20px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 94.133vw;
  }
`
export const LogoSection = styled.img`
  padding: 20px;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 42px;
  line-height: 44px;
  color: #2677e9;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 7.733vw;
  }
`
export const Defination = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  padding: 10px 35px 20px 35px;
`
export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-bottom: 26px;
  gap: 20px;
`

export const Cross = styled.img`
  height: 21px;
  width: 21px;
  cursor: pointer;
`
export const DeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  height: 117px;
  width: 117px;
  background: rgba(252, 82, 69, 0.12);
`
export const CancelButton = styled(Button)`
  max-width: 151px;
  width: 100%;
  background: #fff;
  color: #000;
  border: 1px solid #dfdfdf;
`
export const SaveButton = styled(Button)`
  max-width: 151px;
  width: 100%;
`
