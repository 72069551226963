import { ReactElement } from 'react'
import data from 'views/landing/ConnectWallet/data'
import VectorFeature from 'assets/images/vector-image.png'
import WalletCircal from 'assets/images/wallet-circalone.png'
import MobileKeyFeature from 'assets/images/wallet-mobilenew.png'

import {
  Wrapper,
  WalletPointsSections,
  PointFirst,
  VectorSection,
  RightSection,
  LeftSection,
  HeaderText,
  RightImageSection,
  HeadText,
  MobileDashboardpic,
  TextSection,
  ImageSectionblue,
} from 'styles/views/landing/ConnectWallet'

export const WalletSection = (): ReactElement => (
  <Wrapper>
    <LeftSection>
      <HeaderText>Connect Wallets</HeaderText>
      <HeadText></HeadText>
      <WalletPointsSections>
        {data.map((item, index) => {
          return (
            <PointFirst key={index}>
              <ImageSectionblue>
                <VectorSection src={VectorFeature} />
              </ImageSectionblue>
              <TextSection> {item.title}</TextSection>
            </PointFirst>
          )
        })}
      </WalletPointsSections>
    </LeftSection>
    <RightSection>
      <RightImageSection src={WalletCircal} />
      <MobileDashboardpic src={MobileKeyFeature} />
    </RightSection>
  </Wrapper>
)
export default WalletSection
