import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.376 15.73-.876-2.188-.876 2.187-2.249.2 1.708 1.551-.514 2.312 1.931-1.228 1.931 1.228-.514-2.312 1.708-1.55-2.249-.2ZM6.25 2.082h12.5v2.084H6.25V2.083ZM4.167 6.25h16.666v2.083H4.167V6.25Z"
      fill="#8C929B"
      className="sideIcon"
    />
    <path
      d="M20.833 12.5v8.333H4.167V12.5h16.666Zm0-2.083H4.167A2.083 2.083 0 0 0 2.083 12.5v8.333a2.083 2.083 0 0 0 2.084 2.084h16.666a2.083 2.083 0 0 0 2.084-2.084V12.5a2.083 2.083 0 0 0-2.084-2.083Z"
      fill="#8C929B"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
