import styled from 'styled-components'
interface ISelectedOption {
  selectedItem?: string
}
export const MainContainer = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    height: 301px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const Container = styled.div`
  width: 100%;
  min-height: 303px;
  align-items: center;
  @media (max-width: 767px) {
    min-height: 0vw !important;
  }
`

export const AssignBenificiare = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 35px 28px 23px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  @media (max-width: 767px) {
    gap: 2.667vw;
    padding: 9.333vw 7.467vw 6.133vw 5.333vw;
    align-items: center;
  }
`
export const LeftSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 16.667vw;
  }
`

export const DeletImage = styled.img``

export const BenificiareText = styled.div`
  font-family: 'OutfitRegular';
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  @media (max-width: 767px) {
    font-style: 4.267vw;
  }
`

export const TotalPersentage = styled.div`
  font-family: 'OutfitRegular';
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  color: #2677e9;
  @media (max-width: 767px) {
    font-style: 4.267vw;
  }
`

export const RightSection = styled.img`
  height: 18px;
  width: 10px;
  @media (max-width: 767px) {
    height: 4.8vw;
    width: 2.667vw;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const TextArea = styled.div`
  width: 100% !important;
  width: fit-content;
  display: flex;
  gap: 10px;
  width: max-content;
  padding: 30px 0 0 0;
  font-family: 'OutfitSemiBold';
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
  cursor: pointer;
  @media (max-width: 767px) {
    gap: 2.667vw;
    padding-top: 8vw;
    font-size: 3.733vw;
    line-height: 6.4px;
    display: flex;
    justify-content: space-between;
  }
`
export const AddMore = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 92px;
  gap: 10px;
  @media (max-width: 767px) {
    padding-left: 2.667vw;
    min-width: 24.533vw;
    gap: 2.667vw;
    width: auto;
  }
`
export const WrappSection = styled.div`
  width: 100%;
`
export const TextSectionRed = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  color: red;
`
