import { useState, useContext, useEffect, useRef } from 'react'
import { Checkbox, Slider } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import Table from 'components/Table'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import NickName from 'components/NickName'
import { LoaderContext } from 'context/loader'
import { UserContext } from 'context/user'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import FilterImage from 'assets/images/filter-image.png'
import Watermark from 'assets/images/watermark-image.png'
import resetImage from 'assets/images/reset-green.png'
import {
  FilterShortSection,
  TableWrapper,
  FilterSection,
  FilterLogo,
  FilterText,
  Filter,
  FilterWrapper,
  FilterLabel,
  FilterCheckText,
  RestFilter,
  ResetImage,
  TextReset,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  MainContainer,
  Container,
  PanddingImage,
  AcceptedContainer,
} from 'styles/components/BeneficiariesContent'
import PandingImage from 'assets/images/panding-image.png'
const CheckboxGroup = Checkbox.Group
const plainOptions = ['Pending', 'Fiduciary', 'Beneficiary']
const defaultCheckedList = ['', '']

const Beneficiaries = () => {
  const { setLoader } = useContext(LoaderContext)
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)
  const [sortType, setSortType] = useState(1)
  const [sliderValue, setSliderValue] = useState(30)
  const [shouldCallSlider, setShouldCallSlider] = useState(false)
  const [filterpayload, setFilterPayload] = useState(``)
  const [getTableData, setTableData] = useState<any[]>([])
  const [renderedData, setRenderedData] = useState<any[]>([])

  const wrapperRef = useRef<any>()
  const filterRef = useRef<any>()

  const onChange = (list: CheckboxValueType[]) => {
    setFilterPayload('')

    list.map((item: any) => {
      switch (item) {
        case 'Pending':
          setFilterPayload((prev) => (prev != '' ? prev + `&pending=false` : `pending=false`))
          break
        case 'Joined Fiduciary':
          setFilterPayload((prev) => (prev != '' ? prev + `&joined=true` : `joined=true`))
          break
        case 'Fiduciary':
          setFilterPayload((prev) => (prev != '' ? prev + `&fiduciary=Fiduciary` : `fiduciary=Fiduciary`))
          break
        case 'Beneficiary':
          setFilterPayload((prev) => (prev != '' ? prev + `&beneficiary=Beneficiary` : `beneficiary=Beneficiary`))
          break
        default:
          setFilterPayload(``)
          break
      }
    })
    setCheckedList(list)
  }

  const resetRadioState = () => {
    setFilterPayload(``)
    setCheckedList(defaultCheckedList)
  }

  const {
    data: filterData,
    refetch,
    isLoading: linkLoading,
  } = useGet('get-filtered-beneficiary', `getbeneficiary/getbeneficiary?${filterpayload}`, false, {
    enabled: false,
    token: true,
  })
  const data = filterData != undefined ? filterData?.data?.beneficary : beneficiariesData?.data?.beneficary

  const { mutateAsync: resendEmail, isLoading: loading } = usePost()

  const onResend = async (index: number) => {
    const selectedRow = beneficiariesData?.data?.beneficary[index]

    const payload = {
      email: selectedRow.emailId,
      message: `${user.userName} has assigned you as ${selectedRow?.appointAs} ${selectedRow.userWalletId}`,
      userWalletId: selectedRow?.userWalletId,
      username: selectedRow?.displayName,
      granterWalletIds: user?.userWalletId,
      role: selectedRow?.appointAs,
    }
    try {
      await resendEmail({
        url: '/resend/beneficary',
        payload,
        token: true,
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  const setTableFormatData = (data: any) => {
    const sortedFid = data.filter((item: any) => item.appointAs == 'Fiduciary')
    const sortedBen = data.filter((item: any) => item.appointAs == 'Beneficiary')
    const sortedData = [...sortedFid, ...sortedBen]
    const tableData = []
    for (let i = 0; i < sortedData?.length; i++) {
      const element = sortedData[i]
      tableData.push({
        key: i,
        nickName: <NickName displayName={element?.displayName} userWalletId={element?.userWalletId} />,
        appointerEmail: element?.emailId,
        appointedAs: element?.appointAs,
        totalAmount: `${element?.amount}%`,
        status: element?.isActive ? (
          <>
            <MainContainer>
              <AcceptedContainer>Accepted</AcceptedContainer>
            </MainContainer>
          </>
        ) : (
          <>
            <MainContainer>
              <Container>Pending</Container>
              <PanddingImage
                src={PandingImage}
                onClick={() => {
                  onResend(i)
                }}
              />
            </MainContainer>
          </>
        ),
      })
    }

    setTableData(tableData)
    setRenderedData(tableData)
  }

  const columns = [
    {
      title: 'NickName',
      dataIndex: 'nickName',
    },
    {
      title: 'Email ID',
      dataIndex: 'appointerEmail',
    },
    {
      title: 'Appointed As',
      dataIndex: 'appointedAs',
    },
    {
      title: 'Total Allocation',
      dataIndex: 'totalAmount',
      defaultSortOrder: 'false',
      sorter: (a: any, b: any) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ]

  const setAllocationValue = (e: number) => {
    setTimeout(() => {
      setFilterPayload((prev) => (prev != '' ? prev + `&allocation=${sliderValue}` : `allocation=${sliderValue}`))
      setShouldCallSlider(true)
    }, 3000)
    setSliderValue(e)
  }

  const onDataChange = () => {
    const test = [...getTableData]
    switch (sortType) {
      case 1:
        setSortType(2)
        test.sort((a, b) => (parseInt(a.totalAmount) > parseInt(b.totalAmount) ? 1 : -1))
        break
      case 2:
        setSortType(3)
        test.sort((a, b) => (parseInt(a.totalAmount) < parseInt(b.totalAmount) ? 1 : -1))
        break

      default:
        setSortType(1)
        break
    }

    setRenderedData(test)
  }

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (filterRef.current && !filterRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (shouldCallSlider) {
      refetch()
      setShouldCallSlider(false)
    }
  }, [shouldCallSlider])

  useEffect(() => {
    setTableFormatData(data)
  }, [])

  useEffect(() => {
    setTableFormatData(data)
  }, [data])

  useEffect(() => {
    if (loading) setLoader(true)
    else setLoader(false)
  }, [loading])

  useEffect(() => {
    refetch()
  }, [checkedList])

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  return (
    <div>
      {beneficiariesData?.data?.paymentTransition && beneficiariesData?.data?.beneficary?.length ? (
        <div>
          <FilterShortSection>
            <Filter> </Filter>
            <FilterSection onClick={() => setIsOpen(true)}>
              <FilterLogo src={FilterImage} />
              <FilterText>
                <div>Filter</div>
                {isOpen && (
                  <FilterWrapper ref={filterRef}>
                    <RestFilter onClick={resetRadioState}>
                      <ResetImage src={resetImage} />
                      <TextReset>Reset Filter</TextReset>
                    </RestFilter>
                    <FilterLabel>Status</FilterLabel>
                    <div>
                      <FilterCheckText>
                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                      </FilterCheckText>
                    </div>
                    <div>
                      <FilterLabel>Allocation</FilterLabel>
                      <Slider onChange={setAllocationValue} value={sliderValue} />
                    </div>
                  </FilterWrapper>
                )}
              </FilterText>
            </FilterSection>
          </FilterShortSection>
          <TableWrapper>
            <Table data={renderedData} columns={columns} onFilter={onDataChange} />
          </TableWrapper>
        </div>
      ) : (
        <div>
          <NoBeneficiaries>
            <BeneficiarieImage>
              <CryptoLogo src={Watermark} />
            </BeneficiarieImage>
            <TextSextion>
              <HeadingSection>No Beneficiaries Added</HeadingSection>
              <DefinitionSection></DefinitionSection>
            </TextSextion>
          </NoBeneficiaries>
        </div>
      )}
    </div>
  )
}

export default Beneficiaries
