import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import usePost from 'hooks/usePost'
import { yupResolver } from '@hookform/resolvers/yup'
import CrossLogo from 'assets/images/grayx-logo.png'
import usePatch from 'hooks/usePatch'
import { LoaderContext } from 'context/loader'
import { UserContext } from 'context/user'
import TextInput from 'components/FormElements/TextInput'
import { emailSchema } from 'utils/schema/updateBenficiary'
import {
  MainContainer,
  Wrapper,
  Container,
  HeadText,
  Cross,
  LabelText,
  DeatailsWrapper,
  Email,
  SaveButton,
  ButtonWrapper,
  Error,
  EmailTextSection,
} from 'styles/components/AddEmailModal'

interface UserInterface {
  emailId: string
  emailId2: string
  id: string
  userName: string
  userWalletId: string
}
interface IModalProps {
  showModal: (value: boolean) => void
  showAnotherModal: (value: boolean) => void
  user: UserInterface
}
interface IData {
  secondaryEmail?: string
}

const AddEmailModal: React.FC<IModalProps> = ({ showModal, showAnotherModal }: IModalProps) => {
  const { setLoader } = useContext(LoaderContext)
  const { user, setUser, fetchUser } = useContext(UserContext)

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IData>({
    resolver: yupResolver(emailSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })
  const { mutateAsync: updatAsync, isLoading } = usePatch()
  const { mutateAsync } = usePost()

  const update = async (values: IData) => {
    const payload = {
      secondaryEmail: values.secondaryEmail,
    }
    try {
      const updatedData = await updatAsync({
        url: '/user/update',
        payload: payload,
        token: true,
      })
      if (updatedData) {
        setUser((prev: any) => ({ ...prev, emailId2: values.secondaryEmail }))
      }

      fetchUser()
      showModal(false)
      showAnotherModal(true)
      return updatedData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const verify = async (values: IData) => {
    const payload = {
      email: values.secondaryEmail,
    }
    try {
      const { verifyE } = await mutateAsync({
        url: 'resend/mail',
        payload: payload,
        token: true,
      })

      return verifyE
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const formData = async (values: IData) => {
    verify(values)
    update(values)
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    setValue('secondaryEmail', user?.emailId2)
  }, [user])

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <>
      <MainContainer>
        <Wrapper>
          <Container>
            <HeadText>Add New Email</HeadText>
            <Cross
              src={CrossLogo}
              alt="Close"
              onClick={() => {
                showModal(false)
              }}
            />
          </Container>
          <DeatailsWrapper>
            <LabelText>Primary Email</LabelText>
            <Email>{user?.emailId}</Email>
          </DeatailsWrapper>
          <form onSubmit={handleSubmit(formData)}>
            <EmailTextSection>
              <LabelText>Enter your Secondary Email ID</LabelText>
              <TextInput name="secondaryEmail" className="textemail" placeholder="Email Address" control={control} />
              <Error>{errors?.secondaryEmail?.message}</Error>
            </EmailTextSection>
            <ButtonWrapper>
              <SaveButton label={'Save'} variant="contained" type="submit" />
            </ButtonWrapper>
          </form>
        </Wrapper>
      </MainContainer>
    </>
  )
}

export default AddEmailModal
