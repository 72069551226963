import React, { useState, useEffect, useContext } from 'react'
import StepperContainer from 'views/dashboard/MyPlan/Steper'
import HeaderLogo from 'assets/images/planhader-image.png'
import { IBeneficiaryData } from 'views/dashboard/MyPlan'
import { UserContext } from 'context/user'
import { ethers } from 'ethers'
import Modal from 'components/Modal'
import { useMoralis } from 'react-moralis'
import AccountChangeErrorClose from 'components/Modal/AccountChangeErrorClose'
import {
  Container,
  Wrapper,
  HeadingSection,
  LeftTextSection,
  LeftImageSection,
  DataFlowSection,
} from 'styles/views/dashboard/MyPlan/CreatePlan'

interface IModalProps {
  showModal: (value: boolean) => void
  benificiariesData?: IBeneficiaryData[]
}

let signerAddress: string = ''

const MyCryptoPlan: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [heading, setHeading] = useState('Create My Heading')
  const { user } = useContext(UserContext)
  const validSigner: string = user?.userWalletId
  const [accountChangeError, showAccountChangeError] = useState(false)
  const { account } = useMoralis()

  const checkAccountChange = async () => {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum)
    const signer = provider.getSigner()
    signerAddress = await signer.getAddress()
    showAccountChangeError(!!(signerAddress && validSigner !== signerAddress.toLocaleLowerCase()))
  }

  useEffect(() => {
    checkAccountChange()
  }, [account])

  return (
    <Container>
      <Wrapper>
        <HeadingSection>
          <LeftTextSection>{heading}</LeftTextSection>
          <LeftImageSection src={HeaderLogo} onClick={() => showModal(false)} />
        </HeadingSection>
        <DataFlowSection>
          <StepperContainer showModal={showModal} setHeading={setHeading} />
        </DataFlowSection>
      </Wrapper>
      <Modal isOpen={accountChangeError}>
        <AccountChangeErrorClose
          showModal={() => {
            showModal(false)
            showAccountChangeError(false)
          }}
          success={false}
          errorModal={`Switch to registered account ${validSigner?.substring(0, 10)}..${validSigner.substring(
            validSigner?.length - 4,
          )}`}
        />
      </Modal>
    </Container>
  )
}

export default MyCryptoPlan
