import styled from 'styled-components'
import Button from 'components/Button'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 729px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1080px) {
    height: 450px;
  }
  @media (max-width: 1440px) {
    height: 450px;
  }
`
export const Wrapper = styled.div`
  padding: 30px 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 571px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  gap: 30px;
  margin: auto;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`
export const HeadText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 35px;
`
export const Cross = styled.img`
  height: 21px;
  width: 21px;
  cursor: pointer;
`
export const DeatailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const InputSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0px 5px 1px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    font-family: 'OutfitLight';
    font-size: 18px;
    line-height: 29px;
    min-width: 519px;
    text-align: left;
    margin-left: 17px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #000;
  }
  @media (max-width: 1280px) {
    padding: 0;
  }
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 6px 4px;
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  .sellectone {
    width: 100%;
    border: none;
  }
`
export const LabelText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
`
export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px 0px;
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  outline: none;
`
export const EmailInput = styled(Input)`
  background: #f8f8f8;
  padding: 10px;
  color: #2677e9;
  opacity: 0.6;
  border: none;
`

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-left: 0;
  min-width: 242px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`
export const SaveButton = styled(Button)`
  max-width: 150px;
  width: 100%;
`
export const Percentage = styled.div`
  font-family: 'OutfitLight';
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const PercentageContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const NumberInput = styled.input`
  width: 100%;
  max-width: 50px;
  border: none;
  padding: 0;
  text-align: center;
`
export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const InputSubmitWrapper = styled.div`
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
  .text {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`
export const InputExpirationDate = styled.div`
  width: 100%;
  max-width: 180px;
  .date {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding-top: 8px;
  }
`
export const InputCVVWrapper = styled.div`
  width: 100%;
  max-width: 180px;
  .cvv {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding-top: 8px;
  }
`
export const CvvContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
