import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
`
export const Wrapper = styled.div`
  width: 100%;
  max-width: 461px;
  background: #08193a;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  @media (max-width: 1366px) {
    max-width: 500px;
  }
`

export const Error = styled.div`
  color: red;
  font-size: 12px;
`
export const PrimaryWalletSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 80px;
`

export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 40px;
  color: #a4adcc;
  max-height: 500px;
  overflow-y: scroll;
  text-align: justify;
  padding: 0 30px 30px 65px;
  background: #08193a;
  border-radius: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const TopSection = styled.div`
  font-family: 'OutfitBold';
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  /* text-align: justify; */
  padding: 0 20px 5px 5px;
  background: #08193a;
`
export const CancelSection = styled.img`
  cursor: pointer;
  height: 27px;
`
export const TopRowSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .LogoFaq {
    margin-bottom: 10px;
  }
`

export const OptionSection = styled.div`
  padding: 22px 46px 13px 46px;
  @media (max-width: 1350px) {
    padding: 10px 46px 10px 46px;
  }
  @media (max-width: 1366px) {
    padding: 10px 46px 10px 46px;
  }
`
export const MoreOptionSection = styled.div`
  border: 1px solid #cbd1d8;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  background: #4473f5;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`
export const TextOption = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`
export const TextDropDown = styled.img`
  margin: 5px;
  position: relative;
  cursor: pointer;
`
export const SignUpLastSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`
export const MemberSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0a0c17;
  cursor: pointer;
`
export const SignInContainer = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #4473f5;
  cursor: pointer;
`

export const ImageSection = styled.div`
  display: flex;
  margin: 10px 10px;
`
export const CrossSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 0;
`
export const CardSection = styled.div``

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  .text11 {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 36px 20px 36px;
  .SubmitButton {
    background: #4473f5;
    height: 56px;
    margin: 15px 11px 15px 0;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
  }
`
