import styled from 'styled-components'
import { theme } from 'theme'

export const SubTitle = styled.div`
  font-family: 'OutfitRegular';
  font-size: 20px;
  line-height: 29px;
  color: ${theme.info.extra_light};
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-size: 4.267vw;
    line-height: 5.867vw;
    color: #ffffff;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 77.8vw;
    text-align: left;
  }
`
