import { SVGProps } from 'react'

const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.5 12.75h9M2 6.25l4 3.5m0 0 4-3.5m-4 3.5v-8.5"
      stroke="#2677E9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Download
