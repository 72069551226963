import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const LogoSection = styled.img`
  padding: 20px;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 46px;
  line-height: 58px;
  padding: 20px 86px 19px 86px;
`
export const Defination = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  padding: 10px 35px 20px 35px;
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: 250px;
  align-items: center;
  padding-bottom: 26px;
  justify-content: flex-end;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  padding: 10px;
  .one {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 92.8vw;
  }
`
export const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
`
export const LeftText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 30px;
`
export const CancelSection = styled.img`
  cursor: pointer;
  height: 21px;
`
export const UserDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 10px 10px 0 10px;
`
export const USername = styled.div`
  font-family: 'OutfitMedium';
  font-size: 15px;
`
export const WalletAdress = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  color: #2677e9;
  @media (max-width: 767px) {
    font-size: 3.467vw;
  }
`
export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 4px 2px;
    ::placeholder {
      color: #737b7d;
    }
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .text1 {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    transform: rotate(-0.12deg);
  }
  .textone {
    width: 100%;
    height: 155px;
  }
`
export const LabelText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 24px;
  color: #0a0c17;
  padding-top: 10px;
`

export const InputContainer = styled.div`
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: center;
`
export const TextArea = styled.textarea`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.201228);
  border-radius: 4px;
  width: 100%;
  max-width: 700px;
  height: 144px;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  outline: none;
  padding: 15px 20px;
  ::placeholder {
    color: #dfdfde;
    font-size: 14px;
  }
`
export const InputWrapper = styled.div`
  border-bottom: 1px solid #dfdfdf;
`
