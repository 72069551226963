interface IData {
  title: string
}

const data: IData[] = [
  {
    title: 'Home',
  },
  {
    title: 'Features',
  },
  {
    title: 'How It Works',
  },
  {
    title: 'FAQs',
  },
  {
    title: 'Contact Us',
  },
  {
    title: 'Our Solution',
  },
]

export default data
