import styled from 'styled-components'
import Button from 'components/Button'

export const MainContainer = styled.div`
  padding: 0px 30px;
  @media (max-width: 767px) {
    padding: 0px 5px;
  }
`
export const HeadText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 80px;
  @media (max-width: 767px) {
    line-height: normal;
  }
`
export const TextArea = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const NotifyCards = styled.div`
  @media (max-width: 767px) {
    height: 83.733vw;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const NotifyCard = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  gap: 97px;
  padding: 30px 0px;
  @media (max-width: 767px) {
    width: 100%;
    gap: 3.2vw;
    display: flex;
    flex-direction: column;
    padding: 4.533vw 0vw;
  }
  @media only screen and (max-width: 900px) {
    gap: 47px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 254px;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 19px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 74.667vw;
  }
`
export const CheckButtons = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 767px) {
    gap: 1.333vw;
  }
`
export const CheckText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
`
export const SubHeading = styled.div`
  font-family: 'OutfitMedium';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0a0c17;
`
export const SubText = styled.div`
  font-family: 'OutfitThin';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #737b7d;
  max-width: 225px;
  width: 100%;
`
export const BottomButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 35px;
  @media (max-width: 767px) {
    padding: 0.667vw 0;
  }
`
export const LeftButtonBox = styled.div`
  width: 100%;
`
export const RightButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`
export const ResetButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  color: #1bb691;
  border: 1px solid #1bb691;
  @media (max-width: 767px) {
    max-width: 24.533vw;
  }
`
export const DiscardButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #000;
`
export const SaveButton = styled(Button)`
  max-width: 150px;
`
