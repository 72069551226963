import styled from 'styled-components'
import Button from 'components/Button'
import Backgroundimg from 'assets/images/invoice-bck.png'

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  max-width: 1011px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 27px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
  }
`
export const Container = styled.div`
  background-image: url(${Backgroundimg});
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1011px;
  margin: 0 auto;
  height: 630px;
  padding: 20px 34px;
  @media (max-width: 1366px) {
    overflow-y: scroll;
    height: 600px;
    padding: 15px 34px;
  }
  @media (max-width: 1280px) {
    overflow-y: scroll;
    height: 47.667vw;
    padding: 15px 34px;
  }
  @media (max-width: 767px) {
    overflow-y: scroll;
    height: 400px;
    padding: 15px 34px;
  }
`

export const DownloadWrapper = styled.div`
  position: relative;
  > svg {
    display: none;
    position: absolute;
    left: 16px;
    top: 18px;
    :hover {
      opacity: 0.6;
    }
  }
`

export const Wrapper = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.3px;
`
export const HeadText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
  color: #0a0c17;
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`
export const WrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0;
  align-items: center;
`
export const TransWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  @media (max-width: 767px) {
    gap: 5px;
  }
  @media (max-width: 1280px) {
    gap: 11px;
  }

  @media (max-width: 1366px) {
    margin-top: 5px;
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const TransRow = styled.div`
  display: flex;
  align-items: center;
`
export const SubTransRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
export const Label = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  width: 133px;
  text-align: left;
`
export const BlueText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #2677e9;
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const TransRowWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 100%;
  border-top: 1px solid #dedede;
  gap: 98px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
export const RightTransContainer = styled.div``
export const LastTransContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const SubContainer = styled.div`
  display: flex;
  align-items: center;
`
export const DownloadButton = styled(Button)`
  background: #fff;
  color: #2677e9;
  border: 1px solid #2677e9;
  width: 120px;
  @media (max-width: 767px) {
    width: 16.933vw;
    height: 9.067vw;
    font-size: 2.667vw;
  }
`
export const CancelButton = styled(Button)`
  background: #fff;
  color: #000;
  border: 1px solid #dedede;
  width: 120px;
`
export const DoneButton = styled(Button)`
  background: #2677e9;
  color: #fff;
  width: 120px;
`
export const TransDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1366px) {
    gap: 2px;
  }
`
export const SubTransDetails = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 5px;
`
export const SubTransId = styled.div`
  font-family: 'OutfitMedium';
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #2677e9;
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const SubTransIdMobile = styled.div`
  font-family: 'OutfitMedium';
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #e92626;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
export const TransactionLable = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const TransText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`

export const TransContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding-top: 5px;
`
export const GreyText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
  text-align: left;
`
export const BlockDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const BlockContainer = styled.div`
  background: #ebebeb;
  width: 200px;
  padding: 5px 10px;
`
export const BlockText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #989898;
`
export const SuccessText = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 14px;
  line-height: 22px;
  color: #009f42;
  background: rgba(0, 159, 66, 0.13);
  border-radius: 45px;
  padding: 8px 10px;
`
export const FromContainer = styled.div`
  display: flex;
  gap: 5px;
  border-right: 1px solid #dedede;
  padding-right: 15px;
`
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 767px) {
    align-items: center;
  }
`
export const TransMidContainer = styled.div`
  border-top: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 20px;
  @media (max-width: 1366px) {
    padding-top: 0;
    gap: 20px;
  }
`
export const DownlodContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border: 1px solid rgba(67, 67, 67, 0.2);
  border-radius: 16px;
  width: 100%;
  max-width: 150px;
  padding: 10px 12px 10px 12px;
`
export const DownlodLogo = styled.img``

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TransactionHash = styled.div`
  display: flex;
  padding-top: 6px !important;
  @media (max-width: 1280px) {
    padding-top: 6px !important;
  }

  @media (max-width: 767px) {
    display: flex;
    gap: 10px;
  }
`

export const BlueTransactionText = styled.div`
  display: none;
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #e92626;
  @media (max-width: 767px) {
    display: block;
  }
`
