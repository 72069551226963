import React from 'react'

interface INotifyData {
  notifyData: any
  setNotifyData: any
}

const defaultState = {
  notifyData: false,
  setNotifyData: () => null,
}

const NotifyDataContext = React.createContext<INotifyData>(defaultState)

export { NotifyDataContext }
