import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/user'
import UserProfile from 'components/UserProfile'
import BurgerSidebar from 'components/BurgerSidebar'
import Logo from 'assets/images/logo-black.png'
import AccountLogo from 'assets/images/account-icon.png'
import DropDown from 'assets/images/black-dropdown.png'
import MobileBurger from 'assets/svg/BlackBurger'
import { DashboardRoute } from 'constants/routes'
import { Drawer } from 'antd'

import {
  HeaderWrapper,
  LogoSection,
  ButtonContainers,
  ButtonSection,
  ButtonContainer,
  ImageSection,
  SelectOption,
  RightImage,
  NameText,
  ViewSelectOption,
  GrantorAdress,
  GrantorSection,
  BurgerLogo,
} from 'styles/components/NavBar'

const NavBar = () => {
  const navigate = useNavigate()
  const wrapperRef = useRef<any>()
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOutside = useCallback((event: { target: any }) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <HeaderWrapper>
      <LogoSection src={Logo} onClick={() => navigate(DashboardRoute.path)} />
      <BurgerLogo>
        <MobileBurger onClick={() => showDrawer()} />
      </BurgerLogo>
      <ButtonSection>
        <div>
          <ButtonContainers>
            <ViewSelectOption>
              <GrantorSection>
                <NameText>Grantor’s View</NameText>
                <GrantorAdress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                  user?.userWalletId?.length - 4,
                )}`}</GrantorAdress>
              </GrantorSection>
            </ViewSelectOption>
          </ButtonContainers>
        </div>

        <ButtonContainer onClick={() => setIsOpen(!isOpen)} ref={wrapperRef}>
          <SelectOption>
            <ImageSection src={AccountLogo} />
            <NameText>{user?.userName}</NameText>

            <RightImage src={DropDown} />
          </SelectOption>
          {isOpen && (
            <div>
              <UserProfile />
            </div>
          )}
        </ButtonContainer>
      </ButtonSection>
      <Drawer
        placement="left"
        onClose={onClose}
        visible={open}
        width="52vw"
        closable={false}
        bodyStyle={{ padding: '0px', overflowY: 'hidden' }}
      >
        <BurgerSidebar />
      </Drawer>
    </HeaderWrapper>
  )
}

export default NavBar
