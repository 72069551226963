import React from 'react'

interface IBeneficiaryDataContext {
  tempBen: any
  setTempBen: any
}

const defaultState = {
  tempBen: [{ displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: 0 }],
  setTempBen: () => null,
}

const TempBeneficiaryDataContext = React.createContext<IBeneficiaryDataContext>(defaultState)

export { TempBeneficiaryDataContext }
