const TOKEN_KEY = '_auth'

export const login = (data: string) => {
  localStorage.setItem(TOKEN_KEY, data)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE')
  localStorage.removeItem('username')
  localStorage.removeItem('email')
  localStorage.removeItem('setRegister')
  localStorage.removeItem('grantor')
  localStorage.removeItem('walletconnect')
  localStorage.removeItem('userWalletId')
  localStorage.removeItem('signRole')
  localStorage.removeItem('shards')
}

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true
  }

  return false
}
