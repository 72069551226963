import styled from 'styled-components'
export const MainContainer = styled.div`
  display: grid;
  justify-content: center;
`
export const Container = styled.div`
  width: 100%;
  min-height: 350px;
  max-width: 640px;
  background: #fff;
  border-radius: 34px;
  padding: 20px;
  @media (max-width: 767px) {
    max-width: 90.667vw;
  }
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding: 5px 405px 20px 39px;
  @media (max-width: 767px) {
    padding: 2.667vw;
  }
`
export const PaidSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 31px 22px 37px;
`
export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const RightSection = styled.img``
export const TopLeftText = styled.div`
  font-family: 'OutfitBold';
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-align: inherit;
`
export const BottamLeftText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const ProPlan = styled.div`
  display: flex;
  border-bottom: Outfit;
  justify-content: space-between;
  padding: 0 32px 25px 34px;
  border-bottom: 1px solid #dfdfdf;
`
export const PlanText = styled.div`
  font-family: 'OutfitBold';
  font-size: 22px;
  line-height: 28px;
`
export const WalletValue = styled.div`
  display: flex;
`
export const WalletText = styled.div`
  font-family: 'OutfitBold';
  font-size: 22px;
  line-height: 28px;
`
export const WalletUser = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  color: #737b7d;
`
export const TexInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 29px 7px 39px;
`
export const TexSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TaxText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
`
export const AmmountText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  line-height: 24px;
  color: #737b7d;
`
export const TotalAmount = styled.div`
  font-family: 'OutfitRegular';
  font-size: 22px;
  line-height: 24px;
  color: #2677e9;
`
export const Chooseplan = styled.div`
  width: 100%;
`
export const TopText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 24px;
  line-height: 80px;
  align-items: center;
  @media (max-width: 767px) {
    line-height: 9.333vw;
  }
`
export const OptionText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
  padding-bottom: 15px;
`
export const ButtonContainer = styled.div`
  padding: 30px 10px 10px 10px;
  display: flex;
  gap: 5px;
  justify-content: center;
  gap: 20px;
  .sc-gsnTZi {
    max-width: 140px;
  }
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  gap: 20px;
`
