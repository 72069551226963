import { useEffect, useState } from 'react'
import { BrowserRouter, Routes as ReactRoutes, Route, useNavigate, useLocation } from 'react-router-dom'
import routes from 'constants/routes'
import Spinner from 'components/Loader'
import { isLogin } from 'utils'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import {
  WelcomeWalletRoute,
  FiduciaryMyPlanRoute,
  BeneficarydashboardRoute,
  DashboardRoute,
  WelcomeRoute,
} from 'constants/routes'

const ProtectedRoute = ({ component: Component, restricted }: any) => {
  const navigate = useNavigate()
  const role = localStorage.getItem('signRole')
  const { pathname } = useLocation()
  const [user, setUser] = useState<any>()
  const { refetch } = useGet('user-details', 'users/details', false, {
    token: true,
    cacheTime: 15,
    onSuccess: (responseData: any) => setTimeout(() => setUser(responseData.userData), 500),
  })
  useEffect(() => {
    if (!user && restricted && isLogin()) {
      refetch()
    }

    if (user && !restricted) setUser(null)
  }, [user, restricted, pathname])

  useEffect(() => {
    if (isLogin() && restricted) {
      if (!user?.emailId && !user?.userName) {
        navigate(WelcomeRoute.path)
        if (localStorage.getItem('_welcomeFlag')) navigate(WelcomeWalletRoute.path)
      } else if (pathname === '/welcome') {
        if (localStorage.getItem('_welcomeFlag')) navigate(WelcomeWalletRoute.path)
        else if (role === 'Fiduciary') navigate(FiduciaryMyPlanRoute.path)
        else if (role === 'Beneficiary') navigate(BeneficarydashboardRoute.path)
        else navigate(DashboardRoute.path)
      }
    }

    if (isLogin() && !restricted) {
      if (localStorage.getItem('_welcomeFlag')) navigate(WelcomeWalletRoute.path)
      else navigate(DashboardRoute.path)
    } else if (!isLogin() && restricted) navigate('/')
  }, [user, pathname, isLogin])

  const render = () => (
    <UserContext.Provider value={{ user, setUser, fetchUser: refetch }}>
      <Component />
    </UserContext.Provider>
  )
  return <>{user || !isLogin() ? render() : <Spinner />}</>
}

const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        {routes.map((route, index) => {
          const { component: Component, path, restricted } = route
          return (
            <Route
              key={index}
              path={path}
              element={<ProtectedRoute restricted={restricted} component={Component} />}
            ></Route>
          )
        })}
      </ReactRoutes>
    </BrowserRouter>
  )
}

export default Routes
