import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { notification } from 'antd'
import Heading from 'components/Heading'
import SubHeading from 'components/SubHeading'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import usePost from 'hooks/usePost'
import { LoaderContext } from 'context/loader'
import {
  Container,
  Wrapper,
  ContactusWrapper,
  ContactusInfoSection,
  Form,
  InputContainer,
  RightButton,
  Error,
  TextAreaContainer,
  TextSection,
} from 'styles/views/landing/ContactUs'

interface IContactProps {
  contactRef: React.RefObject<HTMLDivElement>
}
interface IData {
  emailId: string
  description: string
}

const ContactUsSection: React.FC<IContactProps> = ({ contactRef }: IContactProps) => {
  const [api, contextHolder] = notification.useNotification()
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync, isError, error, isLoading } = usePost()

  const schema = yup.object().shape({
    emailId: yup
      .string()
      .required('Email is required')
      .matches(/^\S/, 'First character cannot be Space ')
      .email('Please enter a valid Email')
      .max(255),
    description: yup.string().required('Description is Required'),
  })

  const openNotification = (placement: any) => {
    api.info({
      message: `Email sent successfully`,
      placement,
      duration: 2.5,
    })
  }

  const {
    control,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm<IData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values: IData) => {
    try {
      await mutateAsync({
        url: 'contact/user',
        payload: values,
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    } finally {
      openNotification('bottomRight')
    }
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ emailId: '', description: '' })
    }
  }, [formState, reset])

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <Container ref={contactRef}>
      <Wrapper>
        <ContactusWrapper>
          <Heading label="Contact Us" />
          <SubHeading label={''} />
        </ContactusWrapper>
        <ContactusInfoSection>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextSection>
              <InputContainer
                name="emailId"
                className="text"
                placeholder="Email"
                control={control}
                autoComplete="off"
              />
              <Error>{errors?.emailId?.message}</Error>
            </TextSection>
            <TextSection>
              <TextAreaContainer
                name="description"
                className="text"
                placeholder="Your Message"
                control={control}
                autoComplete="off"
              />
              <Error>{errors?.description?.message}</Error>
            </TextSection>
            {contextHolder}
            <RightButton label="Submit" variant="contained" />
          </Form>
        </ContactusInfoSection>
      </Wrapper>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} error={error} success={false} />
      </Modal>
    </Container>
  )
}

export default ContactUsSection
