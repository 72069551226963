import { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router'
import { columns } from 'views/dashboard/Subscricptions/data'
import EarnedTag from 'assets/images/earnedtag-image.png'
import InvitedFriends from 'assets/images/invited-image.png'
import JoinedFriends from 'assets/images/joined-image.png'
import InviteFriends from 'components/Cards/InviteFriends'
import useGet from 'hooks/useGet'
import SubscricptionsCard from 'components/Cards/SubscricptionsCard'
import PaymentMethod from 'components/Cards/PaymentMethod'
import NavBar from 'components/NavBar'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Tag from 'assets/svg/Tag'

import {
  MainContainer,
  MainWrapper,
  PaymentText,
  SubscricptionsSection,
  USDButton,
  TopText,
  BottomText,
  CardSection,
  SubscricptionsText,
  TextSection,
  Promotions,
  SectionContainer,
  SectionFirst,
  LeftSection,
  RightSection,
  SecndSection,
  TextTopSection,
  ButtonSection,
  PaymentSection,
  PaymentLeftText,
  Invite,
  PromotionsButton,
  MainContainerBox,
  SidebarWrapper,
  RightSectionText,
  TextLeftSection,
  BottomSection,
  PaymentContainer,
  PromotionContainer,
  ButtonLabled,
} from 'styles/views/dashboard/Subscricptions'
import PaymentWalletModal from 'components/PaymentModal'

interface IData {
  id: number
  image: string
  title: string
  subtitle: string
}

export const MobileSubscricptions = (): ReactElement => {
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }

  const { data: subscribe } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })

  const lastElement = subscribe?.subscriptionData?.subscription?.length
    ? subscribe?.subscriptionData?.subscription[subscribe?.subscriptionData?.subscription?.length - 1]
    : {}

  const { data: inviteData } = useGet('get-invite', '/getDetail/inviteData', false, {
    enabled: true,
    token: true,
  })

  let count: number = 0
  for (let i = 0; i < inviteData?.inviteDetails?.length; i++) {
    const element = inviteData?.inviteDetails[i]

    for (let i = 0; i < element?.refralTable?.length; i++) {
      const active = element?.refralTable[i]
      if (active?.isActive === true) {
        count += 1
      }
    }
  }

  const data: IData[] = [
    {
      id: 0,
      image: EarnedTag,
      title: '0',
      subtitle: 'Free Earned Months',
    },
    {
      id: 1,
      image: InvitedFriends,
      title: inviteData?.inviteDetails?.length ? inviteData?.inviteDetails?.length : '0',
      subtitle: 'Invited Friends',
    },
    {
      id: 2,
      image: JoinedFriends,
      title: count,
      subtitle: 'Joined Friends',
    },
  ]

  const dateSplit = new Date(lastElement?.Datetime)?.toLocaleDateString('en-us', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })

  return (
    <MainContainerBox>
      <NavBar />
      <SidebarWrapper>
        <MainContainer>
          <MainWrapper>
            <SubscricptionsSection>
              <PaymentText>
                <TopText>Subscriptions &amp; Payments </TopText>
                <BottomText></BottomText>
              </PaymentText>
              <USDButton>
                <Invite label="Invite Friends" variant="contained" onClick={() => setInviteModal(true)} />
                <Button label="Upgrade Plan" variant="contained" onClick={() => navigate('/mobileupgradeplan')} />
              </USDButton>
            </SubscricptionsSection>
            <CardSection>
              {data?.map((item) => (
                <SubscricptionsCard key={item.id} image={item?.image} title={item?.title} subtitle={item?.subtitle} />
              ))}
            </CardSection>
            <SubscricptionsText>
              <SectionContainer>
                <TextSection>Subscriptions</TextSection>
              </SectionContainer>
              <Promotions>
                <RightSection>
                  <RightSectionText>
                    <SectionFirst>
                      Current Plan
                      <SecndSection>{lastElement?.subscriptionPlan}</SecndSection>
                    </SectionFirst>
                    <SectionFirst>
                      Membership Fee
                      <SecndSection>${lastElement?.subscriptionAmount}</SecndSection>
                    </SectionFirst>
                  </RightSectionText>
                </RightSection>
                <>
                  <LeftSection>
                    <BottomSection>
                      <TextLeftSection>
                        <SectionFirst>
                          Date Joined
                          <SecndSection>{dateSplit}</SecndSection>
                        </SectionFirst>
                      </TextLeftSection>
                      <PromotionContainer>
                        <TextTopSection>Checkout Promotions </TextTopSection>
                        <ButtonSection>
                          <PromotionsButton
                            label={
                              <ButtonLabled>
                                <Tag />
                                Promotions
                              </ButtonLabled>
                            }
                            variant="outline"
                          />
                        </ButtonSection>
                      </PromotionContainer>
                    </BottomSection>
                  </LeftSection>
                </>
              </Promotions>
            </SubscricptionsText>
            <PaymentSection>
              <PaymentLeftText>Payment Method</PaymentLeftText>
            </PaymentSection>

            <PaymentContainer>
              {columns?.map((item) => (
                <PaymentMethod key={item.id} image={item?.image} title={item?.title} subtitle={item?.subtitle} />
              ))}
            </PaymentContainer>
          </MainWrapper>
        </MainContainer>
      </SidebarWrapper>
      <Modal isOpen={isOpen}>
        <PaymentWalletModal showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
      <Modal isOpen={isCreateInviteOpen}>
        <InviteFriends showModal={(value: boolean) => setInviteModal(value)} />
      </Modal>
    </MainContainerBox>
  )
}

export default MobileSubscricptions
