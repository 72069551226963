import styled from 'styled-components'

export const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Image = styled.img`
  min-height: 30vh;
`

export const TextMessage = styled.div`
  color: #d72a2a;
  font-family: 'OutfitMedium';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.1px;
`
