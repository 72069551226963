import React, { useEffect, useState } from 'react'
import { Checkbox as AntdCheckbox } from 'antd'
import DefaultImage from 'assets/images/crypto-currencies.png'
import { useMoralisWeb3Api } from 'react-moralis'
import Watermark from 'assets/images/watermark-image.png'
import { ethers } from 'ethers'
import { CryptoLogo, Defination, ImageContainer } from 'styles/views/welcome/WelcomeSection/WelcomeCrypto'

interface IProps {
  setUniqueTokens: (value: any[]) => void
  currentWallet: string
  setHasAssets: (value: boolean) => void
  filterAsset: Array<string> | undefined
  hasAssets: boolean
  setTokenBalancesLength: (value: number) => void
}

const TableWrapper: React.FC<IProps> = ({
  setUniqueTokens,
  currentWallet,
  setHasAssets,
  filterAsset,
  hasAssets,
  setTokenBalancesLength,
}: IProps) => {
  const Web3Api = useMoralisWeb3Api()
  const [displayBalances, setDisplayBalances] = useState<any[]>()
  const fetchingTokenBalances = async () => {
    const tokenBalances: any[] = await Web3Api.account.getTokenBalances({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: currentWallet as string, //this holds internally linked address of
    })
    setTokenBalancesLength(tokenBalances.length)
    setDisplayBalances(
      tokenBalances.map((obj: { token_address: string }) => {
        const filterAgain = filterAsset === undefined ? '' : filterAsset
        if (filterAgain.includes(obj?.token_address)) {
          return { ...obj, checked: true }
        }

        return { ...obj, checked: false }
      }),
    )
    setHasAssets(true)
  }

  useEffect(() => {
    if (currentWallet) {
      fetchingTokenBalances()
    }
  }, [])
  useEffect(() => {
    if (currentWallet) {
      fetchingTokenBalances()
    }
  }, [currentWallet])

  return (
    <table>
      {hasAssets ? (
        <>
          {displayBalances && displayBalances.length > 0 ? (
            <tr>
              {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>Logo</th> : <th></th>}
              <th>Your Assets</th>
              <th>Balance</th>
              <th>Total Amount</th>
            </tr>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      <tbody>
        {displayBalances !== undefined ? (
          <>
            {displayBalances.length > 0 ? (
              displayBalances.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                      <td>
                        <CryptoLogo className="minified" src={!item?.logo ? DefaultImage : item?.logo} />
                      </td>
                    ) : (
                      <td>{item.logo}</td>
                    )}
                    <td>{item.name}</td>
                    <td>{ethers.utils.formatEther(item?.balance)}</td>
                    <td>{'NA'}</td>
                    <th>
                      <AntdCheckbox
                        checked={item?.checked}
                        onChange={(e) => {
                          const newArray = [...displayBalances]
                          newArray[index].checked = e?.target?.checked
                          setUniqueTokens(newArray)
                        }}
                      />
                    </th>
                  </tr>
                )
              })
            ) : (
              <ImageContainer>
                <CryptoLogo src={Watermark} />
                <Defination>No Assets</Defination>
              </ImageContainer>
            )}
          </>
        ) : (
          <ImageContainer>
            <CryptoLogo src={Watermark} />
            <Defination>No Assets</Defination>
          </ImageContainer>
        )}
      </tbody>
    </table>
  )
}

export default TableWrapper
