import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import UserProfileFiduciary from 'components/UserProfileFiduciary'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import Modal from 'components/Modal'
import FiduciaryMobileSidebar from 'components/FiduciaryMobileSidebar'
import Logo from 'assets/images/logo-black.png'
import AccountLogo from 'assets/images/account-icon.png'
import DropDown from 'assets/images/black-dropdown.png'
import MobileBurger from 'assets/svg/BlackBurger'
import {
  HeaderWrapper,
  LogoSection,
  ButtonContainers,
  ButtonSection,
  ButtonContainer,
  ImageSection,
  SelectOption,
  RightImage,
  NameText,
  ViewSelectOption,
  GrantorAdress,
  GrantorSection,
  BurgerLogo,
} from 'styles/components/NavBar'

const FiduciaryNavBar = () => {
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)
  const { user } = useContext(UserContext)
  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }
  const navigate = useNavigate()
  const wrapperRef = useRef<any>()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const { data: getFiduciaryView, refetch } = useGet('get-fiduciary', 'getFiduciary/getFiduciary', false, {
    enabled: true,
    token: true,
  })

  const { data: getlinkup, refetch: linkupFetch } = useGet('get-linkup', 'getlinkup/getuserLink', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    linkupFetch()
    refetch()
  }, [getlinkup, getFiduciaryView])

  const fiduciaryWallet = getFiduciaryView?.user?.userWalletId

  const fiduciaryName =
    getlinkup?.linkupFiducary && getlinkup.linkupFiducary.length > 0 ? getlinkup.linkupFiducary[0].displayName : ''
  return (
    <HeaderWrapper>
      <LogoSection src={Logo} onClick={() => navigate('/dashboard')} />
      <BurgerLogo>
        <MobileBurger onClick={() => setUpdatePlanModal(true)} />
      </BurgerLogo>
      <ButtonSection>
        <div>
          <ButtonContainers>
            <ViewSelectOption>
              <GrantorSection>
                <NameText>Fiduciary’s View</NameText>
                <GrantorAdress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                  user?.userWalletId?.length - 4,
                )}`}</GrantorAdress>
              </GrantorSection>
            </ViewSelectOption>
          </ButtonContainers>
        </div>

        <ButtonContainer onClick={() => setIsOpen(true)}>
          <SelectOption>
            <ImageSection src={AccountLogo} />
            <NameText>{fiduciaryName}</NameText>

            <RightImage src={DropDown} />
          </SelectOption>
          {isOpen && (
            <div ref={wrapperRef}>
              <UserProfileFiduciary fiduciary={fiduciaryName} wallet={fiduciaryWallet} />
            </div>
          )}
        </ButtonContainer>
      </ButtonSection>
      <Modal isOpen={isUpdatePlanOpen}>
        <FiduciaryMobileSidebar showModal={(value: boolean) => setUpdatePlanModal(value)} />
      </Modal>
    </HeaderWrapper>
  )
}

export default FiduciaryNavBar
