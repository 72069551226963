import React, { useEffect } from 'react'
import Table from 'components/Table'
import useGet from 'hooks/useGet'
import ActionNotes from 'components/Cards/ContractDetails/ActionNotes'
import CancelSign from 'assets/images/black-x.png'
import { columns } from 'components/Cards/ContractDetails/data'
import {
  MainContainer,
  Container,
  LogoSection,
  HeadingText,
  Wrapper,
  TopText,
  BottomText,
  AdressDetails,
  TableWrapper,
  CrossSection,
} from 'styles/components/Cards/ContractDetails'

export interface IModalProps {
  showModal: (value: boolean, type?: string) => void
  eventId?: string
}

const ContractDetails: React.FC<IModalProps> = ({ showModal, eventId }) => {
  const { data: ViewData, refetch } = useGet('get-ViewData', 'data/getViewData', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (!ViewData) refetch()
  })

  const eventHit = ViewData?.eventHit?.eventHit

  const tableData = []

  for (let j = 0; j < eventHit?.length; j++) {
    const eventHitData = eventHit[j]

    const benfeciaryHistroys = eventHitData?.benfeciaryHistroys

    for (let i = 0; i < benfeciaryHistroys?.length; i++) {
      const benfeciaryHistroysData = benfeciaryHistroys[i]

      if (eventId === benfeciaryHistroysData?.eventHitEventId) {
        tableData.push({
          key: i,
          nickname: benfeciaryHistroysData?.displayName,
          appointedas: benfeciaryHistroysData?.appointAs,
          totalamount: `${benfeciaryHistroysData?.amount}%`,
          note: <ActionNotes index={i} />,
        })
      }
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <AdressDetails>
          <Container>
            <HeadingText>
              <TopText>Contract Details</TopText>
              <BottomText></BottomText>
            </HeadingText>
            <CrossSection>
              <LogoSection src={CancelSign} onClick={() => showModal(false)} />
            </CrossSection>
          </Container>
        </AdressDetails>

        <TableWrapper>
          <Table data={tableData} columns={columns} />
        </TableWrapper>
      </Wrapper>
    </MainContainer>
  )
}

export default ContractDetails
