import React from 'react'
import { MobileContainerText, MobileWalletSection, Title } from 'styles/components/Cards/SignUp'
export interface ICardProps {
  title: string
  image?: string
}
const SignUpCard: React.FC<ICardProps> = ({ image, title }) => (
  <MobileContainerText>
    <MobileWalletSection src={image} />
    <Title>{title}</Title>
  </MobileContainerText>
)
export default SignUpCard
