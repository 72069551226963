import React from 'react'
import { MainContainer, Grantor, GrantorId, GrantorText, HeadingText, SubHadding } from 'styles/pages/WelcomePlan'
export interface ICardProps {
  title: string
  subtitle: string
  image: string
  role: () => void
  select: string
  disabled?: boolean
}

const WelcomePlanCard: React.FC<ICardProps> = ({ image, title, subtitle, role, select, disabled = false }) => (
  <MainContainer onClick={role} select={select === title ? true : false} disabled={disabled}>
    <Grantor>
      <GrantorId src={image} />
    </Grantor>
    <GrantorText>
      <HeadingText>{title}</HeadingText>
      <SubHadding>{subtitle}</SubHadding>
    </GrantorText>
  </MainContainer>
)

WelcomePlanCard.defaultProps = {
  disabled: false,
}

export default WelcomePlanCard
