import React, { useEffect } from 'react'
import FirstMetamask from 'assets/images/first-Metamask.png'
import Middpowerfull from 'assets/images/oursolution-midd.png'
import SecndMidd from 'assets/images/Secndlogo-midd.png'
import OpenZapline from 'assets/images/openzapline-midd.png'
import Web3apis from 'assets/images/wb3-apis.png'
import RediusRed from 'assets/images/redis-red.png'
import ShardsLogo from 'assets/images/shard-logo.png'
import ShardsManager from 'assets/images/shards-manager.png'
import {
  MainContainer,
  HeadingWrapper,
  Heading,
  Container,
  AuthenticationWrapper,
  AuthentcationImageWrapper,
  Image,
  TextWrapper,
  SDKWrapper,
  SDKImageWrapper,
  SDKText,
  TrustWrapper,
  TrustImageWrapper,
  ImageWrapper,
  Wrapper,
  ScaleImageWrapper,
  ScalableImageWrapper,
  BottomImageWrapper,
  AwelText,
  ZapWrapper,
  FirstSection,
  SecndSection,
  ThredSection,
  FourthSection,
  ZapText,
  First,
  FirstText,
  LastText,
  SecText,
} from 'styles/views/landing/MobileOurSolution'
import Paath from 'assets/svg/Paath'

const Roadmap = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <MainContainer>
      <HeadingWrapper>
        <Heading>Our Solution</Heading>
      </HeadingWrapper>
      <Container>
        <Paath />
        <AuthenticationWrapper>
          <AuthentcationImageWrapper>
            <Image src={FirstMetamask} alt="" />
          </AuthentcationImageWrapper>
          <TextWrapper>Secured Authentication with MetaMask & WalletConnect</TextWrapper>
        </AuthenticationWrapper>
        <SDKWrapper>
          <SDKImageWrapper>
            <Image src={Middpowerfull} alt="" />
          </SDKImageWrapper>
          <SDKText>Powerful SDK for cloud functions</SDKText>
        </SDKWrapper>
        <TrustWrapper>
          <TrustImageWrapper>
            <Image src={SecndMidd} alt="" />
          </TrustImageWrapper>
          <AwelText>Available on Trusted popular chains</AwelText>
        </TrustWrapper>
        <ZapWrapper>
          <ImageWrapper>
            <Image src={OpenZapline} alt="" />
          </ImageWrapper>
          <ZapText>Battle tested framework for smart Contract </ZapText>
        </ZapWrapper>
        <FirstSection>
          <Wrapper>
            <Image src={Web3apis} alt="" />
          </Wrapper>
          <First>Scalable Web3 APIs</First>
        </FirstSection>
        <SecndSection>
          <ScaleImageWrapper>
            <Image src={RediusRed} alt="" />
          </ScaleImageWrapper>
          <FirstText>To ingest, process and analyse real-time data with sub milli-second latency</FirstText>
        </SecndSection>
        <ThredSection>
          <ScalableImageWrapper>
            <Image src={ShardsLogo} alt="" />
          </ScalableImageWrapper>
          <SecText>To encrypt & decrypt shards</SecText>
        </ThredSection>
        <FourthSection>
          <BottomImageWrapper>
            <Image src={ShardsManager} alt="" />
          </BottomImageWrapper>
          <LastText>Shard Manager</LastText>
        </FourthSection>
      </Container>
    </MainContainer>
  )
}

export default Roadmap
