import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={11} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.315 5c0 2.385-1.903 4.3-4.228 4.3C2.762 9.3.86 7.385.86 5S2.762.7 5.087.7 9.315 2.615 9.315 5Z"
      stroke="#0A0C17"
      strokeWidth={1.4}
    />
    <path
      transform="matrix(-.7019 -.71227 .7019 -.71227 11 11)"
      stroke="#0A0C17"
      strokeWidth={1.4}
      strokeLinecap="round"
      d="M.7-.7h2.812"
    />
  </svg>
)

export default SvgComponent
