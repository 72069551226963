import React, { useContext, useEffect, useState } from 'react'
import { ReactElement } from 'react'
import SerectKey from 'components/Secretkey'
import useGet from 'hooks/useGet'
import Modal from 'components/Modal'
import { UserContext } from 'context/user'
import Table from 'components/Table'
import AddSecretOtp from 'components/Modal/AddSecretOtp'
import { columns, iColumns } from 'views/dashboard/FiduciaryRecoverSecretPhrase/data'
import SecretKeyAllocation from 'components/Modal/SecretKeyAllocation'
import { MainContainer, Container } from 'styles/components/Status'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PdfViewer from 'components/Modal/ShardsViewer'
import Button from 'components/Button'
import { TableWrapper, TextSection, TopTable, LastContainer } from 'styles/views/dashboard/RecoverSecretPhrase'

interface IData {
  key: React.Key
  nickName: string
  appointedAs: string
  secretKey: ReactElement
}

const BeneficiaryRecoverSecretPhrase = () => {
  const [first, setfirst] = useState(false)
  const [open, setOpen] = useState(false)
  const [isOpen, openSet] = useState(false)
  const { user } = useContext(UserContext)
  const verifyOtp = user?.isOTPVerified
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const notify = () =>
    toast(`Note: Please copy your shards responsibly, as once logged out won't be able to retreive it.`)

  const handleOpenModal = () => {
    setModalIsOpen(true)
    notify()
  }
  const { data: beneficary, refetch: beneficaryRefetch } = useGet('Benificary', 'beneficary/getBeneficary', false, {
    enabled: true,
    token: true,
  })

  const { data: getShartsForView } = useGet('shart-getShartsForView', 'shart/getShartsForView', false, {
    enabled: true,
    token: true,
  })

  const filteredFiduciary = getShartsForView?.data?.userData.filter(
    (item: { role: string }) => item?.role[0] === 'Beneficiary',
  )

  const filtered = filteredFiduciary?.some((item: { keys: string }) => item?.keys === null)

  const fiduciaryData = []

  for (let i = 0; i < filteredFiduciary?.length; i++) {
    const element = filteredFiduciary[i]

    const shartkeys = `${element?.keys?.substring(0, 5)}......${element?.keys?.substring(element?.keys.length - 4)}`

    const joinedFiduciary = element?.joinedKeys ? 'Success' : 'Pending'

    if (element?.keys !== null) {
      fiduciaryData.push({
        key: i,
        nickName: element?.userName,
        appointerEmail: shartkeys,
        appointedAs: element?.role,
        status: (
          <MainContainer>
            <Container label={joinedFiduciary} />
          </MainContainer>
        ),
      })
    }
  }

  const iData: IData[] = [
    {
      key: '1',
      nickName: beneficary?.user[0]?.displayName,
      appointedAs: beneficary?.user[0]?.appointAs,
      secretKey: <SerectKey />,
    },
  ]

  useEffect(() => {
    beneficaryRefetch()
  }, [])

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      {first || verifyOtp ? (
        <TableWrapper>
          <TextSection>Secret Key Recover Status</TextSection>
          {window.innerWidth <= 768 && (
            <div>
              <Button label="Preview shards" variant="contained" type="submit" onClick={handleOpenModal} />
              <ToastContainer />
            </div>
          )}{' '}
          <TopTable>
            <Table data={filtered ? iData : fiduciaryData} columns={filtered ? iColumns : columns} />
          </TopTable>
          <LastContainer></LastContainer>
          <Modal isOpen={isOpen}>
            <SecretKeyAllocation showModal={(value: boolean) => openSet(value)} />
          </Modal>
        </TableWrapper>
      ) : (
        <Modal isOpen={open}>
          <AddSecretOtp showModal={(value: boolean) => setOpen(value)} firsts={setfirst} />
        </Modal>
      )}
      <Modal isOpen={modalIsOpen}>
        <PdfViewer showModal={(value: boolean) => setModalIsOpen(value)} />
      </Modal>
    </>
  )
}

export default BeneficiaryRecoverSecretPhrase
