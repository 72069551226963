import { ReactElement, useState } from 'react'
import data from 'views/dashboard/MyPlan/PaymentMethod/data'
import AddPayments from 'components/Cards/AddPayments'
import PaymentModal from 'components/PaymentModal'
import Modal from 'components/Modal'
import Button from 'components/Button'
import {
  MainContainer,
  Container,
  Wrapper,
  ButtonSection,
  WrapperContainer,
  RadioGroup,
  Chooseplan,
  TopText,
  OptionText,
  SectionButton,
  ButtonWrappers,
} from 'styles/views/dashboard/MyPlan/UpgradePlan/UpgradePaymentPlan'

export const UpgradePaymentPlan = ({ prev, next }: any): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <MainContainer>
      <Chooseplan>
        <TopText>Payment Info</TopText>
        <OptionText></OptionText>
      </Chooseplan>
      <Container>
        <WrapperContainer>
          <Wrapper>Added Payment Method</Wrapper>
          <ButtonSection></ButtonSection>
        </WrapperContainer>

        <RadioGroup>
          {data?.map((item) => (
            <AddPayments
              key={item.id}
              image={item?.image}
              title={item?.title}
              subtitle={item?.subtitle}
              defination={item?.defination}
            />
          ))}
        </RadioGroup>
      </Container>
      <Modal isOpen={isOpen}>
        <PaymentModal showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
      <SectionButton>
        <ButtonWrappers>
          <Button label="Previous" variant="outline" onClick={prev} />
          <Button label="Next" variant="contained" onClick={next} />
        </ButtonWrappers>
      </SectionButton>
    </MainContainer>
  )
}

export default UpgradePaymentPlan
