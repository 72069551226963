import React from 'react'
import Button from 'components/Button'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
} from 'styles/components/Cards/CommingSoon'

export interface IModalProps {
  showModal: (value: boolean) => void
}

const CommingSoon: React.FC<IModalProps> = ({ showModal }) => (
  <MainContainer>
    <Wrapper>
      <Container>
        <LogoSection src={Logo} />
      </Container>
      <TextSection>
        <HeadingSection>Coming Soon!</HeadingSection>
        <Defination></Defination>
        <ButtonSection>
          <Button
            label="Close "
            variant="contained"
            type="button"
            onClick={() => {
              showModal(false)
            }}
          />
        </ButtonSection>
      </TextSection>
    </Wrapper>
  </MainContainer>
)

export default CommingSoon
