import styled from 'styled-components'

export const FAQContainer = styled.div`
  height: 100%;
`
export const Wrapper = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  @media (max-width: 767px) {
    padding: 4px 0;
  }
  @media (max-width: 1280px) {
    padding-top: 5px;
  }
  @media (max-width: 1000px) {
    padding-top: 10px;
  }
`
export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  width: 100%;
  @media (max-width: 767px) {
    padding-bottom: 3px;
  }
`

export const CustomSubHeading = styled.div`
  text-align: center;
`
export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 25px;
  column-gap: 25px;
  width: 100%;
  max-width: 1020px;
  justify-content: center;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: auto;
    width: 89.333vw;
    gap: 4vw;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    padding: 15px;
  }
`

export const FAQCard = styled.div`
  cursor: pointer;
  display: flex;
  max-width: 526px;
  color: #fff;
  padding: 34px 26px 29px 17px;
  background: #08193a;
  border-radius: 20px;
  justify-content: flex-start;
  gap: 15px;

  @media screen and (max-width: 1050px) {
    max-width: 460px;
  }

  @media (max-width: 767px) {
    display: flex;
    gap: 2.667vw;
    max-width: 89.333vw;
    padding: 9.6vw 8.533vw 7.733vw 4vw;
  }
`
export const ImageScetion = styled.div`
  .LogoFaq {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
`
export const CardDetials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
`
export const CardTitleText = styled.div`
  font-family: 'OutfitMedium';
  font-style: normal;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.24px;

  @media (max-width: 767px) {
    font-family: 'OutfitMedium';
    font-weight: 400;
    font-size: 5.333vw;
    line-height: 6.4vw;
    color: #ffffff;
    width: 69.6vw;
  }
`
export const CardPara = styled.div`
  font-family: 'OutfitMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a4adcc;
  max-width: 403px;

  @media (max-width: 767px) {
    font-family: 'OutfitMedium';
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 5.6vw;
    color: #ffffff;
    width: 69.6vw;
    text-align: justify;
  }
`
export const LearnText = styled.div`
  font-family: 'OutfitSemibold';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #0e65f3;
`
export const DownIcone = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
    padding-top: 2px;
  }
`
export const ContentWrapper = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
