import BlueWallet from 'assets/images/green-wallet.png'

interface IData {
  id: number
  image: string
  title: string
  subtitle: string
  defination: string
}

const data: IData[] = [
  {
    id: 0,
    image: BlueWallet,
    title: 'Crypto Wallet',
    subtitle: '143265789087654',
    defination: '',
  },
]
export default data
