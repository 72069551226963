import { useState, useEffect, useContext, ReactElement } from 'react'
import Table from 'components/Table'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import { columns } from 'views/dashboard/BeneficaryMyPlan/BeneficaryPlanContent/data'
import {
  TableWrapper,
  ContractWrapper,
  HeadWrapper,
  UpdatedContainer,
  HeadText,
  NumaricText,
  TextSection,
  StatusActiveTerminated,
} from 'styles/views/dashboard/SmartContract/index'

interface IData {
  key: React.Key
  contractname: string
  contractId: string
  contractdate: string
  appointedas: string
  status: ReactElement
}

const PlanBeneficary = () => {
  const [newtoggle, setNewtoggle] = useState(true)
  const { user } = useContext(UserContext)
  const archiveOpen = () => setNewtoggle(true)
  const archiveClose = () => setNewtoggle(false)

  const { data: getBenificaryView, refetch } = useGet('get-Benificary', 'getBenificary/getBenificaryView', false, {
    enabled: true,
    token: true,
  })

  const beneificaryData =
    getBenificaryView?.beneificaryDetail && getBenificaryView?.beneificaryDetail.length > 0
      ? getBenificaryView?.beneificaryDetail[0]
      : '00-00-0000'

  const dateSplit = new Date(beneificaryData?.createDate)?.toLocaleDateString('en-us', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })

  const data: IData[] = [
    {
      key: '1',
      contractname: beneificaryData?.ownerName,
      contractId: `${beneificaryData?.contractId?.substring(0, 10)}..${beneificaryData?.contractId?.substring(
        beneificaryData?.contractId?.length - 4,
      )}`,
      contractdate: dateSplit,
      appointedas: 'Beneficary',
      status: (
        <StatusActiveTerminated
          style={
            user?.contractStatus === 'Terminated'
              ? { color: 'red', background: 'rgba(255, 233, 237, 0.47)' }
              : user?.contractStatus?.toLowerCase() === 'processing'
              ? { color: '#009F42', background: 'rgba(0, 159, 66, 0.19)' }
              : { color: '#2677e9', background: '#E9F2FF' }
          }
        >
          {user?.contractStatus}
        </StatusActiveTerminated>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <ContractWrapper>
        <UpdatedContainer>
          <HeadWrapper>
            {newtoggle ? <DownOutlined onClick={archiveClose} /> : <RightOutlined onClick={archiveOpen} />}
            <TextSection>
              <HeadText>Beneficiaries Contract </HeadText>
              <NumaricText>(01)</NumaricText>
            </TextSection>
          </HeadWrapper>
          {newtoggle && (
            <TableWrapper>
              <Table data={data} columns={columns} />
            </TableWrapper>
          )}
        </UpdatedContainer>
      </ContractWrapper>
    </>
  )
}

export default PlanBeneficary
