import React, { ReactChild } from 'react'
import ReactModal from 'react-modal'

interface IModalProps {
  isOpen: boolean
  children: ReactChild
}

const Modal: React.FC<IModalProps> = ({ isOpen, children }: IModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className="modal"
      ariaHideApp={false}
      overlayClassName="myoverlay"
    >
      {children}
    </ReactModal>
  )
}

export default Modal
