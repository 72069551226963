import React, { useEffect, useState } from 'react'
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js'

const AppleCheckout = () => {
  const stripe: any = useStripe()
  const elements: any = useElements()
  const [paymentRequest, setPaymentRequest] = useState<any>(null)

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 1999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result: any) => {
      if (result) {
        if (pr !== null) {
          setPaymentRequest(pr)
        }
      }
    })

    pr.on('paymentmethod', async (e: any) => {
      const { error: backendError, clientSecret } = await fetch('/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodType: 'card',
          currency: 'usd',
        }),
      }).then((r) => r.json())

      if (backendError) {
        return
      }

      const { error: stripeError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        { handleActions: false },
      )

      if (stripeError) {
        return
      }
    })
  }, [stripe, elements])

  return (
    <>
      <h1>Apple Pay</h1>

      <p>
        Before you start, you need to:
        <ul>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-testing"
              target="_blank"
              rel="noreferrer"
            >
              Add a payment method to your browser.
            </a>{' '}
            For example, add a card to your Wallet for Safari.
          </li>
          <li>
            Serve your application over HTTPS. This is a requirement both in development and in production. One way to
            get up and running is to use a service like{' '}
            <a href="https://ngrok.com/" target="_blank" rel="noopener noreferrer">
              ngrok
            </a>
            .
          </li>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#verifying-your-domain-with-apple-pay"
              target="_blank"
              rel="noreferrer"
            >
              Verify your domain with Apple Pay
            </a>
            , both in development and production.
          </li>
        </ul>
      </p>

      <a href="https://stripe.com/docs/stripe-js/elements/payment-request-button" target="_blank" rel="noreferrer">
        Stripe Documentation
      </a>

      {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}

      <p>
        {' '}
        <a href="https://youtu.be/bMCsJfJyQKA" target="_blank" rel="noreferrer">
          Watch a demo walkthrough
        </a>{' '}
      </p>
    </>
  )
}

export default AppleCheckout
