import React, { useState } from 'react'
import CancelSign from 'assets/images/vector-cancel.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import EmailCopy from 'assets/images/copy-email.png'
import {
  MainContainer,
  TextSection,
  CancelSection,
  TopSection,
  BottomSection,
  BottomText,
  StyledContainer,
} from 'styles/components/Modal/ShardsViewer'

export interface IModalProps {
  showModal: (value: boolean) => void
}

function PdfViewer({ showModal }: IModalProps) {
  const shardsView: any = localStorage.getItem('shards')
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = () => {
    setIsCopied(true)
  }

  return (
    <StyledContainer>
      <MainContainer>
        <TopSection>
          <TextSection>Generated Shards</TextSection>
          <CancelSection src={CancelSign} onClick={() => showModal(false)} />
        </TopSection>
        <CopyToClipboard text={shardsView} onCopy={handleCopy}>
          <div data-tip data-for="from">
            <ReactTooltip id="from" place="top" effect="solid">
              {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
            </ReactTooltip>

            <img
              src={EmailCopy}
              onClick={() => {
                setIsCopied(true)
              }}
            />
          </div>
        </CopyToClipboard>
        <BottomSection>
          <BottomText>{shardsView}</BottomText>
        </BottomSection>
      </MainContainer>
    </StyledContainer>
  )
}

export default PdfViewer
