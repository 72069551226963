import ProfileLogo from 'assets/images/profile-image.png'
import ProfileBlue from 'assets/images/blue-logo.png'
import ProfileRed from 'assets/images/red-logo.png'
interface IData {
  id: number
  image: string
  title: string

  lasttitle: string
}

const data: IData[] = [
  {
    id: 0,
    image: ProfileLogo,
    title: 'Benificiary Changed',
    lasttitle: '11:30(GMT-4)',
  },
  {
    id: 1,
    image: ProfileBlue,
    title: 'Benificiary Changed',
    lasttitle: '11:30(GMT-4)',
  },
  {
    id: 2,
    image: ProfileRed,
    title: 'Benificiary Changed',
    lasttitle: '11:30(GMT-4)',
  },
]
export default data
