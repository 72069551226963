import { useMoralis } from 'react-moralis'
import { MainContainer, HeadText, TextArea, ButtonBox, ContractButton } from 'styles/views/dashboard/Settings/MyDeatils'
import { logout } from 'utils'
import Modal from 'components/Modal'
import AccDeactiveModal from 'components/AccountDeactivateModal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Moralis } from 'moralis'

const ManageAccount: React.FC = () => {
  const { user } = useMoralis()
  const [isRemove, setIsRemove] = useState(false)
  const navigate = useNavigate()

  const deactivateUser = async () => {
    user?.set('isActive', false)
    await user?.save()

    const scheduler = async () => {
      const query = new Moralis.Query('User')
      const object = await query?.first({ useMasterKey: true })
      const query2 = new Moralis.Query('EthAddress')
      query2.equalTo('user', object?.id)
      const object2 = await query2?.first({ useMasterKey: true })
      const query3 = new Moralis.Query('Session')
      query3.equalTo('user', object?.id)
      const object3 = await query3?.first({ useMasterKey: true })

      await object
        ?.destroy()
        .then(() => {
          object2?.destroy()
        })
        .then(() => {
          object3?.destroy()
        })

      logout()
      navigate('/')
    }

    if (user?.set('Active', false)) setTimeout(scheduler, 1296000000)
  }

  return (
    <MainContainer>
      <HeadText>Proceed with caution 🛑</HeadText>
      <TextArea></TextArea>
      <ButtonBox>
        <ContractButton label="Deactivate" onClick={() => setIsRemove(true)} />
      </ButtonBox>
      <Modal isOpen={isRemove}>
        <AccDeactiveModal showModal={(value: boolean) => setIsRemove(value)} deactivateUser={deactivateUser} />
      </Modal>
    </MainContainer>
  )
}

export default ManageAccount
