import styled from 'styled-components'
import { theme } from 'theme'

export const Container = styled.div`
  .back-button {
    background: #fff;
    color: #000;
    border-color: #e0e0e0;
    height: 56px;
    margin: 7px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;

    @media (max-width: 1366px) {
      margin: 1px;
    }
  }

  .no-visible {
    display: none;
  }

  .next-button {
    background: #4473f5;
    height: 56px;
    margin: 15px 11px 15px 0;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
  }
  .nextTree-button {
    background: #4473f5;
    height: 56px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    margin: 0px;
  }
  .steps-action {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    align-items: center;
    margin-right: 35px;

    @media (max-width: 1366px) {
      margin-top: 0px;
      padding: 0px 10px 4px 10px;
      gap: 7px;
    }
    @media (max-width: 1280px) {
      margin-top: 0px;
      align-items: center;
      gap: 22px;
    }
  }
  .ant-steps-item:last-child {
    display: none;
  }
`

export const StepWrapper = styled.div`
  padding: 20px 35px 20px 30px;
  cursor: pointer;
  background: #f1f5fb;
  @media (max-width: 1366px) {
    padding: 8px 35px 8px 30px;
  }
  @media (max-width: 1280px) {
    padding: 5px 35px 5px 30px;
  }

  color: ${theme.info.light};
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2677e9;
    border-color: #fff;
    color: #fff;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps {
    text-align: center;
  }
`
