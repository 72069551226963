import { ReactElement, useEffect, useState, useContext } from 'react'
import { UserContext } from 'context/user'
import { useMoralis } from 'react-moralis'
import { logout } from 'utils'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import usePost from 'hooks/usePost'
import TextInput from 'components/FormElements/TextInput'
import usePatch from 'hooks/usePatch'
import Button from 'components/Button'
import SignupWallet from 'assets/images/signup-wallet.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import Logo from 'assets/images/logo-black.png'
import { Checkbox } from 'antd'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import { LoaderContext } from 'context/loader'
import {
  Container,
  Wrapper,
  WelcomeTextSection,
  TopTextSection,
  ContainerTextSection,
  PrimaryWalletSection,
  PrimaryText,
  MobileWalletSection,
  WalletLogoSection,
  TextSectionMobile,
  TextIdSection,
  MobileTextIdSection,
  PrimaryWalletContainer,
  LeftSection,
  ProfileSection,
  InfoRightSection,
  InputWrapper,
  ButtonSection,
  ButtonWrapper,
  Cancel,
  LeftSectionButton,
  Label,
  WalletButton,
  CondtionsWrapper,
  ConditionsText,
  StyledButton,
  Error,
  HeaderWrapper,
  LogoSection,
} from 'styles/views/welcome/WelcomeSection'

interface ILocation {
  phone: string
  email: string
  name: string
  phoneNo: string
}

const WelcomeSection = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const [next, setNext] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { user, fetchUser } = useContext(UserContext)
  const userAddress = (location as any)?.state?.userAddress || user?.userWalletId
  const { logout: moralisLogout } = useMoralis()
  const { mutateAsync, isSuccess, isError, error, isLoading } = usePost()
  const walletInfo = localStorage?.getItem('walletconnect') || ''

  const emailId = localStorage.getItem('email')
  const userName: string = localStorage.getItem('username') || ''
  const grantor: string = localStorage.getItem('grantor') || ''
  const role = localStorage.getItem('signRole')
  const grantorWallet = grantor.toLowerCase()

  const schema = yup.object().shape({
    name: yup.string().required('Display Name is required.').matches(/^\S/, 'First character cannot be Space. !'),
    email: yup
      .string()
      .required('Email is required')
      .matches(/^\S/, 'First character cannot be Space.')
      .email('Please enter a valid Email')
      .max(255),
    phone: yup
      .string()
      .max(10)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid number',
      ),
  })

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILocation>({ mode: 'onChange', reValidateMode: 'onChange', resolver: yupResolver(schema) })

  const allFields = useWatch({ control })
  const phoneError = allFields?.phoneNo?.includes('_')

  const { mutateAsync: updatAsync, isLoading: updateLoading } = usePatch()

  const updateLogin = async (values: { userName: string; phoneNo: string }) => {
    const payloadData = {
      displayName: values?.userName,
      phoneNumber: values.phoneNo !== '' ? values?.phoneNo : '',
    }

    const onFiduciary = 'fiduciaryUpdate/fiduciaryUpdate'
    const onBeneficiary = 'update/beneificaryUpdate'

    try {
      const updateData = await updatAsync({
        url: role === 'Fiduciary' ? onFiduciary : onBeneficiary,
        payload: payloadData,
        token: true,
      })
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const formData = async (values: ILocation) => {
    const refCode = window?.localStorage?.getItem('referralCode')
    const isRole = window?.localStorage?.getItem('signRole')

    if (isRole === 'Fiduciary' || isRole === 'Beneficiary') {
      localStorage.removeItem('_welcomeFlag')
    } else {
      localStorage.setItem('_welcomeFlag', 'true')
    }

    try {
      if (!walletInfo) {
        const payload = {
          primaryWalletName: 'MetaMask',
          userName: values.name,
          emailId: values.email,
          phoneNo: values.phoneNo,
          granterWalletIds: grantorWallet,
          token: refCode,
          role: role || 'Grantor',
        }

        await mutateAsync({
          url: 'user/onboard',
          payload,
          token: true,
        })
        updateLogin(payload)
      } else {
        const payload = {
          primaryWalletName: JSON.parse(walletInfo)?.peerMeta?.name || '',
          userName: values.name,
          emailId: values.email,
          phoneNo: values.phone,
          role: role || 'Grantor',
        }

        await mutateAsync({
          url: 'user/onboard',
          payload,
          token: true,
        })
        updateLogin(payload)
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  const removeSelectedWallet = async () => {
    await moralisLogout()
    logout()
    navigate('/selectwallet')
  }

  const cancelReg = async () => {
    await moralisLogout()
    logout()
    navigate('/')
  }

  useEffect(() => {
    if (emailId !== null) {
      setValue('name', userName)
      setValue('email', emailId)
    } else {
      setValue('name', '')
      setValue('email', '')
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      fetchUser()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (isLoading || updateLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, updateLoading])

  return (
    <Container>
      <HeaderWrapper>
        <LogoSection src={Logo} />
      </HeaderWrapper>
      <Wrapper>
        <WelcomeTextSection>
          <ContainerTextSection>
            <TopTextSection>Welcome to CryptoPlan</TopTextSection>
          </ContainerTextSection>
        </WelcomeTextSection>
        <PrimaryWalletSection>
          <PrimaryWalletContainer>
            <PrimaryText>Your Primary Wallet</PrimaryText>
            <MobileWalletSection>
              <LeftSection>
                {walletInfo ? (
                  <WalletLogoSection src={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet} />
                ) : (
                  <WalletLogoSection src={SignupWallet} />
                )}

                <MobileTextIdSection>
                  {walletInfo ? (
                    <TextSectionMobile>{JSON.parse(walletInfo)?.peerMeta?.name || ''}</TextSectionMobile>
                  ) : (
                    <TextSectionMobile>MetaMask</TextSectionMobile>
                  )}
                  <TextIdSection>{userAddress}</TextIdSection>
                </MobileTextIdSection>
              </LeftSection>
              <WalletButton>
                <Button label="Change Wallet" variant="outline" type="button" onClick={() => removeSelectedWallet()} />
              </WalletButton>
            </MobileWalletSection>
            <ProfileSection>Create your Profile</ProfileSection>
            <InfoRightSection>
              <form onSubmit={handleSubmit(formData)}>
                <InputWrapper>
                  <Label>Display Name</Label>
                  <TextInput className="text" control={control} {...register('name')} />
                  <Error>{errors?.name?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                  <Label>Email ID</Label>
                  <TextInput
                    rules={[{ type: 'email', required: true }]}
                    className="text"
                    control={control}
                    {...register('email')}
                  />
                  <Error>{errors?.email?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                  <Label>Phone Number (Optional)</Label>
                  <Controller
                    render={({ field }) => (
                      <NumberFormat {...field} className="text" format="+1 (###) ###-####" placeholder="" mask="_" />
                    )}
                    control={control}
                    name="phoneNo"
                  />
                  {phoneError ? <Error>Please enter the valid phone number</Error> : ''}
                </InputWrapper>
                <CondtionsWrapper>
                  <Checkbox onChange={() => setNext(!next)} />
                  <ConditionsText>
                    I agree with the <div className="terms-link">terms & conditions.</div>
                  </ConditionsText>
                </CondtionsWrapper>
                <ButtonSection>
                  <LeftSectionButton>
                    <ButtonWrapper>
                      <Cancel label="Cancel" variant="outline" type="button" onClick={() => cancelReg()} />
                    </ButtonWrapper>
                    <ButtonWrapper>
                      <StyledButton label="Next" variant="contained" type={next && !phoneError ? 'submit' : 'button'} />
                    </ButtonWrapper>
                  </LeftSectionButton>
                </ButtonSection>
              </form>
            </InfoRightSection>
          </PrimaryWalletContainer>
        </PrimaryWalletSection>
      </Wrapper>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} success={isSuccess} error={error} />
      </Modal>
    </Container>
  )
}

export default WelcomeSection
