import styled from 'styled-components'

export interface IProps {
  label: string
}

export const TableWrapper = styled.div`
  width: 100%;
  padding: 6px;
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
  td.ant-table-column-sort {
    background-color: #fff;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
  }
  .center {
    text-align: center;
  }

  table {
    width: 100%;
    padding: 6px;
    .ant-pagination {
      display: none;
    }
    tr {
      height: 71px;
      border-bottom: 1px solid #e7e7e7;
      :last-child {
        border: none;
      }
    }
    th {
      text-align: center;
      padding: 22px;
      font-family: 'OutfitRegular';
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;

      @media (max-width: 1440px) {
        padding: 5px;
      }
      :last-child {
        padding-left: 59px;
      }
    }
    td {
      font-family: 'OutfitMedium';
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0a0c17;
      padding: 20px;
      :first-child {
        text-align: center;
      }

      @media (max-width: 1366px) {
        padding: 10px 10px 10px 10px;
      }
      :nth-child(5) {
        padding-left: 30px;
      }
      :nth-child(1) {
        text-align: inherit;
      }
    }
  }
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  padding: 10px;
`
export const BottomTable = styled.div`
  .ant-table-thead {
    display: none;
  }
  td {
    text-align: center;
    :nth-child(1) {
      width: 13%;
      padding: 10px;
      @media (max-width: 1280px) {
        width: 11%;
      }
      @media (max-width: 1366px) {
        width: 11%;
      }
    }
    :nth-child(2) {
      width: 14%;
      padding: 10px 10px 10px 14px;
      @media (max-width: 1366px) {
        padding: 10px 10px 10px 39px;
      }
    }
    :nth-child(3) {
      width: 10%;
      padding: 10px 10px 10px 4px;
      @media (max-width: 1366px) {
        text-align: end;
      }
    }
    :nth-child(4) {
      width: 20%;
      padding: 10px 10px 10px 69px;
      @media (max-width: 1366px) {
        padding: 10px 10px 10px 108px;
      }
      @media (max-width: 1280px) {
        padding: 10px 10px 10px 4px;
      }
      @media (max-width: 1440px) {
        padding: 10px 10px 10px 14px;
      }
    }
  }
`
export const TopTable = styled.div`
  .ant-pagination {
    display: none;
  }
  td {
    :nth-child(1) {
      width: 30%;
      text-align: center !important;
      padding: 10px 10px 10px 1px;
    }

    :nth-child(3) {
      width: 30%;
      text-align: center;
      padding: 10px 10px 10px 1px;
    }
    :nth-child(2) {
      width: 40;
      text-align: center;
      padding: 10px 10px 10px 1px;
    }
  }
`

export const FiduciaryBottomTable = styled.div`
  .ant-pagination {
    display: none;
  }
  td {
    :nth-child(1) {
      width: 30%;
      text-align: center !important;
      padding: 10px 10px 10px 1px;
    }

    :nth-child(3) {
      width: 30%;
      text-align: center;
      padding: 10px 10px 10px 1px;
    }
    :nth-child(2) {
      width: 40;
      text-align: center;
      padding: 10px 10px 10px 1px;
    }
  }
`

export const LastContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 2.667vw;
  }
`

export const StatusBar = styled.div`
  width: 100%;
  max-width: 400px;
  background: #e6e6e6;
  border-radius: 30px;
  height: 10px;
`

export const GreenBar = styled.div`
  width: 100%;
  max-width: 190px;
  height: 10px;
  background-color: green;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`

export const WhiteBar = styled.div``

export const ButtonSection = styled.div`
  width: 100%;
  max-width: 196px;
`
export const TextArea = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  color: #ff3535;
`
export const Lefttopsection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
