import styled from 'styled-components'
export const MainContainer = styled.div`
  width: 100%;
  margin: 0 25px;
  display: flex;
  justify-content: center;
  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 27px 5px 37px;
`
export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TopHaddingSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #0a0c17;
`
export const BottomHeading = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const MainWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
`
export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #dfdfdf;
  justify-content: center;
`

export const RightSection = styled.div`
  width: 100%;
  max-width: 980px;
`
export const Leftsection = styled.div`
  width: 100%;
  max-width: 297px;
`
export const ContainerDevider = styled.div``
export const RightTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 17px 6px 25px;
  align-items: center;
`
export const NotificationText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.3px;
`
export const ShortContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 117px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 8px 6px 6px 13px;
  @media (max-width: 767px) {
    height: 36px;
    border: none;
  }
`

export const ShortBy = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`
export const ShortImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const FiduciaryChanged = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`
export const FiduciaryContainer = styled.div``
export const ImageSection = styled.img``
export const TextTopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const TextFirst = styled.div`
  font-family: 'OutfitSemiBold';
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #0a0c17;
  gap: 20px;
`
export const TimeSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const TextSEction = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 20px;
`
export const LeftTimeSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.3px;
  color: #737b7d;
  padding: 20px;
`
export const BottomTextArea = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  color: #737b7d;
  width: 100%;
  max-width: 626px;
`
export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 29px 19px 17px 21px;
`
export const NotificationWraper = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.3px;
`
export const ResetImage = styled.img``
export const ResetText = styled.div`
  font-family: 'OutfitMedium';
  font-style: normal;
  font-size: 12px;
  line-height: 33px;
  letter-spacing: 0.2px;
  color: #009f42;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: 'center';
  align-items: center;
  gap: 5px;
  cursor: pointer;
`
export const CheackBoxSection = styled.div`
  padding: 0px 19px 19px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const CheckBox = styled.div`
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 15px 0;
    border: none;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @media (max-width: 767px) {
      gap: 1.333vw;
    }
  }
`
export const ResetButton = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding: 5px 0 22px 20px;
  .ant-checkbox {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 5px 0 2px 20px;
  }
`
export const SortWrappers = styled.div`
  z-index: 1;
  position: absolute;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 184px;
  text-align: left;
  right: 167px;
  bottom: 10px;
  padding: 10px 15px;
`
export const TextForm = styled.div``
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.3px;
  padding: 16px 20px 7px 21px;
`
export const RadioSection = styled.div`
  padding: 7px 88px 21px 10px;
  display: flex;
  gap: 25px;
`
export const FormLeftSection = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`
export const FormRightSection = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`
export const Section = styled.div`
  width: 100%;
  display: flex;
`

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const SortWrapper = styled.div`
  z-index: 1;
  position: absolute;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 111px;
  width: 100%;
  text-align: left;
  right: 10px;
  top: 40px;
  padding: 10px 20px;
`
export const SortText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`

export const NotificationMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
`
