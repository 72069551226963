import { useEffect } from 'react'
import useGet from 'hooks/useGet'
import { Tabs } from 'antd'
import BeneficiaryRecoverSecretPhrase from 'views/dashboard/BeneficiaryRecoverSecretPhrase'
import MyDetails from 'views/dashboard/BeneficarySettings/MyDetails'
import BeneficaryNavBar from 'components/BeneficaryNavBar'
import BeneficarySidebar from 'components/BeneficarySidebar'
import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  TopBox,
  TextContainer,
  Section,
  TableContent,
} from 'styles/views/dashboard/Settings'

const { TabPane } = Tabs

const BeneficarySetting = () => {
  const { data: beneficary, refetch: beneficaryRefetch } = useGet('Benificary', 'beneficary/getBeneficary', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    beneficaryRefetch()
  }, [])

  return (
    <Wrapper>
      <BeneficaryNavBar beneificaryName={beneficary?.user[0]?.displayName} />
      <Section>
        <BeneficarySidebar />
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Settings</TextArea>
              </TextContainer>
            </TopBox>
            <TableContent>
              <Tabs defaultActiveKey="1">
                <TabPane tab="My Details" key="1">
                  <MyDetails
                    beneificaryName={beneficary?.user[0]?.displayName}
                    beneificaryPhoneNo={beneficary?.user[0]?.phoneNumber}
                  />
                </TabPane>

                <TabPane tab="Recover Secret Phrase" key="2">
                  <BeneficiaryRecoverSecretPhrase />
                </TabPane>
              </Tabs>
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default BeneficarySetting
