import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.5 7v4m0 0v4m0-4h4m-4 0h-4" stroke="#2677E9" strokeWidth={2} strokeLinecap="round" />
    <path
      d="M11.5 21c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
      stroke="#2677E9"
      strokeWidth={2}
    />
  </svg>
)

export default SvgComponent
