import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import WalletCopy from 'assets/images/copy-blue-image.png'
import WalletCopyWhite from 'assets/images/copy-white-image.png'
import Crypto from 'assets/images/crypto-currency.png'
import Nft from 'assets/images/nft-currency.png'
import Estate from 'assets/images/real_estate.png'
import MetaMask from 'assets/images/meta-mask.png'
import Ripple from 'assets/images/ripple-crypto.png'
import { CopyHover } from 'styles/components/Tree'
import {
  UserName,
  UserStatus,
  UserWallet,
  UserPlusButton,
  FiduciaryName,
  FiduciaryPercentage,
  FiduciaryResponsibility,
  WalletFiduciary,
  WalletBeneficiary,
  BeneficiaryResponsibility,
} from 'styles/components/DisbursementTree'
import { getBranches, getXAxisPosition, shrinkWalletId, getYAxisPosition } from './treeHelpers'

export const TreeData = (user: any, tokensData: any, setIsWalletAddrAdd: any) => {
  const [shrinkedWalletId, setShrinkedWalletId] = useState('')
  const [shrinkedFiduciaryWalletId, setShrinkedFiduciaryWalletId] = useState('')
  const [nodesSize, setNodesSize] = useState(7)
  const [xPosition, setXPosition] = useState(200)
  const [yPosition, setYPosition] = useState<any>([-70, 75, 235, 350, 490, 630, 785])
  const [activeWallet, setActiveWallet] = useState(2)
  const [copiedWalletId, setCopiedWalletId] = useState(false)
  const [fiduciaryWalletId, setFiduciaryWalletId] = useState(false)
  const [beneficiaryWalletId, setBeneficiaryWalletId] = useState(false)

  const fiduciary = user.beneficary.find((item: any) => item.appointAs == 'Fiduciary')
  const walletInfo = localStorage?.getItem('walletconnect') || ''

  // get X-axis center position & shrinked Wallet-Id for user & Fiduciary
  useEffect(() => {
    const position = getXAxisPosition()
    position && setXPosition(position / 2 - 80)

    const yAxisPosition = getYAxisPosition()
    yAxisPosition &&
      setYPosition(yAxisPosition <= 641 ? [-20, 115, 255, 350, 480, 600, 735] : [-150, 0, 200, 310, 450, 590, 755])
    const userWallet = user.userWallet.length == 0 ? shrinkWalletId(user) : `${user.userWallet.length} Wallets`
    userWallet && setShrinkedWalletId(userWallet)
    const fiduciaryWallet = fiduciary ? shrinkWalletId(fiduciary) : 'wallet028nde339'
    fiduciaryWallet && setShrinkedFiduciaryWalletId(fiduciaryWallet)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (copiedWalletId) setCopiedWalletId(false)
    }, 5000)
  }, [copiedWalletId])

  useEffect(() => {
    setTimeout(() => {
      if (fiduciaryWalletId) setFiduciaryWalletId(false)
    }, 5000)
  }, [fiduciaryWalletId])

  useEffect(() => {
    setTimeout(() => {
      if (beneficiaryWalletId) setBeneficiaryWalletId(false)
    }, 5000)
  }, [beneficiaryWalletId])

  const handleCopy = (data: string) => {
    switch (data) {
      case 'user':
        setCopiedWalletId(true)
        break
      case 'fiduciary':
        setFiduciaryWalletId(true)
        break
      case 'beneficiary':
        setBeneficiaryWalletId(true)
        break
      default:
        break
    }
  }

  //Function for expanding & collapsing the nodes in tree
  const modifyNodes = (val: number) => {
    if (val == nodesSize) {
      val == 5 ? setNodesSize(val + 2) : setNodesSize(val + 1)
    } else setNodesSize(val)
  }
  //Function for switching between different wallets
  const modifyWalletOrNodes = (val: number, level: number) => {
    if (activeWallet == val) modifyNodes(level)
    else setActiveWallet(val)
  }

  const initialNodes = [
    {
      id: '1',
      type: 'input',
      layer: 1,
      style: {
        background: '#4473F5',
        border: '8px solid #FFFFFF',
        borderRadius: '30px',
      },
      parent: [2],
      data: {
        label: (
          <div onClick={() => modifyNodes(1)}>
            <UserName>{user.userName}</UserName>
            <UserStatus fontSize="16px">Owner</UserStatus>
            <div className="wallet">
              <UserWallet padding="4px" fontSize="13px">
                {shrinkedWalletId}
              </UserWallet>
              {user.userWallet.length != 0 ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <UserPlusButton onClick={() => setIsWalletAddrAdd(true)}>+</UserPlusButton>
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <CopyToClipboard text={user.userWalletId} onCopy={() => handleCopy('user')}>
                    <CopyHover data-tip data-for="from">
                      <ReactTooltip id="from" place="right" effect="solid">
                        {copiedWalletId ? <div>copied </div> : <div>Copy To Clipboard </div>}
                      </ReactTooltip>

                      <img
                        src={WalletCopyWhite}
                        onClick={() => {
                          setCopiedWalletId(true)
                        }}
                      />
                    </CopyHover>
                  </CopyToClipboard>
                </div>
              )}
            </div>
          </div>
        ),
      },
      position: { x: xPosition, y: yPosition[0] },
    },
    {
      id: '2',
      layer: 2,
      style: {
        border: '0px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div onClick={() => modifyWalletOrNodes(2, 2)}>
            {walletInfo ? (
              <img src={JSON.parse(walletInfo)?.peerMeta?.icons[0] || ''} alt="img" className="treeImage" />
            ) : (
              <img src={MetaMask} alt="img" className="treeImage" />
            )}
            {walletInfo ? (
              <div className="currency">{JSON.parse(walletInfo)?.peerMeta?.name || ''}</div>
            ) : (
              <div className="currency">MetaMask</div>
            )}
          </div>
        ),
      },
      position: { x: xPosition, y: yPosition[1] },
    },

    {
      id: '3',
      parent: [activeWallet],
      layer: 3,
      style: {
        border: '5px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div onClick={() => modifyNodes(3)}>
            <img src={Crypto} alt="img" className="image" />
            <div className="currency">Crypto Currencies</div>
          </div>
        ),
      },
      position: { x: xPosition - 210, y: yPosition[2] },
    },
    {
      id: '4',
      layer: 3,
      parent: [activeWallet],
      style: {
        border: '5px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div onClick={() => modifyNodes(3)}>
            <img src={Nft} alt="img" className="image" />
            <div className="currency">NFTs</div>
          </div>
        ),
      },
      position: { x: xPosition, y: yPosition[2] },
    },
    {
      id: '5',
      layer: 3,
      parent: [activeWallet],
      style: {
        border: '5px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div onClick={() => modifyNodes(3)}>
            <img src={Estate} alt="img" className="image" />
            <div className="currency">Real Estate</div>
          </div>
        ),
      },
      position: { x: xPosition + 210, y: yPosition[2] },
    },
    {
      id: '6',
      layer: 4,
      parent: [3],
      style: {
        border: '5px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div className="currency" onClick={() => modifyNodes(4)}>
            <div style={{ color: '#4473f5', fontSize: '22px' }}>{tokensData.length ? tokensData.length : 1}</div>{' '}
            <div>Types</div>
          </div>
        ),
      },
      position: { x: xPosition - 210, y: yPosition[3] },
    },
  ]
  const assetsLength = tokensData?.length
  const assetsLeftHalf = assetsLength / 2
  const assetsSPoint =
    assetsLeftHalf % 2 == 0 ? xPosition - ((assetsLeftHalf - 1) * 170 + 85) : xPosition - (assetsLeftHalf - 0.5) * 170
  const calculatedData = tokensData?.map((item: any, i: number) => {
    return {
      id: `${i + initialNodes?.length + 1}`,
      parent: [initialNodes?.length],
      layer: 5,
      style: {
        border: '5px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div onClick={() => modifyNodes(5)}>
            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
              <img src={item.logo} alt="img" className="image" />
            ) : (
              <img src={Ripple} alt="img" className="image" />
            )}

            <div className="currency">{item.name}</div>
          </div>
        ),
      },
      position: { x: assetsSPoint + i * 170, y: yPosition[4] },
    }
  })
  const assetsData = calculatedData.length
    ? [...initialNodes, ...calculatedData]
    : [
        ...initialNodes,
        {
          id: `${initialNodes?.length + 1}`,
          parent: [initialNodes?.length],
          layer: 5,
          style: {
            border: '5px solid rgba(38, 119, 233, 0.15)',
            borderRadius: '10px',
          },
          data: {
            label: (
              <div onClick={() => modifyNodes(5)}>
                <img src={Ripple} alt="img" className="image" />
                <div className="currency">Asset 1</div>
              </div>
            ),
          },
          position: { x: xPosition - 210, y: yPosition[4] },
        },
      ]
  const assetsDataLength = assetsData.length
  const fidParents = []
  if (tokensData.length) {
    for (let i = 0; i < tokensData.length; i++) {
      fidParents.push(assetsDataLength - i)
    }
  } else fidParents.push(assetsDataLength)

  const fidData = fiduciary
    ? [
        ...assetsData,
        {
          id: `${assetsData.length + 1}`,
          parent: fidParents,
          layer: 6,
          style: {
            background: '#1BB691',
            border: '8px solid #FFFFFF',
            borderRadius: '20px',
          },
          data: {
            label: (
              <div onClick={() => modifyNodes(6)}>
                <div className="percentage">
                  <FiduciaryName>{`${fiduciary ? `${fiduciary.displayName.slice(0, 4)}..` : 'Jessica'}`}</FiduciaryName>
                  <FiduciaryPercentage>{`${fiduciary ? `${fiduciary.amount}%` : ' 05%'}`}</FiduciaryPercentage>
                </div>
                <FiduciaryResponsibility>{`${fiduciary ? fiduciary.appointAs : 'Fiduciary'}`}</FiduciaryResponsibility>
                <div className="wallet">
                  <WalletFiduciary>{shrinkedFiduciaryWalletId}</WalletFiduciary>
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <CopyToClipboard text={fiduciary.userWalletId} onCopy={() => handleCopy('fiduciary')}>
                      <CopyHover data-tip data-for="from1">
                        <ReactTooltip id="from1" place="top" effect="solid">
                          {fiduciaryWalletId ? <div>copied</div> : <div>Copy To Clipboard </div>}
                        </ReactTooltip>
                        <img
                          src={WalletCopyWhite}
                          onClick={() => {
                            setFiduciaryWalletId(true)
                          }}
                          alt="img"
                        />
                      </CopyHover>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            ),
          },
          position: { x: xPosition, y: yPosition[5] },
        },
      ]
    : assetsData
  const beneficiaryLength = user?.beneficary?.length - 1
  const LeftHalf = beneficiaryLength / 2
  const SPoint =
    beneficiaryLength % 2 == 0 ? xPosition - ((LeftHalf - 1) * 170 + 85) : xPosition - (LeftHalf - 0.5) * 170
  const beneficary = user.beneficary
    .filter((item: any) => item.appointAs == 'Beneficiary')
    .map((item: any, i: number) => {
      return {
        id: `${fidData.length + 1 + i}`,
        parent: [fidData?.length],
        layer: 7,
        style: {
          border: '8px solid #CEFFF9',
          borderRadius: '20px',
        },
        data: {
          label: (
            <div onClick={() => modifyNodes(7)}>
              <div className="percentage">
                <div className="name">{`${item ? `${item.displayName.slice(0, 6)}...` : 'Kristin'}`}</div>
                <div className="amount">{`${item ? `${item.amount}%` : ' 05%'}`}</div>
              </div>
              <BeneficiaryResponsibility>{`${item ? item.appointAs : 'Beneficiary'}`}</BeneficiaryResponsibility>
              <div className="wallet">
                <WalletBeneficiary>
                  {item
                    ? `${item.userWalletId.slice(0, 4)}...${item.userWalletId.slice(
                        item.userWalletId.length - 3,
                        item.userWalletId.length,
                      )}`
                    : 'wallet028nde339'}
                </WalletBeneficiary>
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <CopyToClipboard text={item.userWalletId} onCopy={() => handleCopy('beneficiary')}>
                    <CopyHover data-tip data-for={`beneficiary${i}`}>
                      <ReactTooltip id={`beneficiary${i}`} place="bottom" effect="solid">
                        {beneficiaryWalletId ? <div>copied</div> : <div>Copy To Clipboard </div>}
                      </ReactTooltip>
                      <img
                        src={WalletCopy}
                        alt="img"
                        onClick={() => {
                          setBeneficiaryWalletId(true)
                        }}
                      />
                    </CopyHover>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          ),
        },
        position: { x: SPoint + i * 170, y: yPosition[6] },
      }
    })
  const totalData = fidData ? [...fidData, ...beneficary] : fidData
  const outputNodes = totalData.filter((item: any) => item.layer <= nodesSize)

  const branches = getBranches(outputNodes, 6, 'tree')

  const totalBranches = branches.reduce((previousValue: any, currentValue: any) => {
    return [...previousValue, ...currentValue]
  })
  return [outputNodes, totalBranches]
}
