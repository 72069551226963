import styled from 'styled-components'
import { theme } from 'theme/index'

interface IStyledButton {
  variant?: 'contained' | 'text' | 'outline'
}

const getButtonStyles = ({ variant }: IStyledButton) => {
  switch (variant) {
    case 'contained':
      return `
      background: ${theme.secondary.main};
      color: ${theme.primary.light};
      border: none;
      `
    case 'outline':
      return `
      background: none;
      color: ${theme.secondary.main};
      border: 1px solid rgba(38, 119, 233, 0.28);
      `
    case 'text':
      return `
      background: none;
      color: ${theme.secondary.main};
      border: none;
      `
    default:
      return `
      background: ${theme.secondary.main};
      color: ${theme.primary.light};
      border: none;
  `
  }
}

export const StyledButton = styled.button<IStyledButton>`
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  padding: 0px 7px;
  ${({ variant }) => getButtonStyles({ variant })};
  font-size: 14px;
  font-family: 'OutfitSemiBold';
  height: 50px;
  border-radius: 16px;
  :hover {
    opacity: 0.5;
  }
`
