import React, { useContext, useEffect } from 'react'
import { Switch } from 'antd'
import { LoaderContext } from 'context/loader'
import { Controller, useForm } from 'react-hook-form'
import usePatch from 'hooks/usePatch'
import { UserContext } from 'context/user'
import {
  MainContainer,
  HeadText,
  TextArea,
  NotifyCards,
  NotifyCard,
  TextWrapper,
  ButtonWrapper,
  CheckButtons,
  CheckText,
  SubHeading,
  BottomButtonBox,
  DiscardButton,
  SaveButton,
  RightButtonBox,
} from 'styles/views/dashboard/Settings/Notifications'

interface IDataProps {
  beneficiaryAddedEmail: boolean
  beneficiaryAddedPush: boolean
  beneficiaryAddedSms: boolean
  contractStartedEmail: boolean
  contractStartedPush: boolean
  contractStartedSms: boolean
  fiduciaryChangeEmail: boolean
  fiduciaryChangePush: boolean
  fiduciaryChangeSms: boolean
}

const Notification = () => {
  const { user } = useContext(UserContext)
  const { mutateAsync: updatAsync, isLoading } = usePatch()
  const { setLoader } = useContext(LoaderContext)

  const { handleSubmit, control, setValue } = useForm<IDataProps>()

  const submitData = async (values: IDataProps) => {
    const payload = {
      fiduciaryChangeEmail: values?.fiduciaryChangeEmail,
      fiduciaryChangePush: values?.fiduciaryChangePush,
      fiduciaryChangeSms: values?.fiduciaryChangeSms,
      beneficiaryAddedEmail: values?.beneficiaryAddedEmail,
      beneficiaryAddedSms: values?.beneficiaryAddedSms,
      beneficiaryAddedPush: values?.beneficiaryAddedPush,
      contractStartedEmail: values?.contractStartedEmail,
      contractStartedSms: values?.contractStartedSms,
      contractStartedPush: values?.contractStartedPush,
    }
    try {
      const { updateData } = await updatAsync({
        url: '/user/update',
        payload: payload,
        token: true,
      })
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    setValue('fiduciaryChangeEmail', user?.fiduciaryChangeEmail)
    setValue('fiduciaryChangeSms', user?.fiduciaryChangeSms)
    setValue('fiduciaryChangePush', user?.fiduciaryChangePush)
    setValue('beneficiaryAddedEmail', user?.beneficiaryAddedEmail)
    setValue('beneficiaryAddedSms', user?.beneficiaryAddedSms)
    setValue('beneficiaryAddedPush', user?.beneficiaryAddedPush)
    setValue('contractStartedEmail', user?.contractStartedEmail)
    setValue('contractStartedSms', user?.contractStartedSms)
    setValue('contractStartedPush', user?.contractStartedPush)
  }, [user])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <MainContainer>
      <HeadText>Notifications</HeadText>
      <TextArea></TextArea>
      <form onSubmit={handleSubmit(submitData)} className="form">
        <NotifyCards>
          <NotifyCard>
            <TextWrapper>
              <SubHeading>Fiduciary Change</SubHeading>
            </TextWrapper>
            <ButtonWrapper>
              <CheckButtons>
                <Controller
                  control={control}
                  name="fiduciaryChangePush"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>Push</CheckText>
              </CheckButtons>
              <CheckButtons>
                <Controller
                  control={control}
                  name="fiduciaryChangeEmail"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>E-mail</CheckText>
              </CheckButtons>
              {user?.phoneNo?.length ? (
                <CheckButtons>
                  <Controller
                    control={control}
                    name="fiduciaryChangeSms"
                    render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                  />
                  <CheckText>SMS</CheckText>
                </CheckButtons>
              ) : (
                ''
              )}
            </ButtonWrapper>
          </NotifyCard>
          <NotifyCard>
            <TextWrapper>
              <SubHeading>Beneficiary Added or Removed</SubHeading>
            </TextWrapper>
            <ButtonWrapper>
              <CheckButtons>
                <Controller
                  control={control}
                  name="beneficiaryAddedPush"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>Push</CheckText>
              </CheckButtons>
              <CheckButtons>
                <Controller
                  control={control}
                  name="beneficiaryAddedEmail"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>E-mail</CheckText>
              </CheckButtons>
              {user?.phoneNo?.length ? (
                <CheckButtons>
                  <Controller
                    control={control}
                    name="beneficiaryAddedSms"
                    render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                  />
                  <CheckText>SMS</CheckText>
                </CheckButtons>
              ) : (
                ''
              )}
            </ButtonWrapper>
          </NotifyCard>
          <NotifyCard>
            <TextWrapper>
              <SubHeading>Contract started & Stopped</SubHeading>
            </TextWrapper>
            <ButtonWrapper>
              <CheckButtons>
                <Controller
                  control={control}
                  name="contractStartedPush"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>Push</CheckText>
              </CheckButtons>
              <CheckButtons>
                <Controller
                  control={control}
                  name="contractStartedEmail"
                  render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                />
                <CheckText>E-mail</CheckText>
              </CheckButtons>
              {user?.phoneNo?.length ? (
                <CheckButtons>
                  <Controller
                    control={control}
                    name="contractStartedSms"
                    render={({ field: { value, onChange } }) => <Switch onChange={onChange} checked={value} />}
                  />
                  <CheckText>SMS</CheckText>
                </CheckButtons>
              ) : (
                ''
              )}
            </ButtonWrapper>
          </NotifyCard>
        </NotifyCards>
        <BottomButtonBox>
          <RightButtonBox>
            <DiscardButton label={'Discard'} variant="contained" type="button" />
            <SaveButton type="submit" label={'Save'} />
          </RightButtonBox>
        </BottomButtonBox>
      </form>
    </MainContainer>
  )
}

export default Notification
