import React from 'react'
import AddWallet from 'assets/images/addemail-dark.png'
import SmartContract from 'assets/images/addwallet-dark.png'
import AddBeneficiar from 'assets/images/disbursement-dark.png'
import LifeChanging from 'assets/images/last-dark.png'
import {
  RoadMap,
  LeftSection,
  RightSection,
  FisrtLeftSection,
  FisrtRightSection,
  SecondLeftSection,
  SecondRightSection,
  ThirdLeftSection,
  FourthLeftSection,
  ThirdRightSection,
  FourthRightSection,
  FileImageContainer,
  AddWalletContainer,
} from 'styles/views/landing/MobileSubscriberRoadMap'

const MobileBeneficiaryRoadMap = () => (
  <RoadMap>
    <LeftSection>
      <FisrtLeftSection>
        <AddWalletContainer src={AddWallet} />
      </FisrtLeftSection>
      <SecondLeftSection>
        <FileImageContainer src={SmartContract} />
        Connect Crypto Wallet
      </SecondLeftSection>
      <ThirdLeftSection>
        <FileImageContainer src={AddBeneficiar} />
      </ThirdLeftSection>
      <FourthLeftSection>
        <FileImageContainer src={LifeChanging} />
      </FourthLeftSection>
    </LeftSection>
    <RightSection>
      <FisrtRightSection> Accept Invitation</FisrtRightSection>
      <SecondRightSection />
      <ThirdRightSection>Assets Disbursed</ThirdRightSection>
      <FourthRightSection />
    </RightSection>
  </RoadMap>
)
export default MobileBeneficiaryRoadMap
