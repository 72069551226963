import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import MobileBurgerDrop from 'components/MobileBurgerDrop'

import data from 'views/landing/Header/data'

import HeaderLogo from 'assets/images/logo.png'

import MobileBurger from 'assets/svg/MobileBurger'

import {
  Container,
  Login,
  LoginRegisterSection,
  Wrapper,
  LogoSection,
  Navmenu,
  MenuItem,
  RegisterButton,
  LogoContainer,
  BurgerLogo,
} from 'styles/views/landing/Header'
import { Drawer } from 'antd'

interface IHeaderProps {
  showModal(value: boolean, type: string): void

  scrollView(refName: string): void

  controls: any
}

const Header: React.FC<IHeaderProps> = ({ showModal, scrollView, controls }: IHeaderProps) => {
  const [, setIsUpdatePlanOpen] = useState(false)
  const navigate = useNavigate()

  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Container initial={{ y: 0 }} animate={controls} transition={{ delay: 0.05, duration: 0.8 }}>
      <Wrapper>
        <LogoContainer>
          <BurgerLogo>
            <MobileBurger onClick={() => showDrawer()} />
          </BurgerLogo>

          <LogoSection src={HeaderLogo} />
        </LogoContainer>
        <Navmenu>
          {data?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                item?.title == 'Our Solution' ? navigate('/oursolution') : scrollView(item.title)
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </Navmenu>

        <LoginRegisterSection>
          <Login
            label="Sign In"
            variant="outline"
            onClick={() => {
              localStorage.setItem('setRegister', 'false')
              process.env.REACT_APP_COMING_SOON === 'false' ? navigate('/timer') : navigate('/WelcomePlan')
            }}
          />

          <RegisterButton
            label="Register"
            variant="contained"
            onClick={() => {
              localStorage.setItem('setRegister', 'true')
              process.env.REACT_APP_COMING_SOON === 'false' ? navigate('/timer') : showModal(true, 'option')
            }}
            type="button"
          />
        </LoginRegisterSection>
      </Wrapper>

      <Drawer
        placement="left"
        onClose={onClose}
        visible={open}
        width="80vw"
        closable={false}
        bodyStyle={{ padding: '0px', overflowY: 'hidden', background: '#01205b' }}
      >
        <MobileBurgerDrop
          showModal={(value: boolean) => setUpdatePlanModal(value)}
          scrollView={(refName: string) => scrollView(refName)}
          setIsUpdatePlan={onClose}
        />
      </Drawer>
    </Container>
  )
}

export default Header
