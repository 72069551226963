import EOS from 'assets/images/eos-network.png'
import Klaytn from 'assets/images/klaytn-network.png'
import Steller from 'assets/images/steller-network.png'
import Tezos from 'assets/images/tezos-network.png'
import Tron from 'assets/images/tron-network.png'
import XDC from 'assets/images/xdc-network.png'
import { SelectWrapper, NetworkImage, NetworkPara } from 'styles/components/MyProfile'

interface IData {
  value: string
  label: any
}

const data: IData[] = [
  {
    value: 'EOS',
    label: (
      <SelectWrapper>
        <NetworkImage src={EOS} />
        <NetworkPara>EOS</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'goerli',
    label: (
      <SelectWrapper>
        <NetworkImage src={EOS} />
        <NetworkPara>goerli</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'Klaytn',
    label: (
      <SelectWrapper>
        <NetworkImage src={Klaytn} />
        <NetworkPara>Klaytn</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'Steller',
    label: (
      <SelectWrapper>
        <NetworkImage src={Steller} />
        <NetworkPara>Steller</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'Tezos',
    label: (
      <SelectWrapper>
        <NetworkImage src={Tezos} />
        <NetworkPara>Tezos</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'Tron',
    label: (
      <SelectWrapper>
        <NetworkImage src={Tron} />
        <NetworkPara>Tron</NetworkPara>
      </SelectWrapper>
    ),
  },
  {
    value: 'XDC',
    label: (
      <SelectWrapper>
        <NetworkImage src={XDC} />
        <NetworkPara>XDC</NetworkPara>
      </SelectWrapper>
    ),
  },
]
export default data
