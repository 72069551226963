import React from 'react'

import DelectIcon from 'assets/images/delete-icon.png'
import CrossLogo from 'assets/images/grayx-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  Cross,
  DeleteContainer,
  CancelButton,
  SaveButton,
} from 'styles/components/RemoveUserModal'

export interface IModalProps {
  showModal: (value: boolean) => void
  removeBenificiary?: () => void
  removeWallet?: () => void
  walletIndex?: string
}

const RemoveUserModal: React.FC<IModalProps> = ({ showModal, removeBenificiary, walletIndex, removeWallet }) => {
  const savehandler = () => {
    if (removeBenificiary) {
      removeBenificiary()
      showModal(false)
    }

    if (removeWallet) removeWallet()
  }

  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <Cross src={CrossLogo} alt="Close" onClick={() => showModal(false)} />
        </Container>
        <DeleteContainer>
          <LogoSection src={DelectIcon} alt="Delete" />
        </DeleteContainer>
        <TextSection>
          <HeadingSection>
            Do you want to remove this {walletIndex?.substring(0, 5)}...
            {walletIndex?.substring(walletIndex?.length - 3, walletIndex?.length)} Wallet?
          </HeadingSection>
          <Defination></Defination>
        </TextSection>
        <ButtonSection>
          <CancelButton label="Cancel " variant="contained" type="button" onClick={() => showModal(false)} />
          <SaveButton label="Remove Now " variant="contained" type="button" onClick={savehandler}></SaveButton>
        </ButtonSection>
      </Wrapper>
    </MainContainer>
  )
}

export default RemoveUserModal
