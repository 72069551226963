import SignupWallet from 'assets/images/signup-wallet.png'
import trust from 'assets/images/TWT.png'
import rainbow from 'assets/images/exodus-mobile.png'
import coinbase from 'assets/images/coin-base.png'
interface IData {
  id: number
  image: string
  title: string
  url: string
}

const data: IData[] = [
  {
    id: 0,
    image: SignupWallet,
    title: 'MetaMask',
    url: 'https://metamask.io/download/',
  },
  {
    id: 1,
    image: trust,
    title: 'Trust',
    url: 'https://trustwallet.com/download/',
  },
  {
    id: 2,
    image: rainbow,
    title: 'Exodus',
    url: 'https://www.exodus.com/download/',
  },
  {
    id: 3,
    image: coinbase,
    title: 'Coinbase',
    url: 'https://www.coinbase.com/wallet/downloads',
  },
]
export default data
