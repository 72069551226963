import React from 'react'
import AddWallet from 'assets/images/addwallet-dark.png'
import SmartContract from 'assets/images/smartcontract-dark.png'
import AddBeneficiar from 'assets/images/addbeneficiar-dark.png'
import LifeChanging from 'assets/images/lifechanging-dark.png'
import Disbursement from 'assets/images/disbursement-dark.png'
import {
  RoadMap,
  LeftSection,
  RightSection,
  FisrtLeftSection,
  FisrtRightSection,
  SecondLeftSection,
  SecondRightSection,
  ThirdLeftSection,
  FourthLeftSection,
  FifthLeftSection,
  ThirdRightSection,
  FourthRightSection,
  FifthrightSection,
  FileImageContainer,
  AddWalletContainer,
} from 'styles/views/landing/MobileSubscriberRoadMap'

const MobileSubscriberRoadMap = () => (
  <RoadMap>
    <LeftSection>
      <FisrtLeftSection>
        <AddWalletContainer src={AddWallet} />
      </FisrtLeftSection>
      <SecondLeftSection>
        <FileImageContainer src={SmartContract} />
        Add Beneficiaries
      </SecondLeftSection>
      <ThirdLeftSection>
        <FileImageContainer src={AddBeneficiar} />
      </ThirdLeftSection>
      <FourthLeftSection>
        <FileImageContainer src={LifeChanging} />
        Life Changing Events
      </FourthLeftSection>
      <FifthLeftSection>
        <FileImageContainer src={Disbursement} />
      </FifthLeftSection>
    </LeftSection>
    <RightSection>
      <FisrtRightSection> Add Wallet</FisrtRightSection>
      <SecondRightSection />
      <ThirdRightSection>Smart Contract Created</ThirdRightSection>
      <FourthRightSection />
      <FifthrightSection>Disbursement</FifthrightSection>
    </RightSection>
  </RoadMap>
)
export default MobileSubscriberRoadMap
