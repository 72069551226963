import { useState, useEffect, useContext } from 'react'
import { Progress } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import More from 'assets/svg/More'
import DaignoalArrow from 'assets/images/daigonal-arrow.png'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { Carddata, columns } from 'views/dashboard/Settings/Billing/data'
import Modal from 'components/Modal'
import PaymentModal from 'components/PaymentModal'
import Watermark from 'assets/images/watermark-image.png'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import Table from 'components/Table'
import AllPaymentView from 'components/Modal/AllPaymentView'
import {
  MainContainer,
  HeadText,
  Container,
  PlanContainer,
  PaymentContainer,
  LabelText,
  TextArea,
  TopContainer,
  SubTopContainer,
  FreeText,
  LimitedText,
  ProgressContainer,
  ProgressText,
  UpgradeText,
  UpgradeContainer,
  DaigonalIcon,
  CardContainer,
  WalletCard,
  WalletContainer,
  BrandText,
  EmailText,
  MethodText,
  BottomContainer,
  AddText,
  TableContainer,
  TableTopcontainer,
  TableWrapper,
  LimtedWrapper,
  Download,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  TextSection,
  MainUpgradeContainer,
  DesktopView,
  MobileView,
  SubTopRowContainer,
} from 'styles/views/dashboard/Settings/Billing'

interface IModalProps {
  invoiceData?: any
  blockData?: any
  willInfo?: any
}

const Billing = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenpay, setIspayOpen] = useState(false)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const plan = beneficiariesData?.data?.beneficary?.length
  const [downloadedData, setDownloadedData] = useState('')
  const { mutateAsync } = usePost()
  const navigate = useNavigate()

  const { data: subscribe } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })

  const lastElement = subscribe?.subscriptionData?.subscription?.length
    ? subscribe?.subscriptionData?.subscription[subscribe?.subscriptionData?.subscription?.length - 1]
    : {}

  const { data: invoiceData, refetch } = useGet('get-invoice ', 'get/invoice ', false, {
    token: true,
  })

  useEffect(() => {
    refetch()
  }, [invoiceData])

  useEffect(() => {
    if (downloadedData) {
      const linkSource = `data:application/pdf;base64,${downloadedData}`
      const downloadLink = document.createElement('a')
      const fileName = 'Payment_Invoice.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }, [downloadedData])

  const userInvoice = invoiceData?.user?.invoice

  const tableData = []

  for (let i = userInvoice?.length - 1; i >= 0; i--) {
    const elementData = userInvoice[i]

    const dateSplit = new Date(elementData?.date_Time)?.toLocaleDateString('en-us', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    })

    const date = new Date(elementData?.data)?.toLocaleDateString('en-us', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    })

    tableData.push({
      key: i,
      invoice: `Invoice #${i + 1} (${dateSplit})`,
      billingDate: date,
      amount: elementData?.transaction_Fee,
      plan: elementData?.plan,
      status: 'Paid',
      action: <Download onClick={() => downloadData()}>Download</Download>,
    })

    const downloadData = async () => {
      const payload = {
        wallet_Address: elementData?.wallet_Address,
        number: elementData?.number,
        date_Time: elementData?.date_Time,
        gas_Price: elementData?.gas_Price,
        eth_Value: elementData?.eth_Value,
        transfer: elementData?.transfer,
        to_WalletAddress: elementData?.to_WalletAddress,
        contract: elementData?.contract,
        from_WalletAddress: elementData?.from_WalletAddress,
        transaction_Fee: elementData?.transaction_Fee,
        ether_Price: elementData?.ether_Price,
      }
      try {
        const downloadData = await mutateAsync({
          url: '/data/downloadData ',
          payload: payload,
          token: true,
        })

        setDownloadedData(downloadData?.data)
        return downloadData?.data
      } catch (error: any) {
        return { error: error?.response?.data?.message }
      }
    }
  }

  return (
    <MainContainer>
      <HeadText>Billing</HeadText>
      <Container>
        <PlanContainer>
          <TopContainer>
            <SubTopRowContainer>
              <div>
                <LabelText>{lastElement?.subscriptionPlan} Plan</LabelText>
              </div>
              <FreeText>${lastElement?.subscriptionAmount}</FreeText>
            </SubTopRowContainer>
            <LimtedWrapper>
              <LimitedText>Limited Beneficiaries</LimitedText>
            </LimtedWrapper>
          </TopContainer>

          <ProgressContainer>
            {plan === 0 && (
              <>
                <ProgressText>No Beneficiaries</ProgressText>
                <Progress
                  percent={2}
                  strokeColor={{
                    '2%': '#e91010de',
                  }}
                />
              </>
            )}
            {plan === 1 && (
              <>
                <ProgressText>{plan} of 3 Beneficiaries</ProgressText>
                <Progress
                  percent={33.33}
                  strokeColor={{
                    '33.33%': '#108ee9',
                  }}
                />
              </>
            )}
            {plan === 2 && (
              <>
                <ProgressText>{plan} of 3 Beneficiaries</ProgressText>
                <Progress
                  percent={66}
                  strokeColor={{
                    '66%': '#108ee9',
                  }}
                />
              </>
            )}
            {plan === 3 && (
              <>
                <ProgressText>{plan} of 3 Beneficiaries</ProgressText>
                <Progress
                  percent={100}
                  strokeColor={{
                    '100%': '#108ee9',
                  }}
                />
              </>
            )}
            {plan === 4 && (
              <>
                <ProgressText>{plan} Beneficiaries</ProgressText>
                <Progress
                  percent={100}
                  strokeColor={{
                    '100%': '#108ee9',
                  }}
                />
              </>
            )}
            {plan > 4 && (
              <>
                <ProgressText>4+ Beneficiaries</ProgressText>
                <Progress
                  percent={95}
                  strokeColor={{
                    '95%': '#108ee9',
                  }}
                />
              </>
            )}
          </ProgressContainer>
          <MainUpgradeContainer>
            <DesktopView>
              <UpgradeContainer>
                <UpgradeText
                  onClick={() => {
                    navigate('/UpgradePlan')
                  }}
                >
                  Upgrade plans
                </UpgradeText>
                <DaigonalIcon src={DaignoalArrow} alt="goto" />
              </UpgradeContainer>
            </DesktopView>
            <MobileView>
              <UpgradeContainer>
                <UpgradeText
                  onClick={() => {
                    navigate('/mobileupgradeplan')
                  }}
                >
                  Upgrade plans
                </UpgradeText>
                <DaigonalIcon src={DaignoalArrow} alt="goto" />
              </UpgradeContainer>
            </MobileView>
          </MainUpgradeContainer>
        </PlanContainer>
        <PaymentContainer>
          <SubTopContainer>
            <div>
              <LabelText>Preferred Payment Mode</LabelText>
              <TextArea>Change how you pay for your plan.</TextArea>
            </div>
            <AddText onClick={() => setIsOpen(!isOpen)}>
              <More />
              <TextSection> Add Payment Method</TextSection>
            </AddText>
          </SubTopContainer>
          <CardContainer>
            {Carddata.map((item, index) => {
              return (
                <WalletCard key={index}>
                  <WalletContainer>
                    <img src={item.image} alt="Apple" />
                    <div>
                      <BrandText>{item.brand}</BrandText>
                      <EmailText>{item.subtitle}</EmailText>
                    </div>
                  </WalletContainer>
                  <MethodText>{}</MethodText>
                </WalletCard>
              )
            })}
          </CardContainer>
          <BottomContainer onClick={() => setIspayOpen(!isOpen)}>
            View All <ArrowRightOutlined />
          </BottomContainer>
        </PaymentContainer>
      </Container>
      <TableContainer>
        <TableTopcontainer>
          <div>
            <LabelText>Invoices</LabelText>
            <TextArea></TextArea>
          </div>
        </TableTopcontainer>
        <TableWrapper>
          {userInvoice ? (
            <Table data={tableData} columns={columns} />
          ) : (
            <div>
              <NoBeneficiaries>
                <BeneficiarieImage>
                  <CryptoLogo src={Watermark} />
                </BeneficiarieImage>
                <TextSextion>
                  <HeadingSection>No Beneficiaries Added</HeadingSection>
                </TextSextion>
              </NoBeneficiaries>
            </div>
          )}
        </TableWrapper>
      </TableContainer>

      <Modal isOpen={isOpen}>
        <PaymentModal showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
      <Modal isOpen={isOpenpay}>
        <AllPaymentView showModal={(value: boolean) => setIspayOpen(value)} />
      </Modal>
    </MainContainer>
  )
}

export default Billing
