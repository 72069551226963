import { SVGProps } from 'react'
const Tick = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="#fff" />
    <path
      d="M6.667 11.927 10.46 16l6.874-8"
      stroke="#4473F5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Tick
