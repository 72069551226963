import { ReactElement, useState } from 'react'
import data from 'components/FiduciarySidebar/data'
import SidebarCard from 'components/Cards/SideBar'
import Modal from 'components/Modal'
import CommingSoon from 'components/Cards/CommingSoon'
import { Container, Wrapper } from 'styles/components/fiduciarySidebar'
export const FiduciarySidebar = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Container>
      <Wrapper>
        {data?.map((item) => (
          <SidebarCard
            key={item.id}
            image={item?.icon}
            title={item?.title}
            path={item?.path}
            showModal={(value: boolean) => setIsOpen(value)}
          />
        ))}
      </Wrapper>
      <Modal isOpen={isOpen}>
        <CommingSoon showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Container>
  )
}

export default FiduciarySidebar
