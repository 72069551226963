import styled from 'styled-components'
import Button from 'components/Button'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
`
export const LogoSection = styled.img`
  padding: 20px;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  width: 100%;
  max-width: 363px;
  text-align: center;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 46px;
  line-height: 58px;
  padding: 20px 86px 19px 86px;
  color: red;
`
export const Defination = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  padding: 10px 35px 20px 35px;
`
export const Definition2 = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitRegular';
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #737b7d;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 150px;
  align-items: center;
  padding-bottom: 26px;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 86.933vw;
  }
`
export const DiscardButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #000;
`
export const SaveButton = styled(Button)`
  max-width: 150px;

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  opacity: ${(props) => (props.type === 'button' ? 0.5 : 1)};
`
export const RightButtonBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  width: 100%;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 10px;
  }
`
