import styled from 'styled-components'
import { Form } from 'antd'
import Button from 'components/Button'
import { theme } from 'theme'

export const Container = styled.div`
  background: #f1f5fb;
  width: 100%;
`
export const Wrapper = styled.div`
  min-height: 900px;
  width: 100%;
`
export const WelcomeTextSection = styled.div`
  display: flex;
  gap: 22px;
  align-items: baseline;
  justify-content: center;
  justify-content: space-between;
`
export const TopTextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 52px;
  line-height: 80px;
  color: #0a0c17;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 11.2vw;
    line-height: 13.333vw;
    text-align: center;
    padding: 13.333vw 0 24vw 0;
  }
`
export const BottomTextSection = styled.div`
  font-family: 'OutfitLight';
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 23px;
`
export const SkipSection = styled.div`
  display: flex;
  cursor: pointer;
  gap: 4px;
  padding-right: 24px;
`
export const SkipText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 17px;
  line-height: 33px;
  letter-spacing: 0.3px;
  color: #000000;
`
export const SkipImageSection = styled.img`
  height: 20px;
  padding-top: 7px;
`
export const ContainerTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex: auto;
`
export const PrimaryWalletSection = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  background: ${theme.primary.light};
  border-radius: 34px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
  }
`
export const PrimaryText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  color: #0a0c17;
  text-align: initial;
  @media (max-width: 767px) {
    font-size: 4.8vw;
    padding: 0.533vw 0px 10.133vw 5.867vw;
  }
`
export const MobileWalletSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  border-bottom: 1px solid #dfdfdf;
`
export const WalletLogoSection = styled.img`
  height: 50px;
  @media (max-width: 767px) {
    height: 14.667vw;
    width: 14.667vw;
  }
`
export const TextSectionMobile = styled.div`
  font-family: 'OutfitRegular';
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`
export const TextIdSection = styled.div`
  font-family: 'OutfitMedium';
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #2677e9;
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileTextIdSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
`
export const LeftSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 12px;
`
export const PrimaryWalletContainer = styled.div`
  padding: 100px 46px 13px 46px;
  @media (max-width: 767px) {
    padding: 8vw 5.067vw 10.133vw 5.867vw;
  }
`
export const ProfileSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  color: #0a0c17;
  text-align: initial;
  padding: 30px 0px 30px 0;
  margin-left: 48px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
`
export const InfoRightSection = styled.div``

export const InputWrapper = styled.div`
  margin-top: 6px;
  .text {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: 90%;
    outline: none;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .ant-row {
    margin-bottom: 7px;
  }
`
export const ButtonSection = styled.div`
  display: flex;
  padding-top: 20px;
  gap: 20px;
  justify-content: end;
  max-width: 70.333vw;
`
export const Cancel = styled(Button)`
  color: ${theme.info.dark};
  border: 1px solid rgba(0, 0, 0, 0.28);
`
export const LeftSectionButton = styled.div`
  display: flex;
  width: 35%;
  gap: 12px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 68.333vw;
  }
`
export const ButtonWrapper = styled(Form.Item)`
  width: 100%;
`
export const Label = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
  text-align: initial;
  margin-left: 50px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
`
export const Error = styled.div`
  color: red;
  font-size: 12px;
  text-align: initial;
  margin-left: 42px;
  margin-top: 6px;
`
export const WalletButton = styled.div`
  width: 100%;
  max-width: 150px;
  .sc-bdvvtL {
    background: rgba(68, 115, 245, 0.13);
    border: 1px solid #a7cbff;
    border-radius: 16px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 28.533vw;
  }
`
export const CondtionsWrapper = styled.div`
  display: flex;
  margin-left: 50px;
  gap: 7px;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  .terms-link {
    color: #4473f5;
  }
  .ant-checkbox-inner {
    width: 17px;
    height: 17px;
    border: 2px solid #000;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 2px solid #000;
  }
  @media (max-width: 767px) {
    margin-left: 0px;
  }
`
export const ConditionsText = styled.div`
  display: flex;
  gap: 5px;
`
export const StyledButton = styled(Button)<{ type: string }>`
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  opacity: ${(props) => (props.type === 'button' ? 0.5 : 1)};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #f1f5fb;
  position: sticky;
  padding: 10px 10px 10px 10px;
  @media (max-width: 1366px) {
    padding: 10px 10px 0 10px;
  }
`

export const ThemeIcon = styled.img`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    height: 11.467vw;
    width: 11.467vw;
  }
`
export const LogoSection = styled.img`
  @media (max-width: 767px) {
    width: 41.333vw;
  }
`
