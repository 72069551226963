import { useEffect, useState, useContext } from 'react'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import usePut from 'hooks/usePut'
import { NextButton } from 'styles/pages/WelcomePlan'

const Start = () => {
  const { setLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const [isOpens, setIsOpens] = useState(false)
  const [error, setError] = useState(false)
  const { mutateAsync, isLoading } = usePut()
  const disabled =
    user?.contractStatus === 'Ready' ||
    user?.contractStatus === 'Processing' ||
    user?.contractStatus === 'Terminated' ||
    user?.isEtherWithdrawn

  const startContract = async () => {
    try {
      const resp = await mutateAsync({
        url: 'contract/startContract',
        token: true,
      })
      if (resp?.success) {
        window.location.reload()
      }
    } catch (error: any) {
      setError(true)
      return { error: error?.response?.data?.message }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <div>
      <NextButton label={'Start contract'} disabled={disabled} onClick={startContract} />
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} error={error} success={false} />
      </Modal>
    </div>
  )
}

export default Start
