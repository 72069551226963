import styled from 'styled-components'
export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 27px;
`
export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 34px;
`
export const Wrapper = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.3px;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 20px;
`
export const WrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  @media (max-width: 1366px) {
    padding: 10px 10px 10px 10px;
  }
`
export const RadioGroup = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 34px;
  padding: 20px;

  @media (max-width: 1366px) {
    gap: 22px;
  }
`
export const BlankSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const ButtonWrapper = styled.div`
  width: 100%;
`
export const ButtonContainer = styled.div`
  display: flex;
  max-width: 350px;
  gap: 15px;
  margin-left: auto;
  padding: 38px 35px 50px 58px;
`
export const ButtonLable = styled.div`
  display: flex;
  text-align: center;
  gap: 7px;
  align-items: center;
`
export const RightButton = styled.div``
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LeftText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
`
export const RightImage = styled.img`
  height: 20px;
`
export const Chooseplan = styled.div`
  width: 100%;
  padding: 8px 10px 10px 37px;
  @media (max-width: 1366px) {
    padding: 8px 5px 5px 37px;
  }
`
export const TopText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 24px;
  font-weight: 500;
  font-size: 24px;
  align-items: center;
`
export const OptionText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const SectionButton = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  .sc-gsnTZi {
    max-width: 140px;
  }
`
export const ButtonWrappers = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  gap: 20px;
`
export const TextSection = styled.div`
  @media (max-width: 767px) {
    font-size: 3.2vw;
    margin-left: 2px;
  }
`
export const MoreSvg = styled.div`
  @media (max-width: 767px) {
  }
`
