import { ReactElement, useEffect } from 'react'
import useGet from 'hooks/useGet'
import FiduciaryNavBar from 'components/FiduciaryNavBar'
import FiduciarySidebar from 'components/FiduciarySidebar'
import FiduciaryMyPlan from 'views/dashboard/FiduciaryMyPlan'

import { Container, SecContainer, SidebarSection } from 'styles/pages/Fiduciarydashboard'

export const Fiduciarydashboard = (): ReactElement => {
  const { data: fiduciaryView, refetch: viewRefetch } = useGet('view-fiduciary', 'getView/getFiduciaryView', false, {
    enabled: true,
    token: true,
  })

  const fiduciaryName =
    fiduciaryView?.fiducarydetail && fiduciaryView?.fiducarydetail.length > 0
      ? fiduciaryView?.fiducarydetail[0].appointedAs
      : ''
  const ownerName =
    fiduciaryView?.fiducarydetail && fiduciaryView?.fiducarydetail.length > 0
      ? fiduciaryView?.fiducarydetail[0]?.ownerName
      : ''

  useEffect(() => {
    viewRefetch()
  }, [])

  return (
    <Container>
      <FiduciaryNavBar />
      <SecContainer>
        <SidebarSection>
          <FiduciarySidebar />
        </SidebarSection>
        <FiduciaryMyPlan ownerName={ownerName} fiduciaryName={fiduciaryName} />
      </SecContainer>
    </Container>
  )
}

export default Fiduciarydashboard
