import React, { useState, useContext } from 'react'
import { Tabs } from 'antd'
import SideBar from 'components/SideBar'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import MyCryptoPlan from 'views/dashboard/MyPlan/CreatePlan'
import InviteFriends from 'components/Cards/InviteFriends'
import UpdateCrypto from 'views/dashboard/Beneficiaries/UpdateCrypto'
import Modal from 'components/Modal'
import NavBar from 'components/NavBar'
import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  HeadingArea,
  ButtonBox,
  TopBox,
  TextContainer,
  Section,
  TableContent,
  Invite,
  CreateButton,
} from 'styles/views/dashboard/Beneficiaries'
import BeneficiariesContent from 'views/dashboard/Beneficiaries/BeneficiariesContent'
import InvitedFriend from 'views/dashboard/Beneficiaries/InvitedFriends'

const { TabPane } = Tabs

const Beneficiaries = () => {
  const [toggle, setToggle] = useState(false)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)
  const [isCreatePlanOpen, setIsCreatePlanOpen] = useState(false)
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)

  const setPlanModal = (value: boolean) => {
    setIsCreatePlanOpen(value)
  }
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }
  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }

  return (
    <Wrapper>
      <NavBar />
      <Section>
        <SideBar />
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Beneficiaries</TextArea>
                <HeadingArea></HeadingArea>
              </TextContainer>
              <ButtonBox>
                {toggle && <Invite label="Invite Friends" variant="contained" onClick={() => setInviteModal(true)} />}
                {beneficiariesData?.data?.paymentTransition === true ? (
                  <CreateButton
                    label="Update My Cryptoplan"
                    variant="contained"
                    onClick={() => setUpdatePlanModal(true)}
                  />
                ) : (
                  <CreateButton label="Create My Cryptoplan" variant="contained" onClick={() => setPlanModal(true)} />
                )}
              </ButtonBox>
            </TopBox>
            <TableContent>
              <Tabs
                defaultActiveKey="1"
                onChange={(activeKey) => {
                  if (activeKey == '2') {
                    setToggle(true)
                  } else {
                    setToggle(false)
                  }
                }}
              >
                <TabPane tab="Beneficiaries" key="1">
                  <BeneficiariesContent />
                </TabPane>
                <TabPane tab="Invited Friends" key="2">
                  <InvitedFriend />
                </TabPane>
              </Tabs>
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
      <Modal isOpen={isCreatePlanOpen}>
        <MyCryptoPlan showModal={(value: boolean) => setPlanModal(value)} />
      </Modal>
      <Modal isOpen={isUpdatePlanOpen}>
        <UpdateCrypto showModal={(value: boolean) => setUpdatePlanModal(value)} />
      </Modal>
      <Modal isOpen={isCreateInviteOpen}>
        <InviteFriends showModal={(value: boolean) => setInviteModal(value)} />
      </Modal>
    </Wrapper>
  )
}

export default Beneficiaries
