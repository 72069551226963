import styled from 'styled-components'
import Button from 'components/Button'

export const SidebarWrapper = styled.div`
  width: 100%;
  display: flex;
`
export const MainContainerBox = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const MainContainer = styled.div`
  background: #f1f5fb;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 800px;
  }
  @media (max-width: 767px) {
    margin: 10;
    width: 100%;
    max-width: 94vw;
  }
`
export const MainWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 15px 30px;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 540px;
    margin-bottom: 10px;
  }
`
export const PaymentText = styled.div``
export const SubscricptionsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px 35px 8px 0;
  align-items: center;
`
export const USDButton = styled.div`
  width: 100%;
  max-width: 280px;
  display: flex;
  gap: 10px;
`
export const TopText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 4.6vw;
  }
`
export const BottomText = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  color: #737b7d;
`
export const EarnedMonths = styled.div`
  width: 100%;
  border: 1px solid #eaeaea;
  width: 318px;
  box-sizing: border-box;
  border-radius: 35px;
  display: flex;
  padding: 26px 24px 26px 30px;
  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 64vw;
    padding: 2.667vw;
  }
`
export const ImageSection = styled.img``
export const Textsection = styled.div`
  display: flex;
  flex-direction: column;
`
export const TopSection = styled.div`
  font-family: 'OutfitLight';
  font-size: 32px;
  line-height: 40px;
`
export const BottomSection = styled.div`
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
export const CardSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const SubscricptionsText = styled.div`
  display: flex;
  flex-direction: column;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  line-height: 29px;
  padding: 8px 31px 21px 6px;
`
export const SectionContainer = styled.div``
export const Promotions = styled.div`
  display: flex;
  width: 100%;
  background: #eff3ff;
  border-radius: 17px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const SectionFirst = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
`
export const MemberShip = styled.div``
export const DateSction = styled.div``
export const PromotionText = styled.div``
export const SecndSection = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
`
export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  gap: 10px;
  align-items: center;
  @media (max-width: 767px) {
    padding: 2.667vw !important;
    width: 100%;
  }
`
export const RightSection = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  justify-content: space-between;
  padding: 18px 39px 18px 30px;
  gap: 120px;
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2.667vw;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    justify-content: space-around;
    gap: 91px;
    padding: 18px 12px 18px 12px;
  }
`
export const TextTopSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 24px;
`
export const ButtonSection = styled.div`
  align-items: center;
`
export const PaymentSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 11px 5px;
  @media (max-width: 767px) {
    align-items: center;
    padding: 10px;
  }
`
export const PaymentLeftText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  letter-spacing: 0.3px;
  @media (max-width: 767px) {
    font-size: 3.667vw;
  }
`
export const Invite = styled(Button)`
  background-color: #1bb691;
`
export const PromotionsButton = styled(Button)`
  border-color: #1bb691;
  color: #1bb691;
  border-radius: 40px;
`
export const RightButton = styled.div``
export const FirstCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px;
`
export const WalletImage = styled.img``
export const WalletText = styled.div`
  width: 100%;
  max-width: 200px;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  gap: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    gap: 2.667vw;
  }
`
export const TotalConnected = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const BottomPyment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Addmethod = styled.div`
  @media (max-width: 767px) {
    font-size: 10px;
  }
`

export const ButtonLable = styled.div`
  display: flex;
  text-align: center;
  gap: 7px;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 10px;
    gap: 1px;
  }
`
export const ButtonLabled = styled.div`
  display: flex;
  text-align: center;
  gap: 7px;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 10px;
    gap: 1px;
    width: 100%;
    max-width: 90px;
    margin-left: 14px;
  }
`
export const MainTopContainer = styled.div`
  width: 100%;
  max-width: 200px;
`
export const RightSectionText = styled.div`
  display: flex;
  gap: 56px;
  @media (max-width: 1280px) {
    display: flex;
    gap: 56px;
    justify-content: space-between;
  }

  @media (max-width: 1366px) {
    display: -webkit-box;
    gap: 56px;
  }
  @media only screen and (max-width: 900px) {
    gap: 40px;
  }
`
export const TextLeftSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const PaymentContainer = styled.div``
export const PromotionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Actions = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  max-width: 119px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 767px) {
    top: 1px;
    right: 7.813vw;
  }
  @media (max-width: 1440px) {
    top: 1px;
  }
`
export const ActionText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`
export const ImageContainer = styled.div`
  @media (max-width: 767px) {
    margin-top: 0.267vw;
  }
`
