import React from 'react'

interface IUniqueTokensContext {
  uniqueTokensContext: any
  setUniqueTokensContext: any
}

const defaultState = {
  uniqueTokensContext: [],
  setUniqueTokensContext: () => null,
}

const UniqueTokensContext = React.createContext<IUniqueTokensContext>(defaultState)

export { UniqueTokensContext }
