import styled from 'styled-components'

export const Container = styled.div``
export const Wrapper = styled.div`
  width: 100%;
  background: #f1f5fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`
export const WelcomeText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 52px;
  line-height: 80px;
  @media (max-width: 767px) {
    width: 65.6vw;
    font-size: 11.2vw;
    line-height: 50px;
    margin-top: 17.333vw;
  }
`
export const WelcomeProfile = styled.div`
  width: 100%;
  max-width: 443px;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto;
`
export const SelectWalletSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding: 29px 139px 11px 63px;
  @media (max-width: 1366px) {
    padding: 22px 139px 7px 63px;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 2.667vw;
  }
`
export const TextSection = styled.div`
  width: 100%;
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  margin-left: 20px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.8vw;
  }
`
export const BottomText = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  color: #737b7d;
  @media (max-width: 767px) {
    font-size: 3.2vw;
  }
`
export const BottomRightText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 18px;
  color: #4473f5;
  cursor: pointer;
`
export const AboutCrypto = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  /* padding: 10px 10px 50px 10px; */
  @media (max-width: 1366px) {
    padding: 20px 10px 20px 10px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 93.133vw;
    display: flex;
  }
`
export const MainSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #f1f5fb;
  position: sticky;
  padding: 10px 10px 10px 10px;
  @media (max-width: 1366px) {
    padding: 10px 10px 0 10px;
  }
`
export const LogoSection = styled.img``
export const ThemeIcon = styled.img`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`
export const BackSection = styled.div`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  display: none;
  padding: 22px 0 22px 21px;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    gap: 2.667vw;
    /* display: block; */
  }
`
export const BackImage = styled.img`
  height: 13px;
`

export const BackText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  color: #a4a4a4;
`
