import styled from 'styled-components'

export const Loader = styled.img`
  height: 100px;
  width: 100px;
`
export const UptoLoader = styled.img`
  height: 220px;
  width: 210px;
`
