import styled from 'styled-components'
import Button from 'components/Button'

export const Invite = styled(Button)`
  background-color: #1bb691;
`
export const Create = styled(Button)``
export const Container = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
  @media only screen and (max-width: 900px) {
    width: 100%;
    max-width: 592px;
  }
`
export const Wrapper = styled.div`
  width: 100%;
`
export const LeftSection = styled.div`
  flex: 10;
  width: 100%;
  max-width: 1280px;
  @media (max-width: 1440px) {
    gap: 8px;
  }
  @media (max-width: 1024px) {
    margin-right: 12px;
  }
`
export const RightSection = styled.div`
  background: #ffffff;
  border-radius: 34px;
  width: 100%;
  max-width: 600px;
  box-shadow: 7px 4px 16px 4px rgb(22 29 36 / 22%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
  }
`
export const LeftTopSection = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  padding: 14px;
  box-shadow: 7px 4px 16px 4px rgb(22 29 36 / 22%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
  }
`
export const LeftBottomSection = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
`
export const WelcomeSection = styled.div`
  font-family: 'OutfitLight';
  font-size: 42px;
  color: #0a0c17;
  @media (max-width: 1440px) {
    font-size: 31px;
  }
  @media (max-width: 767px) {
    font-size: 6.067vw;
  }
`
export const WelcomeText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  color: #737b7d;
  @media (max-width: 767px) {
    font-size: 3.733vw;
  }
`
export const Box = styled.div`
  display: flex;
  background: #e2eaff;
  border-radius: 28px;
  width: 100%;
  justify-content: space-between;
  padding: 15px 15px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
export const ButtonBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 330px;
  align-items: center;
  gap: 13px;
  margin-left: 5px;
`
export const WelcomeBox = styled.div`
  width: 100%;
`
export const DashbordContentSection = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
  }
`
export const ContentWrapper = styled.div``
export const ContainerSecond = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
  @media (max-width: 1440px) {
    margin-bottom: 15px;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`
export const BalanceSection = styled.div`
  padding: 16px 22px;
  justify-content: flex-start;
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  @media (max-width: 767px) {
    padding: 2.667vw;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    /* min-width: 600px; */
    max-width: 592px;
  }
`
export const BalanceDetails = styled.div`
  border-right: 1px solid #dfdfdf;
  padding-right: 25px;
  display: flex;
  gap: 10px;
  @media (max-width: 767px) {
    padding-right: 1.067vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    padding-right: 12px;
  }
`
export const WalletDetails = styled.div`
  padding-left: 35px;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 246px;
  @media (max-width: 767px) {
    padding-left: 1.067vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media only screen and (max-width: 900px) {
    padding-left: 22px;
  }
`
export const BalanceImageSection = styled.img`
  height: 68px;
`
export const WalletImageSection = styled.img`
  height: 71px;
  @media (max-width: 767px) {
    width: 65px;
    height: 68px;
  }
`
export const Details = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #292a34;
  @media (max-width: 767px) {
    font-size: 3.467vw;
    text-align: center;
    padding-top: 10px;
  }
`
export const MoneyDetails = styled.div`
  font-family: 'OutfitRegular';
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 3.667vw;
    line-height: 5.333vw;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`
export const MoneyDetails2 = styled.div`
  font-family: 'OutfitMedium';
  font-size: 16px;
  font-weight: 10;
  line-height: 21px;
  color: #2677e9;
  @media (max-width: 767px) {
    font-size: 2.667vw;
    color: #2677e9;
  }
`
export const MoneyDetails3 = styled.div`
  font-family: 'OutfitMedium';
  font-size: 18px;
  line-height: 35px;
  @media (max-width: 767px) {
    font-size: 2.667vw;
    line-height: 4vw;
    padding-top: 6.333vw;
  }
`
export const MainBalanceSection = styled.div`
  @media (max-width: 767px) {
    text-align: center;
  }
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 6px;

  .center {
    text-align: center;
  }

  cursor: pointer;
  table {
    width: 100%;
    padding: 6px;
    tr {
      height: 71px;
      border-bottom: 1px solid #e7e7e7;
      :last-child {
        border: none;
      }
    }
    th {
      text-align: left;
      padding: 22px;
      font-family: 'OutfitRegular';
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #737b7d;
      @media (max-width: 1440px) {
        padding: 5px;
      }
    }
    td {
      font-family: 'OutfitMedium';
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0a0c17;
      padding: 20px;
      :first-child {
        text-align: center;
      }
      @media (max-width: 1366px) {
        padding: 20px 10px 10px 10px;
      }
    }
    @media (max-width: 767px) {
      overflow-x: scroll;
    }
  }
`
export const RightWalletSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`
export const TextArea = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 30px;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 5.333vw;
  }
`
export const ImageSection = styled.img``

export const SecondImageSection = styled.img``
export const WalletBox = styled.div`
  display: flex;
  gap: 20px;
`
export const LeftPlusBox = styled.div`
  max-width: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f8faff;
  border: 1px dashed #bebebe;
  box-sizing: border-box;
  border-radius: 18px;
  cursor: pointer;
  padding: 20px;
`
export const BottomBoxSection = styled.div`
  display: flex;
  padding: 0 23px 23px 23px;
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    max-width: 95.467vw;
  }
  @media (max-width: 1366px) {
    max-width: 358px;
  }
`
export const PlusSection = styled.img`
  height: 16px;
`
export const ScrollContainer = styled.div`
  display: flex;
  @media (max-width: 767px) {
    gap: 10px;
  }
`

export const ViewAllBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 7px;
  cursor: pointer;
`
export const ViewAllText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2677e9;
  :hover {
    opacity: 0.5;
  }
`
export const ViewImage = styled.img`
  height: 16px;
  margin-top: 8px;
  :hover {
    opacity: 0.5;
  }
`
export const RightBenificiaries = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 34px;
  margin-top: 21px;
  padding: 15px;
  padding-bottom: 1px;
  box-shadow: 0px 31px 20px 0px rgb(22 29 36 / 22%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
    overflow-x: scroll;
  }
`
export const BenificiariesTextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 30px;
  display: flex;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 5.333vw;
  }
`
export const PluseImageBox = styled.img`
  height: 28px;
  cursor: pointer;
`
export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BenificiariescardSection = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
`
export const SectionRight = styled.div`
  flex: 1;
  width: 100%;
  max-width: 470px;
  padding-right: 20px;
  @media (max-width: 1440px) {
    max-width: 400px;
    gap: 8px;
  }
`
export const ViewAllBoxs = styled.div`
  display: flex;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  padding: 26px;

  @media (max-width: 1440px) {
    padding: 17px;
  }
`
export const WelcomeBoldSection = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 80px;
  color: #0a0c17;
`
export const LeftImage = styled.div``
export const RightImage = styled.div``

export const Plus = styled.div`
  background: rgba(0, 159, 66, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 15px;
  color: #009f42;
  max-width: 80px;
`
export const ImageWrapper = styled.img`
  height: 50px;
`
export const Minus = styled.div`
  background: rgba(255, 56, 56, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 15px;
  color: #ff3535;
  max-width: 80px;
`
export const IncContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
  @media (max-width: 767px) {
    margin-left: 11.333vw;
  }
`
export const NoneBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CryptoLogo = styled.img`
  height: 200px;
  width: 196px;
  text-align: center;

  &.minified {
    height: 38px;
    width: 38px;
  }

  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 80px;
  text-align: center;
  @media (max-width: 1440px) {
    padding: 20px 3px 0px 3px;
  }
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    line-height: 8vw;
  }
`
export const DefinitionSection = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 2px 37px 26px 10px;
  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 26px 10px 25px 10px;
  }
`
export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
export const NoAssetsAvalable = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 37px 37px 37px 10px;
  @media (max-width: 1440px) {
    padding: 65px 66px 60px 66px;
  }
`
export const BalanceContainer = styled.div``
export const BalanceMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
export const DesktopView = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileView = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
export const PortfolioTextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 30px;
  display: flex;
  margin-left: 21px;
  padding-top: 9px;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 5.333vw;
  }
`
