import { useEffect, useRef, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import usePost from 'hooks/usePost'
import {
  Launching,
  SubHeading,
  ContentWrapper,
  Progress,
  ProgressWrapper,
  RightButton,
  TextSection,
  FORMWRAPPER,
} from 'styles/components/Timer'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { LoaderContext } from 'context/loader'
import TextInput from 'components/FormElements/TextInput'

const useInterval = (callback: React.EffectCallback, delay: number | null): React.MutableRefObject<number | null> => {
  const intervalRef = useRef<number | null>(null)
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay)

      return () => window.clearInterval(intervalRef.current || 0)
    }
  }, [delay])

  return intervalRef
}

const Timer = () => {
  const [value, setValue] = useState(25)
  const { setLoader } = useContext(LoaderContext)

  useInterval(() => {
    if (value == 100) {
      setValue(0)
    } else {
      setValue((prev) => prev + 25)
    }
  }, 1300)

  const { handleSubmit, control, formState, reset } = useForm()
  const { mutateAsync: emailAsync, isLoading } = usePost()

  const onSubmit = async (values: any) => {
    try {
      await emailAsync({
        url: '/add/useremailCollection',
        payload: { mail: values.emailId },
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ emailId: '' })
    }
  }, [formState, reset])

  return (
    <ProgressWrapper>
      <Progress>
        <CircularProgressbarWithChildren
          value={value}
          styles={buildStyles({
            textSize: '16px',
            pathColor: `#1BB691`,
            textColor: '#1BB691',
            trailColor: '#fff',
            backgroundColor: '#3e98c7',
          })}
          strokeWidth={0.5}
        >
          <ContentWrapper>
            <Launching>We are launching Soon</Launching>
            <SubHeading>Subscribe to our newsletter for updates. Please enter your email below.</SubHeading>
          </ContentWrapper>
          <FORMWRAPPER onSubmit={handleSubmit(onSubmit)} className="one">
            <TextInput name="emailId" className="text" placeholder="Email" autoComplete="off" control={control} />
            <TextSection>
              <RightButton type="submit" label=">" variant="contained" />
            </TextSection>
          </FORMWRAPPER>
        </CircularProgressbarWithChildren>
      </Progress>
    </ProgressWrapper>
  )
}

export default Timer
