import * as React from 'react'
import { SVGProps } from 'react'

const Linkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg width={80} height={79} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={39.579} cy={39.5} r={39.5} fill="#E6F2FF" />
    <g clipPath="url(#a)">
      <path
        d="M49.153 48.154h-3.852V42.12c0-1.438-.026-3.29-2.004-3.29-2.006 0-2.313 1.567-2.313 3.186v6.137h-3.852V35.747h3.698v1.695h.052a4.054 4.054 0 0 1 3.648-2.004c3.905 0 4.625 2.568 4.625 5.91l-.002 6.806ZM32.785 34.05a2.236 2.236 0 1 1-.001-4.472 2.236 2.236 0 0 1 0 4.472Zm1.926 14.103h-3.857V35.747h3.857v12.407Zm16.363-22.152H28.919A1.897 1.897 0 0 0 27 27.876v22.247A1.899 1.899 0 0 0 28.919 52h22.155A1.903 1.903 0 0 0 53 50.123V27.875A1.901 1.901 0 0 0 51.074 26"
        fill="#0A66C2"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(27 26)" d="M0 0h26v26H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Linkedin
