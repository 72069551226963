import styled from 'styled-components'
import { theme } from 'theme/index'

export interface IProps {
  variantWrapper?: 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven'
  variant?: 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven'
}

const getWrapperStyle = ({ variantWrapper }: IProps) => {
  switch (variantWrapper) {
    case 'one':
      return `
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px;
      background: white;
      border: 14px solid #ffffff;
      box-shadow: 0px 24px 38px rgba(22, 29, 36, 0.08);
      border-radius: 30%;
 `
    case 'two':
      return `
      color:#000000;
      background: white;
      border: 10px solid rgba(38, 119, 233, 0.15);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: centre;
      width: 100%;
      height: 170px;
      box-sizing: border-box;
      border-radius: 20px;
 `
    case 'three':
      return `
      color:#000000;
      background: white;
      border: 10px solid rgba(38, 119, 233, 0.15);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 170px;
      box-sizing: border-box;
      border-radius: 20px;
      margin-top:30px;
 `
    case 'four':
      return `
      width: 152px !important;
      height: 152px !important;
      margin: auto !important;
 `
    case 'five':
      return `
      color:#000000;
      background: white;
      border: 10px solid rgba(38, 119, 233, 0.15);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: centre;
      width: 100%;
      height: 170px;
      box-sizing: border-box;
      border-radius: 20px;
`
    case 'six':
      return `
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 230px;
      background: white;
      border: 14px solid #ffffff;
      box-shadow: 0px 24px 38px rgba(22, 29, 36, 0.08);
      border-radius: 10px;
      color: ${theme.primary.light} !important;

      
`
    case 'seven':
      return `
      color:#000000;
      background: white;
      border:16px solid #CEFFF9;
      border-radius: 10px;
      padding:1rem;
      display: flex;
      align-items: center;
      justify-content: centre;
      width: 100%;
      padding:1rem;
      box-sizing: border-box;
      border-radius: 20px;
      
`
    default:
      return `
      color:#000000;
      background: white;
      border:16px solid #CEFFF9;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: centre;
      width: 100%;
      box-sizing: border-box;
      border-radius: 20px;
  `
  }
}
const getStyles = ({ variant }: IProps) => {
  switch (variant) {
    case 'one':
      return `
       gap: 20px;
       background:#4473f5 ;
       width: 100%;
       padding: 0rem 1rem;
       height: 200px; 
       border-radius: 30%;
       color: ${theme.primary.light};
       font-size: 32px;
       box-shadow: 0px 24px 38px rgba(22, 29, 36, 0.08);

 `
    case 'two':
      return `
      min-height:100%;      
      color:#000000;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: centre;
      width: 100%;
      position:relative;
 
 `
    case 'three':
      return `
      min-height:100%;
      color:#000000;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position:relative;
 `
    case 'four':
      return `
      display: flex;
      width: 100%;
      height: 100%;
      background:#ffffff;
`
    case 'five':
      return `
      min-height:100%;
      color:#000000;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: centre;
      width: 100%;
      position:relative;
  margin-top: 10%;

`
    case 'six':
      return `
      gap: 20px;
       background:#1BB691;
       width: 98%;
       height: 200px; 
       border-radius: 10px;
       color: ${theme.primary.light} ;
       font-size: 32px;
       box-shadow: 0px 24px 38px rgba(22, 29, 36, 0.08);
  
`
    case 'seven':
      return `
      min-height:100%;      
      color:#000000;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: centre;
      width: 100%;
     
  
`
    default:
      return `
      min-height:100%;      
      color:#000000;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: centre;
      width: 100%;

  `
  }
}

export const Container = styled.div`
  .treeSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1077px;
    height: 1734px;
    stroke: blue;
    border: 1px solid #f1f1f1;
    margin: 0 auto;

    div > svg > g {
      text-align: center;
    }
    background: #fdfeff;
  }
  .node__root > circle {
    fill: red;
    width: 181px;
    height: 167px;
  }
  .rd3t-link {
    stroke: #2676e6;
  }
  .node__branch > circle {
    fill: yellow;
  }

  .node__leaf > circle {
    fill: green !important;
  }
  .jMapNode {
    background: #4473f5;
  }

  .tpNode {
    width: 162.46px;
    height: 82px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    border: 5px solid rgba(38, 119, 233, 0.15);
    border-radius: 10px;
    color: #000000;
  }
  .tpNode1 {
    width: 100%;
    height: 128px;
    border-radius: 10px;
    background: #e5e5f3;
  }
  .node__branch > foreignObject {
    overflow: visible;
  }
  .node__leaf > foreignObject {
    overflow: visible;
  }
`
export const FiduciarySection = styled.div<IProps>`
  ${({ variantWrapper }: any) => getWrapperStyle({ variantWrapper })}
`

export const FiduciaryDetails = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  ${({ variant }: any) => getStyles({ variant })}
`
export const Owner = styled.div`
  font-family: 'OutfitSemiBold';
  font-style: normal;
  line-height: 24px;
  gap: 10px;
  font-size: 32px;
  text-align: center;
`
export const Title = styled.div`
  font-family: 'OutfitSemiBold';
  font-style: normal;
  line-height: 36px;
  gap: 10px;
  font-size: 32px;
  text-align: center;
`
export const Currency = styled.div`
  font-family: 'OutfitSemiBold';
  font-style: normal;
  line-height: 24px;
  gap: 10px;
  font-size: 32px;
  text-align: center;
`
export const GranterName = styled.div<{
  variantText?: 'Fiduciary' | 'Beneficiary'
}>`
  font-family: 'OutfitRegular';
  font-size: 22px;
  line-height: 24px;
  color: ${({ variantText }) => (variantText === 'Fiduciary' ? '#fff' : '#737b7d')};
`
export const Responsibility = styled.div`
  color: rgba(255, 255, 255, 0.65);
  font-family: 'OutfitMedium';
  font-style: normal;
  font-size: 28px;
  line-height: 24px;
  text-align: center;
`
export const WalletSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`

export const WalletCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.21);
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: ${theme.primary.light};
`

export const PlusSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.21);
`
export const AssestsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  transform: translateY(-50px);
  padding-bottom: 50px;
  > img {
    width: 70px;
  }
`
export const Assests = styled.img``
export const AssestsType = styled.div`
  font-family: 'OutfitMedium';
  font-size: 36px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4473f5;
`
export const CryptoCoin = styled.img`
  width: 50%;
`
export const CryptoSubCoinWrap = styled.div`
  position: absolute;
  transform: translateY(-60px);
  padding-bottom: 50px;
  > img {
    width: 96px;
    height: 90px;
  }
`
export const CryptoSubCoin = styled.img``

export const FiduciaryDivision = styled.div`
  display: flex;
  gap: 10px;
  font-size: 24px;
  font-family: 'OutfitSemiBold';
`
export const Name = styled.div`
  font-family: 'OutfitSemiBold';
  font-style: normal;
  line-height: 36px;
  gap: 10px;
  font-size: 30px;
  text-align: center;
`
export const Percentage = styled.div<{
  variantText?: 'Fiduciary' | 'Beneficiary'
}>`
  color: ${({ variantText }) => (variantText === 'Fiduciary' ? '#fff' : '#1bb691')};
  font-size: 32px;
  line-height: 24px;
  display: flex;
  align-items: center;
`
export const WalletAddressWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const WalletAddress = styled.div<{
  variantText?: 'Fiduciary' | 'Beneficiary'
}>`
  color: ${({ variantText }) => (variantText === 'Fiduciary' ? '#fff' : '#2677E9')};
  font-size: 20px;
  line-height: 24px;
  font-family: 'OutfitMedium';
`
export const Grantor = styled.div<{
  variantText?: 'Fiduciary' | 'Beneficiary'
}>`
  font-family: 'OutfitRegular';
  font-size: 22px;
  line-height: 24px;
  color: ${({ variantText }) => (variantText === 'Fiduciary' ? '#fff' : '#737b7d')};
`
export const WalletCopy = styled.img<{
  variantText?: 'Fiduciary' | 'Beneficiary'
}>`
  background-image: ${({ variantText }) => (variantText === 'Fiduciary' ? '#fff' : '#737b7d')};
  height: 18px;
`
export const LayoutFive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 7%;
`
export const CopyHover = styled.div`
  /* background-color: red; */
  &:hover {
    cursor: pointer;
  }
`
