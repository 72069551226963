import React, { useState, useRef, useEffect, useContext } from 'react'
import { Checkbox, Table, Divider } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import useGet from 'hooks/useGet'
import { LoaderContext } from 'context/loader'
import NavBar from 'components/NavBar'
import Short from 'assets/images/short-image.png'
import changed from 'assets/images/FChanged.png'
import Bchanged from 'assets/images/BChnaged.png'
import ProfileRed from 'assets/images/red-logo.png'
import BChanged from 'assets/images/BlueChanged.png'
import Watermark from 'assets/images/watermark-image.png'
import Refresh from 'assets/images/refresh-image.png'
import Subscription from 'assets/images/SubscriptionUpdated.png'
import Payment from 'assets/images/PaymentSuccess.png'
import Share from 'assets/images/ShareUpdated.png'
import Contract from 'assets/images/ContractUpdated.png'
import {
  Wrapper,
  Section,
  MainContainer,
  Container,
  HeadContainer,
  TopHaddingSection,
  BottomHeading,
  MainWrapper,
  BottomSection,
  RightSection,
  Leftsection,
  RightTop,
  ShortContainer,
  ShortBy,
  ShortImage,
  FilterSection,
  ResetImage,
  ResetText,
  ImageContainer,
  CheackBoxSection,
  CheckBox,
  ResetButton,
  SortWrapper,
  SortText,
  TableWrapper,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  SortWrappers,
} from 'styles/views/dashboard/Notification'
import { previousYear, currentYear, quaterYear, semiYear } from 'views/dashboard/Notification/notificationsHelpers'

const CheckboxGroup = Checkbox.Group
const plainOptions = [
  'Fiduciary Changed',
  'New Contract',
  'Membership Plans',
  'Beneficiary Added or Removed',
  'Wallets & Transaction',
]
const defaultCheckedList = ['', '']

const Notification = () => {
  const { setLoader } = useContext(LoaderContext)
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList)
  const [checkAll, setCheckAll] = useState(false)
  const [notifyData, setNotifyData] = useState('')
  const [dropdown, setDropdown] = useState('')
  const [filteredData, setFilteredData] = useState<any>()
  const [isOpenSort, setIsOpenSort] = useState(false)
  const [isOpenFilter, setisOpenFilter] = useState(false)

  const [, setappoint] = useState('')
  const wrapperRef = useRef<any>()
  const wrapperNotifiRef = useRef<any>()

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list)
    setCheckAll(list.length === plainOptions.length)
    setNotifyData('')
    list.map((item: any) => {
      switch (item) {
        case 'Fiduciary Changed':
          setNotifyData((prev) =>
            prev == '' ? 'type=contract&titleName=updated' : prev + '&type=contract&titleName=updated',
          )
          break
        case 'Wallets & Transaction':
          setNotifyData((prev) =>
            prev == '' ? 'type=transition&titleName=Payment' : prev + '&type=transition&titleName=Payment',
          )
          break
        case 'New Contract':
          setNotifyData((prev) =>
            prev == '' ? 'type=contract&titleName=Created' : prev + '&type=contract&titleName=Created',
          )
          break
        case 'Membership Plans':
          setNotifyData((prev) =>
            prev == '' ? 'type=transition&titleName=Subscription' : prev + '&type=transition&titleName=Subscription',
          )
          break
        case 'Beneficiary Added or Removed':
          setNotifyData((prev) =>
            prev == ''
              ? 'type=contract&titleName=updated&titleName=Beneficiary Added'
              : prev + '&type=contract&titleName=updated&titleName=Beneficiary Added',
          )
          break
        default:
          setNotifyData('')
          break
      }
    })
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    // For case of All-Selected set the notification state as empty so that we will be getting each data
    setNotifyData('')
    setCheckAll(e.target.checked)
  }

  const resetRadioState = () => {
    setappoint('')
    setNotifyData('')
    setCheckedList(defaultCheckedList)
    setCheckAll(false)
  }

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpenSort(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperNotifiRef.current && !wrapperNotifiRef.current?.contains(event?.target)) {
        setisOpenFilter(!isOpenFilter)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperNotifiRef])

  const {
    data: notifications,
    isFetching: linkLoading,
    refetch,
  } = useGet('get-notification1', `/allnotify/collection?${notifyData}`, false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    setFilteredData(notifications?.allData)
  }, [notifications])

  useEffect(() => {
    refetch()
  }, [checkedList])

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else {
      setFilteredData(notifications?.allData)
      setLoader(false)
    }
  }, [linkLoading])

  const columns = [
    {
      dataIndex: 'image',
      key: 'image',
    },
    {
      dataIndex: 'message',
      key: 'message',
    },
    {
      dataIndex: 'time',
      key: 'time',
    },
  ]

  const tableData = []

  const getBenficaryImage = (title = '') => {
    let defaultImage
    switch (title) {
      case 'Beneficiary Added':
        defaultImage = changed
        break
      case 'Created':
        defaultImage = Bchanged
        break
      case 'Shares Updated':
        defaultImage = Share
        break
      case 'Beneficiary Removed':
        defaultImage = ProfileRed
        break
      case 'Fiduciary Changed':
        defaultImage = BChanged
        break
      case 'Subscription':
        defaultImage = Subscription
        break
      case 'Wallet Added':
        defaultImage = BChanged
        break
      case 'Payment':
        defaultImage = Payment
        break
      case 'updated':
        defaultImage = Contract
        break
    }

    return defaultImage
  }

  if (filteredData != undefined) {
    for (let i = filteredData?.length - 1; i >= 0; i--) {
      const element = filteredData[i]

      tableData.push({
        key: i,
        image: <img src={getBenficaryImage(element?.titleName)} />,
        message: element?.message,
        time: element?.contractDate?.split('T')[1]?.split('.')[0],
      })
    }
  }

  useEffect(() => {
    switch (dropdown) {
      case 'current':
        setFilteredData(currentYear(notifications?.allData))
        break
      case 'quarter':
        setFilteredData(quaterYear(notifications?.allData))
        break
      case 'semi':
        setFilteredData(semiYear(notifications?.allData))
        break
      case 'previous':
        setFilteredData(previousYear(notifications?.allData))
        break
      default:
        setFilteredData(notifications?.allData)
        break
    }

    setIsOpenSort(false)
  }, [dropdown])

  return (
    <Wrapper>
      <NavBar />
      <Section>
        <MainContainer>
          <MainWrapper>
            <Container>
              <HeadContainer>
                <TopHaddingSection>Notifications</TopHaddingSection>
                <BottomHeading></BottomHeading>
              </HeadContainer>
            </Container>
            <div>
              {' '}
              <BottomSection>
                <RightSection>
                  <RightTop>
                    <FilterSection onClick={() => setisOpenFilter(!isOpenFilter)} ref={wrapperNotifiRef}>
                      Filter
                      <ShortImage src={Short} />
                    </FilterSection>
                    {isOpenFilter && (
                      <SortWrappers>
                        <Leftsection>
                          <CheackBoxSection>
                            <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                              All
                            </Checkbox>
                            <CheckBox>
                              <Divider />
                            </CheckBox>
                            <CheckBox>
                              <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                            </CheckBox>
                          </CheackBoxSection>
                          <ResetButton>
                            <ImageContainer onClick={resetRadioState}>
                              <ResetImage src={Refresh} />
                              <ResetText>Reset Filter</ResetText>
                            </ImageContainer>
                          </ResetButton>
                        </Leftsection>
                      </SortWrappers>
                    )}
                    <ShortContainer onClick={() => setIsOpenSort(true)}>
                      <ShortBy>
                        Sort By
                        <ShortImage src={Short} />
                        {isOpenSort && (
                          <SortWrapper ref={wrapperRef}>
                            <SortText onClick={() => setDropdown('current')}>Current Year</SortText>
                            <SortText onClick={() => setDropdown('quarter')}>Last Quarter</SortText>
                            <SortText onClick={() => setDropdown('semi')}>Last 6 Months </SortText>
                            <SortText onClick={() => setDropdown('previous')}>Previous Year </SortText>
                            <SortText onClick={() => setDropdown('')}>Clear </SortText>
                          </SortWrapper>
                        )}
                      </ShortBy>
                    </ShortContainer>
                  </RightTop>
                  {notifications?.allData?.length ? (
                    <TableWrapper>
                      <Table columns={columns} bordered dataSource={tableData} />
                    </TableWrapper>
                  ) : (
                    <NoBeneficiaries>
                      <BeneficiarieImage>
                        <CryptoLogo src={Watermark} />
                      </BeneficiarieImage>
                      <TextSextion>
                        <HeadingSection>No Notification Added</HeadingSection>
                        <DefinitionSection></DefinitionSection>
                      </TextSextion>
                    </NoBeneficiaries>
                  )}
                </RightSection>
              </BottomSection>
            </div>
          </MainWrapper>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default Notification
