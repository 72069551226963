import React from 'react'
import WalletsConnectDetails from 'components/Cards/WalletsConnectDetails'
import { columns } from 'components/Cards/ConnectedWallets/data'

import CrossLogo from 'assets/images/grayx-logo.png'
import {
  MainContainer,
  Wrapper,
  Container,
  HeadText,
  PaymentContainer,
  Cross,
} from 'styles/components/Cards/ConnectedWallets'

interface IModalProps {
  showModal: (value: boolean) => void
  showAnotherModal?: (value: boolean) => void
  getResponse?: () => void
}

const ConnectWallets: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  return (
    <>
      <MainContainer>
        <Wrapper>
          <Container>
            <HeadText>All Connected Wallets</HeadText>
            <Cross src={CrossLogo} alt="Close" onClick={() => showModal(!true)} />
          </Container>
          <PaymentContainer>
            {columns?.map((item) => (
              <WalletsConnectDetails key={item.id} image={item?.image} title={item?.title} subtitle={item?.subtitle} />
            ))}
          </PaymentContainer>
        </Wrapper>
      </MainContainer>
    </>
  )
}

export default ConnectWallets
