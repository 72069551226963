import React from 'react'
import {
  FiduciaryChanged,
  TextSEction,
  TextTopSection,
  TextFirst,
  LeftTimeSection,
} from 'styles/views/dashboard/Notification'

export interface ICardProps {
  image: string
  title: string
  subtitle: string
  defination: string
  lasttitle: string
}
const Notification = ({ title, lasttitle }: ICardProps) => (
  <FiduciaryChanged>
    <TextSEction>
      <TextTopSection>
        <TextFirst>{title}</TextFirst>
      </TextTopSection>
    </TextSEction>
    <LeftTimeSection>{lasttitle}</LeftTimeSection>
  </FiduciaryChanged>
)
export default Notification
