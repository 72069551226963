import React from 'react'

interface IUserContext {
  [key: string]: any
}

const defaultState = {
  user: null,
  setUser: () => null,
}

const UserContext = React.createContext<IUserContext>(defaultState)

export { UserContext }
