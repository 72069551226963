import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import CrossLogo from 'assets/images/grayx-logo.png'
import { PaymentWalletContext } from 'context/paymentWallet'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  MainContainer,
  Wrapper,
  Container,
  HeadText,
  DeatailsWrapper,
  Cross,
  InputSubWrapper,
  LabelText,
  ButtonContainer,
  SaveButton,
  InputSubmitWrapper,
  Error,
} from 'styles/components/Modal/PaypalInfo'

interface IModalProps {
  showModal: (value: boolean) => void
  showAnotherModal?: (value: boolean) => void
  data?: any
  getResponse?: () => void
  image: string
}
interface IData {
  displayName: string
  userWalletId: string
  emailId: string
  amount: number
  appointAs: any
  beneficiaryId: string
}

const PlaidInfo: React.FC<IModalProps> = ({ showModal, image }: IModalProps) => {
  const schema = yup.object().shape({
    card: yup
      .string()
      .max(16)
      .required('Card Number is Required')
      .matches(/([0-9])/, 'Only Numbers are Accapted'),
    expirationdate: yup
      .string()
      .matches(/([0-9]{2})\/([0-9]{2})/, 'Not a valid expiration date. Example: MM/YY')
      .required('Expiration Date is Required'),
    securitycode: yup
      .string()
      .max(3)
      .required('Security Code is Required')
      .matches(/([0-9])/, 'Enter Only 3 Numaric Values'),
    select: yup.string().required(' Required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const { paymentWallet, setPaymentWallet } = useContext(PaymentWalletContext)

  const onSaveClick = (data: any) => {
    const dataSend = {
      id: paymentWallet.length + 1,
      image: image,
      title: 'Pay with Plaid',
      subtitle: data?.card,
      defination: '',
    }
    setPaymentWallet([...paymentWallet, dataSend])
  }

  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <MainContainer>
        <Wrapper>
          <Container>
            <HeadText>Add Pay With Plaid</HeadText>
            <Cross src={CrossLogo} alt="Close" onClick={() => showModal(!true)} />
          </Container>
          <DeatailsWrapper>
            <InputSubmitWrapper>
              <LabelText>Debit or Credit Card Number</LabelText>
              <input placeholder="WalletName" className="text" {...register('card')} />
              <Error>{errors?.card?.message}</Error>
            </InputSubmitWrapper>

            <InputSubWrapper>
              <Controller
                control={control}
                name="numberOfDays"
                render={({ field }) => (
                  <select className="sellectone" placeholder="Select Your Card Type" {...field} {...register('select')}>
                    <option value="3">Visa</option>
                    <option value="6">MasterCard</option>
                    <option value="9">American Express</option>
                    <option value="10">Discover</option>
                  </select>
                )}
              />
            </InputSubWrapper>
            {errors.select && <p>{errors.select.message}</p>}
            <InputSubmitWrapper>
              <LabelText>Expiration Date</LabelText>
              <input placeholder="Enter Your Expiration Date" className="text" {...register('expirationdate')} />
              <Error>{errors?.expirationdate?.message}</Error>
            </InputSubmitWrapper>
            <InputSubmitWrapper>
              <LabelText>Security Code</LabelText>
              <input placeholder="Enter Your Security Code" className="text" {...register('securitycode')} />
              <Error>{errors?.securitycode?.message}</Error>
            </InputSubmitWrapper>
          </DeatailsWrapper>
          <ButtonContainer>
            <SaveButton label={'save'} variant="contained" type="submit" />
          </ButtonContainer>
        </Wrapper>
      </MainContainer>
    </form>
  )
}

export default PlaidInfo
