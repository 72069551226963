import React from 'react'

import {
  EthBox,
  EthImageSection,
  EthTextSection,
  EthFirstText,
  EthSecndText,
  EthDemoSection,
} from 'styles/components/Cards/Wallet'
export interface ICardProps {
  title: string
  subtitle: string
  image?: string
  subtext: string
}

const WalletSectionCard: React.FC<ICardProps> = ({ image, title, subtitle, subtext }) => (
  <EthBox>
    <EthImageSection src={image} />
    <EthTextSection>
      <EthFirstText>{title}</EthFirstText>
      <EthSecndText> {subtitle} </EthSecndText>
    </EthTextSection>
    <EthDemoSection> {subtext}</EthDemoSection>
  </EthBox>
)

export default WalletSectionCard
