import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import useGet from 'hooks/useGet'
import PaymentForm from 'components/PaymentForm'

export default function StripeContainer() {
  const { data: getPayment, refetch } = useGet('get-payment ', `payment/payment`, false, {
    token: true,
  })

  useEffect(() => {
    refetch()
  }, [getPayment])

  const PUBLIC_KEY = getPayment?.publishableKey
  const stripePromise = loadStripe(PUBLIC_KEY)

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    </>
  )
}
