import { motion } from 'framer-motion'
import styled from 'styled-components'

export const RoadMap = styled.div`
  display: flex;
  width: 100%;
`
export const BlankSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const AddWalletSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const SmartContractSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`

export const WorkFrame = styled.div`
  display: flex;
  width: 100%;
  padding-top: 100px;
  @media (max-width: 1350px) {
    padding-top: 50px;
  }
`
export const AddBeneficiariesSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const LifeChangingSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const DisbursementSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const BlankFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  position: relative;
  @media (max-width: 1350px) {
    min-height: 150px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const BlankSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  max-width: 328px;
  border-top: 1px solid #44c1f7;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const AddWalletFirstSection = styled.div`
  min-height: 250px;
  border-top-right-radius: 40px;
  width: 100%;
  border-top-left-radius: 40px;
  max-width: 328px;
  border-left: 1px solid #44c1f7;
  border-top: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  font-family: 'Outfit';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  position: relative;
  @media (max-width: 1350px) {
    min-height: 150px;
    width: 170px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
    font-size: 15px;
  }
`
export const AddWalletSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  max-width: 328px;
  margin-left: 1px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const SmartFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  position: relative;
  @media (max-width: 1350px) {
    min-height: 150px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const SmartSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  max-width: 328px;
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #44c1f7;
  border-left: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  @media (max-width: 1350px) {
    min-height: 150px;
    width: 170px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
    font-size: 15px;
  }
`
export const BeneficiarFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  border-left: 1px solid #44c1f7;
  border-top: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  position: relative;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  font-family: 'Outfit';
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  position: relative;
  @media (max-width: 1350px) {
    min-height: 150px;
    width: 170px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const BeneficiarSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const LifeFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  position: relative;
  @media (max-width: 1350px) {
    min-height: 150px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const DisbursFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  border-right: 1px solid #44c1f7;
  border-top: 1px solid #44c1f7;
  border-left: 1px solid #44c1f7;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  font-family: 'Outfit';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  position: relative;
  @media (max-width: 1350px) {
    width: 170px;
    min-height: 150px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const DisbursSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1350px) {
    width: 170px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const LifeSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  display: flex;
  max-width: 328px;
  border-bottom: 1px solid #44c1f7;
  border-left: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  margin-left: 0px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  @media (max-width: 1350px) {
    min-height: 150px;
    width: 170px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const LastBlankSection = styled.div`
  min-height: 500px;
  width: 100%;
  max-width: 328px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 1280px) {
    min-height: 400px;
  }
`
export const LastFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  max-width: 328px;
  border-bottom: 1px solid #44c1f7;
  @media (max-width: 1350px) {
    min-height: 150px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
  }
`
export const Label = styled.div`
  font-family: 'OutfitBold';
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  padding: 40px;
  color: #fdfeff;
  @media (max-width: 1366px) {
    padding: 22px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 150px;
    font-size: 15px;
    padding: 13px;
  }
`
export const FileImageContainer = styled(motion.img)`
  position: absolute;
  bottom: -80px;
  right: -90px;
  z-index: 1;
  @media (max-width: 1350px) {
    width: 120px;
    right: -70px;
    width: 135px;
  }
  @media (max-width: 1000px) {
    width: 90px;
    height: 90px;
    right: -49px;
    bottom: -45px;
  }
`
