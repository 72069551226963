import React, { useState, useContext, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Modal from 'components/Modal'
import ContractSuccess from 'components/ContractCreatedModal'
import usePut from 'hooks/usePut'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { ethers } from 'ethers'
import CrossLogo from 'assets/images/grayx-logo.png'
import Download from 'assets/svg/Download'
import ReactTooltip from 'react-tooltip'
import ConfirmTick from 'assets/images/confirm-tick.png'
import CopyImage from 'assets/images/copy-image.png'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import ErrorModal from 'components/ErrorModal'
import { useMoralisWeb3Api } from 'react-moralis'

import {
  MainContainer,
  Container,
  WrapperContainer,
  HeadText,
  TransWrapper,
  TransRow,
  SubTransRow,
  Label,
  BlockDetails,
  BlueText,
  TransDetails,
  TransRowWrapper,
  LastTransContainer,
  SubContainer,
  SubTransDetails,
  SubTransId,
  TransContainer,
  TransText,
  GreyText,
  DownloadButton,
  SuccessText,
  ContentWrapper,
  TransMidContainer,
  FromContainer,
  DownloadWrapper,
  ButtonSection,
  DoneButton,
  CancelButton,
  CopyContainer,
  TransactionHash,
  BlueTransactionText,
  SubTransIdMobile,
  TransactionLable,
} from 'styles/components/InvoiceModal/index'

interface IModalProps {
  showModal: (value: boolean) => void
  hidePreviousModal?: () => void
  invoiceData?: any
  blockData?: any
  willInfo?: any
}
const Invoice: React.FC<IModalProps> = ({
  showModal,
  hidePreviousModal,
  invoiceData,
  blockData,
  willInfo,
}: IModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [downloadedData, setDownloadedData] = useState('')
  const [isOpens, setIsOpens] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [copiedFrom, setCopiedFrom] = useState(false)
  const [copiedTo, setCopiedTo] = useState(false)
  const [etherPrice, setEtherPrice] = useState('')
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { mutateAsync: updateAsync, isLoading: updateLoading } = usePut()
  const { mutateAsync, isSuccess, error, isError, isLoading } = usePost()
  const transaction = invoiceData?.transactionHash
  const gasPriceinEther = ethers.utils.formatUnits(invoiceData?.effectiveGasPrice, 18)
  const gasPriceinGwei = ethers.utils.formatUnits(invoiceData?.effectiveGasPrice, 9)
  const gasUsedinEther = invoiceData?.gasUsed
  const transactionfee = parseFloat(gasUsedinEther) * parseFloat(gasPriceinEther)
  const from = invoiceData?.from
  const status = invoiceData?.status
  const to = willInfo?.length ? willInfo[willInfo?.length - 1] : ''
  const blockNumber = invoiceData?.blockNumber
  const contract = '0x689fdbcd84ddc215d1e16a788d4bb3c706397a0d'
  const timeStamp = new Date(blockData?.timestamp * 1000)
  const { setLoader } = useContext(LoaderContext)
  const { fetchUser } = useContext(UserContext)
  const Web3Api = useMoralisWeb3Api()
  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''

  useEffect(() => {
    if (isSuccess) {
      fetchUser()
    }
  }, [isSuccess])

  const fetchTokenPrice = async () => {
    //Get token price
    const tokenPrice = await Web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID_ETH as any,
    })
    setEtherPrice(tokenPrice?.usdPrice.toString())
  }

  const addContract = async () => {
    const settingTotalAmount = Number(etherPrice) * transactionfee
    try {
      const payload = {
        hyperlink: to,
        contractAddress: to,
        isActive: status === 1 ? true : false,
        fromAccount: from,
        category: 'crypto',
        totalAmount: transactionfee.toString().slice(0, 6),
        currencyPrice: settingTotalAmount.toString().slice(0, 6),
      }
      const data = await mutateAsync({
        url: 'transaction/transaction',
        payload,
        token: true,
      })

      if (!data) {
        setIsOpens(true)
      } else if (location.pathname === '/UpgradePlan') {
        setIsOpen(false)
      } else {
        setLoader(true)
        setIsOpen(true)
        setLoader(false)
      }
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }
  const Url = `https://goerli.etherscan.io/tx/${transaction}`
  useEffect(() => {
    if (isError) {
      setIsOpens(true)
    }
  }, [isError, error])

  const updateStatus = async () => {
    if (status === 1) {
      const payload = notification?.users?.safeBeneficiary.map((beneficiary: any) => {
        const temp = { ...beneficiary, amount: parseFloat(beneficiary?.amount || 0) }
        delete temp.beneficiaryId
        return temp
      })

      try {
        const updateData = await updateAsync({
          url: 'beneficiary/Updatebeneficiary',
          payload,
          token: true,
        })
        if (!updateData) {
          setIsOpens(true)
        } else if (location.pathname === '/UpgradePlan') {
          setIsOpen(false)
        } else {
          setLoader(true)
          setIsOpen(true)
          setLoader(false)
        }
      } catch (error: any) {
        return { error: error?.response?.data?.message || 'Something went wrong' }
      }
    }
  }

  const { data: notification } = useGet('get-notification', 'all/event/', false, {
    enabled: true,
    token: true,
  })

  const finalData: any = []

  const getViewData = async () => {
    const length = notification?.users?.eventHit.length
    const elementHit = notification?.users?.eventHit[length - 1]

    const data = beneficiariesData?.data?.paymentTransition
      ? notification?.users?.safeBeneficiary
      : beneficiariesData?.data?.beneficary

    const maxLength = beneficiariesData?.data?.paymentTransition
      ? notification?.users?.safeBeneficiary?.length - 1
      : beneficiariesData?.data?.beneficary?.length - 1

    for (let i = 0; i < data?.length; i++) {
      const element = data[i]

      element['amount'] = parseFloat(element.amount)

      const obj = {
        displayName: element?.displayName,
        userWalletId: element?.userWalletId,
        emailId: element?.emailId,
        appointAs: element?.appointAs,
        amount: element?.amount,
        eventHitEventId: elementHit?.eventId,
      }
      finalData.push(obj)
      if (i === maxLength) {
        try {
          await mutateAsync({
            url: 'data/postViewData',
            payload: finalData,
            token: true,
          })
        } catch (error: any) {
          return { error: error.response.data.message }
        }
      }
    }
  }

  const handleClick = () => {
    if (beneficiariesData?.data?.paymentTransition) {
      setLoader(true)
      updateStatus()
      getViewData()
      downloadPost()
      setLoader(false)
    } else {
      setLoader(true)
      addContract()
      getViewData()
      downloadPost()
      setLoader(false)
    }
  }

  const handleCopy = () => {
    setIsCopied(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (isCopied) setIsCopied(false)
    }, 10000)
  }, [isCopied])

  const Copy = () => {
    setCopiedFrom(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (copiedFrom) setIsCopied(false)
      setCopiedFrom(false)
    }, 10000)
  }, [copiedFrom])

  const copyFrom = () => {
    setCopiedTo(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (copiedTo) setCopiedTo(false)
      setCopiedFrom(false)
    }, 10000)
  }, [copiedTo])

  const downloadData = async () => {
    const payload = {
      wallet_Address: transaction,
      number: blockNumber,
      date_Time: timeStamp.toUTCString(),
      gas_Price: gasPriceinEther,
      eth_Value: '0.00',
      transfer: '0.00',
      to_WalletAddress: to,
      contract: to,
      from_WalletAddress: from,
      transaction_Fee: transactionfee,
      ether_Price: `${etherPrice} $`,
    }
    try {
      const downloadData = await mutateAsync({
        url: '/data/downloadData',
        payload: payload,
        token: true,
      })

      setDownloadedData(downloadData?.data)
      return downloadData?.data
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (downloadedData) {
      const linkSource = `data:application/pdf;base64,${downloadedData}`
      const downloadLink = document.createElement('a')
      const fileName = 'Payment_Invoice.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }, [downloadedData])

  useEffect(() => {
    getData()

    async function getData() {
      const res = await fetchTokenPrice()
      return res
    }
  }, [])

  const { data: subscribe } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })
  const subscribeData = subscribe?.subscriptionData?.subscription

  const { mutateAsync: dowloadAsync, isLoading: downloadLoading } = usePost()

  const downloadPost = async () => {
    const payload = {
      wallet_Address: transaction.toString(),
      number: blockNumber.toString(),
      date_Time: timeStamp.toUTCString(),
      gas_Price: gasPriceinEther.toString(),
      eth_Value: '0.00',
      transfer: '0.00',
      to_WalletAddress: to.toString(),
      contract: to.toString(),
      from_WalletAddress: from.toString(),
      transaction_Fee: transactionfee.toString(),
      ether_Price: etherPrice,
      invoice: '0xf525b3e37C0c9d361A8696448c39c5528FFaf31a',
      amount: 80,
      plan: subscribeData[0]?.subscriptionPlan,
      status: true,
    }
    try {
      const invoiceData = await dowloadAsync({
        url: '/invoice/data',
        payload: payload,
        token: true,
      })

      return invoiceData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading || downloadLoading || updateLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, downloadLoading, updateLoading])

  return (
    <MainContainer>
      <Container>
        <WrapperContainer>
          <HeadText>Payment Invoice</HeadText>
          <img
            src={CrossLogo}
            alt="close"
            onClick={() => {
              showModal(false)
            }}
          />
        </WrapperContainer>
        <TransWrapper>
          <TransRow>
            <ContentWrapper>
              <SubTransRow>
                <TransactionLable>Transaction Hash:</TransactionLable>
                <TransactionHash>
                  <a href={Url} target="_blank" rel="noreferrer">
                    <BlueText>{transaction}</BlueText>
                    <BlueTransactionText>{transaction.slice(0, 15)}</BlueTransactionText>
                  </a>
                  <CopyToClipboard text={transaction} onCopy={handleCopy}>
                    <CopyContainer data-tip data-for="from">
                      <ReactTooltip id="from" place="top" effect="solid">
                        {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                      </ReactTooltip>
                      <img
                        src={CopyImage}
                        alt="Copy"
                        onClick={() => {
                          setIsCopied(true)
                        }}
                      />
                    </CopyContainer>
                  </CopyToClipboard>
                </TransactionHash>
              </SubTransRow>
              <DownloadWrapper>
                <DownloadButton label={'Download'} onClick={downloadData} />
                <Download />
              </DownloadWrapper>
            </ContentWrapper>
          </TransRow>
          <TransRow>
            <Label>Status:</Label>
            <SuccessText>{status === 1 ? 'Success' : 'Failed'}</SuccessText>
          </TransRow>
          <TransRow>
            <Label>Block:</Label>
            <BlockDetails>
              <BlueText>{blockNumber}</BlueText>
            </BlockDetails>
          </TransRow>
          <TransRow>
            <Label>Timestamp:</Label>
            <ContentWrapper>
              <FromContainer>
                <GreyText>{timeStamp.toUTCString()}</GreyText>
              </FromContainer>
            </ContentWrapper>
          </TransRow>
          <TransMidContainer>
            <TransRow>
              <TransContainer>
                <Label>From:</Label>
                <ContentWrapper>
                  <FromContainer>
                    <BlueText>{from}</BlueText>
                    <CopyToClipboard text={from} onCopy={copyFrom}>
                      <CopyContainer data-tip data-for="registerTip">
                        <ReactTooltip id="registerTip" place="top" effect="solid">
                          {copiedTo ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                        </ReactTooltip>
                        <img
                          src={CopyImage}
                          alt="Copy"
                          onClick={() => {
                            setCopiedTo(true)
                          }}
                        />
                      </CopyContainer>
                    </CopyToClipboard>
                  </FromContainer>
                </ContentWrapper>
              </TransContainer>
            </TransRow>
            <TransRow>
              <Label>To:</Label>
              <TransDetails>
                <TransContainer>
                  <GreyText>Contract</GreyText>
                  <BlueText> {to}</BlueText>
                  <img src={ConfirmTick} alt="confirmed" />
                  <CopyToClipboard text={contract} onCopy={Copy}>
                    <CopyContainer data-tip data-for="to">
                      <ReactTooltip id="to" place="top" effect="solid">
                        {copiedFrom ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                      </ReactTooltip>
                      <img
                        src={CopyImage}
                        alt="Copy"
                        onClick={() => {
                          setCopiedFrom(true)
                        }}
                      />
                    </CopyContainer>
                  </CopyToClipboard>
                </TransContainer>
                <SubTransDetails>
                  <TransContainer>
                    <TransText>TRANSFER 0.00 Ether From </TransText>
                    <SubTransId>{from.slice(0, 15)}</SubTransId>
                    <SubTransIdMobile>{from.slice(0, 15)}</SubTransIdMobile>
                  </TransContainer>
                  <TransContainer>
                    <TransText>To</TransText>
                    <SubTransId>{to.slice(0, 15)}</SubTransId>
                    <SubTransIdMobile>{to.slice(0, 15)}</SubTransIdMobile>
                  </TransContainer>
                </SubTransDetails>
              </TransDetails>
            </TransRow>
          </TransMidContainer>
          <TransRow>
            <TransRowWrapper>
              <LastTransContainer>
                <SubContainer>
                  <Label>Value:</Label>
                  <GreyText>$0 ETH</GreyText>
                </SubContainer>
                <SubContainer>
                  <Label>Transaction Fee:</Label>
                  <GreyText>{transactionfee} Ether</GreyText>
                </SubContainer>
              </LastTransContainer>
              <LastTransContainer>
                <SubContainer>
                  <Label>Gas Price:</Label>
                  <GreyText>
                    {gasPriceinEther} Ether ({gasPriceinGwei} Gwei)
                  </GreyText>
                </SubContainer>
                <SubContainer>
                  <Label>Ether Price:</Label>
                  <GreyText>{etherPrice} / ETH</GreyText>
                </SubContainer>
              </LastTransContainer>
            </TransRowWrapper>
          </TransRow>
          <ButtonSection>
            <CancelButton
              label={'Cancel'}
              onClick={() => {
                showModal(false)
              }}
            />
            <DoneButton label={'Done'} onClick={handleClick} />
          </ButtonSection>
        </TransWrapper>
      </Container>
      <Modal isOpen={isOpen}>
        <ContractSuccess
          showModal={(value: boolean) => setIsOpen(value)}
          contractDetails={to}
          hideAnotherModal={() => {
            showModal(false)
            if (hidePreviousModal) {
              hidePreviousModal()
            }
          }}
        />
      </Modal>
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} success={isSuccess} error={error} />
      </Modal>
    </MainContainer>
  )
}

export default Invoice
