import { ReactElement, useState, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LoaderContext } from 'context/loader'
import { BeneficiaryContext } from 'context/beneficiaries'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { UniqueTokensContext } from 'context/uniqueTokens'
import { TempBeneficiaryDataContext } from 'context/tempBeneficiary'
import { SubscriptionDataContext } from 'context/subsciptionData'
import { UpdataDataContext } from 'context/updateBeneficiary'
import { PaymentWalletContext } from 'context/paymentWallet'
import { NotifyDataContext } from 'context/notifyData'
import Spinner from 'components/Loader'

import Routes from 'routes'
import './App.css'
import 'antd/dist/antd.min.css'

import { MoralisProvider } from 'react-moralis'

const queryClient = new QueryClient()
const App = (): ReactElement => {
  const [loader, setLoader] = useState(false)
  const [uptoLoader, setUptoLoader] = useState(false)
  const [beneficiary, setBeneficiary] = useState(false)
  const [beneficiariesData, setBeneficiariesData] = useState([])
  const [uniqueTokensContext, setUniqueTokensContext] = useState([])
  const [notifyData, setNotifyData] = useState([])
  const [tempBen, setTempBen] = useState([
    { displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '0' },
  ])
  const [updateBeneficiariesData, setUpdateBeneficiariesData] = useState([])
  const [paymentWallet, setPaymentWallet] = useState([])
  const [subscription, setSubscription] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MoralisProvider
      serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL || ''}
      appId={process.env.REACT_APP_MORALIS_APP_ID || ''}
    >
      <QueryClientProvider client={queryClient}>
        {(loader || uptoLoader) && <Spinner uptoLoader={uptoLoader} />}
        <LoaderContext.Provider value={{ loader, setLoader, uptoLoader, setUptoLoader }}>
          <BeneficiaryContext.Provider value={{ beneficiary, setBeneficiary }}>
            <BeneficiaryDataContext.Provider value={{ beneficiariesData, setBeneficiariesData }}>
              <UniqueTokensContext.Provider value={{ uniqueTokensContext, setUniqueTokensContext }}>
                <TempBeneficiaryDataContext.Provider value={{ tempBen, setTempBen }}>
                  <UpdataDataContext.Provider value={{ updateBeneficiariesData, setUpdateBeneficiariesData }}>
                    <SubscriptionDataContext.Provider value={{ subscription, setSubscription }}>
                      <PaymentWalletContext.Provider value={{ paymentWallet, setPaymentWallet }}>
                        <NotifyDataContext.Provider value={{ notifyData, setNotifyData }}>
                          <Routes />
                        </NotifyDataContext.Provider>
                      </PaymentWalletContext.Provider>
                    </SubscriptionDataContext.Provider>
                  </UpdataDataContext.Provider>
                </TempBeneficiaryDataContext.Provider>
              </UniqueTokensContext.Provider>
            </BeneficiaryDataContext.Provider>
          </BeneficiaryContext.Provider>
        </LoaderContext.Provider>
      </QueryClientProvider>
    </MoralisProvider>
  )
}

export default App
