import styled from 'styled-components'
import Button from 'components/Button'
import TextInput from 'components/FormElements/TextInput'

export const Launching = styled.div`
  font-family: 'OutfitBold';
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;

  @media only screen and (max-width: 1000px) {
    font-size: 38px !important;
  }
  @media (max-width: 1366px) {
    font-size: 53px;
  }
  @media (max-width: 767px) {
    font-size: 7vw !important;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`
export const SubHeading = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #ced4e0;
  margin: 0% 5%;
  @media (max-width: 767px) {
    font-size: 3.467vw;
    line-height: 5.867vw;
  }
`
export const ProgressWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #02205b;
`
export const Progress = styled.div`
  width: 40%;
  @media (max-width: 1350px) {
    width: 47%;
  }
  @media (max-width: 1280px) {
    width: 59%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
`
export const RendererWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
`
export const TimeWrapper = styled.div`
  background: rgb(64, 86, 122);
  border-radius: 15px;
  min-width: 35%;
  min-height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TimeCount = styled.div.attrs((props: { color: string }) => props)`
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.color};
`
export const TimeString = styled.div`
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(206, 212, 224);
`
export const TextSection = styled.div`
  height: 47px;
`

export const TextInputBox = styled(TextInput)`
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding-left: 10px;
  font-size: 18px;
  padding: 8px;
`
export const FORMWRAPPER = styled.form`
  display: flex;
  gap: 2px;
  padding-top: 35px;
  .form-field {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding-left: 10px;
    font-size: 18px;
    padding: 8px;
    border-radius: 4px;
    input:focus,
    textarea:focus,
    select:focus {
      background-color: transparent;
      border: 0px solid;
      height: 20px;
      width: 160px;
      color: #ccc;
    }
    .sc-yGEET {
      :focus {
        outline: 0;
      }
    }
  }
  input {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding-left: 10px;
    font-size: 18px;
    padding: 8px;
    border-radius: 4px;
  }
`

export const RightButton = styled(Button)`
  width: auto;
  border-radius: 1px;
  background: #1bb691;
  height: 47px;
  margin-top: 1px;
`
