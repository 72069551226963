import MyPlan from 'assets/svg/MyPlan'
import Beneficiaries from 'assets/svg/Beneficiaries'
import Portfolio from 'assets/svg/Portfolio'
import Tree from 'assets/svg/Tree'
import SmartContracts from 'assets/svg/SmartContracts'
import Notification from 'assets/svg/Notification'
import Subscriptions from 'assets/svg/Subscriptions'
import Setting from 'assets/svg/Setting'

interface IData {
  id: number
  icon: any
  title: string
  path?: string
}

const data: IData[] = [
  {
    id: 0,
    icon: <MyPlan />,
    title: 'My Plan',
    path: '/dashboard',
  },
  {
    id: 1,
    icon: <Portfolio />,
    title: 'Portfolio',
    path: '/mobileportfolio',
  },
  {
    id: 2,
    icon: <Beneficiaries />,
    title: 'Beneficiaries',
    path: '/mobilebeneficiaries',
  },
  {
    id: 3,
    icon: <Tree />,
    title: 'Disbursement Tree',
    path: '/mobiledisburesementtree',
  },
  {
    id: 4,
    icon: <SmartContracts />,
    title: 'Smart Contracts',
    path: '/mobilesmartcontracts',
  },
  {
    id: 5,
    icon: <Notification />,
    title: 'Notifications',
    path: '/mobilenotification',
  },
  {
    id: 6,
    icon: <Subscriptions />,
    title: 'Subscriptions',
    path: '/MobileSubscricptions',
  },
  {
    id: 7,
    icon: <Setting />,
    title: 'Settings',
    path: '/mobilesettings',
  },
]
export default data
