import { ReactElement } from 'react'
import NavBar from 'components/NavBar'
import SideBar from 'components/SideBar'
import MyPlan from 'views/dashboard/MyPlan'

import { Container, SecContainer, SidebarSection } from 'styles/pages/dashboard'

export const Dashboard = (): ReactElement => {
  return (
    <Container>
      <NavBar />
      <SecContainer>
        <SidebarSection>
          <SideBar />
        </SidebarSection>
        <MyPlan />
      </SecContainer>
    </Container>
  )
}

export default Dashboard
