import React, { useState, useContext, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Modal from 'components/Modal'
import ContractSuccess from 'components/ContractCreatedModal'
import { useNavigate } from 'react-router-dom'
import { SubscricptionsRoute } from 'constants/routes'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import usePatch from 'hooks/usePatch'
import { ethers } from 'ethers'
import CrossLogo from 'assets/images/grayx-logo.png'
import Download from 'assets/svg/Download'
import ReactTooltip from 'react-tooltip'
import CopyImage from 'assets/images/copy-image.png'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import { SubscriptionDataContext } from 'context/subsciptionData'
import ErrorModal from 'components/ErrorModal'
import { useMoralisWeb3Api } from 'react-moralis'

import {
  MainContainer,
  Container,
  WrapperContainer,
  HeadText,
  TransWrapper,
  TransRow,
  SubTransRow,
  Label,
  BlockDetails,
  BlueText,
  TransDetails,
  TransRowWrapper,
  LastTransContainer,
  SubContainer,
  SubTransDetails,
  SubTransId,
  TransContainer,
  TransText,
  GreyText,
  DownloadButton,
  SuccessText,
  ContentWrapper,
  TransMidContainer,
  FromContainer,
  DownloadWrapper,
  ButtonSection,
  DoneButton,
  CancelButton,
  CopyContainer,
} from 'styles/components/InvoiceModal/index'

interface IModalProps {
  showModal: (value: boolean) => void
  hidePreviousModal?: () => void
  invoiceData?: any
  blockData?: any
  willInfo?: any
}
const SubscriptionInvoice: React.FC<IModalProps> = ({
  showModal,
  hidePreviousModal,
  invoiceData,
  blockData,
}: IModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [downloadedData, setDownloadedData] = useState('')
  const [isOpens, setIsOpens] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [copiedFrom, setCopiedFrom] = useState(false)
  const [copiedTo, setCopiedTo] = useState(false)
  const [etherPrice, setEtherPrice] = useState('')
  const { subscription } = useContext(SubscriptionDataContext)
  const transaction = invoiceData?.transactionHash
  const gasPriceinEther = ethers.utils.formatUnits(invoiceData?.effectiveGasPrice, 18)
  const gasPriceinGwei = ethers.utils.formatUnits(invoiceData?.effectiveGasPrice, 9)
  const gasUsedinEther = invoiceData?.gasUsed
  const transactionfee = parseFloat(gasUsedinEther) * parseFloat(gasPriceinEther)
  const from = invoiceData?.from
  const status = invoiceData?.status
  const to = process.env.REACT_APP_RECEIVER_ADDRESS
  const blockNumber = invoiceData?.blockNumber
  const timeStamp = new Date(blockData?.timestamp * 1000)
  const { mutateAsync, isSuccess, error, isError, isLoading } = usePost()
  const { mutateAsync: updateAsync, isLoading: updateLoading } = usePatch()
  const navigate = useNavigate()
  const { setLoader } = useContext(LoaderContext)
  const { fetchUser } = useContext(UserContext)
  const Web3Api = useMoralisWeb3Api()
  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (isSuccess) {
      fetchUser()
    }
  }, [isSuccess])

  const fetchTokenPrice = async () => {
    //Get token price
    const tokenPrice = await Web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID_ETH as any,
    })
    setEtherPrice(tokenPrice?.usdPrice.toString())
  }

  const Url = `https://goerli.etherscan.io/tx/${transaction}`
  useEffect(() => {
    if (isError) {
      setIsOpens(true)
    }
  }, [isError, error])

  const eventPayment = async (value: string) => {
    try {
      await mutateAsync({
        url: '/notify/event',
        payload: {
          message: value === 'create' ? 'Subscription Created' : 'Subscription Updated',
          status: true,
          type: `subscribe`,
          titleName: `Subscription`,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const updatePlan = async () => {
    try {
      const updateSubscription = await updateAsync({
        url: '/subscription/updateSubscription',
        payload: subscription,
        token: true,
      })
      navigate(SubscricptionsRoute.path)
      eventPayment('update')

      return updateSubscription
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const createPlan = async () => {
    try {
      const subscriptionPlan = await mutateAsync({
        url: 'subscription/addSubscription',
        payload: subscription,
        token: true,
      })
      navigate(SubscricptionsRoute.path)
      eventPayment('create')
      return subscriptionPlan
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading || updateLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading || updateLoading])

  const plan = () => {
    if (subscribe?.subscriptionData?.subscription?.length) {
      setLoader(true)
      downloadPost()
      updatePlan()
      setLoader(false)
    } else {
      setLoader(true)
      downloadPost()
      createPlan()
      setLoader(false)
    }
  }

  const handleCopy = () => {
    setIsCopied(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (isCopied) setIsCopied(false)
    }, 10000)
  }, [isCopied])

  useEffect(() => {
    setTimeout(() => {
      if (copiedFrom) setIsCopied(false)
      setCopiedFrom(false)
    }, 10000)
  }, [copiedFrom])

  const copyFrom = () => {
    setCopiedTo(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (copiedTo) setCopiedTo(false)
      setCopiedFrom(false)
    }, 10000)
  }, [copiedTo])

  const downloadDataInvoice = async () => {
    const payload = {
      wallet_Address: transaction,
      number: blockNumber,
      date_Time: timeStamp.toUTCString(),
      gas_Price: gasPriceinEther,
      transfer: '0.00',
      to_WalletAddress: to,
      from_WalletAddress: from,
      transaction_Fee: transactionfee,
      ether_Price: `${etherPrice} $`,
    }
    try {
      const downloadData = await mutateAsync({
        url: '/data/downloadData ',
        payload: payload,
        token: true,
      })

      setDownloadedData(downloadData?.data)
      return downloadData?.data
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (downloadedData) {
      const linkSource = `data:application/pdf;base64,${downloadedData}`
      const downloadLink = document.createElement('a')
      const fileName = 'Payment_Invoice.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }, [downloadedData])

  useEffect(() => {
    async function getData() {
      const res = await fetchTokenPrice()
      return res
    }

    getData()
  }, [])

  const { data: subscribe } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })
  const subscribeData = subscribe?.subscriptionData?.subscription

  const { mutateAsync: dowloadAsync } = usePost()

  const downloadPost = async () => {
    const payload = {
      wallet_Address: transaction.toString(),
      number: blockNumber.toString(),
      date_Time: timeStamp.toUTCString(),
      gas_Price: gasPriceinEther.toString(),
      eth_Value: '0.00',
      transfer: '0.00',
      from_WalletAddress: from.toString(),
      transaction_Fee: transactionfee.toString(),
      ether_Price: etherPrice,
      invoice: '0xf525b3e37C0c9d361A8696448c39c5528FFaf31a',
      amount: 80,
      plan: subscribeData[0]?.subscriptionPlan,
      status: true,
    }
    try {
      const invoiceData = await dowloadAsync({
        url: '/invoice/data',
        payload: payload,
        token: true,
      })

      return invoiceData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  return (
    <MainContainer>
      <Container>
        <WrapperContainer>
          <HeadText>Subscription Invoice</HeadText>
          <img
            src={CrossLogo}
            alt="close"
            onClick={() => {
              showModal(false)
            }}
          />
        </WrapperContainer>
        <TransWrapper>
          <TransRow>
            <ContentWrapper>
              <SubTransRow>
                <Label>Transaction Hash:</Label>
                <a href={Url} target="_blank" rel="noreferrer">
                  <BlueText>{transaction}</BlueText>
                </a>
                <CopyToClipboard text={transaction} onCopy={handleCopy}>
                  <CopyContainer data-tip data-for="from">
                    <ReactTooltip id="from" place="top" effect="solid">
                      {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                    </ReactTooltip>
                    <img
                      src={CopyImage}
                      alt="Copy"
                      onClick={() => {
                        setIsCopied(true)
                      }}
                    />
                  </CopyContainer>
                </CopyToClipboard>
              </SubTransRow>
              <DownloadWrapper>
                <DownloadButton label={'Download'} onClick={downloadDataInvoice} />
                <Download />
              </DownloadWrapper>
            </ContentWrapper>
          </TransRow>
          <TransRow>
            <Label>Status:</Label>
            <SuccessText>{status === 1 ? 'Success' : 'Failed'}</SuccessText>
          </TransRow>
          <TransRow>
            <Label>Block:</Label>
            <BlockDetails>
              <BlueText>{blockNumber}</BlueText>
            </BlockDetails>
          </TransRow>
          <TransRow>
            <Label>Timestamp:</Label>
            <ContentWrapper>
              <FromContainer>
                <GreyText>{timeStamp.toUTCString()}</GreyText>
              </FromContainer>
            </ContentWrapper>
          </TransRow>
          <TransMidContainer>
            <TransRow>
              <TransContainer>
                <Label>From:</Label>
                <ContentWrapper>
                  <FromContainer>
                    <BlueText>{from}</BlueText>
                    <CopyToClipboard text={from} onCopy={copyFrom}>
                      <CopyContainer data-tip data-for="registerTip">
                        <ReactTooltip id="registerTip" place="top" effect="solid">
                          {copiedTo ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                        </ReactTooltip>
                        <img
                          src={CopyImage}
                          alt="Copy"
                          onClick={() => {
                            setCopiedTo(true)
                          }}
                        />
                      </CopyContainer>
                    </CopyToClipboard>
                  </FromContainer>
                </ContentWrapper>
              </TransContainer>
            </TransRow>
            <TransRow>
              <Label>To:</Label>
              <TransDetails>
                <SubTransDetails>
                  <TransContainer>
                    <TransText>TRANSFER 0.00 Ether From </TransText>
                    <SubTransId>{from}</SubTransId>
                  </TransContainer>
                  <TransContainer>
                    <TransText>To</TransText>
                    <SubTransId>{to}</SubTransId>
                  </TransContainer>
                </SubTransDetails>
              </TransDetails>
            </TransRow>
          </TransMidContainer>
          <TransRow>
            <TransRowWrapper>
              <LastTransContainer>
                <SubContainer>
                  <Label>Value:</Label>
                  <GreyText>$0 ETH</GreyText>
                </SubContainer>
                <SubContainer>
                  <Label>Transaction Fee:</Label>
                  <GreyText>{transactionfee} Ether</GreyText>
                </SubContainer>
              </LastTransContainer>
              <LastTransContainer>
                <SubContainer>
                  <Label>Gas Price:</Label>
                  <GreyText>
                    {gasPriceinEther} Ether ({gasPriceinGwei} Gwei)
                  </GreyText>
                </SubContainer>
                <SubContainer>
                  <Label>Ether Price:</Label>
                  <GreyText>{etherPrice} / ETH</GreyText>
                </SubContainer>
              </LastTransContainer>
            </TransRowWrapper>
          </TransRow>
          <ButtonSection>
            <CancelButton
              label={'Cancel'}
              onClick={() => {
                showModal(false)
              }}
            />
            <DoneButton label={'Done'} onClick={plan} />
          </ButtonSection>
        </TransWrapper>
      </Container>
      <Modal isOpen={isOpen}>
        <ContractSuccess
          showModal={(value: boolean) => setIsOpen(value)}
          contractDetails={to}
          hideAnotherModal={() => {
            showModal(false)
            if (hidePreviousModal) {
              hidePreviousModal()
            }
          }}
        />
      </Modal>
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} success={isSuccess} error={error} />
      </Modal>
    </MainContainer>
  )
}

export default SubscriptionInvoice
