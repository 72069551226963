import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import data from 'pages/WelcomePlan/data'
import WelcomeSignin from 'views/landing/WelcomeSignIn'
import WelcomePlanCard from 'components/Cards/WelcomePlanCard'
import Modal from 'components/Modal'
import Logo from 'assets/images/logo-black.png'

import {
  Wrapper,
  Container,
  WelcomeText,
  SelectSection,
  ButtonContainer,
  UserOptionCard,
  HeaderWrapper,
  LogoSection,
  SectionButton,
  CancelButton,
  NextButton,
} from 'styles/pages/WelcomePlan'

const WelcomePlan = () => {
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false)
  const [select, setSelect] = useState<string>('Grantor')
  localStorage.setItem('signRole', select)

  return (
    <Wrapper>
      <HeaderWrapper>
        <LogoSection src={Logo} />
      </HeaderWrapper>
      <Container>
        <WelcomeText>Welcome to Cryptoplan</WelcomeText>
      </Container>
      <SelectSection>Please select user type for Signing In</SelectSection>
      <UserOptionCard>
        {data?.map((item) => (
          <WelcomePlanCard
            key={item.id}
            image={item?.image}
            title={item?.title}
            subtitle={item?.subtitle}
            role={() => setSelect(item?.title)}
            disabled={item?.disabled}
            select={select}
          />
        ))}
      </UserOptionCard>
      <SectionButton>
        <ButtonContainer>
          <CancelButton
            label="Cancel"
            variant="outline"
            type="button"
            onClick={() => {
              navigate('/')
            }}
          />
          <NextButton
            label="Next"
            variant="contained"
            type="button"
            onClick={() => {
              setModalVisible(true)
            }}
          />
        </ButtonContainer>
      </SectionButton>
      {select == 'Grantor' ? (
        <Modal isOpen={modalVisible}>
          <WelcomeSignin
            showModal={(value: boolean) => {
              setModalVisible(value)
            }}
          />
        </Modal>
      ) : select == 'Beneficiary' ? (
        <Modal isOpen={modalVisible}>
          <WelcomeSignin
            showModal={(value: boolean) => {
              setModalVisible(value)
            }}
          />
        </Modal>
      ) : (
        <Modal isOpen={modalVisible}>
          <WelcomeSignin
            showModal={(value: boolean) => {
              setModalVisible(value)
            }}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

export default WelcomePlan
