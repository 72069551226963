import { useState } from 'react'
import GenerateKeyDetails from 'components/Cards/GenerateKeyDetails'
import Modal from 'components/Modal'
import { ActionButton } from 'styles/components/Status'

export interface IProps {
  eventId?: string
}

const GenerateKey: React.FC<IProps> = ({ eventId }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ActionButton label={'Generate Key '} onClick={() => setIsOpen(true)} />

      <Modal isOpen={isOpen}>
        <GenerateKeyDetails showModal={(value: boolean) => setIsOpen(value)} eventId={eventId} />
      </Modal>
    </>
  )
}

export default GenerateKey
