import React from 'react'
import SignUpCard from 'components/Cards/SignUp'
import data from 'components/Cards/CreateWallet/data'
import CancelSign from 'assets/images/vector-cancel.png'
import {
  Container,
  Wrapper,
  PrimaryWalletSection,
  SignUpText,
  TextSection,
  CancelSection,
  OptionSection,
  CardSection,
  BottomText,
} from 'styles/components/Cards/CreateWallet'
interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

const CreateWallet: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  return (
    <Container>
      <Wrapper>
        <PrimaryWalletSection>
          <SignUpText>
            <TextSection>Create Wallet</TextSection>
            <CancelSection
              src={CancelSign}
              onClick={() => {
                showModal(false, 'wallet')
              }}
            />
          </SignUpText>
          <BottomText>Please create an account on these recommended wallets</BottomText>
          <CardSection>
            {data?.map((item) => (
              <div key={item.id} onClick={() => window.open(item?.url)}>
                <SignUpCard image={item?.image} title={item?.title} />
              </div>
            ))}
          </CardSection>
        </PrimaryWalletSection>
        <OptionSection></OptionSection>
      </Wrapper>
    </Container>
  )
}

export default CreateWallet
