import { useState, useEffect, useRef, ReactElement } from 'react'
import Modal from 'components/Modal'
import Dots from 'assets/svg/Dots'
import RemoveWalletModal from 'components/Cards/RemoveWalletModal'
import ConnectWallets from '../ConnectedWallets'
import { FirstCard, WalletImage, WalletText, TotalConnected } from 'styles/components/Cards/WalletsConnectDetails'

export interface ICardProps {
  image: string
  title: string
  subtitle: ReactElement
}
const WalletsConnectDetails = ({ image, title, subtitle }: ICardProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isRemove, setIsRemove] = useState(false)
  const [isResend, setResendInvite] = useState(false)
  const wrapperRef = useRef<any>()

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <FirstCard>
      <WalletText>
        <WalletImage src={image} />
        {title}
      </WalletText>
      <TotalConnected>{subtitle}</TotalConnected>
      <Dots onClick={() => setIsOpen(!isOpen)} />

      <Modal isOpen={isRemove}>
        <RemoveWalletModal
          showModal={(value: boolean) => setIsRemove(value)}
          removeBenificiary={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Modal>
      <Modal isOpen={isResend}>
        <ConnectWallets
          showModal={(value: boolean) => setResendInvite(value)}
          getResponse={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Modal>
    </FirstCard>
  )
}

export default WalletsConnectDetails
