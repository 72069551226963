import styled from 'styled-components'

export const MainContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  @media (max-width: 767px) {
    max-width: 90.667vw;
  }
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 36px;
  color: #2677e9;
  text-align: center;
  @media (max-width: 767px) {
    font-style: 6.667vw !important;
  }
`
export const CancelSection = styled.img`
  cursor: pointer;
  height: 21px;
`
export const TopSection = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`

export const BottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .IdSection {
    width: 100%;
    max-width: 300px;
    display: flex;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 12px;
    /* margin-bottom: 20px; */
    height: 30px;
    @media (max-width: 767px) {
      margin-left: 8vw;
      max-width: 69.333vw;
    }
  }
`
export const ButtonSecton = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: center;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`
export const InputBox = styled.div`
  width: 100%;
  max-width: 340px;
`
export const BottomText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 15px;
  color: #737b7d;
  padding: 0 5px 20px 5px;
  /* margin-top: 10px; */
`
export const InputField = styled.div`
  input {
    ::-webkit-input-placeholder {
      text-align: center;
    }
  }
`
