import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'antd'
import Modal from 'components/Modal'
import AddBenifiduciaryMobile from 'views/dashboard/MyPlan/AddBeniFiduciaryMobile'
import RightLogo from 'assets/images/RightMobile-Logo.png'
import RedPluse from 'assets/images/red-pluse.png'
import More from 'assets/svg/More'
import useDelete from 'hooks/useDelete'
import usePost from 'hooks/usePost'
import { BeneficiaryContext } from 'context/beneficiaries'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { TempBeneficiaryDataContext } from 'context/tempBeneficiary'
import { LoaderContext } from 'context/loader'
import {
  MainContainer,
  Container,
  AssignBenificiare,
  LeftSection,
  DeletImage,
  BenificiareText,
  TotalPersentage,
  RightSection,
  ButtonContainer,
  TextArea,
  AddMore,
  WrappSection,
  TextSectionRed,
} from 'styles/views/dashboard/MyPlan/MobileDisplayBenificiaires'

export interface IModalProps {
  next: () => void
  text?: string
}
export interface IData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

const ContainerModal = ({ item, id, setTotalPercentage }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const { mutateAsync: deleteAsync, isLoading: deleteIsLoading } = useDelete()
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { setBeneficiary, beneficiary } = useContext(BeneficiaryContext)
  const { tempBen, setTempBen } = useContext(TempBeneficiaryDataContext)
  const { setLoader } = useContext(LoaderContext)
  const beneficiaryData = beneficiariesData?.data?.beneficary

  const deleteBeneficiary = async () => {
    setTotalPercentage((prev: any) => prev - parseInt(item.amount))
    if (!item.amount || !beneficiaryData.length) {
      const newBeneficiary = [...tempBen]
      newBeneficiary.splice(id, 1)
      setTempBen(newBeneficiary)
    } else {
      try {
        const { data } = await deleteAsync({
          url: 'deletebeneficiary/deletebeneficiary',
          payload: { id: item?.beneficiaryId },
          token: true,
        })
        setBeneficiary(!beneficiary)
        return data
      } catch (error: any) {
        return { error: error.response.data.message }
      }
    }
  }

  useEffect(() => {
    if (deleteIsLoading) setLoader(true)
    else setLoader(false)
  }, [deleteIsLoading])

  return (
    <div>
      <Container>
        <AssignBenificiare>
          <DeletImage src={RedPluse} onClick={deleteBeneficiary} />
          <LeftSection>
            <BenificiareText>
              {item.appointAs !== '' ? item.appointAs : id === 0 ? 'Fiduciary' : 'Beneficiary'}
            </BenificiareText>
            <TotalPersentage>{item.amount > 0 ? item.amount : 0}%</TotalPersentage>
          </LeftSection>
          <RightSection src={RightLogo} onClick={() => setIsOpen(true)} />
        </AssignBenificiare>
      </Container>
      <Modal isOpen={isOpen}>
        <AddBenifiduciaryMobile
          updateable="false"
          showModal={(value: boolean) => setIsOpen(value)}
          setTotalPercentage={setTotalPercentage}
          indexx={id}
          item={item}
        />
      </Modal>
    </div>
  )
}

const MobilePlanCreate: React.FC<IModalProps> = ({ next }) => {
  const { setLoader } = useContext(LoaderContext)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { setBeneficiary, beneficiary } = useContext(BeneficiaryContext)
  const { tempBen, setTempBen } = useContext(TempBeneficiaryDataContext)
  const [fidAllocated, setFidAllocated] = useState(0)
  const [sumPercentage, setTotalPercentage] = useState(0)

  const addBeneficiary = () => {
    setTempBen((prev: any) => [...prev, { displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '0' }])
  }
  const { mutateAsync, isLoading } = usePost()
  const { mutateAsync: updatAsync } = usePost()

  const sendData = async () => {
    try {
      const updateData = await updatAsync({
        url: 'check/changeStatus',
        payload: tempBen,
        token: true,
      })
      next()
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }
  const sendBeneficiaryData = async () => {
    sendData()
    if (JSON.stringify(tempBen) !== JSON.stringify(beneficiariesData?.data?.beneficary)) {
      try {
        const { data } = await mutateAsync({
          url: 'beneficiary/beneficiary',
          payload: tempBen,
          token: true,
        })
        next()
        setBeneficiary(!beneficiary)

        return data
      } catch (error: any) {
        return { error: error.response.data.message }
      }
    } else {
      next()
    }
  }

  useEffect(() => {
    const beneficiaryData = beneficiariesData?.data?.beneficary
    setTempBen(
      beneficiaryData.length > 0
        ? beneficiaryData
        : [{ displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: 0 }],
    )
    const fedLength = beneficiaryData?.filter((item: any) => item.appointAs === 'Fiduciary')
    setFidAllocated(fedLength.length)
    let temp = 0
    beneficiaryData?.forEach((item: any) => {
      if (typeof item?.amount == 'string') {
        temp += parseInt(item?.amount) || 0
      } else {
        temp += item?.amount || 0
      }
    })
    setTotalPercentage(temp)
  }, [beneficiariesData])

  useEffect(() => {
    const fedLength = tempBen?.filter((item: any) => item.appointAs === 'Fiduciary')
    setFidAllocated(fedLength.length)
  }, [tempBen])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <MainContainer>
      {tempBen.map((item: any, id: any) => (
        <ContainerModal
          item={item}
          key={id}
          tempBen={tempBen}
          setTempBen={setTempBen}
          id={id}
          setTotalPercentage={setTotalPercentage}
        />
      ))}
      <ButtonContainer>
        <TextArea>
          {sumPercentage !== 100 && (
            <AddMore onClick={addBeneficiary}>
              <More />
              <span>Add More</span>
            </AddMore>
          )}
          <AddMore>
            <span>{sumPercentage} %</span>
          </AddMore>
        </TextArea>
        <WrappSection>
          <Button
            disabled={sumPercentage !== 100 || fidAllocated !== 1}
            type="primary"
            htmlType="submit"
            className="next-button"
            onClick={sendBeneficiaryData}
          >
            Next
          </Button>
        </WrappSection>
        <div>{sumPercentage > 100 && <TextSectionRed>{'(Total cannot be more than 100%)'}</TextSectionRed>}</div>
        <div>{sumPercentage < 100 && <TextSectionRed>{'(Total cannot be less than 100%)'}</TextSectionRed>}</div>
        <div>{fidAllocated != 1 && <TextSectionRed>{'(Please select one fiduciary )'}</TextSectionRed>}</div>
      </ButtonContainer>
    </MainContainer>
  )
}

export default MobilePlanCreate
