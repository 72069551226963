import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { Table } from 'antd'
import { columns } from 'views/dashboard/MyWallet/data'
import ReactTooltip from 'react-tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Modal from 'components/Modal'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import useGet from 'hooks/useGet'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import RemoveWalletModal from 'components/Cards/RemoveWalletModal'
import useDelete from 'hooks/useDelete'
import ChooseAssets from 'components/Cards/ChooseAssets'
import MetaMask from 'assets/images/meta-mask.png'
import CopyImage from 'assets/images/copy-image.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import Watermark from 'assets/images/watermark-image.png'
import {
  Wrapper,
  MainContainer,
  TopSection,
  BtcSection,
  BitcoinImage,
  TextSection,
  CoinName,
  CoinPrice,
  CruntPrice,
  PriceDown,
  MiddleSection,
  RecentSection,
  TableContainer,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  CopyContainer,
  CoppyImage,
  TopMainConatiner,
} from 'styles/views/dashboard/MyWallet'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDraggable } from 'react-use-draggable-scroll'

interface ITokenPriceData {
  usdPrice: number
}

interface IData {
  chosenWalletId?: string
}

interface ISelect {
  walletId?: string
}

export const MyProfile = (): ReactElement => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref, {
    activeMouseButton: 'Left', // activate rubber band effect
    applyRubberBandEffect: true,
  })
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }
  const { user, setUser } = useContext(UserContext)
  const { setLoader } = useContext(LoaderContext)
  const Web3Api = useMoralisWeb3Api()
  const [isRemove, setIsRemove] = useState(false)
  const [select, setSelect] = useState('')
  const [walletIndex, setWalletIndex] = useState<number>()
  const [isCopied, setIsCopied] = useState(false)
  const [userwalletId, setUserwalletId] = useState<string>('')
  const [, setNativeTokenPrice] = useState<ITokenPriceData[]>([])
  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''
  const dex_exchange = process.env.REACT_APP_DEX || ''
  const walletInfo = localStorage?.getItem('walletconnect') || ''
  const { mutateAsync, isLoading: deleteIsLoading } = useDelete()
  const [walletBalances, setWalletBalances] = useState<number[]>([])
  const { Moralis } = useMoralis()

  const fetchNativeBalance = async (specificAccount: any) => {
    const response = await Web3Api.account.getNativeBalance({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: specificAccount,
    })
    const assetValue = parseFloat(Moralis.Units.FromWei(response.balance, 18))
    setWalletBalances((prev) => [...prev, assetValue])
  }

  const lengthIterator = user?.userWallet.length

  useEffect(() => {
    callingFetchbalance(user?.userWallet, async (element: any) => {
      await fetchNativeBalance(element?.walletId)
    })
  }, [lengthIterator])

  async function callingFetchbalance(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  const {
    data: userData,
    refetch,
    isLoading,
  } = useGet('user-Transition', 'user/transaction', false, {
    token: true,
  })

  const { refetch: refetchUser, isLoading: userLoading } = useGet('user-details', 'users/details', false, {
    token: true,
    cacheTime: 15,
    onSuccess: (responseData: any) => {
      setIsRemove(false)
      setTimeout(() => setUser(responseData.userData), 500)
    },
  })

  const fetchTokenPrice = async () => {
    //Get token price
    const tokenPrice = await Web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      exchange: dex_exchange,
    })

    const nativePriceUSD: any = tokenPrice?.usdPrice
    setNativeTokenPrice(nativePriceUSD)
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && user?.userWalletId) fetchTokenPrice()
  }, [user])

  const handleCopy = () => {
    setIsCopied(true)
  }

  const tableData = []

  const userTransaction = userData?.data?.transaction
  for (let i = 0; i < userTransaction?.length; i++) {
    const elementData = userTransaction[i]

    tableData.push({
      key: i,
      hyperlink: (
        <CopyContainer>
          <div>
            {`${elementData?.hyperlink?.substring(0, 10)}...${elementData?.hyperlink?.substring(
              elementData?.hyperlink?.length - 10,
            )}`}
          </div>
          <CopyToClipboard text={elementData?.hyperlink} onCopy={handleCopy}>
            <div data-tip data-for="from">
              <ReactTooltip id="from" place="top" effect="solid">
                {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
              </ReactTooltip>
              <CoppyImage
                src={CopyImage}
                alt="Copy"
                onClick={() => {
                  setIsCopied(true)
                }}
              />
            </div>
          </CopyToClipboard>
        </CopyContainer>
      ),
      category: elementData?.category,
      currencyPrice: `$${elementData?.currencyPrice} USD`,
      totalAmount: `${elementData?.totalAmount} ETH`,
      fromAccount: elementData?.fromAccount,
      createTime: elementData?.createTime.split('T', [1]),
    })
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (deleteIsLoading) setLoader(true)
    else setLoader(false)
  }, [deleteIsLoading])

  useEffect(() => {
    if (userLoading) setLoader(true)
    else setLoader(false)
  }, [userLoading])

  const handleClick = (item: ISelect) => {
    setSelect(item?.walletId || '')
  }

  return (
    <Wrapper>
      <MainContainer>
        <TopMainConatiner>
          <TopSection {...events} ref={ref}>
            {user?.userWallet?.map((item: any, index: number) => {
              return (
                <BtcSection key={index} onClick={() => handleClick(item)} className={onmouseenter ? 'focus' : 'not'}>
                  {walletInfo ? (
                    <BitcoinImage
                      src={JSON.parse(walletInfo)?.peerMeta?.icons[0] || ''}
                      onClick={() => {
                        setWalletIndex(index)
                        setUserwalletId(item?.walletId)
                        setInviteModal(true)
                      }}
                    />
                  ) : (
                    <BitcoinImage
                      src={item?.primaryWalletName === 'MetaMask' ? MetaMask : CryptoWallet}
                      onClick={() => {
                        setWalletIndex(index)
                        setUserwalletId(item?.walletId)
                        setInviteModal(true)
                      }}
                    />
                  )}
                  <TextSection>
                    {walletInfo ? (
                      <CoinName>{JSON.parse(walletInfo)?.peerMeta?.name || 'MetaMask'}</CoinName>
                    ) : (
                      <CoinName>{`${item?.walletId.substring(0, 5)}...${item?.walletId.substring(
                        item?.walletId.length - 3,
                        item?.walletId.length,
                      )}`}</CoinName>
                    )}
                    <CoinPrice>{`${walletBalances[index]?.toLocaleString() || '0'} ${
                      process.env.REACT_APP_WEB3_NATIVE_CURR
                    }`}</CoinPrice>
                    <CruntPrice>
                      <PriceDown src={''} />
                    </CruntPrice>
                    {index !== 0 && (
                      <button
                        className="removeButton"
                        onClick={() => {
                          setIsRemove(!isRemove)
                          toast(
                            'You need to deselect assets belongs to this wallet before making changes to Cryptoplan (Portfolio -> Wallets -> Click on respective Wallet Icon -> Choose your assets',
                          )
                        }}
                      >
                        Disconnect
                      </button>
                    )}
                  </TextSection>
                  <Modal isOpen={isRemove}>
                    <RemoveWalletModal
                      showModal={(value: boolean) => setIsRemove(value)}
                      removeWallet={async () => {
                        const payload = {
                          walletId: item?.walletId,
                        }
                        try {
                          const updatedData = await mutateAsync({
                            url: 'wallet/removeWallet',
                            payload: payload,
                            token: true,
                          })
                          refetchUser()
                          return updatedData
                        } catch (error: any) {
                          return { error: error.response.data.message }
                        }
                      }}
                      walletIndex={item?.walletId}
                    />
                  </Modal>
                  {/* </div> */}
                </BtcSection>
              )
            })}
          </TopSection>
        </TopMainConatiner>
        <MiddleSection>
          <RecentSection>Recent Activity</RecentSection>
        </MiddleSection>
        {userData?.data?.transaction?.length ? (
          <TableContainer>
            <Table columns={columns} dataSource={tableData} />
          </TableContainer>
        ) : (
          <NoBeneficiaries>
            <BeneficiarieImage>
              <CryptoLogo src={Watermark} />
            </BeneficiarieImage>
            <TextSextion>
              <HeadingSection>No Transactions Made</HeadingSection>
              <DefinitionSection></DefinitionSection>
            </TextSextion>
          </NoBeneficiaries>
        )}
      </MainContainer>
      <Modal isOpen={isCreateInviteOpen}>
        <ChooseAssets
          showModal={(value: boolean) => setInviteModal(value)}
          type="update"
          index={walletIndex}
          userwalletId={userwalletId}
          select={select}
        />
      </Modal>
      <ToastContainer />
    </Wrapper>
  )
}

export default MyProfile
