import React, { useState } from 'react'
import NavBar from 'components/NavBar'
import Signin from 'views/landing/SignIn'
import Modal from 'components/Modal'
import { Wrapper, MainContainer, Section } from 'styles/views/dashboard/DisbursementTree'
import Zoomable from 'components/Zoomable'

const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper>
      <NavBar />
      <Section>
        <MainContainer>
          <Zoomable />
        </MainContainer>
      </Section>
      <Modal isOpen={isOpen}>
        <Signin showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Wrapper>
  )
}

export default Portfolio
