import styled from 'styled-components'
import Button from 'components/Button'

export interface IProps {
  label: string
}

export const MainContainer = styled.div`
  gap: 17px;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
`
export const Container = styled(Button)<IProps>`
  background: rgba(255, 201, 162, 0.26);
  background: ${(props) => (props.label === 'Pending' ? '#ffc9a242' : '#009f4221')};
  color: ${(props) => (props.label === 'Pending' ? ' #FEA41E' : '#009F42')};
  border-radius: 30px;
  width: 100%;
  max-width: 131px;
`
export const PanddingImage = styled.img`
  height: 23px;
  cursor: pointer;
`
export const ActionButton = styled(Button)`
  background: #fff;
  color: #2677e9;
  border: 1px solid #2677e9;
  border-radius: 16px;
  width: 122px;
  @media only screen and (max-width: 900px) {
    width: 78px;
  }
`
export const ResendButton = styled(Button)`
  background: #fff;
  color: #2677e9;
  border: 1px solid #2677e9;
  border-radius: 16px;
  width: 122px;
`
export const StopButton = styled(Button)`
  display: none;
  background: #ff3535;
  border-radius: 16px;
  width: 122px;
  color: #fff;
`
export const IdStatus = styled.div`
  color: #2677e9;
`
export const StatusActive = styled.div`
  align-items: center;
  color: #1bb691;
`
