import React from 'react'
import StripeContainer from 'components/StripeContainer'
import CrossLogo from 'assets/images/grayx-logo.png'
import { Wrapper, Container, HeadText, Cross } from 'styles/components/Modal/PaypalInfo'
interface IProps {
  showModal: (value: boolean) => void
}

function CheckoutForm({ showModal }: IProps) {
  return (
    <div>
      <Wrapper>
        <Container>
          <HeadText>Add Debit or Credit Card</HeadText>
          <Cross src={CrossLogo} alt="Close" onClick={() => showModal(false)} />
        </Container>

        <StripeContainer />
      </Wrapper>
    </div>
  )
}

export default CheckoutForm
