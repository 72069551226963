import { useState, useEffect, useContext } from 'react'
import Table from 'components/Table'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import Start from './start'
import { columns } from 'views/dashboard/FiduciaryMyPlan/FiduciaryPlanContent/data'
import {
  TableWrapper,
  ContractWrapper,
  HeadWrapper,
  UpdatedContainer,
  HeadText,
  TextSection,
  IdStatus,
  StatusActive,
} from 'styles/views/dashboard/SmartContract/index'

interface Iprop {
  ownerName?: string
}

const Planfiduciary = ({ ownerName }: Iprop) => {
  const [toggle, setToggle] = useState(true)
  const isOpen = () => setToggle(true)
  const isClose = () => setToggle(false)
  const { user } = useContext(UserContext)

  const { data: getFiduciaryView, refetch } = useGet('get-fiduciary', 'getFiduciary/getFiduciary', false, {
    enabled: true,
    token: true,
  })

  const { data: getlinkup, refetch: linkupFetch } = useGet('get-linkup', 'getlinkup/getuserLink', false, {
    enabled: true,
    token: true,
  })

  const { data: getFiduciary, refetch: getFiduciaryRefetch } = useGet(
    'getView-getFiduciaryView',
    'getView/getFiduciaryView',
    false,
    {
      enabled: true,
      token: true,
    },
  )

  const fiduciaryData = getFiduciaryView?.user

  const tableData = []

  const dateSplit = new Date(fiduciaryData?.createDate)?.toLocaleDateString('en-us', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })
  const latestIndex = getlinkup?.linkupFiducary.length - 1

  tableData.push({
    key: 0,
    contractname: ownerName,
    contractid: (
      <IdStatus>{`${getFiduciary?.fiducarydetail[0]?.contractId?.substring(
        0,
        8,
      )}...${getFiduciary?.fiducarydetail[0]?.contractId?.substring(
        getFiduciary?.fiducarydetail[0]?.contractId?.length - 4,
      )}`}</IdStatus>
    ),
    contractdate: dateSplit,
    appointedas: getlinkup?.linkupFiducary[latestIndex]?.appointAs,
    status: (
      <StatusActive
        style={
          user?.contractStatus === 'Terminated'
            ? { color: 'red', background: 'rgba(255, 233, 237, 0.47)' }
            : user?.contractStatus?.toLowerCase() === 'processing'
            ? { color: '#009F42', background: 'rgba(0, 159, 66, 0.19)' }
            : { color: '#2677e9', background: '#E9F2FF' }
        }
      >
        {user?.contractStatus}
      </StatusActive>
    ),
    action: <Start />,
  })

  useEffect(() => {
    linkupFetch()
    getFiduciaryRefetch()
    refetch()
  }, [])

  return (
    <>
      <ContractWrapper>
        <UpdatedContainer>
          <HeadWrapper>
            {toggle ? <DownOutlined onClick={isClose} /> : <RightOutlined onClick={isOpen} />}
            <TextSection>
              <HeadText>Fiduciary Contract</HeadText>
            </TextSection>
          </HeadWrapper>
          {toggle && (
            <TableWrapper>
              <Table data={tableData} columns={columns} />
            </TableWrapper>
          )}
        </UpdatedContainer>
      </ContractWrapper>
    </>
  )
}

export default Planfiduciary
