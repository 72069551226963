import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`
export const Wrapper = styled.div`
  width: 100%;
  max-width: 561px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 85.333vw;
  }
`
export const PrimaryWalletSection = styled.div`
  padding: 10px;
`
export const SignUpText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 7.467vw;
  }
`
export const CancelSection = styled.img`
  cursor: pointer;
  height: 27px;
  @media (max-width: 767px) {
    height: 5.333vw;
    padding-right: 5.333vw;
  }
`
export const OptionSection = styled.div`
  padding: 0 46px 13px 46px;
`
export const MoreOptionSection = styled.div`
  border: 1px solid #cbd1d8;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  background: #4473f5;
  border-radius: 32px;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
`
export const TextOption = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`
export const TextDropDown = styled.img`
  margin: 5px;
  position: relative;
  cursor: pointer;
`
export const CardSection = styled.div`
  height: 400px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    width: 100%;
    /* max-width: 80vw; */
    align-items: center;
  }
`
export const BottomText = styled.div`
  width: 100%;
  max-width: 541px;
  font-family: 'OutfitLight';
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.02em;
  padding: 12px 10px 11px 5px;
  @media (max-width: 1366px) {
    padding: 5px 10px 1px 2px;
  }
  @media (max-width: 767px) {
    font-size: 3.733vw;
  }
`
