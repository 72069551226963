import * as yup from 'yup'

export const schema = yup.object().shape({
  displayName: yup
    .string()
    .required('name is Required')
    .matches(/^\S/, 'First character cannot be Space ')
    .matches(/^([^0-9]*)$/, 'Number not allowed'),
  userWalletId: yup
    .string()
    .required('Valid Wallet is Required')
    .matches(/^\S+$/, 'Space is not allowed ')
    .matches(/^$|[a-zA-Z0-9_]$/, 'Special characters are not allowed ')
    .min(40, 'Wallet Address must be 40-46 characters')
    .max(46, 'Wallet Address must be 40-46 characters'),
  emailId: yup
    .string()
    .required('Email is required')
    .matches(/^\S/, 'First character cannot be Space ')
    .email('Please enter a valid Email')
    .max(255),
})

export const emailSchema = yup.object().shape({
  secondaryEmail: yup
    .string()
    .required('Email is required')
    .matches(/^\S/, 'First character cannot be Space ')
    .email('Please enter a valid Email')
    .max(255),
})

export const userSchema = yup.object().shape({
  userName: yup.string().required('Display Name is required ').matches(/^\S/, 'First character cannot be Space'),
  phone: yup
    .string()
    .max(10)
    .matches(/^[0-9]*$/, 'Please enter a valid number !'),
})

export const seckeySchema = yup.object().shape({
  seckey: yup
    .string()
    .required('Secret recovery phrase is required')
    .matches(
      /^([A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+(\s[A-Za-z]{3,})+$/,
      'Invalid secret recovery phrase',
    ),
})

export const shardsSchema = yup.object().shape({
  shards: yup.string().required('Shards is required'),
})
