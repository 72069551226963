import React, { useContext, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import TextInput from 'components/FormElements/TextInput'
import { useForm, useWatch } from 'react-hook-form'
import { UserContext } from 'context/user'
import usePost from 'hooks/usePost'
import { LoaderContext } from 'context/loader'
import { Tooltip } from 'antd'
import 'react-toastify/dist/ReactToastify.css'
import Contract from 'assets/images/question-mark.png'
import {
  ButtonSection,
  TableWrapper,
  InputContainer,
  InputSection,
  ImageSection,
  QuestionMark,
  MainContainer,
} from 'styles/components/SecretKey'
import { NextButton } from 'styles/pages/WelcomePlan'

const text = (
  <>
    <div style={{ color: 'red', fontSize: '14px' }}>*Only single attempt allowed to enter your shards.</div>
  </>
)

const SerectKey = () => {
  const { control, handleSubmit } = useForm()
  const { user } = useContext(UserContext)
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync: shartsadd, isLoading } = usePost()

  const viewField = useWatch({ control })

  const submit = async (values: any) => {
    await shartsadd({
      url: '/shartsadd/shartsadd',
      payload: { userwallet: user?.userWalletId, key: values?.shardsAdd },
      token: true,
    })
    toast('Shard submitted successfully')
    window.location.reload()
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <TableWrapper onSubmit={handleSubmit(submit)}>
      <MainContainer>
        <InputContainer>
          <InputSection>
            <TextInput
              type="text"
              name="shardsAdd"
              placeholder="Please Enter Your Shards"
              control={control}
              value={'hello'}
            />
          </InputSection>
        </InputContainer>
        <ImageSection>
          <Tooltip placement="rightTop" title={text} color={'#e2eaff'} overlayStyle={{ maxWidth: '500px' }}>
            <QuestionMark src={Contract} />
          </Tooltip>
        </ImageSection>
      </MainContainer>
      <ButtonSection>
        <NextButton
          label="Submit"
          variant="contained"
          disabled={viewField?.shardsAdd === '' || viewField?.shardsAdd === undefined}
          type="submit"
        />
      </ButtonSection>
      <ToastContainer />
    </TableWrapper>
  )
}

export default SerectKey
