import { useState, useEffect, useContext } from 'react'
import { ethers } from 'ethers'
import CPWill from 'artifacts/contracts/CPWillUpgradeable.sol/CPWillUpgradeable.json'
import CPWillFactory from 'artifacts/contracts/CPWillFactoryUpgradeable.sol/CPWillFactoryUpgradeable.json'
import Modal from 'components/Modal'
import usePut from 'hooks/usePut'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import ErrorModal from 'components/ErrorModal'
import { StopButton } from 'styles/pages/WelcomePlan'

const Stop = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const disabled = user?.status === 'Active' || user?.status === 'Ready' || user?.status === 'Terminated'

  const { mutateAsync, isLoading } = usePut()
  const [errorModal, seterrorModal] = useState(false)
  const factoryContractaddress: string = process.env.React_APP_CONTRACT_FACTORY_ADDRESS || ''

  const stopContract = async () => {
    try {
      await mutateAsync({
        url: 'contract/stopContract',
        token: true,
      })
    } catch (error: any) {
      seterrorModal(true)
      return { error: error }
    }
  }

  const revokingContract = async () => {
    setLoader(true)
    const provider = new ethers.providers.Web3Provider((window as any).ethereum)
    const signer = provider.getSigner()
    const signerAddress: string = await signer.getAddress()
    try {
      const contract1 = new ethers.Contract(factoryContractaddress, CPWillFactory.abi, signer)
      const willId = await contract1.getWills(signerAddress)
      const latestContractAddress: string = willId[(willId?.length || 1) - 1]
      const contract2 = new ethers.Contract(latestContractAddress, CPWill.abi, signer)
      const tx = await contract2.revokeRedemption(1, '0x1233')
      const receipt = await tx.wait()
      if (receipt?.events[0]?.event === 'WillRedemptionRevoked') {
        setLoader(false)
        stopContract()
      }
    } catch (error: any) {
      setLoader(false)
      seterrorModal(error?.reason)
      setIsOpen(true)
      return { error: errorModal }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <>
      <StopButton
        label="Stop Contract"
        onClick={revokingContract}
        variant="contained"
        type="button"
        disabled={disabled}
      />

      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} errorModal={errorModal} success={false} />
      </Modal>
    </>
  )
}

export default Stop
