import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 6.25A3.25 3.25 0 0 1 6.25 3h11.5A3.25 3.25 0 0 1 21 6.25v11.5A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V6.25ZM6.25 4.5A1.75 1.75 0 0 0 4.5 6.25v11.5c0 .966.784 1.75 1.75 1.75H9v-15H6.25Zm4.25 0V14h9V6.25a1.75 1.75 0 0 0-1.75-1.75H10.5Zm9 11h-9v4h7.25a1.75 1.75 0 0 0 1.75-1.75V15.5Z"
      fill="#737B7D"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
