const errorName: string[] = [
  'Invalid Address',
  'Contract cannot be grantor!',
  'Address is already a Grantor!',
  'Address is not Fiduciary',
  'Address is already Fiduciary',
  'Will Contract cannot be Fiduciary',
  'Address is already Beneficiary',
  'Redemption Period cannot be changed once will redemption has started',
  'Period must be greater than 1 day',
  'Only Fiduciary or Grantor can start redemption',
  'Redemption not started',
  'Beneficiary Share Owner Balance',
  'invalid signature length',
  'The linked wallet is not able to redeem as it is not authorised by the grantor!',
]
export default errorName
