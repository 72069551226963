import { useContext, useEffect } from 'react'
import { MainContainer, HeadText, TextArea } from 'styles/views/dashboard/Settings/MyDeatils'
import Table from 'components/Table'
import { columns } from 'views/dashboard/SmartContract/SmartContractContent/data'
import { LoaderContext } from 'context/loader'
import useGet from 'hooks/useGet'
import Action from '../../SmartContract/SmartContractContent/action'
import Delete from 'views/dashboard/Settings/ManageWills/delete'
import {
  MainStatusContainer,
  PendingContainer,
  SuccessContainer,
  TableWrapper,
} from 'styles/views/dashboard/SmartContract'

interface IModalProps {
  showModal: (value: boolean) => void
}

const ManageWills: React.FC<IModalProps> = () => {
  const { setLoader } = useContext(LoaderContext)

  const { data: notification, isLoading: linkLoading } = useGet('get-notification', 'all/event/', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  let UpdateData = []
  const length = notification?.users?.eventHit.length
  const eventNotifier = notification?.users?.eventHit

  for (let iterator = length - 1; iterator >= 0; iterator--) {
    if (
      eventNotifier[iterator]?.title === 'Created' ||
      eventNotifier[iterator]?.title === 'updated' ||
      eventNotifier[iterator]?.title == 'Deleted'
    ) {
      if (eventNotifier[iterator]?.title == 'Deleted') {
        UpdateData = []
        break
      } else {
        UpdateData.push({
          key: iterator,
          contractid: eventNotifier[iterator]?.contractId,
          contractdate: eventNotifier[iterator]?.contractDate.split('T', [1]),
          fiduciary: eventNotifier[iterator]?.nameofFiduciary,
          totalbenificiaries: eventNotifier[iterator]?.numberofBeneficiary,
          status: eventNotifier[iterator]?.status ? (
            <>
              <MainStatusContainer>
                <SuccessContainer>Active</SuccessContainer>
              </MainStatusContainer>
            </>
          ) : (
            <MainStatusContainer>
              <PendingContainer>Failed</PendingContainer>
            </MainStatusContainer>
          ),
          action: <Action eventId={eventNotifier[iterator]?.eventId} />,
          delete: <Delete />,
        })
      }
    }
  }

  return (
    <MainContainer>
      <div>
        <HeadText>Deployed Contract(s)</HeadText>
        <TextArea>
          <TableWrapper>
            <Table data={UpdateData.slice(0, 1)} columns={columns.slice(0, -1)} />
          </TableWrapper>
        </TextArea>
      </div>
    </MainContainer>
  )
}

export default ManageWills
