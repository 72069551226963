import styled from 'styled-components'
import { theme } from 'theme'
interface Itheme {
  active?: boolean
}
export const Wrapper = styled.div<Itheme>`
  margin-top: 100px;
  width: 100%;
  display: flex;
  gap: 214px;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
  @media only screen and (max-width: 900px) {
    padding: 0px 20px 10px 17px !important;
    gap: 49px;
  }
  @media (max-width: 1280px) {
    margin-top: 20px !important;
  }
  @media (max-width: 1350px) {
    margin: 0 auto;
    gap: 69px;
    padding: 0px 90px;
  }
  @media (max-width: 1440px) {
    gap: 70px;
  }

  @media (max-width: 767px) {
    display: grid;
    padding: 0 9.067vw 0 9.067vw !important;
    margin-top: 1.6vw;
    gap: 65px;
  }
`

export const WalletPointsSections = styled.div`
  font-family: 'OutfitRegular';
  font-size: 21px;
  line-height: 32px;
  color: ${theme.info.light};
  @media (max-width: 767px) {
    width: 100%;
    max-width: 78.667vw;
  }
`
export const PointFirst = styled.div`
  display: flex;
  margin: 5px;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-size: 18px;
  line-height: 29px;

  @media (max-width: 1366px) {
    font-size: 16px !important;
  }
  @media (max-width: 1440px) {
    margin: 0 auto;
    padding: 0 0 10px 0;
  }
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-size: 3.733vw;
    line-height: 4.8vw;
    color: #ffffff;
    padding: 6px;
  }
`
export const CardWrapper = styled.div`
  width: 264.41px;
  height: 337.26px;
  background: linear-gradient(180deg, #eff4ff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 8px 45px 48px -16px rgba(207, 207, 207, 0.5);
  border-radius: 60px;
  justify-content: center;
  align-items: center;
`
export const ImgConatiner = styled.div``
export const ImageSection = styled.img`
  padding: 50px 73px 19px 70px;
`
export const TitleContainer = styled.div``
export const Title = styled.div`
  font-family: 'OutfitMedium';

  font-size: 24px;
  line-height: 30px;
  text-align: center;
`
export const ImageSectionblue = styled.div``
export const TextSection = styled.div``

export const SubTitle = styled.div`
  font-family: 'OutfitRegular';

  font-size: 12px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.info.gray};
  mix-blend-mode: luminosity;
  padding: 7px 23px 50px 26px;
  max-width: 217px;
  width: 100%;
`
export const VectorSection = styled.img`
  width: 24px;
  height: 18px;
  padding-right: 5px;
  margin-right: 10px;

  @media (max-width: 1366px) {
    margin-right: 6px !important;
  }
  @media (max-width: 1367px) {
    margin-right: 10px;
  }
  @media (max-width: 1440px) {
    padding-right: 6px;
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    padding-right: 1.8vw;
    margin-right: 1.333vw;
  }
`
export const LeftSection = styled.div`
  @media (max-width: 767px) {
    margin-left: 5vw;
  }
`
export const HeaderText = styled.div`
  font-family: 'OutfitSemiBold';
  font-style: normal;
  font-size: 69px;
  line-height: 82px;
  letter-spacing: 0.685715px;
  color: ${theme.info.light};
  @media (max-width: 1440) {
    font-size: 50px;
    line-height: 70px;
  }
  @media only screen and (max-width: 100px) {
    font-size: 32px !important;
    line-height: 43px;
  }

  @media (max-width: 767px) {
    font-family: 'OutfitSemiBold';
    font-weight: 600;
    font-size: 8.533vw;
    line-height: 10.667vw;
    color: #ffffff;
    padding-bottom: 2.667vw;
  }
`
export const RightSection = styled.div`
  display: flex;
  justify-content: center;
  height: 320px;

  @media (max-width: 767px) {
    width: 89.067vw;
    height: 70.133vw;
    order: 1;
    grid-row-end: none;
  }
`
export const RightImageSection = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 1440px) {
    max-width: 450px;
  }
  @media (max-width: 1000px) {
    width: 269px;
    height: 251px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileDashboardpic = styled.img`
  display: none;
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    max-width: 79.333vw;
  }
`

export const HeadText = styled.div`
  font-family: 'OutfitRegular';
  max-width: 750px;
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  color: ${theme.info.extra_light};
  @media only screen and (max-width: 900px) {
    font-size: 14px !important;
    line-height: 24px;
  }
  @media (max-width: 1367px) {
    font-size: 18px;
  }
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    color: #ffffff;
    font-family: 'OutfitRegular';
    font-weight: 400;
    font-size: 4.267vw !important;
    line-height: 5.867vw;
    width: 100%;
    max-width: 80.8vw;
  }
`
