import React, { useState, useEffect } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SaveButton, ButtonContainer, CardDetails, DetailsContainer } from 'styles/components/Modal/PaypalInfo'
import usePost from 'hooks/usePost'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'

export default function PaymentForm() {
  const { mutateAsync } = usePost()
  const [paymentError, setPaymentError] = useState()
  const [isOpens, setIsOpens] = useState(false)
  const [clientSecretKey, setClientSecretKey] = useState()
  const stripe: any = useStripe()
  const elements: any = useElements()

  const intentPost = async () => {
    const intentPayload = {
      amount: 1000,
      currency: 'USD',
      paymentMethordType: 'card',
    }

    try {
      const data = await mutateAsync({
        url: 'payment/Intent',
        payload: intentPayload,
        token: true,
      })
      setClientSecretKey(data?.clientSecret)

      return data
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    intentPost()
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const result = await stripe.confirmCardPayment(clientSecretKey, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: event.target.name.value,
        },
      },
    })

    setPaymentError(result?.error?.message)
    setIsOpens(true)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardDetails>
          <fieldset className="FormGroup">
            <div className="FormRow">
              <input type="text" id="name" name="name" className="carddetails" placeholder="Cardholder Name" />
              <DetailsContainer>
                <CardElement />
              </DetailsContainer>
            </div>
          </fieldset>
        </CardDetails>
        <ButtonContainer>
          <SaveButton label={'Pay'} variant="contained" type="submit" />
        </ButtonContainer>
      </form>
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} success={false} paymentError={paymentError} />
      </Modal>
    </>
  )
}
