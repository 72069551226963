import React from 'react'
import AddWallet from 'assets/images/addwallet-dark.png'
import SmartContract from 'assets/images/smartcontract-dark.png'
import AddBeneficiar from 'assets/images/addbeneficiar-dark.png'
import LifeChanging from 'assets/images/lifechanging-dark.png'
import Disbursement from 'assets/images/disbursement-dark.png'
import LastDisbursement from 'assets/images/last-dark.png'
import {
  RoadMap,
  BlankSection,
  AddWalletSection,
  SmartContractSection,
  WorkFrame,
  AddBeneficiariesSection,
  LifeChangingSection,
  DisbursementSection,
  LastBlankSection,
  BlankFirstSection,
  BlankSecondSection,
  AddWalletFirstSection,
  AddWalletSecondSection,
  SmartFirstSection,
  SmartSecondSection,
  BeneficiarFirstSection,
  BeneficiarSecondSection,
  LifeFirstSection,
  LifeSecondSection,
  DisbursFirstSection,
  DisbursSecondSection,
  LastFirstSection,
  Label,
  FileImageContainer,
} from 'styles/views/landing/SubscriberRoadMap'

const SubscriberRoadMap = () => (
  <RoadMap>
    <WorkFrame>
      <BlankSection>
        <BlankFirstSection>
          <FileImageContainer
            src={AddWallet}
            initial={{ translateX: '-8vw' }}
            animate={{ translateX: '0vw', transition: { duration: 0.5 } }}
          />
        </BlankFirstSection>
        <BlankSecondSection />
      </BlankSection>
      <AddWalletSection>
        <AddWalletFirstSection>
          <Label>Add Wallet</Label>
          {/* <motion.div> */}
          <FileImageContainer
            src={SmartContract}
            initial={{ translateY: '8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5 } }}
          />
          {/* </motion.div> */}
        </AddWalletFirstSection>
        <AddWalletSecondSection />
      </AddWalletSection>
      <SmartContractSection>
        <SmartFirstSection>
          <FileImageContainer
            src={AddBeneficiar}
            initial={{ translateY: '-8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5 } }}
          />
        </SmartFirstSection>
        <SmartSecondSection>
          <Label>Add Beneficiaries</Label>
        </SmartSecondSection>
      </SmartContractSection>
      <AddBeneficiariesSection>
        <BeneficiarFirstSection>
          <FileImageContainer
            src={LifeChanging}
            initial={{ translateY: '8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5 } }}
          />
          <Label>
            Smart Contract <br /> is generated
          </Label>
        </BeneficiarFirstSection>
        <BeneficiarSecondSection />
      </AddBeneficiariesSection>
      <LifeChangingSection>
        <LifeFirstSection>
          <FileImageContainer
            src={Disbursement}
            initial={{ translateY: '-8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5 } }}
          />
        </LifeFirstSection>
        <LifeSecondSection>
          <Label>Unforeseen Event</Label>
        </LifeSecondSection>
      </LifeChangingSection>
      <DisbursementSection>
        <DisbursFirstSection>
          <Label>Asset Disbursement</Label>
          <FileImageContainer
            src={LastDisbursement}
            initial={{ translateX: '8vw' }}
            animate={{ translateX: '0vw', transition: { duration: 0.5 } }}
          />
        </DisbursFirstSection>
        <DisbursSecondSection />
      </DisbursementSection>
      <LastBlankSection>
        <LastFirstSection />
      </LastBlankSection>
    </WorkFrame>
  </RoadMap>
)
export default SubscriberRoadMap
