import React from 'react'
import {
  EarnedMonths,
  ImageSection,
  Textsection,
  TopSection,
  BottomSection,
} from 'styles/views/dashboard/Subscricptions'

export interface ICardProps {
  image: string
  title: string
  subtitle: string
}
const SubscricptionsCard = ({ image, title, subtitle }: ICardProps) => (
  <EarnedMonths>
    <ImageSection src={image} />
    <Textsection>
      <TopSection>{title}</TopSection>
      <BottomSection>{subtitle}</BottomSection>
    </Textsection>
  </EarnedMonths>
)
export default SubscricptionsCard
