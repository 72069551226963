import React, { useState, useRef, useEffect } from 'react'
import data from 'views/dashboard/FiduciaryNotification/data'
import NotificationCard from 'components/Cards/NotificationCard'
import FiduciarySidebar from 'components/FiduciarySidebar'
import FiduciaryNavBar from 'components/FiduciaryNavBar'
import Short from 'assets/images/short-image.png'
import {
  Wrapper,
  Section,
  MainContainer,
  Container,
  HeadContainer,
  TopHaddingSection,
  BottomHeading,
  MainWrapper,
  BottomSection,
  RightSection,
  RightTop,
  NotificationText,
  ShortContainer,
  ShortBy,
  ShortImage,
  SortWrapper,
  SortText,
} from 'styles/views/dashboard/FiduciaryNotification'

const FiduciaryNotification = () => {
  const [isOpenSort, setIsOpenSort] = useState(false)

  const wrapperRef = useRef<any>()

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpenSort(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <Wrapper>
      <FiduciaryNavBar />
      <Section>
        <FiduciarySidebar />
        <MainContainer>
          <MainWrapper>
            <Container>
              <HeadContainer>
                <TopHaddingSection>Notifications</TopHaddingSection>
                <BottomHeading></BottomHeading>
              </HeadContainer>
            </Container>
            <BottomSection>
              <RightSection>
                <RightTop>
                  <NotificationText>Filter Notifications</NotificationText>
                  <ShortContainer onClick={() => setIsOpenSort(true)}>
                    <ShortBy>
                      Sort By
                      <ShortImage src={Short} />
                      {isOpenSort && (
                        <SortWrapper ref={wrapperRef}>
                          <SortText>Current Year</SortText>
                          <SortText>Last Quarter</SortText>
                          <SortText>Last Six Month </SortText>
                          <SortText>Last full Year </SortText>
                        </SortWrapper>
                      )}
                    </ShortBy>
                  </ShortContainer>
                </RightTop>

                {data?.map((item) => (
                  <NotificationCard
                    key={item.id}
                    image={item?.image}
                    title={item?.title}
                    subtitle={''}
                    defination={''}
                    lasttitle={item?.lasttitle}
                  />
                ))}
              </RightSection>
            </BottomSection>
          </MainWrapper>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default FiduciaryNotification
