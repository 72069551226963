import { useContext, useState } from 'react'
import Modal from 'components/Modal'
import { ContractButton } from 'styles/views/dashboard/Settings/MyDeatils'
import { DeletContainer } from 'styles/views/dashboard/SmartContract'
import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import useDelete from 'hooks/useDelete'
import { ethers } from 'ethers'
import CPWillFactory from '../../../../artifacts/contracts/cp.sol/CPWillFactoryPOC.json'
import CPWillPOC from '../../../../artifacts/contracts/cp.sol/CPWillPOC.json'
import Invoice from 'components/InvoiceModal'
import { UserContext } from 'context/user'

const Delete = () => {
  const [isInvOpen, setIsInvOpen] = useState(false)
  const [invoiceData, setInvoiceData] = useState<any | null>(null)
  const [blockData, setBlockData] = useState<any | null>(null)
  const [willInfo, setWillInfo] = useState<any | null>(null)
  const { setLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)
  const EventHit = user?.eventHit
  let targetEventID: unknown
  const reveventHit = [...EventHit].reverse()
  reveventHit.forEach((event: Record<string, unknown>, index, arr) => {
    for (const key in event) {
      if (event[key] == 'updated' || event[key] == 'Created') {
        targetEventID = event.eventId
        arr.length = index + 1
      }
    }
  })

  const ContractAddress = process.env.React_APP_CONTRACT_FACTORY_ADDRESS || ''

  const { mutateAsync } = usePost()
  const { mutateAsync: deleteContract } = useDelete()

  const killContract = async () => {
    if (typeof (window as any).ethereum !== 'undefined') {
      setLoader(true)
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const facInstance = new ethers.Contract(ContractAddress, CPWillFactory.abi, signer)
      try {
        const willId = await facInstance.getWills(signerAddress)
        const latestContractAddress = willId[(willId?.length || 1) - 1]
        const fgcInstance = new ethers.Contract(latestContractAddress, CPWillPOC.abi, signer)
        const transaction = await fgcInstance?.destroyWill()
        const receipt = await transaction?.wait()
        const cblockNumber = receipt?.blockNumber
        const blockDetails = await provider.getBlock(cblockNumber)
        for (let i = 0; i < receipt?.events?.length; i++) {
          if (receipt?.events[i]?.event === 'WillDispose') {
            const msg = `Grantor has destroyed the contract`
            try {
              const response = await deleteContract({
                url: `/delete/contract?eventId=${targetEventID}`,
                token: true,
              })
              if (response.success == true) {
                try {
                  await mutateAsync({
                    url: '/notify/event',
                    payload: {
                      message: msg,
                      status: true,
                      type: `contract`,
                      titleName: `Deleted`,
                    },
                    token: true,
                  })
                  await eventPayment()
                  setInvoiceData(receipt)
                  setWillInfo(willId)
                  setBlockData(blockDetails)
                  setLoader(false)
                  setIsInvOpen(true)
                } catch (err: any) {
                  return err
                }
              }
            } catch (err: any) {
              return err
            }
          }
        }
      } catch (error) {
        setLoader(false)
      }
    }
  }

  const eventPayment = async () => {
    try {
      await mutateAsync({
        url: '/notify/event',
        payload: {
          message: 'Payment successful',
          status: true,
          type: `transition`,
          titleName: `Payment`,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  return (
    <>
      <DeletContainer>
        <ContractButton
          label="Delete"
          onClick={() => {
            killContract()
          }}
        />
      </DeletContainer>

      <Modal isOpen={isInvOpen}>
        <Invoice
          showModal={(value: boolean) => setIsInvOpen(value)}
          invoiceData={invoiceData}
          blockData={blockData}
          willInfo={willInfo}
        />
      </Modal>
    </>
  )
}

export default Delete
