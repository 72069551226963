import styled from 'styled-components'

export const MobileWalletSection = styled.img`
  height: 50px;
  margin: 15px;
  cursor: pointer;
  @media (max-width: 1366px) {
    /* margin: 25px; */
    height: 44px;
  }
`
export const MobileContainerText = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbd1d8;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  cursor: pointer;
  padding: 0px 150px 10px 150px;
  @media (max-width: 1366px) {
    padding: 0 150px 0 150px;
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    padding: 0 21.333vw 0 21.333vw;
    margin-top: 2.667vw;
  }
`
export const MobileWallettext = styled.div`
  margin: 20px;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
`
export const Title = styled.div`
  font-family: 'OutfitRegular';
  font-size: 18px;
  padding-bottom: 10px;
  @media (max-width: 1366px) {
    padding-bottom: 15px;
  }
`
