import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
`
export const LogoSection = styled.img`
  padding-top: 15px;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 36px;
`
export const Defination = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitLight';
  font-size: 25px;
  padding-bottom: 10px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 150px;
  align-items: center;
  padding-bottom: 20px;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 88vw;
    justify-content: center;
  }
`
export const ErrorDefination = styled.div`
  width: 100%;
  max-width: 347px;
  font-family: 'OutfitLight';
  font-size: 25px;
  padding-bottom: 10px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  @media (max-width: 767px) {
    font-family: 'OutfitBold';
    font-size: 3.8vw;
  }
`
