import React, { useState, useRef, useEffect } from 'react'
import { Checkbox, Divider } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import data from 'views/dashboard/FiduciaryNotification/data'
import NotificationCard from 'components/Cards/NotificationCard'
import FiduciaryNavBar from 'components/FiduciaryNavBar'
import Short from 'assets/images/short-image.png'
import Refresh from 'assets/images/refresh-image.png'
import {
  Wrapper,
  Section,
  MainContainer,
  Container,
  HeadContainer,
  TopHaddingSection,
  BottomHeading,
  MainWrapper,
  BottomSection,
  RightSection,
  Leftsection,
  RightTop,
  ShortContainer,
  ShortBy,
  ShortImage,
  FilterSection,
  ResetImage,
  ResetText,
  ImageContainer,
  CheackBoxSection,
  CheckBox,
  ResetButton,
  SortWrapper,
  SortText,
  SortWrappers,
} from 'styles/views/dashboard/FiduciaryNotification'

const CheckboxGroup = Checkbox.Group
const plainOptions = [
  ' Fiduciary Changed',
  ' New Contract',
  'Membership Plans',
  ' Beneficiary Added or Removed',
  'Wallets & Transaction',
  'other',
]
const defaultCheckedList = ['', '']

const MobileFiduciaryNotification = () => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList)
  const [checkAll, setCheckAll] = useState(false)
  const [isOpenSort, setIsOpenSort] = useState(false)
  const [isOpenFilter, setIsOpen] = useState(false)
  const [, setappoint] = useState('')
  const wrapperRef = useRef<any>()

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setCheckAll(e.target.checked)
  }

  const resetRadioState = () => {
    setappoint('')
    setCheckedList(defaultCheckedList)
    setCheckAll(false)
  }

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpenSort(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <Wrapper>
      <FiduciaryNavBar />
      <Section>
        <MainContainer>
          <MainWrapper>
            <Container>
              <HeadContainer>
                <TopHaddingSection>Notifications</TopHaddingSection>
                <BottomHeading></BottomHeading>
              </HeadContainer>
            </Container>
            <div>
              {' '}
              <BottomSection>
                <RightSection>
                  <RightTop>
                    <FilterSection onClick={() => setIsOpen(true)}>
                      Filter
                      <ShortImage src={Short} />
                    </FilterSection>
                    {isOpenFilter && (
                      <SortWrappers ref={wrapperRef}>
                        <Leftsection>
                          <CheackBoxSection>
                            <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                              All
                            </Checkbox>
                            <CheckBox>
                              <Divider />
                            </CheckBox>
                            <CheckBox>
                              <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                            </CheckBox>
                          </CheackBoxSection>
                          <ResetButton>
                            <ImageContainer onClick={resetRadioState}>
                              <ResetImage src={Refresh} />
                              <ResetText>Reset Filter</ResetText>
                            </ImageContainer>
                          </ResetButton>
                        </Leftsection>
                      </SortWrappers>
                    )}
                    <ShortContainer onClick={() => setIsOpenSort(true)}>
                      <ShortBy>
                        Sort By
                        <ShortImage src={Short} />
                        {isOpenSort && (
                          <SortWrapper ref={wrapperRef}>
                            <SortText>Current Year</SortText>
                            <SortText>Last Quarter</SortText>
                            <SortText>Last 6 Months </SortText>
                            <SortText>Previous Year </SortText>
                          </SortWrapper>
                        )}
                      </ShortBy>
                    </ShortContainer>
                  </RightTop>
                  {data?.map((item) => (
                    <NotificationCard
                      key={item.id}
                      image={item?.image}
                      title={item?.title}
                      subtitle={''}
                      defination={''}
                      lasttitle={item?.lasttitle}
                    />
                  ))}
                </RightSection>
              </BottomSection>
            </div>
          </MainWrapper>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default MobileFiduciaryNotification
