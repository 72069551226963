import React from 'react'
import WalletLogo from 'assets/images/cryptowallet-logo.png'

interface IStatus {
  title: string
}

interface IData {
  id: number
  image: string
  brand: string
  subtitle: string
  method: string
}
const Carddata: IData[] = [
  {
    id: 0,
    image: WalletLogo,
    brand: 'Crypto Wallet',
    subtitle: '',
    method: 'Primary Method',
  },
]

interface ITableData {
  key: React.Key
  invoice: string
  billingDate: string
  amount: string
  plan: string
  status: IStatus
  action: string
}

const data: ITableData[] = [
  {
    key: '0',
    invoice: 'Invoice #005 - May 2022',
    billingDate: '10/03/2022',
    amount: 'USD $98.99',
    plan: 'Cryptoplan LITE',
    status: { title: 'Paid' },
    action: 'Download',
  },
  {
    key: '2',
    invoice: 'Invoice #005 - May 2022',
    billingDate: '10/03/2022',
    amount: 'USD $98.99',
    plan: 'Cryptoplan LITE',
    status: { title: 'Paid' },
    action: 'Download',
  },
  {
    key: '3',
    invoice: 'Invoice #005 - May 2022',
    billingDate: '10/03/2022',
    amount: 'USD $98.99',
    plan: 'Cryptoplan LITE',
    status: { title: 'Paid' },
    action: 'Download',
  },
]

interface IColumns {
  title: string
  dataIndex: string
}

const columns: IColumns[] = [
  {
    title: 'Invoice',
    dataIndex: 'invoice',
  },
  {
    title: 'Billing Date',
    dataIndex: 'billingDate',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Allocation',
    dataIndex: 'action',
  },
]

export { data, columns, Carddata }
