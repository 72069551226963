import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import AppleCheckout from 'components/AppleCheckout'
import CrossLogo from 'assets/images/grayx-logo.png'
import { Wrapper, Container, HeadText, Cross } from 'styles/components/Modal/PaypalInfo'
interface IProps {
  showModal: (value: boolean) => void
}

function ApplePay({ showModal }: IProps) {
  const PUBLIC_KEY =
    'pk_test_51Kzz2cSI50W4RVf7hJXKBieDr8Li5C8Il4JjZYk3RxbsjasmxTHWvaxZMDIPAO01Ls8jIitIs6j84UObAJeTcjwc00EXO9FIHX'

  const stripePromise = loadStripe(PUBLIC_KEY)
  return (
    <div>
      <Wrapper>
        <Container>
          <HeadText></HeadText>
          <Cross src={CrossLogo} alt="Close" onClick={() => showModal(false)} />
        </Container>
        <Elements stripe={stripePromise}>
          <AppleCheckout />
        </Elements>
      </Wrapper>
    </div>
  )
}

export default ApplePay
