import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
  @media (max-width: 767px) {
    padding: 15px;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 0px;
  @media (max-width: 767px) {
    gap: 10px;
    padding: 20px 0 10px 0;
  }
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 52px;
  line-height: 66px;
  text-align: center;
  color: #2677e9;
  @media (max-width: 767px) {
    font-size: 5.333vw;
  }
`
export const Defination = styled.div`
  font-family: 'OutfitLight';
  width: 100%;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  @media (max-width: 767px) {
    font-size: 4.333vw;
    font-family: 'OutfitBold';
  }
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 150px;
  align-items: center;
  padding-bottom: 26px;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 595px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  @media (max-width: 767px) {
    max-width: 90.667vw;
  }
`
export const ContractDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0px;
  cursor: pointer;
  @media (max-width: 767px) {
    max-width: 90.667vw;
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
`
export const Text = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
  @media (max-width: 767px) {
    font-size: 3.2vw;
  }
`
export const Para = styled(Text)`
  color: #2677e9;
`
export const Copyimages = styled.div`
  display: flex;
`
export const ConfirmedImage = styled.img`
  @media (max-width: 767px) {
    height: 4px;
    width: 40px;
  }
`
export const GreenCheck = styled.img`
  height: 20px;
`
