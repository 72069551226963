import GreenDot from 'assets/images/green-dot.png'

interface IData {
  title: string
  value?: number
}
const data: IData[] = [
  {
    title: 'After 30 Days',
    value: 30,
  },
  {
    title: 'After 60 Days',
    value: 60,
  },
  {
    title: 'After 90 Days',
    value: 90,
  },
]

const timesData: IData[] = [
  {
    title: 'Daily',
    value: 1,
  },
  {
    title: '2X Daily',
    value: 2,
  },
  {
    title: '3X Daily',
    value: 3,
  },
]
const emailData: IData[] = [
  {
    title: 'Email',
  },
  {
    title: 'SMS',
  },
  {
    title: 'Both',
  },
]
interface IColData {
  title: string
  value?: number
  image: string
}
const Adddata: IColData[] = [
  {
    image: GreenDot,
    title:
      'The grantor will enter the nicknames of the recipients so that subscribers can remember who they have included in their contract. Since the anonymous identifier is the Wallet Address',
  },
  {
    image: GreenDot,
    title:
      'The grantor has to appoint a fiduciary who will act on his behalf when the grantor is deceased. Then fiduciary will start the contract and let the assets get disbursed among the mentioned beneficiaries in the smart contract. ',
  },
  {
    image: GreenDot,
    title:
      'The grantor can update the smart contract as many times as possible. Which will equate to a new contract in the chain and the prior being removed. Each time is a charge (Gas fees +) (most likely) ',
  },
  {
    image: GreenDot,
    title:
      'The grantor can halt the smart contract during the threshold countdown which they have selected (30 days, 60 days, 90 days). This purpose is if the grantor is not deceased yet, and your fiduciary is trying to halt the contract.  ',
  },
  {
    image: GreenDot,
    title:
      'The grantor will receive alerts that someone is trying to proceed with their smart contract when the grantor is still alive',
  },
  {
    image: GreenDot,
    title:
      'The grantor will receive these alerts (Daily/2X Daily/3X Daily) times  for consecutive (30/60/90) days, depending upon the selection of days for the alert notification. If the grantor does not act on the send alerts, it shall be declared that the grantor is dead, and the assets will get disbursed as specified in the smart contract.',
  },
  {
    image: GreenDot,
    title:
      'If the grantor acts upon the alerts, the processing of smart contract will be stopped.There can be only one fiduciary, not more than that, and the count of beneficiaries depends upon the plan the grantor has selected.',
  },
  {
    image: GreenDot,
    title:
      'The fiduciary can only start the disbursement of assets when the grantor is deceased. They can neither add nor remove anything from the contract.',
  },
  {
    image: GreenDot,
    title:
      'The assets only get disbursed after (30 days, 60 days, 90 days), and the representative will receive the assets as mentioned by the grantor. ',
  },
]
export { data, timesData, emailData, Adddata }
