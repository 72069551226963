import styled from 'styled-components'
import { theme } from 'theme'

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 505px;
  box-sizing: border-box;
  border-radius: 21px;
  gap: 10px;
  padding: 30px 23px;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  :hover {
    background: #2677e9;
    border-radius: 21px;

    .icon {
      fill: white;
    }
  }
  @media (max-width: 375px) {
    padding: 5px;
  }
  @media (max-width: 1440px) {
    padding: 16px 23px;
  }
`
export const ImgConatiner = styled.div`
  margin-top: 5px;
`
export const ImageWrapper = styled.img`
  width: 100%;
  max-width: 84px;
  height: auto;
`

export const TitleContainer = styled.div`
  display: grid;
  padding-right: 20px;
  width: 100%;
  gap: 5px;
  padding: 6px;
`
export const Title = styled.div`
  font-family: 'OutfitMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: ${theme.info.light};

  @media (max-width: 375px) {
    font-family: 'OutfitMedium';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
`

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e5e5e5;
  mix-blend-mode: luminosity;
  @media (max-width: 1350px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #a4adcc;
    text-align: left;
  }
`
