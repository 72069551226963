import styled from 'styled-components'

export const UserName = styled.div`
  color: rgb(255, 255, 255);
  font-size: 19px;
  line-height: 24px;
  font-family: OutfitSemiBold;
`
export const UserStatus = styled.div.attrs((props: { fontSize: string }) => props)`
  color: rgba(255, 255, 255, 0.65);
  font-size: ${(props: any) => props.fontSize};
  line-height: 24px;
  font-family: OutfitMedium;
`
export const UserWallet = styled.div.attrs((props: { fontSize: string; padding: string }) => props)`
  background: rgba(255, 255, 255, 0.21);
  color: rgb(255, 255, 255);
  font-size: ${(props: any) => props.fontSize};
  line-height: 24px;
  font-family: OutfitBold;
  border-radius: 50px;
  padding: ${(props: any) => props.padding};
`
export const UserPlusButton = styled.div`
  background: #ffffff36;
  color: #ffffff;
  font-size: 15px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  padding: 0px 5px;
`
export const FiduciaryName = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 19px;
  line-height: 24px;
  color: rgb(255, 255, 255);
`
export const FiduciaryPercentage = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 19px;
  line-height: 24px;
  color: #ffffff;
`
export const FiduciaryResponsibility = styled.div`
  color: rgba(255, 255, 255, 0.65);
  font-size: 15px;
  font-family: 'OutfitRegular';
  line-height: 24px;
`
export const WalletFiduciary = styled.div`
  color: rgb(255, 255, 255);
  font-size: 12px;
`
export const BeneficiaryResponsibility = styled.div`
  color: #737b7d;
  font-size: 12px;
  font-family: 'OutfitRegular';
  line-height: 24px;
`
export const WalletBeneficiary = styled.div`
  color: #2677e9;
  font-size: 12px;
  line-height: 24px;
  font-family: 'OutfitMedium';
`
