import Landing from 'pages/landing'
import Welcome from 'pages/welcome'
import PageNotFound from 'views/landing/PageNotFound'
import Dashboard from 'pages/dashboard'
import Disbursement from 'views/dashboard/DisbursementTree'
import Beneficiaries from 'views/dashboard/Beneficiaries'
import Notification from 'views/dashboard/Notification'
import Subscricptions from 'views/dashboard/Subscricptions'
import Portfolio from 'views/dashboard/Portfolio'
import SelectWallet from 'pages/SelectWallet'
import WelcomePlan from 'pages/WelcomePlan'
import SmartContract from 'views/dashboard/SmartContract'
import UpgradePlan from 'views/dashboard/MyPlan/UpgradePlan'
import Settings from 'views/dashboard/Settings'
import WelcomeCrypto from 'views/welcome/WelcomeSection/WelcomeCrypto'
import InviteLink from 'views/landing/InviteLink'
import FiduciarySidebar from 'components/FiduciarySidebar'
import FiduciaryMyplan from 'pages/Fiduciarydashboard'
import FiduciaryCryptoAssets from 'views/dashboard/FiduciaryCryptoAssets'
import FiduciaryNotification from 'views/dashboard/FiduciaryNotification'
import FiduciarySetting from 'views/dashboard/FiduciarySettings'
import Beneficarydashboard from 'pages/Beneficarydashboard'
import BeneficaryCryptoAssets from 'views/BeneficaryCryptoAssets'
import BeneficaryNotification from 'views/dashboard/BeneficiaryNotification'
import BeneficarySetting from 'views/dashboard/BeneficarySettings'
import MobilePortfolio from 'views/dashboard/MobilePortfolio'
import MobileBeneficiaries from 'views/dashboard/MobileBeneficiaries'
import MobileDisburesementTree from 'views/dashboard/MobileDisbursementTree'
import MobileSmartContracts from 'views/dashboard/MobileSmartContract'
import MobileNotification from 'views/dashboard/MobileNotification'
import MobileSubscricptions from 'views/dashboard/MobileSubscricption'
import MobileSettings from 'views/dashboard/MobileSettings'
import MobileUpgradePlan from 'views/dashboard/MyPlan/MobileUpgardePlan'
import MobileUpdateCrypto from 'views/dashboard/MobileUpdateCrypto'
import FiduciaryCryptoMobileAssets from 'views/dashboard/FiduciaryCryptoMobileAssets'
import MobileFiduciaryNotification from 'views/dashboard/MobileFiduciaryNotification'
import BeneficaryMobileNotification from 'views/dashboard/BeneficiaryMobilenotification'
import Timer from 'components/Timer'
import KeyFeat from 'views/landing/KeyFeature'
import OurSolution from 'views/landing/OurSolution'
import BeneficiaryCryptoMobileAssets from 'views/dashboard/BeneficiaryCryptoMobileAssets'
import BeneficiarySetting from 'views/dashboard/BeneficaryMobileSetting'

export const LandingRoute = {
  component: Landing,
  path: '/',
  exact: true,
  restricted: false,
}

export const WelcomeRoute = {
  component: Welcome,
  path: '/welcome',
  exact: true,
  restricted: true,
}

export const PageNotFoundRoute = {
  component: PageNotFound,
  path: '/*',
  exact: true,
  restricted: false,
}

export const WelcomePlanRoute = {
  component: WelcomePlan,
  path: '/welcomeplan',
  exact: true,
  restricted: false,
}
export const SelectWalletRoute = {
  component: SelectWallet,
  path: '/selectwallet',
  exact: true,
  restricted: false,
}
export const DashboardRoute = {
  component: Dashboard,
  path: '/dashboard',
  exact: true,
  restricted: true,
}
export const DisbursementRoute = {
  component: Disbursement,
  path: '/disbursement-tree',
  exact: true,
  restricted: true,
}
export const BeneficiariesRoute = {
  component: Beneficiaries,
  path: '/beneficiaries',
  exact: true,
  restricted: true,
}
export const SmartContractRoute = {
  component: SmartContract,
  path: '/smartcontract',
  exact: true,
  restricted: true,
}
export const NotificationRoute = {
  component: Notification,
  path: '/notifications',
  exact: true,
  restricted: true,
}
export const SubscricptionsRoute = {
  component: Subscricptions,
  path: '/subscricptions',
  exact: true,
  restricted: true,
}
export const SettingsRoute = {
  component: Settings,
  path: '/settings',
  exact: true,
  restricted: true,
}

export const PortfolioRoute = {
  component: Portfolio,
  path: '/portfolio',
  exact: true,
  restricted: true,
}
export const UpgratePlanRoute = {
  component: UpgradePlan,
  path: '/UpgradePlan',
  exact: true,
  restricted: true,
}
export const WelcomeWalletRoute = {
  component: WelcomeCrypto,
  path: '/welcomecrypto',
  exact: true,
  restricted: true,
}

export const InviteLinkRoute = {
  component: InviteLink,
  path: '/invitelink',
  exact: true,
  restricted: false,
}
export const FiduciarySidebarRoute = {
  component: FiduciarySidebar,
  path: '/fiduciarysidebar',
  exact: true,
  restricted: false,
}
export const FiduciaryMyPlanRoute = {
  component: FiduciaryMyplan,
  path: '/fiduciarydashboard',
  exact: true,
  restricted: true,
}
export const FiduciaryCryptoAssetsRoute = {
  component: FiduciaryCryptoAssets,
  path: '/fiduciarycryptoassetsRoute',
  exact: true,
  restricted: true,
}
export const FiduciaryNotificationRoute = {
  component: FiduciaryNotification,
  path: '/fiduciarynotification',
  exact: true,
  restricted: true,
}
export const FiduciarySettingsRoute = {
  component: FiduciarySetting,
  path: '/fiduciarysetting',
  exact: true,
  restricted: true,
}
export const BeneficarydashboardRoute = {
  component: Beneficarydashboard,
  path: '/beneficarydashboard',
  exact: true,
  restricted: true,
}
export const BeneficaryCryptoAssetsRoute = {
  component: BeneficaryCryptoAssets,
  path: '/beneficarycryptoassets',
  exact: true,
  restricted: true,
}
export const BeneficaryNotificationRoute = {
  component: BeneficaryNotification,
  path: '/beneficarynotification',
  exact: true,
  restricted: true,
}
export const BeneficarySettingRoute = {
  component: BeneficarySetting,
  path: '/beneficarysetting',
  exact: true,
  restricted: true,
}
export const MobilePortfolioRoute = {
  component: MobilePortfolio,
  path: '/mobileportfolio',
  exact: true,
  restricted: true,
}
export const MobileBeneficiariesRoute = {
  component: MobileBeneficiaries,
  path: '/mobilebeneficiaries',
  exact: true,
  restricted: true,
}
export const MobileDisburesementTreeRoute = {
  component: MobileDisburesementTree,
  path: '/mobiledisburesementtree',
  exact: true,
  restricted: true,
}
export const MobileSmartContractsRoute = {
  component: MobileSmartContracts,
  path: '/mobilesmartcontracts',
  exact: true,
  restricted: true,
}
export const MobileNotificationRoute = {
  component: MobileNotification,
  path: '/mobilenotification',
  exact: true,
  restricted: true,
}
export const MobileSubscricptionsRoutes = {
  component: MobileSubscricptions,
  path: '/mobilesubscricptions',
  exact: true,
  restricted: true,
}
export const MobileSettingsRoutes = {
  component: MobileSettings,
  path: '/mobilesettings',
  exact: true,
  restricted: true,
}

export const MobileUpgradePlanRoutes = {
  component: MobileUpgradePlan,
  path: '/mobileupgradeplan',
  exact: true,
  restricted: true,
}
export const MobileUpdateCryptoRoutes = {
  component: MobileUpdateCrypto,
  path: '/mobileupdatecrypto',
  exact: true,
  restricted: true,
}
export const FiduciaryCryptoMobileAssetsRoutes = {
  component: FiduciaryCryptoMobileAssets,
  path: '/fiduciarycryptomobileassets',
  exact: true,
  restricted: true,
}
export const MobileFiduciaryNotificationRoutes = {
  component: MobileFiduciaryNotification,
  path: '/mobilefiduciarynotification',
  exact: true,
  restricted: true,
}
export const BeneficaryMobileNotificationRoutes = {
  component: BeneficaryMobileNotification,
  path: '/beneficarymobilenotification',
  exact: true,
  restricted: true,
}

export const TimerRoutes = {
  component: Timer,
  path: '/timer',
  exact: true,
  restricted: false,
}
export const KeyFeatRoute = {
  component: KeyFeat,
  path: '/keyfeat',
  exact: true,
  restricted: true,
}
export const OurSolutionRoutes = {
  component: OurSolution,
  path: '/oursolution',
  exact: true,
  restricted: false,
}
export const BeneficiaryCryptoMobileAssetsRoutes = {
  component: BeneficiaryCryptoMobileAssets,
  path: '/beneficiarycryptomobileassets',
  exact: true,
  restricted: true,
}
export const BeneficiarySettingRoutes = {
  component: BeneficiarySetting,
  path: '/beneficiarysetting',
  exact: true,
  restricted: true,
}

const ROUTES = [
  LandingRoute,
  WelcomeRoute,
  DashboardRoute,
  BeneficiariesRoute,
  SmartContractRoute,
  NotificationRoute,
  SubscricptionsRoute,
  SettingsRoute,
  PortfolioRoute,
  SelectWalletRoute,
  WelcomePlanRoute,
  DisbursementRoute,
  UpgratePlanRoute,
  WelcomeWalletRoute,
  InviteLinkRoute,
  FiduciarySidebarRoute,
  FiduciaryMyPlanRoute,
  FiduciaryCryptoAssetsRoute,
  FiduciaryNotificationRoute,
  FiduciarySettingsRoute,
  BeneficarydashboardRoute,
  BeneficaryCryptoAssetsRoute,
  BeneficaryNotificationRoute,
  BeneficarySettingRoute,
  PageNotFoundRoute,
  MobilePortfolioRoute,
  MobileBeneficiariesRoute,
  MobileSmartContractsRoute,
  MobileDisburesementTreeRoute,
  MobileNotificationRoute,
  MobileSubscricptionsRoutes,
  MobileSettingsRoutes,
  MobileUpgradePlanRoutes,
  FiduciaryCryptoMobileAssetsRoutes,
  MobileFiduciaryNotificationRoutes,
  BeneficaryMobileNotificationRoutes,
  BeneficiaryCryptoMobileAssetsRoutes,
  TimerRoutes,
  KeyFeatRoute,
  BeneficiarySettingRoutes,
  OurSolutionRoutes,
]

export default ROUTES
