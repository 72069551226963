import styled from 'styled-components'
import { motion } from 'framer-motion'
import Button from 'components/Button'
import { theme } from 'theme'

export const Login = styled(Button)`
  color: ${theme.info.light};
  width: 100%;
  max-width: 156px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 1000px) {
    max-width: 80px;
  }
`
export const RegisterButton = styled(Button)`
  width: 100%;
  max-width: 156px;
  @media (max-width: 1000px) {
    max-width: 80px;
  }
`
export const LoginMobile = styled(Button)`
  color: ${theme.info.light};
  min-width: 80px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  :hover {
    opacity: 0.5;
  }
`
export const RegisterMobile = styled(Button)`
  min-width: 106px;
`

export const Container = styled(motion.div)`
  padding: 18px 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  width: 100%;
  @media (max-width: 1350px) {
    padding: 18px 80px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(192, 210, 226, 0.2);
  backdrop-filter: blur(54px);
  border-radius: 17px;
  padding: 20px 19px;
  width: 100%;
  > img {
    cursor: pointer;
  }
  > p {
    flex: 1 1 0%;
  }
  > svg {
    flex: 1 1 0%;
  }
  @media (max-width: 1366px) {
    padding: 19px;
  }
  @media (max-width: 767px) {
    margin-top: 4.8vw;
    padding: 3.067vw 3.067vw;
    width: 100%;
    max-width: 89.867vw;
  }
  @media (max-width: 1000px) {
    gap: 10px;
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
`
export const LogoContainer = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    max-width: 88.8vw;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 14.6vw;
    justify-content: start;
  }
`

export const LoginRegisterSection = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 284px;
  gap: 10px;
  width: 100%;
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-width: 1000px) {
    max-width: 165px;
  }
`
export const LogoSection = styled.img`
  @media (max-width: 767px) {
    width: 44.8vw !important;
  }
  @media (max-width: 1000px) {
    width: 115px;
  }
`
export const Navmenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 1350px) {
    gap: 5px 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-width: 1000px) {
    gap: 5px 9px;
  }
`
export const MobileNavmenu = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-left: 24px;
  @media (max-width: 767px) {
    margin-top: 5.667vw;
    display: flex;
    flex-direction: column;
    gap: 5.667vw;
    padding-left: 10.4vw;
  }
`
export const ThemeIcon = styled.img`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    height: 10.4vw;
    width: 10.4vw;
  }
  @media (max-width: 1000px) {
    height: 41px;
    width: 42px;
  }
`
export const MenuItem = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  color: ${theme.info.light};
  cursor: pointer;
  :hover,
  :active {
    color: ${theme.secondary.main};
  }
  @media (max-width: 1000px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 600;
  }
`

export const BurgerLogo = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
export const DropDownSection = styled.div`
  width: 100%;
  max-width: 375px;
  background: #01205b;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 80vw;
    height: 100vh;
    margin-right: 1.063vw;
  }
`
export const Dropaheader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 39px 24px 39px 24px;
  align-items: center;
  @media (max-width: 767px) {
    padding: 6.133vw 6.4vw 1.4vw 6.4vw;
  }
`
export const MobileButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
  padding: 0 27px 36px 27px;
`
export const XLogo = styled.img`
  height: 25px;
  cursor: pointer;
  @media (max-width: 767px) {
    height: 20px;
  }
`
export const SigninButton = styled.div`
  width: 100%;
  max-width: 80px;
`
