import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
`
export const Wrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  padding: 20px;
  height: 427px;
  overflow: scroll;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 93.333vw;
  }
`
export const CloseImage = styled.img`
  height: 20px;
  cursor: pointer;
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 15px 0px 20px 0px;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  table {
    width: 100%;
    padding: 6px;
    align-items: center;
  }
  td {
    position: relative;
    font-family: 'OutfitMedium';
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.02em;
    padding: 14px;
    text-align: center;
    border-bottom: 1px solid #dfdfdf;
  }
  table tr input {
    padding: 0 10px;
  }
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 190px;
  align-items: center;
  padding-bottom: 26px;
  display: flex;
  margin: auto;
  gap: 10px;
`
export const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`
export const TextWrapper = styled.div`
  flex: 1;
  text-align: center;
  color: red;
`
export const CheckboxWrapper = styled.div`
  margin-right: 7px;
`
