import React from 'react'
import BeneficiariesCard from 'components/Cards/Beneficiaries'
import data from 'views/landing/Beneficiaries/data'
import {
  BeneficiariesContainer,
  HeadWrapper,
  MainWrapper,
  CardContainer,
  CardWrapper,
  SubHeadingText,
  HeadingText,
} from 'styles/views/landing/Beneficiaries'

const Beneficiaries: React.FC = () => (
  <BeneficiariesContainer>
    <HeadWrapper>
      <HeadingText>Beneficiaries</HeadingText>
      <SubHeadingText>
        Create your Cryptoplan by adding any family member, friend or charity as beneficiaries. If they don&apos;t have
        a crypto wallet, we will help them create one.
      </SubHeadingText>
    </HeadWrapper>
    <MainWrapper>
      <CardContainer>
        <CardWrapper>
          {data?.map((item) => (
            <BeneficiariesCard key={item.id} image={item?.image} title={item?.title} />
          ))}
        </CardWrapper>
      </CardContainer>
    </MainWrapper>
  </BeneficiariesContainer>
)

export default Beneficiaries
