import styled from 'styled-components'
import { theme } from 'theme'

export const MobileWalletSection = styled.img`
  width: 45px;
  height: 45px;
  cursor: pointer;
`
export const MobileContainerText = styled.div`
  width: 246px;
  height: 123px;
  border: 1px solid #cbd1d8;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 13px;
  padding-left: 26px;
  cursor: pointer;
  :hover {
    box-shadow: 5px 20px 50px -16px rgba(103, 146, 255, 0.5);
    border-radius: 40px;
    color: ${theme.info.light};
    background: linear-gradient(180deg, #4675f7 0%, #1b49cc 100%);
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 140px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
  }
`
export const MobileWallettext = styled.div`
  margin: 20px;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
`
export const Title = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 767px) {
    font-size: 3.733vw;
    text-align: -webkit-center;
  }
`
