import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import EmailCopy from 'assets/images/copy-email.png'
import { MainContainer, Container, EmailInformation, Email } from 'styles/components/NickName'

const NickName = ({ userWalletId, displayName }: any) => {
  return (
    <MainContainer>
      <Container>
        {displayName}
        <EmailInformation>
          <Email>{`${userWalletId?.substring(0, 10)}...${userWalletId?.substring(userWalletId?.length - 10)}`}</Email>

          <CopyToClipboard text={userWalletId}>
            <div>
              <img src={EmailCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </EmailInformation>
      </Container>
    </MainContainer>
  )
}

export default NickName
