import styled from 'styled-components'
import { theme } from 'theme'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  max-width: 782px;
  align-items: center;
`
export const LogoSection = styled.img`
  height: 25px;
  cursor: pointer;
`
export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 782px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  margin-left: auto;
  padding: 20px;
  @media (max-width: 767px) {
    margin-top: 18vw;
  }
`
export const HeadingText = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 24px;
`
export const BottomText = styled.div`
  font-family: 'Outfit';
  font-size: 14px;
  line-height: 18px;
  color: #737b7d;
`
export const AdressDetails = styled.div`
  width: 100%;
  max-width: 750px;
  background: #e2eaff;
  border-radius: 28px;
  padding: 0px 10px;
  @media (max-width: 757px) {
    border-radius: 0px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
`
export const AdressText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 19px 21px 29px 20px;
  @media (max-width: 1366px) {
    padding: 10px;
  }
`
export const IdDetails = styled.div`
  font-family: 'Outfit';
  font-size: 36px;
  line-height: 45px;
`
export const FullAdress = styled.div`
  display: flex;
  font-family: 'OutfitMedium';
  font-size: 14px;
  color: #737b7d;
  align-items: center;
  gap: 5px;
`
export const AdressID = styled.div`
  font-family: 'Mulish';
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
`
export const PendingStatus = styled.div`
  width: 100%;
  max-width: 99px;
  background: #ffffff;
  border-radius: 30px;
  font-family: 'Mulish';
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e98f26;
  margin-left: auto;
  padding: 10px 20px 10px 26px;
`
export const TextBeneficiares = styled.div`
  display: flex;
  gap: 10px;
  padding: 26px 10px 32px 26px;
  align-items: center;
  @media (max-width: 1366px) {
    padding: 10px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  gap: 6px;
`
export const PlusLogo = styled.img`
  cursor: pointer;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
`
export const Count = styled.div`
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
`
export const TableWrapper = styled.div`
  padding: 20px;
  overflow-y: auto;
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #fafafa;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: center;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 15px 12px;
  }
  @media (max-width: 767px) {
    padding: 4vw 3.133vw;
  }
  @media (max-width: 1366px) {
    padding: 0px;
  }
  @media (max-width: 1280px) {
    overflow-y: auto;
    height: 455px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const CrossSection = styled.div``
export const NotesContainer = styled.div`
  cursor: pointer;
`
export const NotifySection = styled.div`
  display: flex;
  flex-direction: column;
`
export const Star = styled.div`
  color: #ef0707;
  font-size: 20px;
`
export const AfterSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px 5px 1px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    font-family: 'OutfitLight';
    font-size: 18px;
    line-height: 29px;
    min-width: 920px;
    text-align: left;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #4473f5;
  }
  @media (max-width: 1280px) {
    padding: 0;
  }
`
