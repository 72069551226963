import React from 'react'
import { Input } from 'antd'
import Finder from 'assets/svg/Finder'
import { MainContainer } from 'styles/components/Search'

export interface ISearchProps {
  placeholder?: string
  onChange?: any
}
const SearchInput: React.FC<ISearchProps> = ({ placeholder, onChange }: ISearchProps) => {
  return (
    <MainContainer>
      <Input prefix={<Finder />} placeholder={placeholder} style={{ width: 200 }} onChange={onChange} />
    </MainContainer>
  )
}

export default SearchInput
