import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LogoSection = styled.img`
  padding: 20px;
`
export const TextSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const HeadingSection = styled.div`
  width: 100%;
  font-family: 'OutfitRegular';
  font-size: 36px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 6.667vw;
  }
`
export const Defination = styled.div`
  width: 100%;
  max-width: 395px;
  font-family: 'OutfitLight';
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #737b7d;
  padding: 15px;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 500px;
  align-items: center;
  padding-bottom: 26px;
  display: flex;
  justify-content: center;
  gap: 30px;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  padding: 20px;
  @media (max-width: 767px) {
    max-width: 89.333vw;
  }
`
export const ImageSection = styled.img`
  height: 25px;
  cursor: pointer;
`
export const LeftButton = styled.div`
  /* max-width: 100px; */
`
export const RightButton = styled.div`
  width: 100%;
  max-width: 110px;
`
export const CryptoLogo = styled.img`
  height: 176px;
  width: 179px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
