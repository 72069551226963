import { ReactElement } from 'react'
import React from 'react'
import TypePortfolio from 'components/TypePortfolio'

export interface IData {
  key: React.Key
  type: ReactElement
  category: string
  totalvalue: string
  amount: string
  amountdetail: string
  date: string
}

export const data: IData[] = [
  {
    key: '1',
    type: <TypePortfolio />,
    category: 'Subscription Payment',
    totalvalue: '$122.19 USD ',
    amount: '0.92 ETH',
    amountdetail: 'From: jwdwu382',
    date: '30/01/2002',
  },
  {
    key: '2',
    type: <TypePortfolio />,
    category: 'Subscription Payment',
    totalvalue: '$122.19 USD ',
    amount: '0.92 ETH',
    amountdetail: 'To: jwdwu382',
    date: '30/01/2002',
  },
  {
    key: '3',
    type: <TypePortfolio />,
    category: 'Subscription Payment',
    totalvalue: '$122.19 USD ',
    amount: '0.92 ETH',
    amountdetail: 'From: jwdwu382',
    date: '30/01/2002',
  },
  {
    key: '4',
    type: <TypePortfolio />,
    category: 'Subscription Payment',
    totalvalue: '$122.19 USD ',
    amount: '0.92 ETH',
    amountdetail: 'To: jwdwu382',
    date: '30/01/2002',
  },
]
export interface IColumns {
  title: string
  dataIndex: string
}

export const columns = [
  {
    title: 'Transaction Hash:',
    dataIndex: 'hyperlink',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Total Value',
    dataIndex: 'currencyPrice',
  },
  {
    title: 'Amount',
    dataIndex: 'totalAmount',
  },
  {
    title: 'Account',
    dataIndex: 'fromAccount',
  },
  {
    title: 'Date',
    dataIndex: 'createTime',
  },
]
export default { data, columns }
