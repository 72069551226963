import React from 'react'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import PaypalButton from 'components/PaypalComponent/PaypalButton'

const PaypalOrder = () => {
  const initialOptions = {
    'client-id': 'test',
    currency: 'USD',
    intent: 'capture',
  }

  const createOrderHandler = ({ actions }: any) => {
    // Set up the transaction
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: '15',
          },
        },
      ],
    })
  }

  const onApproveHandler = ({ actions }: any) => {
    // This function captures the funds from the transaction.
    return actions.order.capture().then(function ({ details }: any) {
      // This function shows a transaction success message to your buyer.
      alert('Transaction completed by ' + details.payer.name.given_name)
    })
  }

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        <PaypalButton createOrder={createOrderHandler} onApprove={onApproveHandler} />
      </PayPalScriptProvider>
    </>
  )
}

export default PaypalOrder
