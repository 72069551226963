import styled from 'styled-components'

export const RoadMap = styled.div`
  width: 100%;
  background: linear-gradient(121.63deg, #4473f5 0%, #50c6be 98.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`
export const OurSolutionBox = styled.div`
  width: 100%;
  max-width: 80vw;
  margin: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileMap = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export const SolutionTopSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 1480px;
  height: 370px;
  border-left: 17px solid rgba(255, 255, 255, 0.22);
  border-bottom-left-radius: 17px;
  margin-bottom: 0.1vh;
  @media (max-width: 1280px) {
    margin: 0vh;
  }
`
export const SolutionMiddleSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1480px;
  height: 250px;
  border-top: 17px solid rgba(255, 255, 255, 0.22);
  border-right: 17px solid rgba(255, 255, 255, 0.22);
  border-bottom: 17px solid rgba(255, 255, 255, 0.22);
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  transform: translate(16.7px, -18px);
  @media (max-width: 1280px) {
    transform: translate(15.6px, -17px) !important;
  }
  @media (max-width: 1440px) {
    transform: translate(16.4px, -18px);
  }
  @media (max-width: 1366px) {
    transform: translate(16.5px, -18px);
  }
`
export const SolutionLastBox = styled.div`
  width: 100%;
  max-width: 1480px;
  height: 250px;
  border-left: 17px solid rgba(255, 255, 255, 0.22);
  border-bottom: 17px solid rgba(255, 255, 255, 0.22);
  border-bottom-left-radius: 24px;
  position: relative;
`

export const TopLeftSection = styled.div`
  width: 100%;
  max-width: 579px;
  display: flex;
  position: relative;
  gap: 20px;
`
export const ToprightSection = styled.div`
  width: 100%;
  max-width: 800px;
  font-family: 'OutfitBold';
  font-style: normal;
  font-size: 74px;
  display: flex;
  color: #ffffff;
  padding-top: 30px;
`
export const ImageCintainer = styled.img`
  max-width: 172px;
  height: 149px;
  position: absolute;
  left: -85px;
  bottom: 60px;
  z-index: 0;
  transform: scale(0.8);

  @media (max-width: 1280px) {
    width: 100%;
    max-width: 139px;
    height: 125px;
    left: -91px;
    bottom: 87px;
    z-index: 0;
  }
`
export const TextSection = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OutfitMedium';
  font-style: normal;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #ffffff;
`
export const ImageBlueBaclground = styled.div`
  width: 100%;
  max-width: 188px;
  height: 188px;
  background-color: rgba(255, 255, 255, 0.22);
  border-radius: 960px;
`
export const BottomLastBox = styled.div`
  width: 100%;
  max-width: 1480px;
  height: 250px;
  border-right: 17px solid rgba(255, 255, 255, 0.22);
  border-top-right-radius: 17px;
  margin-top: -16.7px;
  margin-left: 16.9px;
  @media (max-width: 947px) {
    margin-left: 17.1px;
  }
  @media (max-width: 924px) {
    margin-left: 16.9px;
  }
`
export const MiddFirstSection = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
export const ScalableFirstSection = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
`
export const MiddSecSection = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
export const MiddleSection = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7px;
`
export const ZapDetails = styled.div`
  position: absolute;
  top: -49%;
  display: flex;
  width: 100%;
  max-width: 1480px;
  justify-content: space-between;
`
export const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 1480px;
  bottom: -186px;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1280px) {
    bottom: -242px;
  }
`
export const ZapDetailsLast = styled.div`
  position: absolute;
  bottom: -119px;
  display: flex;
  width: 100%;
  max-width: 1480px;
  justify-content: space-between;
  align-items: end;
`
export const MiddLastSection = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
export const MiddImageCintainer = styled.img`
  max-width: 188px;
  height: 146px;
  position: absolute;
  z-index: 0;
  transform: scale(0.8);
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 139px;
    height: 125px;
    z-index: 0;
  }
`
export const MiddSecndImg = styled.img`
  max-width: 188px;
  height: 146px;
  z-index: 0;
  transform: scale(0.8);
`
export const MiddZapSection = styled.img`
  max-width: 188px;
  height: 146px;
  z-index: 0;
  transform: scale(0.8);
`
export const BackgrundBrack = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4473f5 0%, #4689e8 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
export const BackgrundGreenBlue = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4792e3 0%, #489ddb 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
export const BackgrundBrackBlue = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4795df 0%, #4796df 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
export const BackgrundBrackGreen = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4473f5 0%, #4bafce 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
export const Backgrundhide = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4473f5 0%, #4689e8 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  left: -81px;
  bottom: 144px;
  :hover {
    /* transform: translateY(0.5vw); */
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
`
export const BackgrundToEncrypt = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #49a3d8 0%, #4aabd1 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
export const BackgrundBrackMetamask = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(121.63deg, #4473f5 0%, #4689e8 7.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-left: -100px;
  margin-top: 143px;
  :hover {
    > img {
      transition: all 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 157px;
    height: 154px;
  }
`
