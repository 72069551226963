import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="67.2vw"
    height="425.867vw"
    viewBox="0 0 252 1597"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.497 6H48.84c16.568 0 30 13.431 30 30v96c0 16.569 13.431 30 30 30H216c16.569 0 30 13.431 30 30v144.5c0 16.569-13.431 30-30 30H108.84c-16.569 0-30 13.431-30 30v144c0 16.569 13.431 30 30 30H216c16.569 0 30 13.431 30 30v142c0 16.569-13.431 30-30 30H108.84c-16.569 0-30 13.431-30 30V948c0 16.569 13.431 30 30 30H216c16.569 0 30 13.431 30 30v145c0 16.57-13.431 30-30 30H108.84c-16.569 0-30 13.43-30 30v143c0 16.57 13.431 30 30 30H216c16.569 0 30 13.43 30 30v145c0 16.57-13.431 30-30 30H0"
      stroke="#fff"
      strokeOpacity={0.17}
      strokeWidth={11}
    />
  </svg>
)

export default SvgComponent
