import CoppyWalletId from 'components/CoppyWalletId'
import FirstWallet from 'assets/images/wallet-one.png'
import PlaidWallet from 'assets/images/wallet-info.png'
import AppleWallet from 'assets/images/wallet-Ethereum.png'

export interface IColumns {
  id: number
  image: string
  title: string
  subtitle: string
}

export const columns = [
  {
    id: 0,
    image: FirstWallet,
    title: 'Tezos',
    subtitle: <CoppyWalletId />,
  },
  {
    id: 1,
    image: PlaidWallet,
    title: 'Stellar',
    subtitle: <CoppyWalletId />,
  },
  {
    id: 2,
    image: AppleWallet,
    title: 'Ethereum',
    subtitle: <CoppyWalletId />,
  },
]
export default columns
