import { useState, useContext, useEffect } from 'react'
import { PaymentWalletContext } from 'context/paymentWallet'
import data from 'components/Modal/AllPaymentView/data'
import Modal from 'components/Modal'
import PaymentModal from 'components/PaymentModal'
import AddPayments from 'components/Cards/AddPayments'
import Button from 'components/Button'
import More from 'assets/svg/More'
import CancelSign from 'assets/images/vector-cancel.png'
import LoginConnectWallet from 'views/landing/LoginConnectWallet'
import {
  MainContainer,
  Container,
  Wrapper,
  ButtonSection,
  WrapperContainer,
  RadioGroup,
  RightButton,
  ButtonLable,
  TopText,
  HeadingText,
  LogoSection,
  ImageSection,
} from 'styles/components/Modal/AllPaymentView'

interface IModalProps {
  showModal: (value: boolean) => void
}

const AllPaymentView: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const { paymentWallet, setPaymentWallet } = useContext(PaymentWalletContext)
  const [isOpen, setIsOpen] = useState(false)
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }

  useEffect(() => {
    setPaymentWallet(data)
  }, [])

  return (
    <MainContainer>
      <Container>
        <TopText>
          <HeadingText>Choose Payment Method</HeadingText>
          <LogoSection
            src={CancelSign}
            onClick={() => {
              showModal(false)
            }}
          />
        </TopText>
        <WrapperContainer>
          <Wrapper>Added Payment Method</Wrapper>
          <ButtonSection>
            <RightButton>
              <Button
                label={
                  <ButtonLable onClick={() => setInviteModal(true)}>
                    <ImageSection>
                      <More />
                    </ImageSection>
                    Add New Wallet
                  </ButtonLable>
                }
                variant="outline"
              />
            </RightButton>
          </ButtonSection>
        </WrapperContainer>
        <RadioGroup>
          {paymentWallet?.map((item: any) => (
            <AddPayments
              key={item.id}
              image={item?.image}
              title={item?.title}
              subtitle={item?.subtitle}
              defination={item?.defination}
            />
          ))}
        </RadioGroup>
      </Container>
      <Modal isOpen={isOpen}>
        <PaymentModal showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
      <Modal isOpen={isCreateInviteOpen}>
        <LoginConnectWallet showModal={(value: boolean) => setInviteModal(value)} />
      </Modal>
    </MainContainer>
  )
}

export default AllPaymentView
