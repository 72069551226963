import React from 'react'
import Button from 'components/Button'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  ErrorDefination,
} from 'styles/components/Cards/CommingSoon'
export interface IModalProps {
  success: boolean
  error?: any
  errorModal?: any
  switchError?: any
  sameAddError?: any
  showModal: (value: boolean) => void
  paymentError?: any
}

const ErrorModal: React.FC<IModalProps> = ({ showModal, error, sameAddError, errorModal, paymentError }) => {
  const parseErrorMessage = (message: string) => {
    if (message?.length < 2) return 'Something went wrong'
    return message
  }

  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} />
        </Container>
        <TextSection>
          <HeadingSection>{'Error'}</HeadingSection>
          <Defination>{parseErrorMessage(error?.response?.data?.message)}</Defination>
          <Defination>{parseErrorMessage(sameAddError)}</Defination>
          <ErrorDefination>{parseErrorMessage(errorModal)}</ErrorDefination>
          <ErrorDefination>{parseErrorMessage(paymentError)}</ErrorDefination>
          <ButtonSection>
            <Button
              label="Close "
              variant="contained"
              type="button"
              onClick={() => {
                showModal(false)
              }}
            />
          </ButtonSection>
        </TextSection>
      </Wrapper>
    </MainContainer>
  )
}

export default ErrorModal
