import React, { useState, useEffect, useContext } from 'react'
import { Select, Button } from 'antd'
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'
import { BeneficiaryContext } from 'context/beneficiaries'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import More from 'assets/svg/More'
import TextInput from 'components/FormElements/TextInput'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import useDelete from 'hooks/useDelete'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import { ethers } from 'ethers'
import CircelImage from 'assets/images/circel-image.png'
import PluseImage from 'assets/images/pluse-image.png'
import RedPluse from 'assets/images/red-pluse.png'
import {
  MainContainer,
  Container,
  DisplayInfo,
  DisplayName,
  InputSection,
  CountSection,
  LeftImage,
  RightImage,
  ButtonContainer,
  TextArea,
  AddMore,
  SelectContainer,
  Input,
  Error,
  PercentageContainer,
  Inputs,
  PerText,
  TotalScore,
  InputContainer,
  ErrorAppoint,
  ImageSection,
  AppointError,
} from 'styles/views/dashboard/MyPlan/DisplayBenificiaires'

export interface IModalProps {
  next: () => void
  text?: string
}
export interface IData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

const DisplayBenificiaires: React.FC<IModalProps> = ({ next, text }) => {
  const { setBeneficiary, beneficiary } = useContext(BeneficiaryContext)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const beneficiaryData = beneficiariesData?.data?.beneficary
  const sortedFid = beneficiaryData.filter((item: { appointAs: string }) => item.appointAs === 'Fiduciary')
  const sortedBen = beneficiaryData.filter((item: { appointAs: string }) => item.appointAs === 'Beneficiary')
  const sortedData = [...sortedFid, ...sortedBen]
  const [connection, setConnection] = useState<any>([-1])

  const schema = yup.object().shape({
    beneficiaries: yup.array().of(
      yup.object().shape({
        displayName: yup
          .string()
          .required('Display name is required ')
          .matches(/^\S/, 'First character cannot be Space '),

        userWalletId: yup
          .string()
          .required('Wallet address is required ')
          .matches(/^\S+$/, 'Space is not allowed ')
          .matches(/^$|[a-zA-Z0-9_]$/, 'Special characters are not allowed ')
          .min(40, 'Wallet Address must be 40-46 characters')
          .max(46, 'Wallet Address must be 40-46 characters'),
        emailId: yup
          .string()
          .required('Email Id is required ')
          .email('Please enter a valid Email ')
          .matches(/^\S/, 'Space is not allowed '),

        appointAs: yup.string().required('Please select one '),
      }),
    ),
  })

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      beneficiaries: sortedData
        ? sortedData
        : [{ displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '' }],
    },
  })

  const findError: any = formErrors

  const allFields = useWatch({ control })
  const [sumPercentage, setSumPercentage] = useState(0)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'beneficiaries',
  })

  const appoint = beneficiaryData?.some((item: { appointAs: string }) => item.appointAs === 'Fiduciary')

  const options = [
    { value: 'Fiduciary', label: 'Fiduciary' },
    { value: 'Beneficiary', label: 'Beneficiary' },
  ]

  const setoption = [{ value: 'Beneficiary', label: 'Beneficiary' }]

  const [isOpen, setIsOpen] = useState(false)

  const { setLoader } = useContext(LoaderContext)

  const { mutateAsync, isError, error, isLoading } = usePost()

  const { mutateAsync: deleteAsync, isLoading: deleteIsLoading } = useDelete()

  const formData = async (values: any) => {
    const filterData = values?.beneficiaries?.filter(
      (item: any) => !beneficiaryData.some((d: { beneficiaryId: string }) => d.beneficiaryId === item?.beneficiaryId),
    )
    filterData.forEach((data: any) => {
      data['amount'] = parseFloat(data.amount)
      data['userWalletId'] = data?.userWalletId.toLowerCase()
      delete data.isActive
    })

    const data: any = []
    values?.beneficiaries?.map((item: any) => {
      const beneficiaryData = {
        amount: item.amount,
        userId: item.userId,
        emailId: item.emailId,
        appointAs: item.appointAs,
        displayName: item.displayName,
        userWalletId: item.userWalletId.toLowerCase(),
      }

      data.push(beneficiaryData)
    })

    if (beneficiaryData)
      try {
        const beneficiaryData = await mutateAsync({
          url: 'beneficiary/beneficiary',
          payload: filterData,
          token: true,
        })
        next()
        setBeneficiary(!beneficiary)

        return beneficiaryData
      } catch (error: any) {
        return { error: error?.response?.data?.message }
      }
  }

  const deleteBeneficiary = async (item: any, index: number) => {
    try {
      const { data } = await deleteAsync({
        url: 'deletebeneficiary/deletebeneficiary',
        payload: { id: item?.beneficiaryId },
        token: true,
      })
      setBeneficiary(!beneficiary)
      return data
    } catch (error: any) {
      return { error: error.response.data.message }
    } finally {
      remove(index)
    }
  }

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])
  useEffect(() => {
    if (isLoading || deleteIsLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, deleteIsLoading])

  const incrementFunction = (data: any, i: number) => {
    const val = 0
    const amount = typeof data[i]?.amount == 'string' ? parseInt(data[i]?.amount) : data[i]?.amount
    if (amount === 0) {
      setValue(`beneficiaries.${i}.amount`, val + 1)
    } else {
      setValue(`beneficiaries.${i}.amount`, amount + 1)
    }
  }

  const decrementFunction = (data: any, i: number) => {
    const amount = typeof data[i]?.amount == 'string' ? parseInt(data[i]?.amount) : data[i]?.amount
    if (amount === 0) {
      setValue(`beneficiaries.${i}.amount`, 0)
    } else {
      setValue(`beneficiaries.${i}.amount`, amount - 1)
    }
  }

  const appointFunction = (element: any, i: number) => {
    allFields?.beneficiaries?.forEach((data: any, index: number) => {
      if (element === 'Beneficiary') {
        setValue(`beneficiaries.${i}.appointAs`, 'Beneficiary')
      } else if ((element === 'Fiduciary' || data.appointAs.value === 'Fiduciary') && index !== i) {
        setValue(`beneficiaries.${index}.appointAs`, 'Beneficiary')
      } else {
        setValue(`beneficiaries.${index}.appointAs`, 'Fiduciary')
      }
    })
  }

  const found = allFields?.beneficiaries?.some((item: { appointAs: string }) => item.appointAs === 'Fiduciary')

  const foundZero = allFields?.beneficiaries?.some((item: { amount: string }) => item.amount === '0')

  const foundNegative = allFields?.beneficiaries?.some((item: { amount: string }) =>
    item.amount?.toString().includes('-'),
  )

  const disabled = !found || sumPercentage !== 100 || foundNegative || foundZero || connection.length === 2
  useEffect(() => {
    if (!beneficiaryData?.length) {
      append({ displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '0' })
    }
  }, [beneficiaryData])

  const disableFields = text !== 'Save'

  useEffect(() => {
    allFields?.beneficiaries?.forEach((data: any, index: number) => {
      if (data.userWalletId.length > 0) {
        if (
          !ethers.utils.isAddress(data.userWalletId) ||
          data?.userWalletId === '0x0000000000000000000000000000000000000000'
        ) {
          if (!connection.includes(index)) {
            const data: any = []
            connection.map((item: any) => {
              data.push(item)
            })
            data.push(index)
            setConnection(data)
          }
        } else {
          const fors = []
          for (let i = 0; i < connection.length; i++) {
            if (!(connection[i] === index)) {
              fors.push(connection[i])
            }
          }

          setConnection(fors)
        }
      }
    })
  }, [allFields])

  useEffect(() => {
    let temp = 0

    allFields.beneficiaries?.forEach((item: { amount: string }) => {
      if (typeof item?.amount == 'string') {
        temp += parseFloat(item?.amount) || 0
      } else {
        temp += item?.amount || 0
      }
    })

    setSumPercentage(temp)
  }, [allFields])

  return (
    <MainContainer>
      <Container>
        <DisplayInfo>
          <DisplayName>Display Name</DisplayName>
          <DisplayName>Wallet Address</DisplayName>
          <DisplayName>Email Address</DisplayName>
          <DisplayName>Appoint As</DisplayName>
          <DisplayName>Allocation</DisplayName>
        </DisplayInfo>

        <form onSubmit={handleSubmit(formData)}>
          <Inputs>
            {fields.map((item: IData, index: number) => {
              const foundData = beneficiaryData.some(
                (d: { userWalletId: string }) => d.userWalletId === item?.userWalletId,
              )

              return (
                <InputSection key={item.id}>
                  <InputContainer>
                    <TextInput
                      name={`beneficiaries.${index}.displayName`}
                      className="text"
                      placeholder="Nickname"
                      value={item?.displayName}
                      control={control}
                      autoComplete="off"
                      disabled={foundData ? true : false}
                    />

                    <Error>{findError?.beneficiaries?.[index]?.displayName?.message} </Error>
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      name={`beneficiaries.${index}.userWalletId`}
                      className="text"
                      placeholder="Wallet Address "
                      control={control}
                      autoComplete="off"
                      disabled={foundData ? true : false}
                    />
                    <Error>{connection.includes(index) ? 'Invalid address ' : ''}</Error>
                  </InputContainer>
                  <InputContainer>
                    <TextInput
                      name={`beneficiaries.${index}.emailId`}
                      className="text"
                      placeholder="Email "
                      control={control}
                      autoComplete="off"
                      disabled={foundData ? true : false}
                    />
                    <Error>{findError?.beneficiaries?.[index]?.emailId?.message}</Error>
                  </InputContainer>
                  <InputContainer>
                    <SelectContainer>
                      <Controller
                        control={control}
                        name={`beneficiaries.${index}.appointAs`}
                        render={({ field: { value } }) => (
                          <Select
                            options={appoint && disableFields ? setoption : options}
                            placeholder="Appointed As"
                            value={value}
                            onChange={(val: any) => appointFunction(val, index)}
                            disabled={foundData ? true : false}
                          />
                        )}
                      />

                      <ErrorAppoint>{findError?.beneficiaries?.[index]?.appointAs?.message}</ErrorAppoint>
                    </SelectContainer>
                    {!found && getValues().beneficiaries?.[index]?.appointAs?.[index] && (
                      <AppointError>Please Select One Fiduciary</AppointError>
                    )}
                  </InputContainer>
                  <CountSection>
                    {!foundData && (
                      <LeftImage
                        src={CircelImage}
                        onClick={() => {
                          decrementFunction(allFields.beneficiaries, index)
                        }}
                      />
                    )}

                    <Input
                      className="input"
                      name={`beneficiaries.${index}.amount`}
                      value={item?.amount}
                      control={control}
                      type="number"
                      disabled={foundData ? true : false}
                    />

                    {!foundData && (
                      <RightImage src={PluseImage} onClick={() => incrementFunction(allFields.beneficiaries, index)} />
                    )}
                  </CountSection>

                  <ImageSection
                    src={RedPluse}
                    onClick={() => {
                      const foundAt = beneficiaryData.findIndex(
                        (d: { userWalletId: string }) => d.userWalletId === item?.userWalletId,
                      )
                      if (foundAt > -1) {
                        deleteBeneficiary(beneficiaryData[foundAt], index)
                      } else {
                        remove(index)
                      }
                    }}
                  />
                </InputSection>
              )
            })}
          </Inputs>
          <PercentageContainer>
            <PerText>Total Percentage </PerText>

            <TotalScore>
              <div>
                <div>{sumPercentage}%</div>
              </div>
              <div>
                <Error>{sumPercentage > 100 && <div>{'(Total cannot be more than 100%)'}</div>}</Error>
                <Error>{foundNegative && <div>{'(Negative allocating is prohibited)'}</div>}</Error>
                <Error>{foundZero && <div>{'(zero allocating is not allowed)'}</div>}</Error>
              </div>
            </TotalScore>
          </PercentageContainer>
          <ButtonContainer>
            <TextArea
              onClick={() => {
                append({ displayName: '', userWalletId: '', emailId: '', appointAs: '', amount: '0' })
              }}
            >
              {sumPercentage != 100 && (
                <AddMore>
                  <More />
                  <span>Add More</span>
                </AddMore>
              )}
            </TextArea>

            <Button disabled={disabled} type="primary" htmlType="submit" className="next-button">
              Next
            </Button>
          </ButtonContainer>
        </form>
      </Container>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} error={error} success={false} />
      </Modal>
    </MainContainer>
  )
}

export default DisplayBenificiaires
