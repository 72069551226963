import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
`
export const TextSection = styled.div`
  width: 100%;
  max-width: 300px;
  font-family: 'OutfitRegular';
  font-size: 30px;
  color: #2677e9;
  text-align: center;
`
export const BlueBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CancelSection = styled.img`
  cursor: pointer;
  height: 27px;
`
export const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const BottomSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .IdSection {
    width: 100%;
    max-width: 300px;
    display: flex;
    margin-left: 90px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 12px;
    margin-bottom: 20px;
    height: 30px;
  }
`
export const ButtonSecton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`
export const InputBox = styled.div`
  width: 100%;
  max-width: 340px;
`
export const BottomText = styled.div`
  width: 100%;
  max-width: 353px;
  font-family: 'OutfitRegular';
  font-size: 15px;
  color: #737b7d;
  text-align: center;
`
export const TextInputSection = styled.div`
  margin-top: 10px;
`
