import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from 'components/Button'
import TableWrapper from 'components/Cards/ChooseAssets/Table'
import Close from 'assets/images/close-gray.png'
import ErrorModal from 'components/ErrorModal'
import { PortfolioRoute } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import Modal from 'components/Modal'
import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import { Checkbox as AntdCheckbox } from 'antd'
import { UserContext } from 'context/user'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  MainContainer,
  TextSection,
  CloseImage,
  ButtonSection,
  Wrapper,
  TableContainer,
  TopSection,
  HeadingWrapper,
  TextWrapper,
  CheckboxWrapper,
} from 'styles/components/Cards/ChooseAssets'
import { CryptoLogo, Defination } from 'styles/views/welcome/WelcomeSection/WelcomeCrypto'
import Watermark from 'assets/images/watermark-image.png'
import useGet from 'hooks/useGet'
import { ethers } from 'ethers'
import CPWillFactory from 'artifacts/contracts/CPWillFactoryUpgradeable.sol/CPWillFactoryUpgradeable.json'
import CPWill from 'artifacts/contracts/CPWillUpgradeable.sol/CPWillUpgradeable.json'

export interface IModalProps {
  showModal: (value: boolean) => void
  type: string
  userwalletId?: string
  index?: number
  select?: string
}
interface ITokenData {
  name: string
  token_address: string
  checked?: boolean
}

interface IArrayProps {
  yourAssets: string
  tokenAddress: string
  walletId: string
}
const ChooseAssets: React.FC<IModalProps> = ({ showModal, type, userwalletId, index }: IModalProps) => {
  const navigate = useNavigate()
  const dataRef = useRef(null)
  const { user } = useContext(UserContext)
  const { setLoader } = useContext(LoaderContext)
  const [uniqueTokens, setUniqueTokens] = useState<ITokenData[]>([])
  const linkedAddresses = user?.userWallet
  const [hasAssets, setHasAssets] = useState(false)
  const { mutateAsync, isLoading, error, isSuccess, isError } = usePost()
  const [isOpens, setIsOpens] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [errorModal, seterrorModal] = useState(false)
  const [filterAsset, setFilterAsset] = useState<Array<any>>()
  const [tokenBalancesLength, setTokenBalancesLength] = useState<number>(0)
  const ContractAddress = process.env.React_APP_CONTRACT_FACTORY_ADDRESS || ''
  let asset: any[]
  useEffect(() => {
    if (isUpdated) {
      toast('Your Assets are Updated')
      setIsUpdated(false)
      getAssetRefetch()
      fetchTokenBalance()
    }
  }, [isUpdated])
  const fetchTokenBalance = async () => {
    if (type === 'add') {
      setFilterAsset([])
    } else {
      const reducedAsset = asset?.filter((item: any) => item?.walletId === userwalletId)
      const filterAssetProxy = reducedAsset[0]?.assests?.map((item: any) => item?.tokenAddress)
      setFilterAsset(filterAssetProxy)
    }
  }
  const onSubmit = async () => {
    let returnVal = null
    try {
      if (isChecked === true && CurrentWallet[0]?.assests[0]?.isAuthorised !== true) {
        returnVal = await newAddressForGrantor()
      }

      if (returnVal !== undefined || returnVal === null) {
        const maxLength = uniqueTokens?.length - 1
        const finalData: Array<IArrayProps> = []
        if (uniqueTokens.length === 0 && type === 'update') {
          const proxyFinalData: Array<IArrayProps> = []
          const payload = {
            yourAssets: CurrentWallet[0]?.assests[0]?.yourAssets,
            tokenAddress: CurrentWallet[0]?.assests[0]?.tokenAddress,
            walletId: CurrentWallet[0]?.assests[0]?.userWalletUserWallet as string,
          }
          proxyFinalData.push(payload)
          try {
            await mutateAsync({
              url: 'assetcollection/userAsset',
              payload: { userAsset: proxyFinalData, isAuthorised: isChecked },
              token: true,
            })
            setIsUpdated(true)
            const navigationRoute = window.innerWidth <= 767 ? '/mobileportfolio' : PortfolioRoute.path
            showModal(false)
            navigate(navigationRoute)
          } catch (error: any) {
            return { error: error.response.data.message }
          }
        }

        for (let i = 0; i < uniqueTokens?.length; i++) {
          const elementData = uniqueTokens[i]
          const payload = {
            yourAssets: elementData?.checked ? elementData.name : '',
            tokenAddress: elementData?.checked ? elementData.token_address : '',
            walletId: userwalletId as string,
          }
          finalData.push(payload)
          if (i === maxLength) {
            try {
              await mutateAsync({
                url: 'assetcollection/userAsset',
                payload: { userAsset: finalData, isAuthorised: index === 0 ? true : isChecked },
                token: true,
              })
              setIsUpdated(true)
              const navigationRoute = window.innerWidth <= 767 ? '/mobileportfolio' : PortfolioRoute.path
              showModal(false)
              navigate(navigationRoute)
            } catch (error: any) {
              return { error: error.response.data.message }
            }
          }
        }
      }
    } catch (error) {
      return error
    }
  }

  const newAddressForGrantor = async () => {
    if (typeof (window as any).ethereum !== 'undefined') {
      setLoader(true)
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      const signer = provider.getSigner()
      const signerAddress = await signer.getAddress()
      const facInstance = new ethers.Contract(ContractAddress, CPWillFactory.abi, signer)
      const willId = await facInstance.getWills(signerAddress)
      if (!willId[0]) {
        seterrorModal(true)
        setLoader(false)
      } else {
        try {
          const latestContractAddress = willId[(willId?.length || 1) - 1]
          const FgcInstance = new ethers.Contract(latestContractAddress, CPWill.abi, signer)
          const transaction = await FgcInstance?.addGrantor(
            userwalletId,
            1,
            '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65',
          )
          const receipt = await transaction?.wait()

          for (let i = 0; i < receipt?.events?.length; i++) {
            if (receipt?.events[i]?.event === 'RoleGranted') {
              const msg = `New wallet of Grantor has been added`
              try {
                await mutateAsync({
                  url: '/notify/event',
                  payload: {
                    message: msg,
                    status: true,
                    type: `contract`,
                    titleName: `Wallet Added`,
                  },
                  token: true,
                })
                await eventPayment()
                setLoader(false)
                return true
              } catch (err: any) {
                return err
              }
            }
          }
        } catch (error) {
          setLoader(false)
        }
      }
    }
  }

  const eventPayment = async () => {
    try {
      await mutateAsync({
        url: '/notify/event',
        payload: {
          message: 'Payment successful',
          status: true,
          type: `transition`,
          titleName: `Payment`,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const {
    data: getAsset,
    refetch: getAssetRefetch,
    isLoading: loadingAsset,
  } = useGet('get-Asset', 'asset/getAsset', false, {
    enabled: false,
    token: true,
  })
  if (getAsset) asset = getAsset?.user?.userWallet

  const CurrentWallet = getAsset?.user?.userWallet?.filter((item: any) => item?.walletId === userwalletId)

  useEffect(() => {
    if (getAsset) {
      const data = getAsset?.user?.userWallet?.filter((item: any) => item?.walletId === userwalletId)
      setIsChecked(data[0]?.assests[0]?.isAuthorised)
    }
  }, [getAsset])

  useEffect(() => {
    getAssetRefetch()
  }, [])

  useEffect(() => {
    if (isLoading || loadingAsset) setLoader(true)
    else setLoader(false)
  }, [isLoading, loadingAsset])

  useEffect(() => {
    if (isError) {
      setIsOpens(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (linkedAddresses.length && dataRef?.current === null) {
      fetchTokenBalance()
      dataRef.current = linkedAddresses
    }
  }, [linkedAddresses])

  useEffect(() => {
    fetchTokenBalance()
  }, [])

  useEffect(() => {
    if (uniqueTokens.length === 0) {
      setLoader(true)
      setTimeout(() => {
        setLoader(false)
      }, 5000)
    }
  }, [])

  return (
    <MainContainer>
      <Wrapper>
        <TopSection>
          <TextSection>{type === 'add' ? 'Add ' : 'Update '}Your Assets</TextSection>
          <CloseImage
            src={Close}
            onClick={() => {
              showModal(false)
            }}
          />
        </TopSection>
        <TableContainer>
          {filterAsset !== undefined && CurrentWallet ? (
            <>
              <TableWrapper
                setUniqueTokens={setUniqueTokens}
                currentWallet={CurrentWallet[0]?.walletId}
                setHasAssets={setHasAssets}
                filterAsset={filterAsset}
                hasAssets={hasAssets}
                setTokenBalancesLength={setTokenBalancesLength}
              />
              {tokenBalancesLength > 0 && hasAssets === true && type !== 'add' && index !== 0 && (
                <HeadingWrapper>
                  <TextWrapper>Authorize this wallet to disburse assets</TextWrapper>
                  <CheckboxWrapper>
                    <AntdCheckbox
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      disabled={CurrentWallet[0]?.assests[0]?.isAuthorised}
                    />
                  </CheckboxWrapper>
                </HeadingWrapper>
              )}
            </>
          ) : (
            <>
              <CryptoLogo src={Watermark} />
              <Defination>No Assets</Defination>
            </>
          )}
        </TableContainer>
        <ButtonSection>
          {(hasAssets === true || type !== 'add') && (
            <Button label="Skip " variant="contained" onClick={() => showModal(false)} />
          )}
          {tokenBalancesLength > 0 && hasAssets === true && (
            <Button label="Update " variant="contained" type="button" onClick={() => onSubmit()} />
          )}
        </ButtonSection>
      </Wrapper>
      <Modal isOpen={isOpens}>
        <ErrorModal showModal={(value: boolean) => setIsOpens(value)} success={isSuccess} error={error} />
      </Modal>
      <ToastContainer />

      <Modal isOpen={errorModal}>
        <ErrorModal
          showModal={(value: boolean) => seterrorModal(value)}
          sameAddError={'You do not have a CryptoPlan yet with your registered wallet!'}
          success={true}
        />
      </Modal>
    </MainContainer>
  )
}

export default ChooseAssets
