import styled from 'styled-components'
import Button from 'components/Button'
import { theme } from 'theme'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 25px;
  gap: 20px;
  @media (max-width: 767px) {
    padding: 5px;
  }
`
export const HeadText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 40px;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  width: 100%;
  max-width: 845px;
`
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 21px;
  @media (max-width: 767px) {
    gap: 0;
  }
`
export const SubTopRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 21px;
  @media (max-width: 767px) {
    padding: 7px 21px;
    display: flex;
  }
`
export const SubTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 21px;
  @media (max-width: 767px) {
    padding: 7px 21px;
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 900px) {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
export const PaymentContainer = styled.div`
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  width: 100%;
  max-width: 845px;
`
export const LabelText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  color: #0a0c17;
`
export const TextArea = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  color: #737b7d;
  @media (max-width: 767px) {
    padding: 2.667vw;
  }
`
export const FreeText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 30px;
  color: #1bb691;
`
export const LimtedWrapper = styled.div`
  padding: 0px 21px;
  cursor: pointer;
`
export const LimitedText = styled.div`
  max-width: 170px;
  padding: 10px 20px;
  background: #50c6be;
  border-radius: 50px;
  font-family: 'OutfitMedium';
  font-size: 14px;
  text-align: center;
  color: #ffffff;
`
export const ProgressContainer = styled.div`
  border-bottom: 1px solid #dfdfdf;
  padding: 20px 21px;
  @media (max-width: 767px) {
    padding: 2.933vw 5.6vw;
  }
  .ant-progress-text {
    display: none;
  }
`
export const ProgressText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 18px;
`
export const UpgradeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 23px 25px;
  gap: 10px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 2.933vw 5.6vw;
  }
`
export const UpgradeText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 18px;
  color: #4473f5;
`
export const DaigonalIcon = styled.img`
  width: 12px;
  height: 12px;
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 40px 20px;
  @media (max-width: 767px) {
    padding: 1.333vw 5.333vw;
  }
  @media only screen and (max-width: 900px) {
    padding: 8px;
  }
`
export const WalletCard = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 32px;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  gap: 40px;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 2.667vw !important;
    gap: 2.667vw;
  }
  @media only screen and (max-width: 900px) {
    padding: 13px 18px;
  }
`
export const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`
export const BrandText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 16px;
  line-height: 24px;
`
export const EmailText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const MethodText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #1bb691;
`
export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 18px;
  color: #4473f5;
  cursor: pointer;
`
export const AddText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #4473f5;
  padding: 20px 28px;
  border: 1px solid #a7cbff;
  border-radius: 16px;
  font-family: 'OutfitBold';
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 2.667vw;
  }
  @media (max-width: 1440px) {
    padding: 10px 15px;
  }
`
export const TableContainer = styled.div`
  width: 100%;
  .ant-table {
    @media (max-width: 767px) {
      max-width: 340px;
      overflow-x: scroll;
    }
  }
`
export const TableTopcontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
`
export const TableWrapper = styled.div`
  padding: 20px;
  @media (max-width: 767px) {
    padding: 5px;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    font-family: 'OutfitMedium';
  }
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  @media only screen and (max-width: 900px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 16px 7px;
    }
  }
`
export const DownlodContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border: 1px solid rgba(67, 67, 67, 0.2);
  border-radius: 16px;
  width: 100%;
  max-width: 150px;
  padding: 10px 12px 10px 12px;
`
export const DownlodText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
`
export const DownlodLogo = styled.img``

export const BottomButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 35px;
`
export const LeftButtonBox = styled.div`
  width: 100%;
`
export const RightButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`
export const ResetButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  color: #1bb691;
  border: 1px solid #1bb691;
`
export const DiscardButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #000;
`
export const SaveButton = styled(Button)`
  max-width: 150px;
`
export const Download = styled.a`
  cursor: pointer;
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
export const CryptoLogo = styled.img`
  height: 176px;
  width: 196px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 80px;
  padding: 21px 11px 21px 11px;
  text-align: center;
  @media (max-width: 1440px) {
    padding: 20px 3px 22px 3px;
  }
`
export const TextSection = styled.div``
export const MainUpgradeContainer = styled.div``
export const DesktopView = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileView = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
