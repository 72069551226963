import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 767px) {
    padding: 4.267vw !important;
  }
`
export const TextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 28px;
`
export const Wrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  max-width: 750px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  padding: 20px;
  .multi_select {
    height: 59px;
    overflow-y: auto;
    padding-top: 8px;
    font-size: 15px;
    > input {
      width: 100% !important;
      max-width: 200px;
    }
    .css-1s2u09g-control {
      height: 59px;
      border: 1px solid #dfdfdf;
      border-radius: 11px;
    }
    .css-1pahdxg-control {
      border: 1px solid #dfdfdf;
      border-radius: 11px;
      height: 59px;
      box-shadow: none;
      outline: none;
    }
    .css-1rhbuit-multiValue {
      background: rgba(38, 119, 233, 0.2);
      border-radius: 12px;
      height: 40px;
      align-items: center;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-26l3qy-menu {
      display: none;
    }
  }
`
export const CloseImage = styled.img`
  height: 20px;
  cursor: pointer;
`

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 260px;
  align-items: center;
  padding-bottom: 26px;
  display: flex;
  gap: 10px;
  margin-left: auto;
  padding-top: 50px;
  @media (max-width: 767px) {
    padding-top: 4.267vw;
  }
`
export const SocialPletforms = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  padding: 24px 0px 10px 0px;
  @media (max-width: 767px) {
    padding: 0.667vw 0px 0.667vw 0px;
  }
`
export const SocialIcons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: inherit;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 40px;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    padding: 3.733vw;
  }
`
export const Linkedin = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
export const ImageSection = styled.img``
export const LinkedinText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
`
export const InviteEmail = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  line-height: 56px;
`
export const InviteTo = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
`

export const Input = styled.input`
  width: 100%;
  padding: 10px 0;
`
export const InstaText = styled.div`
  padding-top: 5px;
  padding-left: 9px;
`
export const CopyText = styled.div`
  padding-top: 6px;
  padding-left: 13px;
`
export const CopyLinkSection = styled.div`
  cursor: pointer;
`
export const Instalink = styled.div``
export const InstagramLogo = styled.img``
export const CoppyLinkLogo = styled.img``
export const EmailTag = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(38, 119, 233, 0.2);
  border-radius: 9px;
  height: 30px;
  max-width: 100%;
  padding: 5px;
  text-align: center;
  gap: 5px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 17px;
  justify-content: space-around;
  span {
    display: flex;
    cursor: pointer;
  }
`
export const WhatsappLogo = styled.img``
export const TelegramLogo = styled.img``
export const TextWhatsapp = styled.div`
  padding-top: 4px;
`
export const TelegramText = styled.div`
  padding-top: 4px;
`
export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: left;
`
export const TextLinkedin = styled.div`
  margin-left: 17px;
`
