import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const Wrapper = styled.div`
  width: 100%;
  max-width: 561px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;

  @media (max-width: 1366px) {
    max-width: 500px;
  }
  @media (max-width: 375px) {
    width: 100%;
    max-width: 335px;
  }
`
export const PrimaryWalletSection = styled.div`
  padding: 12px 36px 60px 36px;
  @media (max-width: 1366px) {
    padding: 12px 36px 60px 36px;
  }
  @media (max-width: 767px) {
    padding: 4.267vw;
  }
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
  color: #0a0c17;
`
export const CancelSection = styled.img`
  cursor: pointer;
  height: 27px;
`
export const OptionSection = styled.div`
  padding: 22px 46px 13px 46px;
  @media (max-width: 1350px) {
    padding: 10px 46px 10px 46px;
  }
  @media (max-width: 1366px) {
    padding: 10px 46px 10px 46px;
  }
`
export const MoreOptionSection = styled.div`
  border: 1px solid #cbd1d8;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  background: #4473f5;
  border-radius: 32px;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`
export const TextOption = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`
export const TextDropDown = styled.img`
  margin: 5px;
  position: relative;
  cursor: pointer;
`
export const SignUpLastSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`
export const MemberSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0a0c17;
  cursor: pointer;
`
export const SignInContainer = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  color: #4473f5;
  cursor: pointer;
`
export const CardSection = styled.div`
  width: 100%;
`
