import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'components/Modal'
import UserProfile from 'components/UserProfile'
import { UserContext } from 'context/user'
import BeneficaryMobileSidebar from 'components/BeneficaryMobileSidebar'
import Logo from 'assets/images/logo-black.png'
import AccountLogo from 'assets/images/account-icon.png'
import DropDown from 'assets/images/black-dropdown.png'
import MobileBurger from 'assets/svg/BlackBurger'
import {
  HeaderWrapper,
  LogoSection,
  ButtonContainers,
  ButtonSection,
  ButtonContainer,
  ImageSection,
  SelectOption,
  RightImage,
  NameText,
  ViewSelectOption,
  GrantorAdress,
  GrantorSection,
  BurgerLogo,
} from 'styles/components/NavBar'

interface IProp {
  beneificaryName?: string
}

const BeneficaryNavBar = ({ beneificaryName }: IProp) => {
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)

  const { user } = useContext(UserContext)
  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }
  const navigate = useNavigate()
  const wrapperRef = useRef<any>()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <HeaderWrapper>
      <LogoSection src={Logo} onClick={() => navigate('/dashboard')} />
      <BurgerLogo>
        <MobileBurger onClick={() => setUpdatePlanModal(true)} />
      </BurgerLogo>
      <ButtonSection>
        <div>
          <ButtonContainers>
            <ViewSelectOption>
              <GrantorSection>
                <NameText>Beneficary’s View</NameText>
                <GrantorAdress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                  user?.userWalletId?.length - 4,
                )}`}</GrantorAdress>
              </GrantorSection>
            </ViewSelectOption>
          </ButtonContainers>
        </div>
        <ButtonContainer onClick={() => setIsOpen(true)}>
          <SelectOption>
            <ImageSection src={AccountLogo} />
            <NameText>{beneificaryName}</NameText>

            <RightImage src={DropDown} />
          </SelectOption>
          {isOpen && (
            <div ref={wrapperRef}>
              <UserProfile beneificaryName={beneificaryName} />
            </div>
          )}
        </ButtonContainer>
      </ButtonSection>
      <Modal isOpen={isUpdatePlanOpen}>
        <BeneficaryMobileSidebar showModal={(value: boolean) => setUpdatePlanModal(value)} />
      </Modal>
    </HeaderWrapper>
  )
}

export default BeneficaryNavBar
