import styled from 'styled-components'
import { ICardProps } from 'components/Cards/Beneficiaries'
import { theme } from 'theme'

export const CardContainer = styled.div<ICardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 361px;
  gap: 8px;
  background: #08193a;
  border-radius: 40px;
  background-repeat: no-repeat;
  align-self: center;
  box-sizing: border-box;
  padding: 30px 24px;
  border-radius: 32px;
  :hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 767px) {
    padding: 5.333vw 5.333vw;
    width: 80vw;
    height: 66.667vw;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`
export const Title = styled.div`
  text-align: center;
  font-family: 'OutfitMedium';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${theme.info.light};
  @media screen and (max-width: 767px) {
    font-size: 6.4vw;
  }
`

export const SubTitle = styled.div<ICardProps>`
  font-family: 'OutfitRegular';
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #e5e5e5;
  mix-blend-mode: luminosity;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 3.467vw;
  }
`
export const ImgConatiner = styled.div`
  padding: 20px;
`
export const ImageWrapper = styled.img`
  @media screen and (max-width: 767px) {
    width: 28vw;
  }
`
