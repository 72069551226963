import React, { useEffect, useState, useContext } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { Select } from 'antd'
import { Adddata } from 'views/dashboard/MyPlan/PaymentSection/data'
import { Button } from 'antd'
import AddACknowlegment from 'components/Cards/AddAcknowlegment'
import { NotifyDataContext } from 'context/notifyData'
import usePost from 'hooks/usePost'
import { UserContext } from 'context/user'
import useGet from 'hooks/useGet'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import { LoaderContext } from 'context/loader'

import {
  MainContainer,
  NotifyWrapper,
  NotifySection,
  TextSection,
  AfterSection,
  TermsSectionOne,
  TermText,
  ContainerButton,
  TextContainer,
  Star,
} from 'styles/views/dashboard/MyPlan/PaymentSection'

interface IModalProps {
  next: () => void
  prev: () => void
  text?: string
}

interface INotify {
  acknowledgement: string
  acknowledgementId: string
  category: string
  isActive: boolean
  numberOfDays: string
  numberOfTimes: string
}
const PymentSection: React.FC<IModalProps> = ({ next, prev }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync, isSuccess, isError, error, isLoading } = usePost()
  const { user } = useContext(UserContext)
  const { setNotifyData } = useContext(NotifyDataContext)
  const { handleSubmit, control, setValue } = useForm()

  const watchData = useWatch({ control })
  setNotifyData(watchData)

  const selectData = async (values: any) => {
    try {
      await mutateAsync({
        url: 'user/notiy',
        payload: values,
        token: true,
      })
      next()
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  const {
    data: notify,
    refetch,
    isLoading: getAcknowledgementLoading,
  } = useGet('get-notify', 'getAcknowledgement/user', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (isLoading === false) {
      refetch()
    }
  }, [isLoading])

  const handleClick = () => {
    prev()
  }

  useEffect(() => {
    if (notify) {
      setValue('numberOfDays', notify?.users?.numberOfDays)
      setValue('numberOfTimes', notify?.users?.numberOfTimes)
      setValue('category', notify?.users?.category)
    }
  }, [notify])

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  useEffect(() => {
    if (isLoading || getAcknowledgementLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, getAcknowledgementLoading])

  return (
    <MainContainer>
      <form onSubmit={handleSubmit(selectData)}>
        <NotifyWrapper>
          <NotifySection>
            <TextContainer>
              <TextSection>How long do you want to wait before asset disbursement?</TextSection>
              <Star>*</Star>
            </TextContainer>
            <AfterSection>
              <Controller
                control={control}
                name="numberOfDays"
                render={({ field }) => (
                  <Select placeholder="Please select one" {...field}>
                    <Select.Option value="30">After 30 Days</Select.Option>
                    <Select.Option value="60">After 60 Days</Select.Option>
                    <Select.Option value="90">After 90 Days</Select.Option>
                  </Select>
                )}
              />
            </AfterSection>
          </NotifySection>
          <NotifySection>
            <TextContainer>
              <TextSection>How often due you want to receive notifications to stop the contract?</TextSection>
              <Star>*</Star>
            </TextContainer>
            <AfterSection>
              <Controller
                control={control}
                name="numberOfTimes"
                render={({ field }) => (
                  <Select placeholder="Please select one" {...field}>
                    <Select.Option value="Daily">Daily</Select.Option>
                    <Select.Option value="2X Daily">2X Daily</Select.Option>
                    <Select.Option value="3X Daily ">3X Daily</Select.Option>
                  </Select>
                )}
              />
            </AfterSection>
          </NotifySection>
          <NotifySection>
            <TextContainer>
              <TextSection>How do you want to be notified?</TextSection>
              <Star>*</Star>
            </TextContainer>
            {user?.phoneNo?.length ? (
              <AfterSection>
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <Select placeholder="Please select one" {...field}>
                      <Select.Option value="Email">Email</Select.Option>
                      <Select.Option value="SMS">SMS</Select.Option>
                      <Select.Option value="Both">Both</Select.Option>
                    </Select>
                  )}
                />
              </AfterSection>
            ) : (
              <AfterSection>
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <Select placeholder="Please select one" {...field}>
                      <Select.Option value="Email">Email</Select.Option>
                    </Select>
                  )}
                />
              </AfterSection>
            )}
          </NotifySection>
        </NotifyWrapper>
        <TermsSectionOne>
          <TermText>Acknowledgement</TermText>
          {Adddata?.map((item, index) => (
            <AddACknowlegment key={index} image={item?.image} title={item?.title} />
          ))}
        </TermsSectionOne>

        <ContainerButton>
          <Button type="primary" onClick={handleClick} className="Back-button">
            Back
          </Button>

          <Button type="primary" htmlType="submit" className="next-button">
            Next
          </Button>
        </ContainerButton>
      </form>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} success={isSuccess} error={error} />
      </Modal>
    </MainContainer>
  )
}

export default PymentSection
