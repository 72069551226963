import React from 'react'
import AddWallet from 'assets/images/addemail-dark.png'
import SmartContract from 'assets/images/addwallet-dark.png'
import AddBeneficiar from 'assets/images/disbursement-dark.png'
import LastDark from 'assets/images/last-dark.png'
import {
  RoadMap,
  BlankSection,
  AddWalletSection,
  SmartContractSection,
  WorkFrame,
  AddBeneficiariesSection,
  DisbursementSection,
  BlankFirstSection,
  BlankSecondSection,
  AddWalletFirstSection,
  AddWalletSecondSection,
  SmartFirstSection,
  SmartSecondSection,
  BeneficiarFirstSection,
  BeneficiarSecondSection,
  DisbursFirstSection,
  DisbursSecondSection,
  Label,
  FileImageContainer,
  BorderHider,
} from 'styles/views/landing/BeneficiariesRoadMap'

const BenificiaryRoadMap = () => (
  <RoadMap>
    <WorkFrame>
      <BlankSection>
        <BlankFirstSection>
          <FileImageContainer
            src={AddWallet}
            initial={{ translateX: '-8vw' }}
            animate={{ translateX: '0vw', transition: { duration: 0.5, delay: 0.3 } }}
          />
        </BlankFirstSection>
        <BlankSecondSection />
      </BlankSection>
      <AddWalletSection>
        <AddWalletFirstSection>
          <Label>Accept Invitation</Label>
          <FileImageContainer
            src={SmartContract}
            initial={{ translateY: '8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5, delay: 0.3 } }}
          />
        </AddWalletFirstSection>
        <AddWalletSecondSection />
      </AddWalletSection>
      <SmartContractSection>
        <SmartFirstSection>
          <FileImageContainer
            src={AddBeneficiar}
            initial={{ translateY: '-8vw' }}
            animate={{ translateY: '0vw', transition: { duration: 0.5, delay: 0.3 } }}
          />
        </SmartFirstSection>
        <SmartSecondSection>
          <Label>Connect Crypto Wallet</Label>
        </SmartSecondSection>
      </SmartContractSection>
      <AddBeneficiariesSection>
        <BeneficiarFirstSection>
          <Label>Assets Disbursed</Label>
          <FileImageContainer
            src={LastDark}
            initial={{ translateX: '8vw' }}
            animate={{ translateX: '0vw', transition: { duration: 0.5, delay: 0.3 } }}
          />
          <BorderHider />
        </BeneficiarFirstSection>
        <BeneficiarSecondSection />
      </AddBeneficiariesSection>
      <DisbursementSection>
        <DisbursFirstSection />
        <DisbursSecondSection />
      </DisbursementSection>
    </WorkFrame>
  </RoadMap>
)
export default BenificiaryRoadMap
