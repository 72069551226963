import styled from 'styled-components'
interface IActiveSection {
  active: boolean
}
export const Container = styled.div`
  overflow-x: hidden;
`
export const Wrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 45px;
  padding: 0px 50px;
  @media (max-width: 1280px) {
    margin-top: 25px;
  }
`
export const LeftSection = styled.div`
  display: grid;
`
export const HeadOption = styled.div<IActiveSection>`
  font-family: 'OutfitMedium';
  background: ${({ active }) => (active ? '#0E65F3' : '')};
  color: #fff;
  padding: 16px 58px;
  border-radius: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1280px) {
    padding: 16px 43px;
  }
`
export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const RightSubSection = styled.div`
  max-width: 496px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 64px;
  padding: 8px 10px;
  background: #092d72;
  @media (max-width: 1280px) {
    margin-top: 72px;
    max-width: 360px;
  }
`
export const SubscriberCardwrapper = styled.div`
  display: flex;
  position: relative;
  top: 350px;
  gap: 88px;
  left: 180px;
  z-index: 1;
  width: fit-content;
`
export const BenificiaryCardwrapper = styled.div`
  display: flex;
  position: relative;
  top: 350px;
  gap: 141px;
  left: 224px;
  z-index: 1;
  width: fit-content;
`
