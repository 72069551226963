import { ReactElement } from 'react'

interface IStatus {
  title: string
}
interface IData {
  key: React.Key
  GenerateKey: ReactElement
  contractid: ReactElement
  contractdate: string
  fiduciary: string
  totalbenificiaries: string
  status: IStatus
  action: ReactElement
}

interface IColumns {
  title: string
  dataIndex: string
}

export const columns = [
  {
    title: 'Contract Id',
    dataIndex: 'contractid',
  },

  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
  {
    title: '',
    dataIndex: 'delete',
  },
  {
    title: '',
    dataIndex: 'contract',
  },
  {
    title: ' ',
    dataIndex: 'generatekey',
  },
]

export const columns2 = [
  {
    title: 'Contract Id',
    dataIndex: 'contractid',
  },

  {
    title: 'Total Beneficiaries',
    dataIndex: 'totalbenificiaries',
  },

  {
    title: 'Action',
    dataIndex: 'action',
  },
]
export default { columns, columns2 }
