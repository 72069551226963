import * as React from 'react'
import { SVGProps } from 'react'

const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg width={80} height={79} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={39.579} cy={39.5} r={39.5} fill="#55ACEE" fillOpacity={0.13} />
    <path
      d="M57 31.166c-1.198.528-2.47.875-3.77 1.029a6.557 6.557 0 0 0 2.886-3.615 13.185 13.185 0 0 1-4.169 1.586 6.572 6.572 0 0 0-4.792-2.064c-3.626 0-6.566 2.926-6.566 6.534 0 .512.059 1.01.17 1.489a18.668 18.668 0 0 1-13.531-6.827 6.523 6.523 0 0 0 2.032 8.723 6.562 6.562 0 0 1-2.974-.817v.082c0 3.166 2.262 5.807 5.266 6.407-.967.262-1.981.3-2.965.112a6.568 6.568 0 0 0 6.133 4.538 13.213 13.213 0 0 1-8.154 2.796c-.53 0-1.052-.03-1.566-.09a18.648 18.648 0 0 0 10.064 2.935c12.076 0 18.679-9.956 18.679-18.59 0-.284-.006-.566-.019-.846A13.31 13.31 0 0 0 57 31.166"
      fill="#55ACEE"
    />
  </svg>
)

export default Twitter
