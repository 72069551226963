import React from 'react'
import Heading from 'components/Heading'
import KeyFeatCard from 'components/Cards/KeyFeature'
import data from 'views/landing/KeyFeature/data'
import FeatImage from 'assets/images/Mac-Group.png'
import MobileKeyFeature from 'assets/images/Mac-Group.png'

import {
  KeyFeatContainer,
  KeyFeatWrapper,
  HeadWrapper,
  MainWrapper,
  CardWrapper,
  ImgWrapper,
  ImageView,
  MobileDashboardpic,
  SubHeadingText,
} from 'styles/views/landing/KeyFeature'
interface IAboutProps {
  aboutRef: React.RefObject<HTMLDivElement>
}

const KeyFeat: React.FC<IAboutProps> = ({ aboutRef }: IAboutProps) => (
  <KeyFeatContainer ref={aboutRef}>
    <KeyFeatWrapper>
      <HeadWrapper>
        <Heading label="Why Cryptoplan?" />
        <SubHeadingText>
          Estate planning for digital assets is difficult. You need a plan to pass on your digital wealth or you risk
          losing your assets forever. Cryptoplan solves this in minutes.
        </SubHeadingText>
      </HeadWrapper>
      <MainWrapper>
        <ImgWrapper>
          <ImageView src={FeatImage} alt={'Image'} />
          <MobileDashboardpic src={MobileKeyFeature} />
        </ImgWrapper>
        <CardWrapper>
          {data?.map((item) => (
            <KeyFeatCard key={item.id} image={item?.image} title={item?.title} subtitle={item?.subtitle} />
          ))}
        </CardWrapper>
      </MainWrapper>
    </KeyFeatWrapper>
  </KeyFeatContainer>
)

export default KeyFeat
