import loader from 'assets/loader/LOADER_CRYPTOPLAN.gif'
import upto from 'assets/loader/LoaderUpto.gif'
import { Loader, UptoLoader } from 'styles/components/Loader'

interface ISpinner {
  uptoLoader?: boolean
}

const Spinner = ({ uptoLoader }: ISpinner) => (
  <div
    style={{
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99999,
      backdropFilter: 'blur(0.4px)',
      background: uptoLoader ? 'rgba(240, 248, 255, 0.81)' : '',
    }}
  >
    {uptoLoader ? <UptoLoader src={upto} alt="loader" /> : <Loader src={loader} alt="loader" />}
  </div>
)

Spinner.defaultProps = {
  fullScreen: false,
}

export default Spinner
