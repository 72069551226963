import React from 'react'

import { MobileContainerText, MobileWalletSection, Title } from 'styles/components/Cards/PaymentCard'
export interface ICardProps {
  title: string
  image?: string
  onClick: (value: boolean) => void
}
const PaymentCard: React.FC<ICardProps> = ({ image, title, onClick }) => {
  return (
    <MobileContainerText onClick={() => onClick(true)}>
      <MobileWalletSection src={image} />
      <Title>{title}</Title>
    </MobileContainerText>
  )
}

export default PaymentCard
