import styled from 'styled-components'
import { theme } from 'theme'

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgb(0 0 0 / 12%);
  border-radius: 32px;

  .back-button {
    background: #fff;
    color: #000;
    border-color: #e0e0e0;
    height: 56px;
    margin: 7px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin: 1.333vw 0 10px 1.333vw;
    }
    @media (max-width: 1366px) {
      margin: 1px;
    }
  }

  .no-visible {
    display: none;
  }

  .next-button {
    background: #4473f5;
    height: 56px;
    margin: 15px 11px 15px 0;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin: 1.333vw 10px 10px 1.333vw;
    }
  }
  .save-button {
    background: #4473f5;
    height: 56px;
    margin: 15px 11px 15px 0;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin: 1.333vw 10px 10px 1.333vw;
    }
  }
  .nextTree-button {
    background: #4473f5;
    height: 56px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    margin: 0px;
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin: 1.333vw 10px 10px 1.333vw;
      border-radius: 16px;
    }
  }
  .steps-action {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    align-items: center;

    @media (max-width: 1366px) {
      margin-top: 0px;
      padding: 3px 10px 4px 10px;
      gap: 7px;
    }
    @media (max-width: 1280px) {
      margin-top: 0px;
      padding: 0;
      align-items: center;
      padding: 10px;
    }
  }
  .ant-steps-item:last-child {
    display: none;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #f0f0f0;
  }
`
export const StepWrapper = styled.div`
  padding: 20px 35px 20px 30px;
  cursor: pointer;
  background: #f1f5fb;

  @media (max-width: 767px) {
    padding-top: 4vw;
    display: flex;
  }
  @media (max-width: 767px) {
    .ant.strps.item-container {
      width: 26.667vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-steps-item {
      width: 19.333vw;
      display: flex;
    }
    .ant-steps {
      width: 88.667vw;
      display: flex;
    }
    .ant-steps.ant-steps-vertical {
      display: flex;
      display: -webkit-box;
    }
    .ant-steps-item-container {
      width: 26.667vw;
      margin-left: 6.333vw;
    }
  }
  @media (max-width: 1280px) {
    padding: 2px;
  }
  @media (max-width: 1366px) {
    padding: 15px 35px 8px 20px;
  }

  color: ${theme.info.light};
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2677e9;
    border-color: #fff;
    color: #fff;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps {
    text-align: center;
  }
`
