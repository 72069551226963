import { useState, useContext, useEffect } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoaderContext } from 'context/loader'
import SignupWallet from 'assets/images/signup-wallet.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import EmailEdit from 'assets/images/edit1.png'
import usePatch from 'hooks/usePatch'
import NumberFormat from 'react-number-format'
import More from 'assets/svg/More'
import AddModal from 'components/AddEmailModal'
import Modal from 'components/Modal'
import LoginConnectWallet from 'views/landing/LoginConnectWallet'
import TextInput from 'components/FormElements/TextInput'
import { UserContext } from 'context/user'
import { userSchema } from 'utils/schema/updateBenficiary'
import {
  MainContainer,
  HeadText,
  TextArea,
  ButtonBox,
  LabelText,
  EmailText,
  WalletWrapper,
  WalletLogoSection,
  SubDetailContainer,
  Form,
  LabelContainer,
  AddEmail,
  SubWalletWrapper,
  Wallet,
  WalletAddress,
  BottomButtonBox,
  LeftButtonBox,
  RightButtonBox,
  DiscardButton,
  SaveButton,
  Error,
  SecondaryImg,
  SecndEmailLabel,
  EmailLabelContainer,
  CopySection,
  LabelEmail,
  LabelContainerPhone,
} from 'styles/views/dashboard/Settings/MyDeatils'

interface IData {
  userName: string
  phoneNo?: string
}

const MyDetails = () => {
  const { user, fetchUser } = useContext(UserContext)
  const [isOpen, setisOpen] = useState(false)
  const [isOpenWallet, setIsOpenWallet] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const walletInfo = localStorage?.getItem('walletconnect') || ''

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IData>({
    resolver: yupResolver(userSchema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const allFields = useWatch({ control })

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    setValue('userName', user?.userName)
    setValue('phoneNo', user?.phoneNo)
  }, [user])

  useEffect(() => {
    reset(user)
  }, [user])

  const { mutateAsync: updataAsync, isLoading } = usePatch()

  const formData = async (values: IData) => {
    try {
      const { updateData } = await updataAsync({
        url: '/user/update',
        payload: values,
        token: true,
      })
      fetchUser()
      return updateData
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  const phoneError = allFields?.phoneNo?.includes('_')

  return (
    <MainContainer>
      <div>
        <HeadText>Primary Wallet</HeadText>
        <TextArea></TextArea>
        <ButtonBox>
          <WalletWrapper>
            {walletInfo ? (
              <WalletLogoSection src={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet} />
            ) : (
              <WalletLogoSection src={SignupWallet} alt="MetaMask" />
            )}
            <SubWalletWrapper>
              {walletInfo ? <Wallet>{JSON.parse(walletInfo)?.peerMeta?.name}</Wallet> : <Wallet>Metamask</Wallet>}
              <WalletAddress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                user?.userWalletId?.length - 4,
              )}`}</WalletAddress>
            </SubWalletWrapper>
          </WalletWrapper>
        </ButtonBox>
      </div>
      <div>
        <HeadText>Profile</HeadText>
        <TextArea></TextArea>
      </div>
      <Form onSubmit={handleSubmit(formData)}>
        <EmailLabelContainer>
          <LabelText>Email ID</LabelText>
          <EmailText>{user?.emailId}</EmailText>
        </EmailLabelContainer>
        <SubDetailContainer>
          {user?.emailId2 ? (
            <SecndEmailLabel>
              <LabelEmail>Secondary Email ID </LabelEmail>
              <CopySection>
                {user?.emailId2}
                <SecondaryImg src={EmailEdit} onClick={() => setisOpen(true)} />
              </CopySection>
            </SecndEmailLabel>
          ) : (
            <LabelContainer>
              <LabelText>Secondary Email ID</LabelText>
              <AddEmail onClick={() => setisOpen(true)}>
                <More />
                Add New Email
              </AddEmail>
            </LabelContainer>
          )}
        </SubDetailContainer>
        <SubDetailContainer>
          <LabelContainer>
            <LabelText>Display Name</LabelText>
          </LabelContainer>
          <LabelContainer>
            <TextInput name="userName" className="text" placeholder="Enter Username" control={control} />
            <Error>{errors?.userName?.message}</Error>
          </LabelContainer>
        </SubDetailContainer>
        <SubDetailContainer>
          <LabelContainer>
            <LabelText>Phone Number (Optional)</LabelText>
          </LabelContainer>
          <LabelContainerPhone>
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  className="text11"
                  format="+1 (###) ###-####"
                  placeholder="Enter Phone Number"
                  mask="_"
                />
              )}
              control={control}
              name="phoneNo"
            />
            {phoneError ? <Error>Please enter the valid phone number</Error> : ''}
          </LabelContainerPhone>
        </SubDetailContainer>
        <BottomButtonBox>
          <LeftButtonBox></LeftButtonBox>
          <RightButtonBox>
            <DiscardButton label={'Discard'} variant="contained" type="button" onClick={() => reset()} />
            <SaveButton
              disabled={phoneError}
              label={'Save'}
              variant="contained"
              type={phoneError ? 'button' : 'submit'}
            />
          </RightButtonBox>
        </BottomButtonBox>
      </Form>

      <Modal isOpen={isOpen}>
        <AddModal
          user={user}
          showModal={(value: boolean) => {
            if (!value) {
              fetchUser()
            }

            setisOpen(value)
          }}
          showAnotherModal={(value: boolean) => value}
        />
      </Modal>
      <Modal isOpen={isOpenWallet}>
        <LoginConnectWallet showModal={(value: boolean) => setIsOpenWallet(value)} />
      </Modal>
    </MainContainer>
  )
}

export default MyDetails
