import styled from 'styled-components'
import { theme } from 'theme/index'

export const Wrapper = styled.div`
  width: 100%;
`
export const MainContainer = styled.div`
  width: 100%;
`
export const TopSection = styled.div`
  width: 100%;
  max-width: 1600px;
  display: flex;
  padding-left: 24px;
  gap: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1440px) {
    max-width: 79vw;
  }
  @media (max-width: 1024px) {
    max-width: 72vw;
  }
  @media only screen and (max-width: 820px) {
    max-width: 84vw;
  }
`
export const TopMainConatiner = styled.div`
  width: 100%;
  max-width: 1600px;
  display: flex;
  overflow-x: auto;
`
export const BtcSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 185px;
  background: linear-gradient(180deg, #eff4ff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 8px 40px 29px -16px rgba(207, 207, 207, 0.5);
  border-radius: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  :hover {
    box-shadow: 8px 40px 80px -16px rgba(103, 146, 255, 0.5);
    border-radius: 40px;
    color: ${theme.info.light};
    background: linear-gradient(180deg, #4675f7 0%, #1b49cc 100%);
    .removeButton {
      color: #fff;
      background: #4473f5;
      border: 1px solid #fff;
    }
  }
  @media (max-width: 767px) {
    max-width: 36vw;
  }
  .removeButton {
    width: 100%;
    cursor: pointer;
    padding: 0px 20px;
    background: transparent;
    color: #4473f5;
    border: none;
    font-size: 14px;
    font-family: 'OutfitSemiBold';
    height: 50px;
    border: 1px solid #4473f5;
    border-radius: 16px;
  }
`
export const BitcoinImage = styled.img`
  padding: 27px 56px 19px 50px;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
`
export const CoinName = styled.div`
  font-family: 'OutfitBold';
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
`
export const CoinPrice = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
`
export const CruntPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #e60202;
`
export const PriceDown = styled.img`
  height: 12px;
`
export const ImageSection = styled.div`
  align-items: center;
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 150px;
  align-items: center;
  padding-bottom: 26px;
`
export const MiddleSection = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
`
export const RecentSection = styled.div`
  width: 100%;
  max-width: 600px;
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.3px;
  padding: 31px 54px 18px 35px;
  @media (max-width: 1366px) {
    padding: 31px 70px 18px 35px;
  }
  @media (max-width: 767px) {
    padding: 8.267vw 0 2.667vw 0;
  }
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 6px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 111.467vw;
    /* overflow-x: scroll; */
    .ant-table {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  table {
    width: 100%;
    padding: 6px;

    tr {
      border-bottom: 1px solid #e7e7e7;
    }
    th {
      background: none;
      text-align: center;
      font-family: 'OutfitBold';
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #737b7d;
      border: none;
    }
    td {
      font-family: 'OutfitMedium';
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #0a0c17;
      padding: 20px;

      :nth-child(1) {
        color: #2677e9;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :nth-child(5) {
        color: #2677e9;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      :nth-child(1) {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media (max-width: 1440px) {
        padding: 25px 10px;
        :nth-child(5) {
          color: #2677e9;

          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :nth-child(1) {
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 80px;
  padding: 21px 11px 21px 11px;
  text-align: center;
  @media (max-width: 1440px) {
    padding: 20px 3px 22px 3px;
  }
`
export const DefinitionSection = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 26px 37px 26px 10px;
  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 26px 10px 25px 10px;
  }
`
export const CryptoLogo = styled.img`
  height: 200px;
  width: 196px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
export const CopyContainer = styled.div`
  display: flex;
`
export const CoppyImage = styled.img`
  cursor: pointer;
  @media (max-width: 1280px) {
    height: 13px;
  }
`
