import React from 'react'
import Button from 'components/Button'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  ErrorDefination,
} from 'styles/components/Cards/AccountChangeError'
export interface IModalProps {
  success: boolean
  error?: any
  errorModal?: any
  switchError?: any
  sameAddError?: any
  showModal: (value: boolean) => void
}

const AccountChangeErrorClose: React.FC<IModalProps> = ({
  showModal,
  error,
  sameAddError,
  errorModal,
  switchError,
}) => {
  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} />
        </Container>
        <TextSection>
          <HeadingSection>{'Error'}</HeadingSection>
          <Defination>{error?.response?.data?.message}</Defination>
          <Defination>{sameAddError}</Defination>
          <ErrorDefination>{errorModal}</ErrorDefination>
          <ErrorDefination>{switchError}</ErrorDefination>

          <ButtonSection>
            <Button
              label="Close "
              variant="contained"
              type="button"
              onClick={() => {
                showModal(false)
              }}
            />
          </ButtonSection>
        </TextSection>
      </Wrapper>
    </MainContainer>
  )
}

export default AccountChangeErrorClose
