import React from 'react'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  Wrapper,
  ErrorDefination,
} from 'styles/components/Cards/AccountChangeError'
export interface IModalProps {
  success: boolean
  error?: any
  errorModal?: any
  switchError?: any
  sameAddError?: any
  showModal: (value: boolean) => void
}

const ErrorAccountChange: React.FC<IModalProps> = ({ error, sameAddError, errorModal, switchError }) => {
  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} />
        </Container>
        <TextSection>
          <HeadingSection>{'Error'}</HeadingSection>
          <Defination>{error?.response?.data?.message}</Defination>
          <Defination>{sameAddError}</Defination>
          <ErrorDefination>{errorModal}</ErrorDefination>
          <ErrorDefination>{switchError}</ErrorDefination>
        </TextSection>
      </Wrapper>
    </MainContainer>
  )
}

export default ErrorAccountChange
