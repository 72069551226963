import React, { useState, useEffect } from 'react'
import Modal from 'components/Modal'
import PaymentModal from 'components/Modal/PaymentModal'
import Paypalinfo from 'components/Modal/PaypalInfo'
import CommingSoon from 'components/Cards/CommingSoon'
import PlaidInfo from 'components/Modal/PlaidInfo'
import ApplePay from 'components/Modal/ApplePay'
import Signin from 'views/landing/SignIn'
import data from 'components/PaymentModal/data'
import PaymentCard from 'components/Cards/PaymentCard'
import CrossLogo from 'assets/images/grayx-logo.png'
import {
  MainContainer,
  Container,
  Wrapper,
  ImageSection,
  ModalHeading,
  MainWrapper,
} from 'styles/components/PaymentModal'
import useGet from 'hooks/useGet'
import { PlaidLink } from 'react-plaid-link'
import { MobileContainerText, MobileWalletSection, Title } from 'styles/components/Cards/PaymentCard'
import usePost from 'hooks/usePost'

export interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

const PaymentWalletModal: React.FC<IModalProps> = ({ showModal }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [isInfo, setInfoOpen] = useState(false)
  const [apple, setApple] = useState(false)
  const [isCommingsoonOpen, setCommingOpen] = useState(false)
  const [isPayInfo, setPayInfoOpen] = useState(false)
  const [image, setImage] = useState('')
  const { mutateAsync } = usePost()

  const { data: viewData, refetch: getViewDataRefetch } = useGet('createLinkTokenn', 'createLinkToken/api', false, {
    enabled: false,
    token: true,
  })

  useEffect(() => {
    getViewDataRefetch()
  }, [])

  const onSuccesst = (metadata: any) => {
    itemPublicToken(metadata?.public_token)
  }

  const itemPublicToken = async (public_token: any) => {
    const payload = {
      linkToken: public_token,
    }
    try {
      const response = await mutateAsync({
        url: 'itemPublicToken/api1',
        payload: payload,
        token: true,
      })
      itemAccessToken(response.accessToken)
      // return { error: false, verifyE }
    } catch (error: any) {
      return { error: true }
    }
  }

  const itemAccessToken = async (accessToken: any) => {
    const payload = {
      name: accessToken,
    }
    try {
      const verifyE = await mutateAsync({
        url: 'recipientId/api',
        payload: payload,
        token: true,
      })
      return verifyE
    } catch (error: any) {
      // return { error: true }
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <ModalHeading>Choose Payment Methodssss</ModalHeading>
          <ImageSection src={CrossLogo} onClick={() => showModal(false, 'option')} />
        </Container>
        <MainWrapper>
          {data?.map((item: any, index: number) => {
            if (item.title === 'Pay with Plaidd') {
              return (
                <PlaidLink
                  key={index}
                  clientName="Personal"
                  env="sandbox"
                  product={['auth', 'transactions']}
                  publicKey="632ab4457c40790014a9a0a8"
                  token={viewData?.linkToken}
                  onSuccess={onSuccesst}
                  style={{
                    padding: 'invalid',
                    borderRadius: 'invalid',
                    border: '0px',
                    background: 'transparent',
                  }}
                >
                  <MobileContainerText>
                    <MobileWalletSection src={item.image} />
                    <Title>Plaid</Title>
                  </MobileContainerText>
                </PlaidLink>
              )
            } else {
              return (
                <>
                  <PaymentCard
                    onClick={(value: boolean) => {
                      item.title === 'Paypal'
                        ? (setCommingOpen(value), setImage(item.image))
                        : item.title === 'Credit/Debit Card'
                        ? (setCommingOpen(value), setImage(item.image))
                        : item.title === 'Crypto Wallet'
                        ? setCommingOpen(value)
                        : item.title === 'Apple Pay'
                        ? setCommingOpen(value)
                        : item.title === 'Pay with Plaid'
                        ? (setCommingOpen(value), setImage(item.image))
                        : alert('Comming Soon')
                    }}
                    key={index}
                    image={item?.image}
                    title={item?.title}
                  />
                </>
              )
            }
          })}
        </MainWrapper>
      </Wrapper>
      <Modal isOpen={isOpen}>
        <PaymentModal showModal={(value: boolean) => setIsOpen(value)} image={image} />
      </Modal>
      <Modal isOpen={isClose}>
        <Paypalinfo showModal={(value: boolean) => setIsClose(value)} />
      </Modal>
      <Modal isOpen={isInfo}>
        <Signin showModal={(value: boolean) => setInfoOpen(value)} />
      </Modal>

      <Modal isOpen={apple}>
        <ApplePay showModal={(value: boolean) => setApple(value)} />
      </Modal>
      <Modal isOpen={isPayInfo}>
        <PlaidInfo showModal={(value: boolean) => setPayInfoOpen(value)} image={image} />
      </Modal>
      <Modal isOpen={isCommingsoonOpen}>
        <CommingSoon showModal={(value: boolean) => setCommingOpen(value)} />
      </Modal>
    </MainContainer>
  )
}

export default PaymentWalletModal
