import React from 'react'
import CancelSign from 'assets/images/vector-cancel.png'
import InfoIcon from 'assets/svg/Info'

import {
  StyledContainer,
  CancelSection,
  TextSection,
  Container,
  Wrapper,
  CrossSection,
  TopSection,
  TopRowSection,
} from 'styles/components/Modal/FaqModal'

const FaqSection: React.FC<any> = ({ showModal, allDetail }: any) => {
  return (
    <StyledContainer>
      <Container>
        <Wrapper>
          <CrossSection>
            <CancelSection src={CancelSign} onClick={() => showModal(false)} />
          </CrossSection>
          <div>
            <TopRowSection>
              <div>
                <InfoIcon className="LogoFaq" />
              </div>
              <TopSection>{allDetail?.panel}</TopSection>
            </TopRowSection>
            <TextSection>{allDetail?.description}</TextSection>
          </div>
        </Wrapper>
      </Container>
    </StyledContainer>
  )
}

export default FaqSection
