import { useState, useRef, useEffect, useContext } from 'react'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import BeneficaryNavBar from 'components/BeneficaryNavBar'
import { LoaderContext } from 'context/loader'
import useGet from 'hooks/useGet'

import {
  Wrapper,
  MainContainer,
  BalanceSection,
  TableContainer,
  DashbordContentSection,
  LeftBottomSection,
  FilterSection,
  RightSectionFilter,
  ImageWrapper,
  Minus,
  Plus,
  ValueSection,
  Section,
} from 'styles/views/dashboard/FiduciaryCryptoAssets'

interface ITokenData {
  token_address: string
  name: string
  symbol: string
  logo?: string | undefined
  thumbnail?: string | undefined
  decimals: number
  balance: string
  tokenPrice?: string | number
}

const BeneficiaryCryptoMobileAssets = () => {
  const [, setShow] = useState({ show: false, pos: null } as any)
  const { setLoader } = useContext(LoaderContext)
  const [tokenData, settokenData] = useState<Array<ITokenData>>([])
  const [, setFilter] = useState(false)
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const currentUser = Moralis.User.current()?.attributes?.accounts[0] || undefined

  const getAllTokensInWallet = async () => {
    const tokenData: ITokenData[] = await Moralis.Web3API.account.getTokenBalances({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: currentUser || '',
    })
    if (tokenData.length === 0) {
      setLoader(false)
    }

    if (tokenData.length > 0) {
      for (let i = 0; i < tokenData.length; i++) {
        try {
          const tokenPrice = await Web3Api.token.getTokenPrice({
            address: tokenData[i].token_address,
            chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
          })
          tokenData[i].tokenPrice = tokenPrice?.usdPrice.toPrecision(2)
        } catch {
          tokenData[i].tokenPrice = 'null'
        }
      }

      setLoader(false)
      settokenData(tokenData)
    }
  }

  const { data: beneficary, refetch: beneficaryRefetch } = useGet('Benificary', 'beneficary/getBeneficary', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    beneficaryRefetch()
  }, [])

  const name = beneficary?.user && beneficary?.user.length > 0 ? beneficary?.user[0].displayName : ''

  useEffect(() => {
    setLoader(true)
    getAllTokensInWallet()
  }, [])

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const containerRef = useRef(null)
  useOutsideContainer(containerRef)

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current?.contains(event?.target)) {
          setShow({ show: false, pos: '' })
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  function useOutsideContainer(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current?.contains(event?.target)) {
          setFilter(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  return (
    <Wrapper>
      <BeneficaryNavBar beneificaryName={name} />
      <Section>
        <MainContainer>
          <LeftBottomSection>
            <DashbordContentSection>
              <BalanceSection>My Crypto Assets </BalanceSection>
              <FilterSection>
                <RightSectionFilter></RightSectionFilter>
              </FilterSection>
              <TableContainer>
                <table>
                  <tr>
                    <th></th>

                    <th>Name</th>
                    <th>24 H</th>
                    <th>Price</th>
                    <th>Value</th>
                  </tr>
                  {tokenData.map((item: any, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <ImageWrapper src={item?.image} />
                        </td>
                        <td>{item?.name}</td>

                        <td>
                          <ValueSection>
                            {item?.price_change_24h?.toString()?.includes('-') ? (
                              <Minus>&#x2193;{item?.price_change_24h?.toFixed(2)}</Minus>
                            ) : (
                              <Plus>&#x2191;+{item?.price_change_24h?.toFixed(2)}</Plus>
                            )}
                          </ValueSection>
                        </td>
                        <td>{item?.current_price?.toFixed(1)}</td>
                        <td>{item?.price_change_24h?.toFixed(2)}</td>
                      </tr>
                    )
                  })}
                </table>
              </TableContainer>
            </DashbordContentSection>
          </LeftBottomSection>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default BeneficiaryCryptoMobileAssets
