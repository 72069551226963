import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.7 17.048v-2.673a4.025 4.025 0 0 0-4.025-4.025h-2.3a1.725 1.725 0 0 1-1.725-1.725V5.952a2.754 2.754 0 0 0 .802-4.454A2.76 2.76 0 0 0 8.74 3.45c0 1.112.662 2.065 1.61 2.502v2.673a1.725 1.725 0 0 1-1.725 1.725h-2.3A4.025 4.025 0 0 0 2.3 14.375v2.673a2.755 2.755 0 0 0 1.15 5.262 2.756 2.756 0 0 0 1.15-5.262v-2.673a1.725 1.725 0 0 1 1.725-1.725h2.3c.62 0 1.2-.152 1.725-.402v4.8a2.756 2.756 0 0 0 1.15 5.262 2.756 2.756 0 0 0 1.15-5.262v-4.8c.524.25 1.105.402 1.725.402h2.3a1.725 1.725 0 0 1 1.725 1.725v2.673a2.754 2.754 0 0 0-.802 4.454 2.76 2.76 0 0 0 4.712-1.952 2.755 2.755 0 0 0-1.61-2.502Zm-9.2-15.19a1.592 1.592 0 1 1 0 3.183 1.592 1.592 0 0 1 0-3.184ZM3.45 21.142a1.592 1.592 0 1 1 0-3.184 1.592 1.592 0 0 1 0 3.184Zm8.05 0a1.592 1.592 0 1 1 0-3.184 1.592 1.592 0 0 1 0 3.184Zm8.05 0a1.592 1.592 0 1 1 0-3.184 1.592 1.592 0 0 1 0 3.184Z"
      fill="#8C929B"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
