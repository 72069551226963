import Blockchain from 'assets/svg/Blockchain'
import KeyFeatureTree from 'assets/svg/KeyFeatureTree'
import Lock from 'assets/svg/Lock'

interface IData {
  id: number
  image: any
  title: any
  subtitle: string
}

const data: IData[] = [
  {
    id: 0,
    image: <Lock />,
    title: (
      <div style={{ display: 'grid', gap: '10px' }}>
        <div>Seamless & Secure</div>
        <div>Crypto Asset Transfers</div>
      </div>
    ),
    subtitle:
      'Maintain your security and privacy by never giving anyone access to your private keys, including us at Cryptoplan',
  },
  {
    id: 1,
    image: <Blockchain />,
    title: 'No Intermediaries',
    subtitle:
      'Execute trustless automated peer-to-peer transfer’s on the blockchain, without expensive legal fees and involvement',
  },
  {
    id: 2,
    image: <KeyFeatureTree />,
    title: 'Personalized Smart Contract Engine',
    subtitle: 'Customize & modify your crypto estate plan to include any family, friend, or charity',
  },
]

export default data
