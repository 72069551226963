import { ReactElement } from 'react'

interface IData {
  key: React.Key
  nickname: string
  appointedas: string
  totalamount: string
  note: ReactElement
}
//testß

interface IColumns {
  title: string
  dataIndex: string
}

export const columns = [
  {
    title: 'Nickname',
    dataIndex: 'nickname',
  },
  {
    title: 'Appointed As',
    dataIndex: 'appointedas',
  },
  {
    title: 'Total Allocation',
    dataIndex: 'totalamount',
  },
  {
    title: 'Note',
    dataIndex: 'note',
  },
]

export default { columns }
