import React from 'react'
import { SubTitle } from 'styles/components/SubHeading'
export interface ISubHeadingProps {
  label: string
}
const SubHeading: React.FC<ISubHeadingProps> = ({ label, ...rest }: ISubHeadingProps) => (
  <SubTitle {...rest}>{label}</SubTitle>
)

export default SubHeading
