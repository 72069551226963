import { useState, useRef, useEffect, useContext } from 'react'
import Table from 'components/Table'
import Action from 'views/dashboard/SmartContract/SmartContractContent/action'
import Stop from './stop'
import GenerateKey from 'views/dashboard/SmartContract/SmartContractContent/GenerateKey'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import Watermark from 'assets/images/watermark-image.png'
import { columns, columns2 } from 'views/dashboard/SmartContract/SmartContractContent/data'
import {
  TableWrapper,
  ContractWrapper,
  HeadWrapper,
  UpdatedContainer,
  HeadText,
  TextSection,
  MainStatusContainer,
  SuccessContainer,
  TextMessage,
  Image,
  NoDataContainer,
  HeadTextOne,
} from 'styles/views/dashboard/SmartContract/index'

const SmartContract = () => {
  const [toggle, setToggle] = useState(true)
  const [newtoggle, setNewtoggle] = useState(true)
  const isOpenSup = () => setToggle(true)
  const isClose = () => setToggle(false)
  const archiveOpen = () => setNewtoggle(true)
  const archiveClose = () => setNewtoggle(false)
  const [, setIsOpenSort] = useState(false)
  const { user } = useContext(UserContext)
  const { setLoader } = useContext(LoaderContext)
  const wrapperRef = useRef<any>()

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpenSort(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const { data: notification, isLoading: linkLoading } = useGet('get-notification', 'all/event/', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  const updateData = []
  const tableData = []
  let archivedAccount = 0
  const length = notification?.users?.eventHit.length

  const element = notification?.users?.eventHit

  for (let i = length - 1; i >= 0; i--) {
    const dateSplit = new Date(element[i]?.contractDate)?.toLocaleDateString('en-us', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    })

    if (element[i]?.isNotify === 'final' && archivedAccount === 0) {
      updateData.push({
        key: i,
        contractid: element[i]?.contractId.slice(0, 15),
        contractdate: dateSplit,
        generatekey: <GenerateKey eventId={element[i]?.eventId} />,
        totalbenificiaries: element[i]?.numberofBeneficiary,
        contract: <Stop />,
        status: (
          <MainStatusContainer>
            <SuccessContainer
              style={
                user?.status === 'Terminated'
                  ? { color: 'red', background: 'rgba(255, 233, 237, 0.47)' }
                  : { color: '#2677e9', background: '#E9F2FF' }
              }
            >
              {user?.status}
            </SuccessContainer>
          </MainStatusContainer>
        ),
        action: <Action eventId={element[i]?.eventId} />,
      })
      archivedAccount += 1
      continue
    }

    if (element[i]?.isNotify === 'final') {
      tableData.push({
        key: i,
        contractid: element[i]?.contractId,
        contractdate: dateSplit,
        fiduciary: element[i]?.nameofFiduciary,
        totalbenificiaries: element[i]?.numberofBeneficiary,

        action: <Action eventId={element[i]?.eventId} />,
      })
    }
  }

  return (
    <div>
      {notification?.users?.eventHit?.length ? (
        <div>
          <ContractWrapper>
            {notification?.users?.eventHit[length - 1] && (
              <UpdatedContainer>
                <HeadWrapper>
                  {toggle ? <DownOutlined onClick={isClose} /> : <RightOutlined onClick={isOpenSup} />}
                  <TextSection>
                    <HeadText>Updated Contract </HeadText>
                  </TextSection>
                </HeadWrapper>
                {toggle && (
                  <TableWrapper>
                    <Table data={updateData} columns={columns} />
                  </TableWrapper>
                )}
              </UpdatedContainer>
            )}

            {notification?.users?.eventHit?.length - 1 ? (
              <UpdatedContainer>
                <HeadWrapper>
                  {newtoggle ? <DownOutlined onClick={archiveClose} /> : <RightOutlined onClick={archiveOpen} />}
                  <TextSection>
                    <HeadTextOne>Archived</HeadTextOne>
                  </TextSection>
                </HeadWrapper>
                {newtoggle && (
                  <TableWrapper>
                    {tableData?.length ? (
                      <Table data={tableData} columns={columns2} />
                    ) : (
                      <>
                        {' '}
                        <NoDataContainer>
                          <div>
                            <Image src={Watermark} />
                          </div>
                          <TextMessage> No archived contract .</TextMessage>
                        </NoDataContainer>
                      </>
                    )}
                  </TableWrapper>
                )}
              </UpdatedContainer>
            ) : (
              <UpdatedContainer>
                <HeadWrapper>
                  {newtoggle ? <DownOutlined onClick={archiveClose} /> : <RightOutlined onClick={archiveOpen} />}
                  <TextSection>
                    <HeadText>Archived</HeadText>
                  </TextSection>
                </HeadWrapper>
                {newtoggle && (
                  <NoDataContainer>
                    <div>
                      <Image src={Watermark} />
                    </div>
                    <TextMessage>There is no archived contract.</TextMessage>
                  </NoDataContainer>
                )}
              </UpdatedContainer>
            )}
          </ContractWrapper>
        </div>
      ) : (
        <div>
          <NoDataContainer>
            <div>
              <Image src={Watermark} />
            </div>
            <TextMessage>Smart Contract not created yet</TextMessage>
          </NoDataContainer>
        </div>
      )}
    </div>
  )
}

export default SmartContract
