import React, { useState, useEffect, useContext } from 'react'
import { Select, Button } from 'antd'
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from 'components/FormElements/TextInput'
import { TempBeneficiaryDataContext } from 'context/tempBeneficiary'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import useDelete from 'hooks/useDelete'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'
import CircelImage from 'assets/images/circel-image.png'
import PluseImage from 'assets/images/pluse-image.png'
import CancelSign from 'assets/images/vector-cancel.png'

import {
  MainContainer,
  Container,
  DisplayName,
  InputSection,
  CountSection,
  LeftImage,
  RightImage,
  ButtonContainer,
  SelectContainer,
  Input,
  Error,
  PercentageContainer,
  Inputs,
  PerText,
  TotalScore,
  InputContainer,
  ErrorAppoint,
  AppointError,
  CancelSection,
  InfoText,
  TextSection,
  AllocationSectopn,
} from 'styles/views/dashboard/MyPlan/AddBeniFiduciaryMobile'

export interface IModalProps {
  showModal: (value: boolean) => void
  next?: () => void
  text?: string
  item?: any
  indexx?: number
  setTotalPercentage?: any
  updateable?: string
}
export interface IData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

const AddBenifiduciaryMobile: React.FC<IModalProps> = ({
  text,
  showModal,
  indexx,
  item,
  setTotalPercentage,
  updateable,
}) => {
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const beneficiaryData = beneficiariesData?.data?.beneficary
  const { tempBen, setTempBen } = useContext(TempBeneficiaryDataContext)

  const schema = yup.object().shape({
    beneficiaries: yup.array().of(
      yup.object().shape({
        displayName: yup
          .string()
          .required('Display name is required ')
          .matches(/^\S/, 'First character cannot be Space '),
        userWalletId: yup
          .string()
          .required('Wallet address is required ')
          .matches(/^\S+$/, 'Space is not allowed ')
          .matches(/^$|[a-zA-Z0-9_]$/, 'Special characters are not allowed ')
          .min(40, 'Wallet Address must be 40-46 characters')
          .max(46, 'Wallet Address must be 40-46 characters'),
        emailId: yup
          .string()
          .required('Email Id is required ')
          .email('Please enter a valid Email ')
          .matches(/^\S/, 'space is not allowed '),
        appointAs: yup.string().required('Please select one '),
      }),
    ),
  })

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      beneficiaries: [item],
    },
  })

  const allFields = useWatch({ control })
  const findError: any = formErrors
  const [sumPercentage, setSumPercentage] = useState(0)

  const { fields } = useFieldArray({
    control,
    name: 'beneficiaries',
  })
  const appoint = beneficiaryData?.some((item: { appointAs: string }) => item.appointAs === 'Fiduciary')
  const options = [
    { value: 'Fiduciary', label: 'Fiduciary' },
    { value: 'Beneficiary', label: 'Beneficiary' },
  ]

  const [isOpen, setIsOpen] = useState(false)

  const { setLoader } = useContext(LoaderContext)

  const { isError, error, isLoading } = usePost()

  const { isLoading: deleteIsLoading } = useDelete()

  const formData = async (values: any) => {
    const value = values?.beneficiaries[0]
    const prevValue = tempBen
    if (value.appointAs == 'Fiduciary') prevValue.map((obj: any) => obj.appointAs == 'Beneficiary')
    if (indexx != undefined)
      prevValue[indexx] = {
        displayName: value.displayName,
        userWalletId: value.userWalletId,
        emailId: value.emailId,
        appointAs: value.appointAs,
        amount: parseInt(value.amount),
      }

    setTempBen([...prevValue])
    setTotalPercentage((prev: any) => prev + parseInt(value.amount) - parseInt(item.amount))

    const filterData = values?.beneficiaries?.filter(
      (item: any) => !beneficiaryData.some((d: { userWalletId: string }) => d.userWalletId === item?.userWalletId),
    )
    filterData.forEach((data: any) => {
      data['amount'] = parseInt(data.amount)
      delete data.isActive
    })
    const data: any = []
    values?.beneficiaries?.map((item: any) => {
      const beneficiaryData = {
        amount: item.amount,
        userId: item.userId,
        emailId: item.emailId,
        appointAs: item.appointAs,
        displayName: item.displayName,
        userWalletId: item.userWalletId,
      }

      data.push(beneficiaryData)
    })
    showModal(false)
  }

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])
  useEffect(() => {
    if (isLoading || deleteIsLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, deleteIsLoading])

  const incrementFunction = (data: any, i: number) => {
    const val = 0
    const amount = typeof data[i]?.amount == 'string' ? parseInt(data[i]?.amount) : data[i]?.amount
    if (amount === 0) {
      setValue(`beneficiaries.${i}.amount`, val + 1)
    } else {
      setValue(`beneficiaries.${i}.amount`, amount + 1)
    }
  }
  const decrementFunction = (data: any, i: number) => {
    const amount = typeof data[i]?.amount == 'string' ? parseInt(data[i]?.amount) : data[i]?.amount
    if (amount === 0) {
      setValue(`beneficiaries.${i}.amount`, 0)
    } else {
      setValue(`beneficiaries.${i}.amount`, amount - 1)
    }
  }
  const appointFunction = (element: any, i: number) => {
    allFields?.beneficiaries?.forEach((data: any, index: number) => {
      if (element === 'Beneficiary') {
        setValue(`beneficiaries.${i}.appointAs`, 'Beneficiary')
      } else if ((element === 'Fiduciary' || data.appointAs.value === 'Fiduciary') && index !== i) {
        setValue(`beneficiaries.${index}.appointAs`, 'Beneficiary')
      } else {
        setValue(`beneficiaries.${index}.appointAs`, 'Fiduciary')
      }
    })
  }

  useEffect(() => {
    let temp = 0

    allFields.beneficiaries?.forEach((item: any) => {
      if (typeof item?.amount == 'string') {
        temp += parseInt(item?.amount) || 0
      } else {
        temp += item?.amount || 0
      }
    })

    setSumPercentage(temp)
  }, [allFields])

  const disabled = sumPercentage > 100

  const disableFields = text !== 'Save'

  return (
    <MainContainer>
      <Container>
        <InfoText>
          <TextSection>{indexx != undefined ? tempBen[indexx].appointAs : 'Appoint'}</TextSection>
          <CancelSection src={CancelSign} onClick={() => showModal(false)} />
        </InfoText>

        <form onSubmit={handleSubmit(formData)}>
          <Inputs>
            {fields.map((item: any, index: number) => {
              const foundData = beneficiaryData.some(
                (d: { userWalletId: string }) => d.userWalletId === item?.userWalletId,
              )

              return (
                <InputSection key={item.id}>
                  <InputContainer>
                    <DisplayName>Display Name</DisplayName>
                    <TextInput
                      name={`beneficiaries.${index}.displayName`}
                      className="text"
                      placeholder="Nickname"
                      value={item?.displayName}
                      control={control}
                      autoComplete="off"
                      disabled={updateable ? false : foundData ? true : false}
                    />

                    <Error>{findError?.beneficiaries?.[index]?.displayName?.message}</Error>
                  </InputContainer>
                  <InputContainer>
                    <DisplayName>Wallet Address</DisplayName>
                    <TextInput
                      name={`beneficiaries.${index}.userWalletId`}
                      className="text"
                      placeholder="Wallet Address "
                      control={control}
                      autoComplete="off"
                      disabled={updateable ? false : foundData ? true : false}
                    />
                    <Error>{findError?.beneficiaries?.[index]?.userWalletId?.message}</Error>
                  </InputContainer>
                  <InputContainer>
                    <DisplayName>Email Address</DisplayName>
                    <TextInput
                      name={`beneficiaries.${index}.emailId`}
                      className="text"
                      placeholder="Email "
                      control={control}
                      autoComplete="off"
                      disabled={updateable ? false : foundData ? true : false}
                    />
                    <Error>{findError?.beneficiaries?.[index]?.emailId?.message}</Error>
                  </InputContainer>
                  <InputContainer>
                    <DisplayName>Appoint As</DisplayName>
                    <SelectContainer>
                      <Controller
                        control={control}
                        name={`beneficiaries.${index}.appointAs`}
                        render={({ field: { value } }) => (
                          <Select
                            options={appoint && disableFields ? options : options}
                            placeholder="Appointed As"
                            value={value}
                            onChange={(val: any) => appointFunction(val, index)}
                            disabled={updateable ? false : foundData ? true : false}
                          />
                        )}
                      />

                      <ErrorAppoint>{findError?.beneficiaries?.[index]?.appointAs?.message}</ErrorAppoint>
                    </SelectContainer>
                    {false && getValues().beneficiaries?.[index]?.appointAs?.[index] && (
                      <AppointError>Please Select One Fiduciary</AppointError>
                    )}
                  </InputContainer>
                  <AllocationSectopn>
                    <DisplayName>Allocation</DisplayName>
                    <CountSection>
                      {!foundData && (
                        <LeftImage
                          src={CircelImage}
                          onClick={() => {
                            decrementFunction(allFields.beneficiaries, index)
                          }}
                        />
                      )}
                      <Input
                        className="input"
                        name={`beneficiaries.${index}.amount`}
                        value={item?.amount}
                        control={control}
                        type="number"
                      />

                      {!foundData && (
                        <RightImage
                          src={PluseImage}
                          onClick={() => incrementFunction(allFields.beneficiaries, index)}
                        />
                      )}
                    </CountSection>
                  </AllocationSectopn>
                </InputSection>
              )
            })}
          </Inputs>
          <PercentageContainer>
            <PerText>Total Percentage </PerText>

            <TotalScore>
              <div>
                <div>{sumPercentage}%</div>
              </div>
              <div>
                <Error>{sumPercentage > 100 && <div>{'(Total cannot be more than 100%)'}</div>}</Error>
              </div>
            </TotalScore>
          </PercentageContainer>
          <ButtonContainer>
            <Button disabled={disabled} type="primary" htmlType="submit" className="next-button">
              save
            </Button>
          </ButtonContainer>
        </form>
      </Container>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} error={error} success={false} />
      </Modal>
    </MainContainer>
  )
}

export default AddBenifiduciaryMobile
