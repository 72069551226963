import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { Slider } from 'antd'
import { UserContext } from 'context/user'
import { useMoralisWeb3Api } from 'react-moralis'
import Moralis from 'moralis'
import CreatableSelect from 'react-select/creatable'
import data from 'components/MyProfile/data'
import SearchInput from 'components/Search'
import useGet from 'hooks/useGet'
import WalletSection from 'assets/images/dashboard-wallet.png'
import Short from 'assets/images/short-image.png'
import Filter from 'assets/images/filter-image.png'
import resetImage from 'assets/images/reset-green.png'
import Watermark from 'assets/images/watermark-image.png'
import WalletPortfolioSection from 'assets/images/mobile-PortfolioWallet.png'
import DefaultImage from 'assets/images/crypto-currencies.png'
import { ethers } from 'ethers'
import moment from 'moment'

import {
  Wrapper,
  MainContainer,
  BalanceSection,
  BalanceDetails,
  WalletDetails,
  BalanceImageSection,
  WalletImageSection,
  Details,
  MoneyDetails,
  MainBalanceSection,
  TableContainer,
  DashbordContentSection,
  LeftBottomSection,
  FilterSection,
  DropSection,
  LeftSectionFilter,
  RightSectionFilter,
  DropImage,
  NetworkText,
  FilterBy,
  FilterImage,
  RightDrop,
  FilterContainer,
  ValueText,
  RestFilter,
  ResetImage,
  TextReset,
  SortRows,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
} from 'styles/components/MyProfile'

interface ITokenData {
  name: string
  balance: string
}
interface ITokenPriceData {
  usdPrice: number
}

interface IEventProp {
  target: { value: string }
  e: React.ChangeEvent<HTMLInputElement>
}

const MobileMyProfile = () => {
  const [, setTableData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [uniqueTokens, setUniqueTokens] = useState<ITokenData[]>([])
  const [assetTokenPrice, setAssetTokenPrice] = useState<ITokenPriceData[]>([])
  const [talDifference, setTalDifference] = useState<any>([])
  const [totalAssetValue, setTotalAssetValue] = useState<any>()
  const [, setTokensData] = useState<ITokenData[]>([])
  const [isDivOpen, setIsDivOpen] = useState(false)
  const filterRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const balanceRef = useRef<any>()
  const { user } = useContext(UserContext)
  const linkedAddresses = user?.userWallet
  const Web3Api = useMoralisWeb3Api()
  const [walletNativeBalance, setWalletNativeBalance] = useState<string>('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState<ITokenPriceData[]>([])
  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''
  const dex_exchange = process.env.REACT_APP_DEX || ''

  const { refetch: fetchDetails } = useGet(
    'wallet-detail',
    'api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false/',
    true,
  )

  const filterArray = [10, 20, 50, 100]

  const fetchNativeBalance = async () => {
    let totalBalance = 0
    const element = user?.userWallet
    for (let i = 0; i < user?.userWallet.length; i++) {
      const { balance } = await Web3Api.account.getNativeBalance({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: element[i]?.walletId,
      })
      totalBalance = totalBalance + Number(balance)
    }

    setWalletNativeBalance(Moralis.Units.FromWei(totalBalance.toString(), 18))
  }

  const getWalletData = async () => {
    const response = await fetchDetails()
    const { data } = response
    const filteredData = data.slice(0, 5)
    setTableData(filteredData)
  }

  const fetchTokenPrice = async () => {
    const tokenPrice = await Web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      exchange: dex_exchange,
    })

    const nativePriceUSD: any = tokenPrice?.usdPrice
    setNativeTokenPrice(nativePriceUSD)
  }

  const { data: getAsset, refetch: getAssetRefetch } = useGet('get-Asset', 'asset/getAsset', false, {
    enabled: false,
    token: true,
  })

  const asset = getAsset?.user?.userWallet
  const getAssetdata: any = []

  for (let i = 0; i < asset?.length; i++) {
    const element = asset[i]

    const assetData = element?.assests

    for (let j = 0; j < assetData?.length; j++) {
      const item = assetData[j]

      getAssetdata.push(item)
    }
  }

  const fetchAssetPriceBalance = async () => {
    const tokens: any[] = []

    for (let iterator = 0; iterator < linkedAddresses.length; iterator++) {
      const tokenbalances: any[] = await Web3Api.account.getTokenBalances({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: linkedAddresses[iterator].walletId, //this holds internally linked address of MM
      })
      tokens.push(...tokenbalances)
      if (tokens != undefined) setTokensData(tokens)
    }

    const uniqueTokens = [
      ...tokens
        .reduce((map, current) => {
          const { token_address } = current
          const grouped = map.get(token_address)
          if (!grouped) {
            map.set(token_address, { ...current })
          } else {
            map.set(token_address, {
              ...grouped,
              balance: (Number(grouped.balance) + Number(current.balance)).toLocaleString('fullwide', {
                useGrouping: false,
              }),
            })
          }

          return map
        }, new Map())
        .values(),
    ]
    setUniqueTokens(uniqueTokens)

    //Get asset token price
    const getAssetsPrice: any = []
    let totalassetsvalue: any = 0
    for (let iterator = 0; iterator < uniqueTokens.length; iterator++) {
      const assetPrice = await Web3Api.token.getTokenPrice({
        address: uniqueTokens[iterator]?.token_address,
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      })

      const assetPriceUSD: any = assetPrice?.usdPrice
      totalassetsvalue =
        totalassetsvalue + assetPriceUSD * Number(ethers.utils.formatEther(uniqueTokens[iterator].balance))
      getAssetsPrice?.push(assetPriceUSD)
    }

    setTotalAssetValue(totalassetsvalue)
    if (getAssetsPrice != undefined) setAssetTokenPrice(getAssetsPrice)
  }

  const intersectionOfAssets = uniqueTokens.filter((a: any) =>
    getAssetdata.some((b: { tokenAddress: string }) => a.token_address === b.tokenAddress),
  )

  const filteredItems = intersectionOfAssets.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase()),
  )

  const dataWithToken = filteredItems
    ?.map((item: any) => {
      return {
        ...item,
        token_balance:
          item.decimals == 6
            ? Number(Moralis.Units.FromWei(item.balance, 6))
            : Number(Moralis.Units.FromWei(item.balance, 18)),
      }
    })
    ?.filter((item: any) => {
      return item.token_balance <= balanceRef.current
    })

  const updatingTally = async () => {
    const assetArray: any = []
    const assetOfCurrentUser = getAsset?.user?.userWallet || ''
    let assetCounter = 0
    for (let iterator = 0; iterator < assetOfCurrentUser.length; iterator++) {
      const counterStop = (assetOfCurrentUser[iterator]?.assests).length
      assetOfCurrentUser[iterator].assests.forEach(async (element: any) => {
        const currentAssetData = await changeInPrice(element.tokenAddress).catch(() => {
          new Error('Error in fetching token information')
        })
        assetArray.push(currentAssetData)
        assetCounter = assetCounter + 1
        if (currentAssetData !== 'undefined' && assetCounter === counterStop) {
          setTalDifference(assetArray)
        }
      })
    }
  }

  async function changeInPrice(address: string) {
    //Getting block from date
    const date = await Web3Api.native.getDateToBlock({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      date: moment().subtract(1, 'days').toString(),
    })

    //Getting the price with particular Block Number
    const price24hAgo = await Web3Api.token.getTokenPrice({
      address: address,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toBlock: date.block,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    //Getting the current price
    const currentPrice = await Web3Api.token.getTokenPrice({
      address: address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    const netChangedData = (((currentPrice?.usdPrice - price24hAgo?.usdPrice) / price24hAgo?.usdPrice) * 100).toFixed(2)
    return netChangedData
  }

  const handleClickOutside = useCallback((event: { target: any }) => {
    if (filterRef.current && !filterRef.current?.contains(event?.target)) {
      setIsDivOpen(false)
    }
  }, [])

  const handleClickOutsideDiv = useCallback((event: { target: any }) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    getAssetRefetch()
  }, [getAsset])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && user?.userWalletId) fetchTokenPrice()
  }, [user])

  useEffect(() => {
    if (user?.userWalletId) fetchNativeBalance()
  }, [user])
  useEffect(() => {
    balanceRef.current = 100
    getWalletData()
    fetchAssetPriceBalance()
  }, [])

  useEffect(() => {
    getAssetRefetch()
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && getAsset) {
      updatingTally()
      const priceInterval = setInterval(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') updatingTally()
      }, 25000)
      return () => clearInterval(priceInterval)
    }
  }, [getAsset])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('mousedown', handleClickOutsideDiv)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('mousedown', handleClickOutsideDiv)
    }
  }, [handleClickOutside, handleClickOutsideDiv])

  return (
    <Wrapper>
      <MainContainer>
        <LeftBottomSection>
          <DashbordContentSection>
            <BalanceSection>
              <BalanceDetails>
                <BalanceImageSection src={WalletPortfolioSection} />
                <MainBalanceSection>
                  <Details>Native Token(s) Worth</Details>
                  {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                    <MoneyDetails>
                      {isNaN(Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                        ? '↺'
                        : (Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                            ?.toLocaleString()
                            .substring(0, 9)}
                    </MoneyDetails>
                  ) : (
                    <MoneyDetails>
                      {isNaN(Number(walletNativeBalance))
                        ? '↺'
                        : Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
                    </MoneyDetails>
                  )}
                </MainBalanceSection>
              </BalanceDetails>
              <WalletDetails>
                <WalletImageSection src={WalletSection} />
                <MainBalanceSection>
                  <Details>Wallets Connected</Details>
                  <MoneyDetails>{linkedAddresses.length}</MoneyDetails>
                </MainBalanceSection>
              </WalletDetails>
            </BalanceSection>
            {getAssetdata.length ? (
              <div>
                <FilterSection>
                  <LeftSectionFilter>
                    <DropSection>
                      <CreatableSelect
                        isClearable
                        isMulti
                        className="multi_select"
                        options={data}
                        placeholder="Networks"
                      />
                    </DropSection>
                  </LeftSectionFilter>
                  <RightSectionFilter>
                    <SearchInput
                      placeholder="Search Crypto Assets"
                      onChange={(e: IEventProp) => setSearchValue(e.target.value)}
                    />
                    <RightDrop onClick={() => setIsOpen(!isOpen)} ref={wrapperRef}>
                      <NetworkText> {balanceRef?.current || 0}</NetworkText>
                      <DropImage src={Short} />
                      {isOpen && (
                        <SortRows>
                          {filterArray?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                balanceRef.current = item
                                setIsOpen(!isOpen)
                              }}
                            >
                              {item}
                            </div>
                          ))}
                        </SortRows>
                      )}
                    </RightDrop>
                    <FilterBy onClick={() => setIsDivOpen(!isDivOpen)} ref={filterRef}>
                      <FilterImage src={Filter} />
                      Filter
                    </FilterBy>
                    {isDivOpen && (
                      <FilterContainer>
                        <RestFilter>
                          <ResetImage src={resetImage} />
                          <TextReset>Reset Filter</TextReset>
                        </RestFilter>
                        <ValueText>Value</ValueText>
                        <Slider range={{ draggableTrack: true }} />
                      </FilterContainer>
                    )}
                  </RightSectionFilter>
                </FilterSection>
                <TableContainer>
                  <table>
                    <tr>
                      <th></th>
                      {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>Logo</th> : ''}
                      <th>Name</th>
                      <th>24 H</th>
                      <th>Price in $ (DEX)</th>
                      <th>Token Balance</th>
                      <th>Value in $ (≈)</th>
                    </tr>

                    {getAssetdata.length ? (
                      dataWithToken.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="image"></td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                <CryptoLogo
                                  className="minified"
                                  src={getAssetdata[index].logo === undefined ? DefaultImage : getAssetdata[index].logo}
                                />
                              </td>
                            ) : (
                              ''
                            )}
                            <td>{item.name}</td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                <div>
                                  {talDifference.length == 0 || talDifference[index] === undefined
                                    ? '↺'
                                    : talDifference[index] + '%'}
                                </div>
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                {Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4).length == 0 ||
                                isNaN(Number(assetTokenPrice[index]))
                                  ? '↺'
                                  : Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4) || 'NA'}
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                            <td>{item.token_balance}</td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                {(Number(assetTokenPrice[index]) * Number(item.balance))
                                  ?.toLocaleString()
                                  .substring(0, 4).length == 0 ||
                                isNaN(Number(assetTokenPrice[index]) * Number(item.balance))
                                  ? '↺'
                                  : (Number(assetTokenPrice[index]) * Number(item.balance))
                                      ?.toLocaleString()
                                      .substring(0, 4) || 'NA'}
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td className="center" colSpan={5}>
                          <NoBeneficiaries>
                            <BeneficiarieImage>
                              <CryptoLogo src={Watermark} />
                            </BeneficiarieImage>
                            <TextSextion>
                              <HeadingSection>No Assets Available</HeadingSection>
                            </TextSextion>
                          </NoBeneficiaries>
                        </td>
                      </tr>
                    )}
                    {getAssetdata.length && filteredItems.length == 0 && (
                      <tr>
                        <td>Loading..</td>
                      </tr>
                    )}
                  </table>
                </TableContainer>
              </div>
            ) : (
              <TableContainer>
                <table>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>24 H</th>
                    <th>Price</th>
                    <th>Token Balance</th>
                    <th>Value</th>
                    <th>Allocation</th>
                  </tr>
                  <tr>
                    <td className="center" colSpan={8}>
                      <NoBeneficiaries>
                        <BeneficiarieImage>
                          <CryptoLogo src={Watermark} />
                        </BeneficiarieImage>
                        <TextSextion>
                          <HeadingSection>No Assets Available</HeadingSection>
                          <DefinitionSection></DefinitionSection>
                        </TextSextion>
                      </NoBeneficiaries>
                    </td>
                  </tr>
                </table>
              </TableContainer>
            )}
          </DashbordContentSection>
        </LeftBottomSection>
      </MainContainer>
    </Wrapper>
  )
}

export default MobileMyProfile
