import React from 'react'
import { Table } from 'antd'
interface ITableProps {
  data: Array<IData>
  columns: Array<IColumns>
  onFilter?: () => void
}
export interface IStatus {
  title: string
}
interface IData {
  key: React.Key
  ConnectionName?: string
  MobileNumber?: number
  DateCreated?: string
}
interface IColumns {
  title: string
  dataIndex: string
}

const TableComponent: React.FC<ITableProps> = ({ data, columns, onFilter }: ITableProps) => (
  <Table columns={columns} dataSource={data} onChange={onFilter} />
)

export default TableComponent
