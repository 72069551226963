import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from 'App'
import 'antd/dist/antd.css'
import 'assets/fonts/Outfit-SemiBold.ttf'
import 'assets/fonts/Outfit-Regular.ttf'
import 'assets/fonts/Outfit-Medium.ttf'
import 'assets/fonts/Outfit-Bold.ttf'
import 'assets/fonts/Outfit-ExtraLight.ttf'
import 'assets/fonts/Outfit-Light.ttf'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,

  document.getElementById('root'),
)
