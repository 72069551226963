import styled from 'styled-components'
export const MainContainer = styled.div``
export const Container = styled.div`
  font-family: 'OutfitMedium';
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0a0c17;
`
export const Email = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const EmailInformation = styled.div`
  display: flex;
  gap: 10px;
`
export const EmailCoppyImage = styled.img`
  height: 15px;
  cursor: pointer;
`
