import styled from 'styled-components'
import { motion } from 'framer-motion'

export const RoadMap = styled.div`
  display: flex;
  width: 100%;
`
export const BlankSection = styled.div`
  min-height: 500px;
  width: 25%;
`
export const AddWalletSection = styled.div`
  min-height: 500px;
  width: 25%;
`
export const SmartContractSection = styled.div`
  min-height: 500px;
  width: 25%;
`
export const WorkFrame = styled.div`
  display: flex;
  width: 100%;
  padding-top: 100px;
`
export const AddBeneficiariesSection = styled.div`
  min-height: 500px;
  width: 25%;
`
export const DisbursementSection = styled.div`
  min-height: 500px;
  width: 100%;
  width: 32%;
`
export const BlankFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  position: relative;
`
export const BlankSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  border-top: 1px solid #44c1f7;
`
export const AddWalletFirstSection = styled.div`
  min-height: 250px;
  border-top-right-radius: 40px;
  width: 100%;
  border-top-left-radius: 40px;
  border-left: 1px solid #44c1f7;
  border-top: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  font-family: 'Outfit';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  position: relative;
`
export const AddWalletSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  margin-left: 1px;
`
export const SmartFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  position: relative;
`
export const SmartSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #44c1f7;
  border-left: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
`

export const BorderHider = styled.div`
  width: 100px;
  height: 80px;
  background-color: #04205b;
  position: absolute;
  right: -50px;
  bottom: -4.2vw;
`
export const BeneficiarFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  border-left: 1px solid #44c1f7;
  border-top: 1px solid #44c1f7;
  border-right: 1px solid #44c1f7;
  position: relative;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  font-family: 'Outfit';
  font-size: 18px;
  line-height: 23px;
  text-align: center;
`
export const BeneficiarSecondSection = styled.div`
  min-height: 180px;
  width: 100%;
`
export const DisbursFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
`
export const DisbursSecondSection = styled.div`
  min-height: 180px;
  position: relative;
  right: 2px;
  border-top: 1px solid #44c1f7;
  border-bottom-left-radius: 0px;
  transform: translate(0px, -26px);
`

export const LastBlankSection = styled.div`
  min-height: 500px;
  width: 100%;
`
export const LastFirstSection = styled.div`
  min-height: 250px;
  width: 100%;
  border-bottom: 1px solid #44c1f7;
`
export const Label = styled.div`
  font-family: 'OutfitBold';
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 40px;
  color: #fdfeff;
`
export const FileImageContainer = styled(motion.img)`
  position: absolute;
  bottom: -80px;
  right: -90px;
  z-index: 1;
  @media (max-width: 1350px) {
    width: 150px;
    right: -75px;
  }
`
