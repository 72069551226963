import React, { useState, useRef, useEffect, useContext } from 'react'
import data from 'components/BurgerSidebar/data'
import SidebarCard from 'components/Cards/SideBar'
import Modal from 'components/Modal'
import { UserContext } from 'context/user'
import { useNavigate, useLocation } from 'react-router-dom'
import CommingSoon from 'components/Cards/CommingSoon'
import HeaderLogo from 'assets/images/mobile-headernew.png'
import { DashboardRoute } from 'constants/routes'
import {
  MainContainer,
  Container,
  Wrapper,
  GrantorView,
  ButtonContainers,
  ViewSelectOption,
  GrantorSection,
  NameText,
  GrantorAdress,
  Dropaheader,
  LogoSection,
  BurgerHeader,
} from 'styles/components/BurgerSidebar'

interface IShowModalProps {
  (value: boolean, type: string): void
}

interface IHeaderProps {
  showModal?: IShowModalProps
}

export const BurgerSidebar = ({ showModal }: IHeaderProps) => {
  const wrapperRef = useRef<any>()
  const navigate = useNavigate()
  const viewRef = useRef<any>()
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, viewRef])

  return (
    <MainContainer ref={wrapperRef}>
      <Container>
        <BurgerHeader>
          <Dropaheader>
            <LogoSection
              src={HeaderLogo}
              onClick={() => {
                if (location.pathname === '/dashboard' && typeof showModal === 'function') {
                  showModal(false, 'option')
                } else navigate(DashboardRoute.path)
              }}
            />
          </Dropaheader>
          <GrantorView>
            <ButtonContainers>
              <ViewSelectOption>
                <GrantorSection>
                  <NameText>Grantor’s View</NameText>
                  <GrantorAdress>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
                    user?.userWalletId?.length - 4,
                  )}`}</GrantorAdress>
                </GrantorSection>
              </ViewSelectOption>
            </ButtonContainers>
          </GrantorView>
        </BurgerHeader>
        <Wrapper>
          {data?.map((item) => (
            <SidebarCard
              key={item.id}
              image={item?.icon}
              title={item?.title}
              path={item?.path}
              showModal={(value: boolean) => setIsOpen(value)}
            />
          ))}
        </Wrapper>
        <Modal isOpen={isOpen}>
          <CommingSoon showModal={(value: boolean) => setIsOpen(value)} />
        </Modal>
      </Container>
    </MainContainer>
  )
}

export default BurgerSidebar
