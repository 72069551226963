import styled from 'styled-components'
import Button from 'components/Button'
import TextInput from 'components/FormElements/TextInput'
import TextArea from 'components/FormElements/TextArea'
import { theme } from 'theme'

export const Container = styled.div`
  width: 100%;
`

export const InputContainer = styled(TextInput)`
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding-left: 10px;
  font-size: 15px;
  padding: 22px;
  border-radius: 4px;
  border: none;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  @media (max-width: 1280px) {
    padding-top: 15px !important;
  }
  @media (max-width: 1366px) {
    padding-top: 23px;
  }

  @media (max-width: 767px) {
    padding-top: 2.667vw;
    gap: 10.133vw;
  }
`
export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: center;
`
export const ContactusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ContactusInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 585px;
  width: 100%;
  .form-field {
    background: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.201228);
    border-radius: 4px;

    ::placeholder {
      font-size: 14px;
      color: #dfdfde;
    }
    :focus {
      border: 1px solid rgba(0, 0, 0, 0.201228);
      outline: none;
      box-shadow: none;
    }
    :hover ::after {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.333vw;
    display: grid;
    align-items: center;
    gap: 9.333vw;
  }
`
export const InfoSectionFirst = styled.div`
  font-family: 'OutfitMedium';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #4473f5;
`
export const InfoSectionSecond = styled.div`
  font-family: 'OutfitMedium';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: ${theme.info.light};
`
export const Form = styled.form`
  display: grid;
  gap: 17px;
`
export const RightButton = styled(Button)`
  height: 62px;
`
export const ErrorText = styled.div`
  color: red;
  font-size: 15px;
  padding-left: 10px;
`
export const TextSection = styled.div`
  .ant-form-item {
    margin: 0 0 2px;
  }
`
export const TextAreaContainer = styled(TextArea)`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.201228);
  border-radius: 4px;
  width: 100%;
  max-width: 700px;
  height: 144px;
  color: #fff;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  outline: none;
  padding: 15px 20px;
  ::placeholder {
    color: #dfdfde;
    font-size: 14px;
  }
`
