import styled from 'styled-components'
import { theme } from 'theme'
export const Title = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 74px;
  color: ${theme.info.light};
  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 767px) {
    font-family: 'OutfitBold';
    font-size: 8.533vw;
    color: #ffffff;
    width: 100%;
    max-width: 77.933vw;
    padding-top: 0px;
    text-align: left;
  }
`
