import React, { useContext } from 'react'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Wrapper,
  Definition2,
} from 'styles/components/Cards/Warning'
import { UserContext } from 'context/user'
import { ButtonSection } from 'styles/views/landing/PlanFuture'
import { CancelButton } from 'styles/pages/WelcomePlan'
export interface IModalProps {
  success: boolean
  info?: any
  showModal: (value: boolean) => void
}

const InfoModal: React.FC<IModalProps> = ({ showModal, info }) => {
  const { user } = useContext(UserContext)
  info = `Account Switched from (${user?.userWalletId?.substring(0, 5)}
  ..${user?.userWalletId?.substring(user?.userWalletId?.length - 4)})
  To link, click on Signature Request from your Wallet, sign it, or else click on cancel to stop.`
  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} />
        </Container>
        <TextSection>
          <HeadingSection>{''}</HeadingSection>⚠<Definition2>{info}</Definition2>
        </TextSection>
        <ButtonSection>
          <CancelButton label="Close" variant="contained" type="button" onClick={() => showModal(false)} />
        </ButtonSection>
      </Wrapper>
    </MainContainer>
  )
}

export default InfoModal
