import React, { useState } from 'react'
import MobileMyProfile from 'components/MobileMyProfile'
import MyWallet from 'views/dashboard/MyWallet'
import Button from 'components/Button'
import { Tabs } from 'antd'
import NavBar from 'components/NavBar'
import Signin from 'views/landing/SignIn'
import Modal from 'components/Modal'

import {
  Wrapper,
  MainContainer,
  Container,
  Section,
  TopSection,
  TextSection,
  ProfileText,
  ProfileDeff,
  ButtonWrapper,
  TableSection,
} from 'styles/views/dashboard/portfolio'

const { TabPane } = Tabs
const ontab = () => {
  // abc
}

const MobilePortfolio = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper>
      <NavBar />
      <Section>
        <MainContainer>
          <Container>
            <TopSection>
              <TextSection>
                <ProfileText>Portfolio </ProfileText>
                <ProfileDeff></ProfileDeff>
              </TextSection>

              <ButtonWrapper onClick={() => setIsOpen(true)}>
                <Button label="Add Wallet" variant="contained" type="submit" />
              </ButtonWrapper>
            </TopSection>
            <TableSection>
              <Tabs defaultActiveKey="1" onChange={ontab}>
                <TabPane tab="My Portfolio" key="1">
                  <MobileMyProfile />
                </TabPane>
                <TabPane tab="My Wallets" key="2">
                  <MyWallet />
                </TabPane>
              </Tabs>
            </TableSection>
          </Container>
        </MainContainer>
      </Section>
      <Modal isOpen={isOpen}>
        <Signin showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Wrapper>
  )
}

export default MobilePortfolio
