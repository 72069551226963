import styled from 'styled-components'

export const BurgerLogo = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 19px;
  width: 100%;
  position: sticky;
  z-index: 1;
  > img {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    padding: 4vw 5.067vw;
  }
`

export const LogoSection = styled.img`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 369px;
  gap: 22px;
  width: 100%;
  @media (max-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    justify-content: flex-end;
    gap: 10px;
  }
`
export const ThemeIcon = styled.img`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    width: 43px;
    height: 43px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
  max-width: 168px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
  border-radius: 50px;
  padding: 5px;
  justify-content: flex-start;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: auto;
    max-width: 177px;
  }
`
export const ImageSection = styled.img`
  height: 24px;
  cursor: pointer;
`
export const RightImage = styled.img`
  height: 8px;
  cursor: pointer;
`
export const SelectOption = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
`
export const NameText = styled.div`
  min-width: 63px;
  width: 100%;
  font-family: 'OutfitSemiBold';
  font-size: 12px;
  line-height: 22px;
  text-align: initial;
  letter-spacing: 0.3px;
  color: #000000;
`
export const ButtonContainers = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const ViewSelectOption = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid #d9d9d9;
`
export const AllViewSection = styled.div``
export const GrantorAdress = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 24px;
  color: #2677e9;
`
export const GrantorSection = styled.div`
  display: flex;
  flex-direction: column;
`
