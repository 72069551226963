import styled from 'styled-components'
export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 27px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 93.867vw;
  }
`
export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 34px;
  min-height: 400px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const Wrapper = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.3px;
`
export const ButtonSection = styled.div`
  display: flex;
  gap: 20px;
`
export const WrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 0;
  align-items: center;
  @media (max-width: 1366px) {
    padding: 10px 10px 10px 10px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 2.667vw;
  }
`
export const RadioGroup = styled.div`
  display: grid;

  @media (max-width: 1366px) {
    gap: 10px;
  }
`
export const BlankSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const ButtonWrapper = styled.div`
  width: 100%;
`
export const ButtonContainer = styled.div`
  display: flex;
  max-width: 350px;
  gap: 15px;
  margin-left: auto;
  padding: 38px 35px 50px 58px;
`
export const ButtonLable = styled.div`
  display: flex;
  text-align: center;
  gap: 7px;
  align-items: center;
`
export const RightButton = styled.div``
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LeftText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
`
export const RightImage = styled.img`
  height: 20px;
`
export const WalletSection = styled.div``
export const TopText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px 0;
  align-items: center;
`

export const HeadingText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 40px;
  @media (max-width: 767px) {
    font-size: 5.533vw;
  }
`
export const ImageSection = styled.div``

export const LogoSection = styled.img`
  height: 25px;
  cursor: pointer;
`
