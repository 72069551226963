import { ReactElement } from 'react'
import InviteActions from 'components/InviteActions'

interface IStatus {
  title: string
}

interface IData {
  key: React.Key
  invitedDate: string
  appointerEmail: string
  status: IStatus
  action: ReactElement
}

export const data: IData[] = [
  {
    key: '1',
    invitedDate: '10/03/2022',
    appointerEmail: 'Jessica.dave@gmail.com',
    status: { title: 'Joined' },
    action: <InviteActions />,
  },
  {
    key: '2',
    invitedDate: '10/03/2022',
    appointerEmail: 'Jessica.dave@gmail.com',
    status: { title: 'Joined' },
    action: <InviteActions />,
  },
  {
    key: '3',
    invitedDate: '10/03/2022',
    appointerEmail: 'Jessica.dave@gmail.com',
    status: { title: 'Joined' },
    action: <InviteActions />,
  },
  {
    key: '4',
    invitedDate: '10/03/2022',
    appointerEmail: 'Jessica.dave@gmail.com',
    status: { title: 'Pending' },
    action: <InviteActions />,
  },
  {
    key: '5',
    invitedDate: '10/03/2022',
    appointerEmail: 'Jessica.dave@gmail.com',
    status: { title: 'Pending' },
    action: <InviteActions />,
  },
]
export interface IColumns {
  title: string
  dataIndex: string
}

export const columns = [
  {
    title: 'Invited Date',
    dataIndex: 'invitedDate',
  },
  {
    title: 'Email ID',
    dataIndex: 'appointerEmail',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
]
export default { data, columns }
