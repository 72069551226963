import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useMoralis } from 'react-moralis'
import ReactTooltip from 'react-tooltip'
import Profile from 'assets/images/userprofile.png'
import EmailCopy from 'assets/images/copy-email.png'
import BalanceDollar from 'assets/images/total-balance.png'
import Logout from 'assets/images/logout-icon.png'
import Wallet from 'assets/images/wallet-icon.png'
import {
  MainContainer,
  Wrapper,
  EmailWrapper,
  EmailImg,
  UserImg,
  EmailText,
  UserText,
  BalanceWrapper,
  BalanceText,
  BalanceNumber,
  WalletWrapper,
  WalletImg,
  LogoutWrapper,
} from 'styles/components/UserProfile'
import { logout } from 'utils'
import { UserContext } from 'context/user'
import { useMoralisWeb3Api } from 'react-moralis'
import Moralis from 'moralis'

interface ITokenPriceData {
  usdPrice: number
}

interface IProps {
  wallet: string
  fiduciary: string
}

const UserProfileFiduciary = ({ wallet, fiduciary }: IProps) => {
  const navigate = useNavigate()
  const { setUser, user } = useContext(UserContext)
  const [isCopied, setIsCopied] = useState(false)
  const { logout: moralisLogout } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const [walletNativeBalance, setWalletNativeBalance] = useState<string>('')

  const fetchNativeBalance = async () => {
    const { balance } = await Web3Api.account.getNativeBalance({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: user?.userWalletId,
    })
    setWalletNativeBalance(Moralis.Units.FromWei(balance, 18))
  }

  useEffect(() => {
    if (user?.userWalletId) fetchNativeBalance()
  }, [user])

  const handleCopy = () => {
    setIsCopied(true)
  }

  return (
    <MainContainer>
      <Wrapper>
        <div>
          <UserImg src={Profile} />
        </div>
        <div>
          <UserText>{fiduciary}</UserText>
          <EmailWrapper>
            <EmailText>{`${wallet?.substring(0, 10)}..${wallet?.substring(wallet?.length - 4)}`}</EmailText>
            <CopyToClipboard text={wallet} onCopy={handleCopy}>
              <div data-tip data-for="from">
                <ReactTooltip id="from" place="top" effect="solid">
                  {isCopied ? <div>copied !</div> : <div>Copy To Clipboard</div>}
                </ReactTooltip>
                <EmailImg
                  src={EmailCopy}
                  onClick={() => {
                    setIsCopied(true)
                  }}
                />
              </div>
            </CopyToClipboard>
          </EmailWrapper>
        </div>
      </Wrapper>
      <BalanceWrapper>
        <div>
          <img src={BalanceDollar} />
        </div>
        <div>
          <BalanceText>Native Balance</BalanceText>
          <BalanceNumber>
            {'$'} {Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
          </BalanceNumber>
        </div>
      </BalanceWrapper>
      <WalletWrapper>
        <WalletImg src={Wallet} />
        <div>Connect Wallets</div>
      </WalletWrapper>
      <LogoutWrapper
        onClick={async () => {
          setUser(null)

          await moralisLogout()
          logout()
          navigate('/')
          window.location.reload()
        }}
      >
        <img src={Logout} />
        <div>Log Out</div>
      </LogoutWrapper>
    </MainContainer>
  )
}

export default UserProfileFiduciary
