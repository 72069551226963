import React from 'react'
import { TermsSection, GrrenDot, TermsContainer } from 'styles/views/dashboard/MyPlan/PaymentSection'

export interface ICardProps {
  image: string
  title: string
}
const Add = ({ image, title }: ICardProps) => (
  <TermsSection>
    <TermsContainer>
      <GrrenDot src={image} />
      {title}
    </TermsContainer>
  </TermsSection>
)
export default Add
