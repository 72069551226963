import { useEffect, useContext, useState } from 'react'
import usePost from 'hooks/usePost'
import Watermark from 'assets/images/watermark-image.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoaderContext } from 'context/loader'
import { MainContainer, Image, TextMessage } from 'styles/views/landing/InviteLink'

const InviteLink = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState(false)
  const { mutateAsync, isLoading } = usePost()
  const { setLoader } = useContext(LoaderContext)

  const verifyToken = async () => {
    const search = location.search
    const params = new URLSearchParams(search)
    const tokenData = params.get('token')

    const email = params.get('email')

    const referalname = params.get('referalname')

    const connect = [tokenData, email, referalname]
    const token = connect.join('&')

    window.localStorage.setItem('referralCode', token as any)

    const payload = {
      token,
    }

    try {
      await mutateAsync({
        url: '/userVerification/userVerification',
        payload: payload,
      })
      navigate('/selectwallet')
    } catch (error: any) {
      setError(true)

      return { error: error?.response?.data?.message }
    }
  }

  useEffect(() => {
    verifyToken()
  }, [])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <>
      {error ? (
        <MainContainer>
          <div>
            <Image src={Watermark} />
          </div>
          <TextMessage>Invite link has expired !</TextMessage>
        </MainContainer>
      ) : (
        <>
          <MainContainer>
            <div>
              <Image src={Watermark} />
            </div>
          </MainContainer>
        </>
      )}
    </>
  )
}

export default InviteLink
