import styled from 'styled-components'

export const RoadMap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 74px;
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    margin-top: 19.733vw;
  }
`
export const LeftSection = styled.div`
  width: 100%;
  height: auto;

  border-top: 1px solid #44c1f7;
`

export const RightSection = styled.div`
  width: 100%;
  border-bottom: 1px solid #44c1f7;
  border-left: 1px solid #44c1f7;
  border-bottom-left-radius: 40px;
  @media (max-width: 767px) {
    width: 100%;
    border-bottom: 1px solid #44c1f7;
    border-left: 1px solid #44c1f7;
    border-bottom-left-radius: 10.667vw;
  }
`
export const FisrtLeftSection = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
  }
`
export const SecondLeftSection = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OutfitRegular';
  font-size: 18px;
  color: #fdfeff;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    color: #fdfeff;
  }
`
export const FisrtRightSection = styled.div`
  width: 100%;
  height: 253px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'OutfitRegular';
  font-size: 15px;
  text-align: center;
  color: #fdfeff;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    text-align: center;
    color: #fdfeff;
  }
`
export const SecondRightSection = styled.div`
  width: 100%;
  height: 253px;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
  }
`
export const ThirdLeftSection = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
  }
`
export const FourthLeftSection = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OutfitRegular';
  font-size: 18px;
  color: #fdfeff;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    color: #fdfeff;
  }
`

export const FifthLeftSection = styled.div`
  width: 100%;
  height: 253px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
  }
`

export const ThirdRightSection = styled.div`
  width: 100%;
  height: 253px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OutfitRegular';
  font-size: 18px;
  color: #fdfeff;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    padding: 2.667vw;
    color: #fdfeff;
  }
`

export const FourthRightSection = styled.div`
  width: 100%;
  height: 253px;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
  }
`

export const FifthrightSection = styled.div`
  width: 100%;
  height: 253px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OutfitRegular';
  font-size: 18px;
  color: #fdfeff;
  @media (max-width: 767px) {
    width: 100%;
    height: 57.467vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OutfitRegular';
    font-size: 4.8vw;
    color: #fdfeff;
  }
`
export const FileImageContainer = styled.img`
  height: 125px;
  width: 118px;
  position: absolute;
  bottom: 178px;
  right: -60px;
  z-index: 1;
  @media (max-width: 767px) {
    height: 33.333vw;
    width: 31.467vw;
    position: absolute;
    bottom: 37.467vw;
    right: -16vw;
    z-index: 1;
  }
`
export const AddWalletContainer = styled.img`
  height: 125px;
  width: 118px;
  position: absolute;
  bottom: 183px;
  right: -60px;
  z-index: 1;
  @media (max-width: 767px) {
    height: 33.333vw;
    width: 31.467vw;
    position: absolute;
    bottom: 38.8vw;
    right: -16vw;
    z-index: 1;
  }
`
export const TextArea = styled.div`
  font-family: 'OutfitRegular';
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #fdfeff;
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-weight: 400;
    font-size: 4vw;
    text-align: center;
    color: #fdfeff;
  }
`
