import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 450px;
  background: #ffffff;
  @media (max-width: 1366px) {
    height: 430px;
  }
  @media (max-width: 767px) {
    height: 53.333vw;
  }
`
export const Wrapper = styled.div``
export const TreeImage = styled.img`
  height: 250px;
  width: 200px;
  display: flex;
  @media (max-width: 767px) {
    height: 336px;
    width: 333px;
  }
`
