import React, { useState } from 'react'
import SignUpSection from 'views/landing/SignUp'
import Modal from 'components/Modal'
import CreateWallet from 'components/Cards/CreateWallet'
import Logo from 'assets/images/logo-black.png'
import { useLocation } from 'react-router-dom'
import {
  Container,
  Wrapper,
  WelcomeText,
  SelectWalletSection,
  TextSection,
  BottomText,
  BottomRightText,
  AboutCrypto,
  MainSection,
  HeaderWrapper,
  LogoSection,
} from 'styles/pages/SelectWallet'

interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

export const SelectWallet = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)

  const email: string = params.get('emailid') || ''
  const userWalletId: string = params.get('userWalletId') || ''
  const username: string = params.get('username') || ''
  const grantor: string = params.get('grantor') || ''
  const role: string = params.get('role') || ''

  localStorage.setItem('email', email)
  localStorage.setItem('userWalletId', userWalletId)
  localStorage.setItem('username', username)
  localStorage.setItem('grantor', grantor)
  localStorage.setItem('signRole', role)

  return (
    <Wrapper>
      <HeaderWrapper>
        <LogoSection src={Logo} />
      </HeaderWrapper>
      <MainSection>
        <Container>
          <WelcomeText>Welcome to CryptoPlan</WelcomeText>
        </Container>
        <SelectWalletSection>
          <TextSection>Please select your wallet</TextSection>
          <AboutCrypto>
            <BottomText>Don’t have a wallet? </BottomText>
            <BottomRightText onClick={() => setIsOpen(true)}>Create Wallet</BottomRightText>
          </AboutCrypto>
          <SignUpSection />
        </SelectWalletSection>
      </MainSection>
      <Modal isOpen={isOpen}>
        <CreateWallet showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Wrapper>
  )
}

export default SelectWallet
