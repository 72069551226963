import Family from 'assets/images/fullfamily-blue.png'
import Friends from 'assets/images/friendsgrup-green.png'
import Charity from 'assets/images/newtrust-green.png'
interface IData {
  id: number
  image: string
  title: string
}

const data: IData[] = [
  {
    id: 0,
    image: Friends,
    title: 'Friends',
  },
  {
    id: 1,
    image: Family,
    title: 'Family',
  },
  {
    id: 2,
    image: Charity,
    title: 'Charity & Trust',
  },
]
export default data
