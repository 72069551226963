import Button from 'components/Button'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 767px) {
    max-width: 90.667vw;
    margin-left: 4.8vw;
  }
`
export const Wrapper = styled.div`
  padding: 30px 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  gap: 30px;
  margin: auto;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`
export const HeadText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
`
export const Cross = styled.img`
  height: 21px;
  width: 21px;
  cursor: pointer;
`
export const LabelText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
`
export const Email = styled.div`
  font-family: 'OutfitMedium';
  font-size: 18px;
  line-height: 24px;
  color: #4473f5;
`
export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
  outline: none;
`
export const DeatailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const SaveButton = styled(Button)`
  max-width: 160px;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: left;
`
export const EmailTextSection = styled.div`
  .ant-row {
    margin-bottom: 5px !important;
  }
  .textemail {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`
