import SignupWallet from 'assets/images/signup-wallet.png'
import wc from 'assets/images/wc.png'

interface IData {
  id: number
  image: string
  title: string
}

const data: IData[] = [
  {
    id: 0,
    image: SignupWallet,
    title: 'MetaMask',
  },

  {
    id: 1,
    image: wc,
    title: 'WalletConnect',
  },
]
export default data
