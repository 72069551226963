import { useState, useEffect, useContext, useRef } from 'react'
import { Slider } from 'antd'
import { UserContext } from 'context/user'
import { useMoralisWeb3Api } from 'react-moralis'
import Moralis from 'moralis'
import CreatableSelect from 'react-select/creatable'
import data from 'components/MyProfile/data'
import SearchInput from 'components/Search'
import { LoaderContext } from 'context/loader'
import useGet from 'hooks/useGet'
import WalletSection from 'assets/images/dashboard-wallet.png'
import Short from 'assets/images/short-image.png'
import Filter from 'assets/images/filter-image.png'
import resetImage from 'assets/images/reset-green.png'
import Watermark from 'assets/images/watermark-image.png'
import MoneySection from 'assets/images/money-image.png'
import DefaultImage from 'assets/images/crypto-currencies.png'
import {
  Wrapper,
  MainContainer,
  BalanceSection,
  BalanceDetails,
  WalletDetails,
  WalletImageSection,
  Details,
  MoneyDetails,
  MainBalanceSection,
  TableContainer,
  DashbordContentSection,
  LeftBottomSection,
  FilterSection,
  DropSection,
  LeftSectionFilter,
  RightSectionFilter,
  SearchBox,
  DropImage,
  NetworkText,
  FilterBy,
  FilterImage,
  RightDrop,
  FilterContainer,
  ValueText,
  RestFilter,
  ResetImage,
  TextReset,
  SortRows,
  NoBeneficiaries,
  BeneficiarieImage,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  BalanceImageSection,
  DefinitionSection,
  FilterText,
} from 'styles/components/MyProfile'
import moment from 'moment'
import { ethers } from 'ethers'

interface ITokenData {
  name: string
  balance: string
  token_address: string
}
interface ITokenPriceData {
  usdPrice: number
}

interface IEventProp {
  target: { value: string }
  e: React.ChangeEvent<HTMLInputElement>
}

interface INewdata {
  balance: string
  decimals: number
  logo: null
  name: string
  symbol: string
  thumbnail: null
  token_address: string
}
interface IAssetData {
  logo: string | undefined
  createDate: string
  id: string
  newData: INewdata
  tokenAddress: string
  userWalletUserWallet: string
  yourAssets: string
}

const MyProfile = () => {
  const [, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const [filterOpen, setFilterOpen] = useState(false)
  const wrapperRef = useRef<any>()
  const filterRef = useRef<any>()
  const { user } = useContext(UserContext)
  const { refetch: fetchDetails } = useGet(
    'wallet-detail',
    'api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false/',
    true,
  )

  const Web3Api = useMoralisWeb3Api()
  const [walletNativeBalance, setWalletNativeBalance] = useState<string>('')
  const [lastSyncedAt, setLastSyncedAt] = useState<string>('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState<ITokenPriceData[]>([])
  const [assetTokenPrice, setAssetTokenPrice] = useState<ITokenPriceData[]>([])
  const [totalAssetValue, setTotalAssetValue] = useState<any>()
  const wrapped_native_currency_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''
  const [talDifference, setTalDifference] = useState<any>([])
  const linkedAddresses = user?.userWallet
  const [, setTokensData] = useState<ITokenData[]>([])
  const [uniqueTokens, setUniqueTokens] = useState<ITokenData[]>([])

  const fetchNativeBalance = async () => {
    let totalBalance = 0
    const element = user?.userWallet
    for (let i = 0; i < user?.userWallet.length; i++) {
      const { balance } = await Web3Api.account.getNativeBalance({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: element[i]?.walletId,
      })
      totalBalance = totalBalance + Number(balance)
    }

    setWalletNativeBalance(Moralis.Units.FromWei(totalBalance.toString(), 18))
  }

  const getWalletData = async () => {
    const response = await fetchDetails()
    const { data } = response
    const filteredData = data.slice(0, 5)
    setTableData(filteredData)
  }

  const fetchAssetPriceBalance = async () => {
    const tokens: any[] = []

    for (let iterator = 0; iterator < linkedAddresses.length; iterator++) {
      const tokenbalances: any[] = await Web3Api.account.getTokenBalances({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: linkedAddresses[iterator].walletId, //this holds internally linked address of MM
      })
      tokens.push(...tokenbalances)
      if (tokens != undefined) setTokensData(tokens)
    }

    const uniqueTokens = [
      ...tokens
        .reduce((map, current) => {
          const { token_address } = current
          const grouped = map.get(token_address)
          if (!grouped) {
            map.set(token_address, { ...current })
          } else {
            map.set(token_address, {
              ...grouped,
              balance: (Number(grouped.balance) + Number(current.balance)).toLocaleString('fullwide', {
                useGrouping: false,
              }),
            })
          }

          return map
        }, new Map())
        .values(),
    ]
    setUniqueTokens(uniqueTokens)

    //Get asset token price
    const getAssetsPrice: any = []
    let totalassetsvalue: any = 0
    for (let iterator = 0; iterator < uniqueTokens.length; iterator++) {
      const assetPrice = await Web3Api.token.getTokenPrice({
        address: uniqueTokens[iterator]?.token_address,
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      })

      const assetPriceUSD: any = assetPrice?.usdPrice
      totalassetsvalue =
        totalassetsvalue + assetPriceUSD * Number(ethers.utils.formatEther(uniqueTokens[iterator].balance))
      getAssetsPrice?.push(assetPriceUSD)
    }

    setTotalAssetValue(totalassetsvalue)
    if (getAssetsPrice != undefined) setAssetTokenPrice(getAssetsPrice)
  }

  const fetchTokenPrice = async () => {
    const price = await Web3Api.token.getTokenPrice({
      address: wrapped_native_currency_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    const nativePriceUSD: any = price.usdPrice
    setNativeTokenPrice(nativePriceUSD)
    const lastSync = new Date().toLocaleTimeString()
    setLastSyncedAt(lastSync)
  }

  const { data: getAsset, refetch: getAssetRefetch } = useGet('get-Asset', 'asset/getAsset', false, {
    enabled: false,
    token: true,
  })
  const asset = getAsset?.user?.userWallet || ''
  let getAssetdata: Array<IAssetData> = []
  asset.forEach((element: any) => {
    getAssetdata = [...getAssetdata, ...element?.assests]
  })
  const intersectionOfAssets = uniqueTokens.filter((a) =>
    getAssetdata.some((b: { tokenAddress: string }) => a.token_address === b.tokenAddress),
  )

  const filteredItems = intersectionOfAssets.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase()),
  )
  const updatingTally = async () => {
    const assetArray: any = []
    const assetOfCurrentUser = getAsset?.user?.userWallet || ''
    let assetCounter = 0
    for (let iterator = 0; iterator < assetOfCurrentUser.length; iterator++) {
      const counterStop = (assetOfCurrentUser[iterator]?.assests).length
      assetOfCurrentUser[iterator].assests.forEach(async (element: any) => {
        const currentAssetData = await changeInPrice(element.tokenAddress).catch(() => {
          new Error('Error in fetching token information')
        })
        assetArray.push(currentAssetData)
        assetCounter = assetCounter + 1
        if (currentAssetData !== 'undefined' && assetCounter === counterStop) {
          setTalDifference(assetArray)
        }
      })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && user?.userWalletId) {
      fetchTokenPrice()
      const priceInterval = setInterval(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
          fetchTokenPrice()
          fetchAssetPriceBalance()
        }
      }, 15000)
      return () => clearInterval(priceInterval)
    }
  }, [user])

  useEffect(() => {
    getAssetRefetch()
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && getAsset) {
      updatingTally()
      const priceInterval = setInterval(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') updatingTally()
      }, 25000)
      return () => clearInterval(priceInterval)
    }
  }, [getAsset])

  // Getting the Price Change in 24H
  async function changeInPrice(address: string) {
    //Getting block from date
    const date = await Web3Api.native.getDateToBlock({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      date: moment().subtract(1, 'days').toString(),
    })

    //Getting the price with particular Block Number
    const price24hAgo = await Web3Api.token.getTokenPrice({
      address: address,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toBlock: date.block,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    //Getting the current price
    const currentPrice = await Web3Api.token.getTokenPrice({
      address: address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })
    const netChangedData = (((currentPrice?.usdPrice - price24hAgo?.usdPrice) / price24hAgo?.usdPrice) * 100).toFixed(2)
    return netChangedData
  }

  useEffect(() => {
    if (user?.userWalletId) fetchNativeBalance()
  }, [user])

  useEffect(() => {
    getWalletData()
  }, [])

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      } else if (filterRef.current && !filterRef.current?.contains(event?.target)) {
        setFilterOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (filteredItems.length === 0) {
      setLoader(true)
      setTimeout(() => {
        setLoader(false)
      }, 7000)
    }
  }, [])

  useEffect(() => {
    fetchAssetPriceBalance()
  }, [])

  useEffect(() => {
    getAssetRefetch()
  }, [])

  return (
    <Wrapper>
      <MainContainer>
        <LeftBottomSection>
          <DashbordContentSection>
            <BalanceSection>
              <BalanceDetails>
                <BalanceImageSection src={MoneySection} />
                <MainBalanceSection>
                  <Details>Assets worth(≈)</Details>
                  {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                    <MoneyDetails>
                      {isNaN(Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                        ? '↺'
                        : (Number(nativeTokenPrice) * Number(walletNativeBalance) + Number(totalAssetValue))
                            ?.toLocaleString()
                            .substring(0, 9)}
                    </MoneyDetails>
                  ) : (
                    <MoneyDetails>
                      {isNaN(Number(walletNativeBalance))
                        ? '↺'
                        : Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
                    </MoneyDetails>
                  )}
                  <Details>🔁 {lastSyncedAt}</Details>
                </MainBalanceSection>
              </BalanceDetails>
              <WalletDetails>
                <WalletImageSection src={WalletSection} />
                <MainBalanceSection>
                  <Details>Wallets Connected</Details>
                  <MoneyDetails>{linkedAddresses.length}</MoneyDetails>
                </MainBalanceSection>
              </WalletDetails>
            </BalanceSection>
            {getAssetdata.length ? (
              <div>
                <FilterSection>
                  <LeftSectionFilter>
                    <SearchBox>
                      <SearchInput
                        placeholder="Search Crypto Assets"
                        onChange={(e: IEventProp) => setSearchValue(e.target.value)}
                      />
                    </SearchBox>
                    <DropSection>
                      <CreatableSelect
                        isClearable
                        isMulti
                        className="multi_select"
                        options={data}
                        placeholder="Networks"
                      />
                    </DropSection>
                  </LeftSectionFilter>
                  <RightSectionFilter>
                    <RightDrop onClick={() => setIsOpen(true)}>
                      <NetworkText> 10</NetworkText>
                      <DropImage src={Short} />
                      {isOpen && (
                        <SortRows ref={wrapperRef}>
                          <div>10</div>
                          <div>20</div>
                          <div>50</div>
                          <div>100</div>
                        </SortRows>
                      )}
                    </RightDrop>

                    <FilterBy onClick={() => setFilterOpen(true)}>
                      <FilterImage src={Filter} />
                      <FilterText> Filter</FilterText>
                    </FilterBy>
                    {filterOpen && (
                      <FilterContainer ref={filterRef}>
                        <RestFilter>
                          <ResetImage src={resetImage} />
                          <TextReset>Reset Filter</TextReset>
                        </RestFilter>
                        <ValueText>Value</ValueText>
                        <Slider range={{ draggableTrack: true }} />
                      </FilterContainer>
                    )}
                  </RightSectionFilter>
                </FilterSection>
                <TableContainer>
                  <table>
                    <tr>
                      <th></th>
                      {process.env.REACT_APP_ENVIRONMENT === 'prod' ? <th>Logo</th> : ''}
                      <th>Name</th>
                      <th>24 H</th>
                      <th>Price in $ (DEX)</th>
                      <th>Token Balance</th>
                      <th>Value in $ (≈)</th>
                    </tr>

                    {filteredItems.length ? (
                      filteredItems.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="image"></td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                <CryptoLogo
                                  className="minified"
                                  src={getAssetdata[index].logo === undefined ? DefaultImage : getAssetdata[index].logo}
                                />
                              </td>
                            ) : (
                              ''
                            )}
                            <td>{item.name}</td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                <div>
                                  {talDifference.length == 0 || talDifference[index] === undefined
                                    ? '↺'
                                    : talDifference[index] + '%'}
                                </div>
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                {Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4).length == 0 ||
                                isNaN(Number(assetTokenPrice[index]))
                                  ? '↺'
                                  : Number(assetTokenPrice[index])?.toLocaleString().substring(0, 4) || 'NA'}
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                            <td>
                              {item.decimals == 6
                                ? Moralis.Units.FromWei(item.balance, 6)
                                : Moralis.Units.FromWei(item.balance, 18)}
                            </td>
                            {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                              <td>
                                {(Number(assetTokenPrice[index]) * Number(item.balance))
                                  ?.toLocaleString()
                                  .substring(0, 4).length == 0 ||
                                isNaN(Number(assetTokenPrice[index]) * Number(item.balance))
                                  ? '↺'
                                  : (Number(assetTokenPrice[index]) * Number(item.balance))
                                      ?.toLocaleString()
                                      .substring(0, 4) || 'NA'}
                              </td>
                            ) : (
                              <td>{'NA'}</td>
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td className="center" colSpan={5}>
                          <NoBeneficiaries>
                            <BeneficiarieImage>
                              <CryptoLogo src={Watermark} />
                            </BeneficiarieImage>
                            <TextSextion>
                              <HeadingSection>No Assets Available</HeadingSection>
                            </TextSextion>
                          </NoBeneficiaries>
                        </td>
                      </tr>
                    )}
                  </table>
                </TableContainer>
              </div>
            ) : (
              <TableContainer>
                <table>
                  <tr>
                    <th></th>

                    <th>Name</th>
                    <th>24 H</th>
                    <th>Price</th>
                    <th>Token Balance</th>
                    <th>Value</th>
                    <th>Allocation</th>
                  </tr>
                  <tr>
                    <td className="center" colSpan={8}>
                      <NoBeneficiaries>
                        <BeneficiarieImage>
                          <CryptoLogo src={Watermark} />
                        </BeneficiarieImage>
                        <TextSextion>
                          <HeadingSection>No Assets Available</HeadingSection>
                          <DefinitionSection></DefinitionSection>
                        </TextSextion>
                      </NoBeneficiaries>
                    </td>
                  </tr>
                </table>
              </TableContainer>
            )}
          </DashbordContentSection>
        </LeftBottomSection>
      </MainContainer>
    </Wrapper>
  )
}

export default MyProfile
