import styled from 'styled-components'

export const Container = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const SecContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 767px) {
    padding: 5.333vw;
  }
`
export const SidebarSection = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
