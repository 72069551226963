import { useEffect } from 'react'
import { Tabs } from 'antd'
import MyDetails from 'views/dashboard/BeneficarySettings/MyDetails'
import useGet from 'hooks/useGet'
import FiduciarySidebar from 'components/FiduciarySidebar'
import BeneficaryNavBar from 'components/BeneficaryNavBar'
import BeneficiaryRecoverSecretPhrase from 'views/dashboard/BeneficiaryRecoverSecretPhrase'

import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  HeadingArea,
  TopBox,
  TextContainer,
  Section,
  TableContent,
  NavContainer,
} from 'styles/views/dashboard/Settings'

const { TabPane } = Tabs

const BeneficiarySetting = () => {
  const { data: beneficary, refetch: beneficaryRefetch } = useGet('Benificary', 'beneficary/getBeneficary', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    beneficaryRefetch()
  }, [])
  const name = beneficary?.user && beneficary?.user.length > 0 ? beneficary?.user[0].displayName : ''
  const phoneNo = beneficary?.user && beneficary?.user.length > 0 ? beneficary?.user[0].phoneNumber : ''

  return (
    <Wrapper>
      <BeneficaryNavBar beneificaryName={name} />
      <Section>
        <NavContainer>
          <FiduciarySidebar />
        </NavContainer>
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Settings</TextArea>
                <HeadingArea></HeadingArea>
              </TextContainer>
            </TopBox>
            <TableContent>
              <Tabs defaultActiveKey="1">
                <TabPane tab="My Details" key="1">
                  <MyDetails beneificaryName={name} beneificaryPhoneNo={phoneNo} />
                </TabPane>

                <TabPane tab="Recover Secret Phrase" key="2">
                  <BeneficiaryRecoverSecretPhrase />
                </TabPane>
              </Tabs>
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default BeneficiarySetting
