import React from 'react'

interface IBeneficiaryContext {
  beneficiary: boolean
  setBeneficiary: any
}

const defaultState = {
  beneficiary: false,
  setBeneficiary: () => null,
}

const BeneficiaryContext = React.createContext<IBeneficiaryContext>(defaultState)

export { BeneficiaryContext }
