import styled from 'styled-components'
import { theme } from 'theme'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const DeletContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

export const TableContent = styled.div`
  padding: 10px 10px 17px 10px;
  @media (max-width: 767px) {
    margin-bottom: 5.333vw;
  }
`
export const Section = styled.div`
  width: 100%;
  display: flex;
`
export const MainContainer = styled.div`
  background: #f1f5fb;
  width: 100%;
  margin: 0 24px;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 0 3.2vw;
  }
  @media (max-width: 1024px) {
    margin: 0;
  }
`
export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 93.333vw;
    margin: 0 3.8vw !important;
  }
`
export const TextArea = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #0a0c17;
  @media (max-width: 767px) {
    line-height: 0px;
    display: flex;
    font-size: 5.333vw;
  }
`
export const HeadingArea = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const ButtonBox = styled.div`
  display: flex;
  min-width: 186px;
  gap: 10px;
  .sc-bdvvtL {
    padding: 15px;
  }
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    max-width: 32vw;
    min-width: 0;
  }
`
export const TopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  border-bottom: 1px solid #dfdfdf;
  @media (max-width: 767px) {
    padding: 4vw;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContractWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
  @media (max-width: 767px) {
    margin-top: 5.867vw;
  }
`
export const UpdatedContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 31px;
  border-bottom-right-radius: 31px;
  border-top-left-radius: 7px;
  border-top-right-radius: 8px;
`
export const HeadWrapper = styled.div`
  background: #effff1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 10px;
  border-radius: 12px 12px 30px 30px;
`
export const HeadText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  line-height: 56px;
  align-items: center;
  letter-spacing: 0.2px;
`
export const FilterContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 6px 6px 13px;
`
export const FilterShortSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 15px;
  cursor: pointer;
`
export const TableWrapper = styled.div`
  padding: 20px;
  @media (max-width: 767px) {
    .ant-table {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  td {
    :nth-child(2) {
      color: #2677e9;
    }
    :nth-child(1) {
      color: #2677e9;

      /* background: rgb(233, 242, 255); */
    }
  }
  .ant-table-thead > tr > th {
    color: #737b7d;
    font-size: 13px;
    font-family: 'Outfitmedium';
    font-weight: 400;
  }
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #fafafa;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: center;

    @media only screen and (max-width: 900px) {
      padding: 16px 9px;
    }
  }
`
export const NumaricText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  line-height: 56px;
  align-items: center;
  letter-spacing: 0.2px;
  color: #2677e9;
`
export const TextSection = styled.div`
  display: flex;
  gap: 5px;
`
export const SortWrapper = styled.div`
  z-index: 1;
  position: absolute;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 99px;
  width: 100%;
  text-align: left;
  right: 0px;
  top: 40px;
  padding: 10px 20px;
`
export const SortText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`
export const ShortImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const MainStatusContainer = styled.div`
  gap: 17px;
  display: flex;
  align-items: center;
`
export const SuccessContainer = styled.div`
  /* background: #e9f2ff;
  color: #2677e9; */
  border-radius: 30px;
  width: 100%;
  max-width: 120px;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
`

export const PendingContainer = styled.div`
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
  border-radius: 30px;
  background: #ffc9a242;
  color: #fea41e;
`

export const NoDataContainer = styled.div`
  min-height: 31vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Image = styled.img`
  min-height: 19vh;
`

export const TextMessage = styled.div`
  font-family: 'OutfitMedium';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.1px;
  @media (max-width: 767px) {
    font-size: 5.333vw;
    text-align: center;
    line-height: 5.333vw;
  }
`
export const IdStatus = styled.div`
  color: #2677e9;
`
export const StatusActive = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0px 7px;
  background: #bd1476;
  border: none;
  font-size: 14px;
  font-family: 'OutfitSemiBold';
  height: 50px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StatusActiveTerminated = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0px 7px;
  background: #300b1c;
  border: none;
  font-size: 14px;
  font-family: 'OutfitBold';
  height: 50px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const HeadTextOne = styled.div`
  font-family: 'OutfitMedium';
  font-size: 20px;
  line-height: 56px;
  align-items: center;
  letter-spacing: 0.2px;
  color: red;
`
