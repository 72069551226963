import { useState, useEffect, useRef, useContext, useMemo } from 'react'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import Table from 'components/Table'
import { LoaderContext } from 'context/loader'
import SearchInput from 'components/Search'
import PandingImage from 'assets/images/panding-image.png'
import Short from 'assets/images/short-image.png'
import { columns } from 'views/dashboard/Beneficiaries/InvitedFriends/data'
import Watermark from 'assets/images/watermark-image.png'
import {
  FilterShortSection,
  FilterContainer,
  TableWrapper,
  SortWrapper,
  SortText,
  ShortImage,
  MainContainer,
  PendingContainer,
  AcceptedContainer,
  MainPending,
  Pendingimg,
  Container,
  TextMessage,
  Image,
} from 'styles/components/InvitedFriends'

const Beneficiaries = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [sortOrder, setSortOrder] = useState('newest')
  const { setLoader } = useContext(LoaderContext)
  const wrapperRef = useRef<any>()

  const {
    data: inviteData,
    refetch,
    isLoading,
  } = useGet('get-invite', '/getDetail/inviteData', false, {
    enabled: true,
    token: true,
  })

  const { mutateAsync: resendEmail, isLoading: loader } = usePost()
  const onResend = async (index: number) => {
    const email = inviteData?.inviteDetails[index]?.emailId

    try {
      await resendEmail({
        url: '/resend/mail',
        payload: {
          email,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message }
    }
  }

  const tableData = []
  const [searchValue, setSearchValue] = useState('')
  const filteredData = inviteData?.inviteDetails?.filter((item: { emailId: string }) =>
    item?.emailId.toLowerCase().includes(searchValue.toLowerCase()),
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleSort = (order: string) => {
    setSortOrder(order)
  }

  const sortedData = useMemo(() => {
    //Guard Clause
    if (!filteredData?.length) {
      return []
    }

    const sortedArray = [...filteredData]
    if (sortOrder === 'newest') {
      sortedArray.sort((a, b) => new Date(b.inviteDate).valueOf() - new Date(a.inviteDate).valueOf())
    } else if (sortOrder === 'oldest') {
      sortedArray.sort((a, b) => new Date(a.inviteDate).valueOf() - new Date(b.inviteDate).valueOf())
    }

    return sortedArray
  }, [filteredData, sortOrder])

  for (let i = 0; i < sortedData?.length; i++) {
    const element = sortedData[i]

    tableData.push({
      key: i,
      invitedDate: element?.inviteDate?.split('T')[0],
      appointerEmail: element?.emailId,
      status: element?.refralTable[0]?.isActive ? (
        <MainContainer>
          <AcceptedContainer>Joined</AcceptedContainer>
        </MainContainer>
      ) : (
        <MainPending>
          <PendingContainer> Pending </PendingContainer>
          <Pendingimg src={PandingImage} onClick={() => onResend(i)} />
        </MainPending>
      ),
    })
  }

  useEffect(() => {
    if (loader) setLoader(true)
    else setLoader(false)
  }, [loader])
  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current?.contains(event?.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <>
      {inviteData?.inviteDetails?.length ? (
        <>
          <FilterShortSection>
            <SearchInput onChange={handleSearch} placeholder="Search by email id" />

            <FilterContainer onClick={() => setIsOpen(!isOpen)} ref={wrapperRef}>
              <div>Sort By</div>
              <ShortImage src={Short} />
              {isOpen && (
                <SortWrapper>
                  <SortText onClick={() => handleSort('newest')}>Newest</SortText>
                  <SortText onClick={() => handleSort('oldest')}>Oldest</SortText>
                </SortWrapper>
              )}
            </FilterContainer>
          </FilterShortSection>
          <TableWrapper>
            <Table data={tableData} columns={columns} />
          </TableWrapper>
        </>
      ) : (
        <Container>
          <div>
            <Image src={Watermark} />
          </div>
          <TextMessage>No friends invited yet</TextMessage>
        </Container>
      )}
    </>
  )
}

export default Beneficiaries
