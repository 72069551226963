import styled from 'styled-components'

import { theme } from 'theme'
export const Container = styled.div`
  width: 100%;
  background: ${theme.primary.light};
  display: flex;
  flex-direction: column;
  background: #02205b;
  background-size: cover;
  @media (max-width: 767px) {
    width: 100vw;
  }
`
export const FirstContainer = styled.div`
  width: 100%;
  background-size: cover;
`

export const LastContainer = styled.div`
  width: 100%;
  height: auto;
  background-size: cover;
  margin: 30px 0 80px 0;
`

export const lightTheme = {
  body: '#E2E2E2',
  text: '#363537',
  toggleBorder: '#FFF',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
}

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
}

export const RoadMap = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileMap = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`
