import React, { useContext } from 'react'
import Logo from 'assets/images/comming-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Wrapper,
  Definition2,
} from 'styles/components/Cards/Warning'
import { UserContext } from 'context/user'
import { ButtonSection } from 'styles/views/landing/PlanFuture'
import { CancelButton } from 'styles/pages/WelcomePlan'
import { useNavigate } from 'react-router'
import { logout } from 'utils'
export interface IModalProps {
  success: boolean
  info?: any
  showModal: (value: boolean) => void
}

const InfoModal2: React.FC<IModalProps> = ({ showModal, info }) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  info = `Account (${user?.userWalletId?.substring(0, 5)}..${user?.userWalletId?.substring(
    user?.userWalletId?.length - 4,
  )}) is in-active, contact admin@cp.com`
  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <LogoSection src={Logo} />
        </Container>
        <TextSection>
          <HeadingSection>{''}</HeadingSection>⚠<Definition2>{info}</Definition2>
        </TextSection>
        <ButtonSection>
          <CancelButton
            label="Close"
            variant="contained"
            type="button"
            onClick={() => {
              showModal(false)
              logout()
              navigate('/')
            }}
          />
        </ButtonSection>
      </Wrapper>
    </MainContainer>
  )
}

export default InfoModal2
