import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 9.167a5.5 5.5 0 0 0-11 0V16.5h11V9.167Zm1.833 7.944.367.489a.459.459 0 0 1-.367.733H3.667A.459.459 0 0 1 3.3 17.6l.367-.489V9.167a7.333 7.333 0 1 1 14.666 0v7.944ZM8.708 19.25h4.584a2.291 2.291 0 1 1-4.584 0Z"
      fill="#8C929B"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
