import styled from 'styled-components'
export const Container = styled.div`
  width: 100%;
  max-width: 210px;
  height: 100vh;
  background: #f1f5fb;
`
export const Wrapper = styled.div`
  width: 100%;
`
export const ButtonContainers = styled.div`
  padding-bottom: 5px;
`
export const ViewSelectOption = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
export const GrantorSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const GrantorAdress = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 24px;
  color: #2677e9;
`
export const RightImage = styled.img`
  height: 8px;
  cursor: pointer;
`
export const NameText = styled.div`
  min-width: 63px;
  width: 100%;
  font-family: 'OutfitSemiBold';
  font-size: 12px;
  line-height: 22px;
  text-align: initial;
  letter-spacing: 0.3px;
`
export const GrantorView = styled.div`
  width: 100%;
  justify-content: flex-start;
  padding-left: 20px;
  max-width: 350px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
`
export const Dropaheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px 24px 20px 24px;
`
export const XLogo = styled.img`
  height: 15px;
  cursor: pointer;
`
export const LogoSection = styled.img`
  @media (max-width: 767px) {
    width: 36.8vw;
  }
  @media (max-width: 1000px) {
    width: 115px;
  }
`
export const BurgerHeader = styled.div`
  display: flex;
  flex-direction: column;
`
