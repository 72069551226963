import styled from 'styled-components'
export const ShowWalletSection = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;

  padding: 22px 0px;
  @media (max-width: 1366px) {
    padding: 13px 0px;
  }
`

export const RadioButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
  text-align: left;
  @media (max-width: 767px) {
    gap: 13px;
  }
`
export const WalletImage = styled.img`
  @media (max-width: 767px) {
    height: 9.2vw;
    width: 9.2vw;
  }
`
export const WalletName = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  min-width: 150px;
  @media (max-width: 767px) {
    min-width: 12.8vw;
    font-size: 12px;
    line-height: 3.267vw;
    max-width: 10.8vw;
  }
`
export const WalletCount = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
  @media (max-width: 767px) {
    margin-right: 1.333px;
  }
`
export const BlankSection = styled.div``
export const ActionsContainer = styled.div`
  position: absolute;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  right: -19px;
  top: 54px;
  padding: 15px;
  cursor: pointer;
`
export const ActionText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 25px;
`
