import { useState, useEffect, useContext } from 'react'
import { data } from 'views/dashboard/MyPlan/data'
import { UserContext } from 'context/user'
import WalletSectionCard from 'components/Cards/Wallet'
import Signin from 'views/landing/SignIn'
import Modal from 'components/Modal'
import useGet from 'hooks/useGet'
import { LoaderContext } from 'context/loader'
import Planfiduciary from 'views/dashboard/FiduciaryMyPlan/FiduciaryPlanContent/index'
import PlusImage from 'assets/images/plus-image.png'
import Watermark from 'assets/images/watermark-image.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import { TableContainer, NoDataContainer } from 'styles/views/dashboard/FiduciaryCryptoAssets'
import { ethers } from 'ethers'
import {
  Container,
  Wrapper,
  LeftSection,
  RightSection,
  LeftTopSection,
  LeftBottomSection,
  WelcomeSection,
  Box,
  WelcomeBox,
  DashbordContentSection,
  ContentWrapper,
  ContainerSecond,
  RightWalletSection,
  TextArea,
  WalletBox,
  LeftPlusBox,
  BottomBoxSection,
  PlusSection,
  RightBenificiaries,
  BenificiariesTextSection,
  RightContainer,
  BenificiariescardSection,
  SectionRight,
  NoBeneficiaries,
  CryptoLogo,
  TextSextion,
  HeadingSection,
  DefinitionSection,
  BeneficiarieImage,
  ReadMore,
  MessageContainer,
  MessageText,
} from 'styles/views/dashboard/FiduciaryMyPlan'

export interface IBeneficiaryData {
  id: null | undefined
  displayName: string
  userWalletId: string
  emailId: string
  appointAs: string
  amount: number
}

interface ITokenData {
  token_address: string
  name: string
  symbol: string
  logo?: string | undefined
  thumbnail?: string | undefined
  decimals: number
  balance: string
}

interface Iprop {
  ownerName?: string
  fiduciaryName?: string
}

interface ITokenPriceData {
  usdPrice: number
}

export const FiduciaryMyPlan = ({ ownerName, fiduciaryName }: Iprop) => {
  const [tokenData, settokenData] = useState<Array<ITokenData>>([])
  const [walletNativeBalance, setWalletNativeBalance] = useState<string>('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState<ITokenPriceData[]>([])
  const fidAssets: Array<string> = []
  const { setLoader } = useContext(LoaderContext)
  const [showFull, setShowFull] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const walletInfo = localStorage?.getItem('walletconnect') || ''
  const { user } = useContext(UserContext)
  const { Moralis } = useMoralis()
  const web3Api = useMoralisWeb3Api()
  const getAssetsPrice: Array<string> = []
  const currentUser = Moralis.User.current()?.attributes?.accounts[0] || undefined
  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''

  const fetchTokenPrice = async () => {
    const tokenPrice = await web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
    })

    const nativePriceUSD: any = tokenPrice?.usdPrice
    setNativeTokenPrice(nativePriceUSD)
  }

  const fetchNativeBalance = async () => {
    const { balance } = await web3Api.account.getNativeBalance({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: user?.userWalletId,
    })
    setWalletNativeBalance(Moralis.Units.FromWei(balance, 18))
  }

  const getAllTokensInWallet = async () => {
    const tokenData = await Moralis.Web3API.account.getTokenBalances({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: currentUser || '',
    })
    if (tokenData.length > 0) {
      settokenData(tokenData)
      tokenData.forEach((element: ITokenData) => {
        fidAssets.push(element?.token_address)
      })
    }

    for (let iterator = 0; iterator < fidAssets.length; iterator++) {
      const assetPrice = await web3Api.token.getTokenPrice({
        address: fidAssets[iterator],
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      })
      const assetPriceUSD = assetPrice?.usdPrice
      getAssetsPrice?.push(assetPriceUSD.toString())
    }
  }

  const { data: getlinkup, refetch: linkupFetch } = useGet('get-linkup', 'getlinkup/getuserLink', false, {
    enabled: true,
    token: true,
  })

  const email =
    getlinkup?.linkupFiducary && getlinkup?.linkupFiducary.length > 0 ? getlinkup?.linkupFiducary[0]?.email : ''

  const {
    data: getNoteData,
    refetch: getNoteRefetch,
    isLoading,
  } = useGet('get-noteData ', `/noteData/get?email=${email}`, false, {
    token: true,
  })

  useEffect(() => {
    getAllTokensInWallet()
    linkupFetch()
  }, [])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  useEffect(() => {
    if (getlinkup?.linkupFiducary[0]?.email) getNoteRefetch()
  }, [getlinkup])

  useEffect(() => {
    if (user?.userWalletId) fetchNativeBalance()
  }, [user])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod' && user?.userWalletId) fetchTokenPrice()
  }, [user])

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <ContainerSecond>
            <LeftSection>
              <LeftTopSection>
                <Box>
                  <WelcomeBox>
                    <WelcomeSection>Welcome {fiduciaryName}!</WelcomeSection>

                    {getNoteData?.userGet?.message !== undefined && user?.isContractEtherWithdrawn && (
                      <div>
                        <MessageText>{`Grantor's Message for you!`}</MessageText>
                        <MessageContainer showFull={showFull}>
                          <div>
                            {showFull
                              ? getNoteData?.userGet?.message
                              : `${getNoteData?.userGet?.message.slice(0, 100)}...`}
                          </div>
                          <ReadMore onClick={() => setShowFull(!showFull)}>
                            {showFull ? 'Read less' : 'Read more'}
                          </ReadMore>
                        </MessageContainer>
                      </div>
                    )}
                  </WelcomeBox>
                </Box>
              </LeftTopSection>
              <LeftBottomSection>
                <DashbordContentSection>
                  <Planfiduciary ownerName={ownerName} />
                </DashbordContentSection>
              </LeftBottomSection>
            </LeftSection>
            <SectionRight>
              <RightSection>
                <RightWalletSection>
                  <TextArea>Wallets</TextArea>
                  <WalletBox></WalletBox>
                </RightWalletSection>
                <BottomBoxSection>
                  <LeftPlusBox onClick={() => setIsOpen(true)}>
                    <PlusSection src={PlusImage} />
                  </LeftPlusBox>
                  {process.env.REACT_APP_ENVIRONMENT === 'prod' ? (
                    walletInfo ? (
                      <WalletSectionCard
                        image={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet}
                        title={(Number(nativeTokenPrice) * Number(walletNativeBalance))
                          ?.toLocaleString()
                          .substring(0, 9)}
                        subtitle={'USD'}
                        subtext={JSON.parse(walletInfo)?.peerMeta?.name}
                      />
                    ) : (
                      data?.map((item, index) => (
                        <WalletSectionCard
                          key={index}
                          image={item?.image}
                          title={(Number(nativeTokenPrice) * Number(walletNativeBalance))
                            ?.toLocaleString()
                            .substring(0, 9)}
                          subtitle={'USD'}
                          subtext={item?.subtext}
                        />
                      ))
                    )
                  ) : walletInfo ? (
                    <WalletSectionCard
                      image={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet}
                      title={Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
                      subtitle={'USD'}
                      subtext={JSON.parse(walletInfo)?.peerMeta?.name}
                    />
                  ) : (
                    data?.map((item, index) => (
                      <WalletSectionCard
                        key={index}
                        image={item?.image}
                        title={Number(walletNativeBalance)?.toLocaleString().substring(0, 9)}
                        subtitle={'USD'}
                        subtext={item?.subtext}
                      />
                    ))
                  )}
                </BottomBoxSection>
                <RightBenificiaries>
                  <RightContainer>
                    <BenificiariesTextSection>Portfolio</BenificiariesTextSection>
                  </RightContainer>
                  <TableContainer>
                    <table>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                      {tokenData.length ? (
                        tokenData.map((val: ITokenData, key: number) => {
                          return (
                            <tr key={key}>
                              <th>{val.name}</th>
                              <th>{ethers.utils.formatEther(val.balance)}</th>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className="center" colSpan={5}>
                            <NoDataContainer>
                              <NoBeneficiaries>
                                <BeneficiarieImage>
                                  <CryptoLogo src={Watermark} />
                                </BeneficiarieImage>
                                <TextSextion>
                                  <HeadingSection>No Assets Received</HeadingSection>
                                  <DefinitionSection></DefinitionSection>
                                </TextSextion>
                              </NoBeneficiaries>
                            </NoDataContainer>
                          </td>
                        </tr>
                      )}
                    </table>
                  </TableContainer>
                  <BenificiariescardSection></BenificiariescardSection>
                </RightBenificiaries>
              </RightSection>
            </SectionRight>
          </ContainerSecond>
        </ContentWrapper>
      </Wrapper>

      <Modal isOpen={isOpen}>
        <Signin showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </Container>
  )
}

export default FiduciaryMyPlan
