import styled from 'styled-components'
import TextInput from 'components/FormElements/TextInput'
import Button from 'components/Button'
interface ISelectedOption {
  selectedItem?: string
}
export const MainContainer = styled.div``
export const Container = styled.div`
  padding: 35px 30px;
  @media (max-width: 1440px) {
    padding: 15px 30px;
  }

  @media (max-width: 1366px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1366px) {
    padding: 10px 50px;
    min-height: 338px;
  }
  @media (max-width: 1280px) {
    padding: 10px 25px 0 25px;
  }
`
export const CancelSection = styled.img``

export const DisplayInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 53px;
  @media (max-width: 1366px) {
    gap: 31px;
  }
  @media (max-width: 1280px) {
    gap: 31px;
  }
`
export const DisplayName = styled.div`
  font-family: 'OutfitMedium';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  min-width: 150px;
  text-align: left;
  :last-child {
    margin-left: -40px;
  }
  @media (max-width: 1366px) {
    min-width: 150px;
    max-width: 150px;
  }
`

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  gap: 21px;
  margin-top: 30px;
  align-items: flex-start;

  @media (max-width: 1366px) {
    margin-top: 28px;
  }

  .text {
    min-width: 150px;
    max-width: 176px;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    background: transparent;
    height: 32px;
    margin-left: 2px;
  }
  @media (max-width: 1366px) {
    min-width: 150px;
    max-width: 150px;
  }
  .ant-input[disabled] {
    color: #000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`

export const Section = styled.div<ISelectedOption>`
  text-align: left;
  border: 1px solid #dfdfdf;
  border-top: none;

  .ant-select {
    min-width: 150px;
    color: ${({ selectedItem }) => (selectedItem == 'Beneficiary' ? '#2677E9' : '#1BB691')};

    .ant-select-selector {
      border: none;
    }
    .ant-select-arrow {
      color: ${({ selectedItem }) => (selectedItem == 'Beneficiary' ? '#2677E9' : '#1BB691')};
    }
  }
`
export const CountSection = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #dfdfdf;
  min-width: 120px;
  justify-content: center;

  .ant-input-number {
    color: #2677e9;
    font-size: 20px;
  }

  .ant-form-item-control-input {
    align-items: flex-start;
  }

  .ant-input-number-input {
    width: 75px;
  }

  .ant-input-number-group-addon:first-child {
    border: none;
  }

  .ant-input-number-group-addon {
    border: none;
  }

  .ant-input-number-group-addon {
    background-color: #fff;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
    border: none;
  }
  .ant-form-item {
    margin-bottom: 1px;
  }
  @media screen and (max-width: 1366px) {
    min-width: 95px;
  }
`

export const LeftImage = styled.img`
  height: 25px;
  cursor: pointer;
`
export const Text = styled.div`
  font-family: 'OutfitLight';
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const RightImage = styled.img`
  height: 25px;
  cursor: pointer;
`
export const TotalPercentage = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const PercentageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1450px) {
    margin: 20px 40px 0 0;
  }
  @media screen and (max-width: 1280px) {
    margin: 5px 79px 0 0;
  }
`
export const TotalScore = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2677e9;
  margin-right: 76px !important;
  @media screen and (max-width: 1366px) {
    margin-right: 65px;
  }
`
export const ButtonLable = styled.div`
  display: flex;
  text-align: center;
  padding-top: 5px;
  gap: 7px;
  align-items: center;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
`
export const TextArea = styled.div`
  width: fit-content;
  display: flex;
  gap: 10px;
  width: max-content;
  padding: 30px 0 0 0;
  font-family: 'OutfitSemiBold';
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
  cursor: pointer;
`
export const AddMore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 92px;
`

export const Error = styled.div`
  color: red;
  font-size: 12px;
`
export const ErrorAppoint = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`
export const Input = styled(TextInput)`
  width: 100%;
  max-width: 50px;
  border: none;
  padding: 0;
  text-align: center;
`
export const Inputs = styled.div`
  height: 300px;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 233px;
  }
`

export const SelectContainer = styled.div`
  .ant-select {
    width: 130px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
`
export const PerText = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const InputContainer = styled.div`
  .ant-form-item {
    margin-bottom: 4px;
  }
`
export const ImageSection = styled.img`
  height: 26px;
  cursor: pointer;
`

export const VerifyIcon = styled.img`
  height: 25px;
  cursor: pointer;
`
export const StyledButton = styled(Button)<{ type: string }>`
  opacity: ${(props) => (props.type === 'button' ? 0.5 : 1)};
`
export const AppointError = styled.div`
  color: red;
  font-size: 10px;
  padding-top: 10px;
`
export const CheckContainer = styled.div``
