import styled from 'styled-components'

export const RightButton = styled.div``
export const FirstCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px;
`
export const WalletImage = styled.img``
export const WalletText = styled.div`
  width: 100%;
  max-width: 200px;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  gap: 20px;
  display: flex;
  align-items: center;
`
export const TotalConnected = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
