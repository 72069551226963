import Craditcard from 'assets/images/credit-card.png'
import Apple from 'assets/images/apple-logo.png'
import Plaid from 'assets/images/plaid-image.png'
import Paypal from 'assets/images/Paypal-wallet.png'

interface IData {
  image: string
  title: string
}

const data: IData[] = [
  {
    image: Craditcard,
    title: 'Credit/Debit Card',
  },
  {
    image: Apple,
    title: 'Apple Pay',
  },
  {
    image: Plaid,
    title: 'Pay with Plaid',
  },
  {
    image: Paypal,
    title: 'Paypal',
  },
]
export default data
