import MetamaskWallet from 'assets/images/meta-mask.png'
import DashboardWallet from 'assets/images/bitcoin-dashbord.png'
import WalletEtherium from 'assets/images/dashboard-etherium.png'

interface IData {
  id: number
  image?: string
  title: string
  subtitle: string
  subtext: string
  subheading?: string
}
interface TData {
  id: number
  image?: string
  name: string
  symbol: string
  incdec: string
  price: string | number
  value: string
}

const data: IData[] = [
  {
    id: 0,
    image: MetamaskWallet,
    title: '7.00698',
    subtitle: '$64.093',
    subtext: 'MetaMask',
  },
]
const benificiariesData: IData[] = [
  { id: 0, title: 'Jessica', subtitle: 'wallet028nde339', subtext: 'Fiduciary', subheading: '06%' },
  { id: 1, title: 'Eleanor', subtitle: 'wallet028nde339', subtext: 'Beneficiary', subheading: '06%' },
  { id: 2, title: 'Floyd', subtitle: 'wallet028nde339', subtext: 'Beneficiary', subheading: '06%' },
  { id: 3, title: 'Vikram', subtitle: 'wallet028nde339', subtext: 'Beneficiary', subheading: '06%' },
]

const tableData: TData[] = [
  {
    id: 0,
    name: 'Bitcoin',
    image: DashboardWallet,
    symbol: 'BTH',
    incdec: '+$122.19',
    price: '262.99 ETH',
    value: '$1282.00',
  },
  {
    id: 0,
    name: 'Ethereum',
    image: WalletEtherium,
    symbol: 'ETH',
    incdec: '-$122.19',
    price: '262.99 ETH',
    value: '$17822.00',
  },
  {
    id: 0,
    name: 'Binance Coin',
    image: WalletEtherium,
    symbol: 'BNB',
    incdec: '-$122.39',
    price: '262.99 ETH',
    value: '$17822.00',
  },
]
export { data, benificiariesData, tableData }
