import styled from 'styled-components'

export interface IProps {
  label: string
}

export const TableWrapper = styled.form`
  width: 100%;
  display: flex;
  gap: 13px;
  align-items: center;
`

export const ButtonSection = styled.div`
  width: 100%;
  max-width: 180px;
  align-items: center;
  font-size: 7px;
`
export const MainContainer = styled.div`
  width: 100%;
  display: flex;
`

export const InputSection = styled.div``
export const ImageSection = styled.div`
  width: 100%;
  max-width: 28px;
  height: 55px;
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
`
export const QuestionMark = styled.img`
  height: 20px;
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 18px;
  @media (max-width: 767px) {
    width: 90px;
  }
  .textemail {
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 11px;
    height: 47px;

    @media (max-width: 767px) {
      max-width: 26.667vw;
    }
    .ant-form-item {
      margin-top: 8px !important;
    }
    .ant-col {
      margin-top: 12px;
    }
  }
`
