import React from 'react'
import DelectIcon from 'assets/images/delete-icon.png'
import CrossLogo from 'assets/images/grayx-logo.png'
import {
  MainContainer,
  Container,
  LogoSection,
  TextSection,
  HeadingSection,
  Defination,
  ButtonSection,
  Wrapper,
  Cross,
  DeleteContainer,
  CancelButton,
  SaveButton,
} from 'styles/components/RemoveUserModal'

export interface IModalProps {
  showModal: (value: boolean) => void
  deactivateUser: () => void
}

const AccDeactiveModal: React.FC<IModalProps> = ({ showModal, deactivateUser }) => (
  <MainContainer>
    <Wrapper>
      <Container>
        <Cross src={CrossLogo} alt="Close" onClick={() => showModal(false)} />
      </Container>
      <DeleteContainer>
        <LogoSection src={DelectIcon} alt="Delete" />
      </DeleteContainer>
      <TextSection>
        <HeadingSection>Do you want to remove this user?</HeadingSection>
        <Defination></Defination>
      </TextSection>
      <ButtonSection>
        <CancelButton label="Cancel " variant="contained" type="button" onClick={() => showModal(false)} />
        <SaveButton
          label="Deactivate"
          variant="contained"
          type="button"
          onClick={() => {
            deactivateUser()
            showModal(false)
          }}
        />
      </ButtonSection>
    </Wrapper>
  </MainContainer>
)

export default AccDeactiveModal
