import React, { useState, useContext } from 'react'
import { Steps } from 'antd'
import { Container, StepsContainer } from 'styles/views/dashboard/MyPlan/UpgradeSteper'
import ChoosePlan from 'views/dashboard/MyPlan/ChoosePlan'
import ReviewPlan from 'views/dashboard/Subscricptions/ReviewPlan'
import SubscriptionInvoice from 'components/SubscriptionInvoice'
import Modal from 'components/Modal'
import { LoaderContext } from 'context/loader'
import { ethers } from 'ethers'
import { useMoralisWeb3Api } from 'react-moralis'
import UpgradePaymentPlan from '../UpgradePlan/UpgradePaymentPlan'

const { Step } = Steps

const UpgradeSteper: React.FC = () => {
  const [current, setCurrent] = useState(0)
  const [invoiceData, setInvoiceData] = useState<any | null>(null)
  const [blockData, setBlockData] = useState<any | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const Web3Api = useMoralisWeb3Api()
  const [setback, setSetback] = useState(false)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const { setUptoLoader } = useContext(LoaderContext)

  const wrapped_eth_address = process.env.REACT_APP_WRAPPED_NATIVE_CURRENCY_ADDRESS || ''
  const dex_exchange = process.env.REACT_APP_DEX || ''
  const fetchTokenPrice = async () => {
    //Get token price
    const tokenPrice = await Web3Api.token.getTokenPrice({
      address: wrapped_eth_address,
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      exchange: dex_exchange,
    })
    const nativePriceUSD: any = tokenPrice.usdPrice
    return nativePriceUSD
  }

  const payCrypto = async () => {
    if (typeof (window as any).ethereum !== 'undefined') {
      setUptoLoader(true)
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      const signer = provider.getSigner()
      const nativePriceUSD = await fetchTokenPrice()
      const ethersPerDollar = 1 / nativePriceUSD
      const totalEthers = ethersPerDollar * 40
      const transaction = await signer.sendTransaction({
        to: process.env.REACT_APP_RECEIVER_ADDRESS,
        value: ethers.utils.parseEther(totalEthers.toString()),
      })
      const receipt = await transaction.wait()
      const blocknumber = receipt.blockNumber
      const BlockDetails = await provider.getBlock(blocknumber)
      setInvoiceData(receipt)
      setBlockData(BlockDetails)
      setUptoLoader(false)
      setIsOpen(true)
    }
  }

  const steps = [
    {
      title: 'Acknowledgement',
      content: <ChoosePlan next={next || null} prev={prev || null} />,
    },
    {
      title: 'Subscription',
      content: <UpgradePaymentPlan next={next || null} prev={prev || null} />,
    },
    {
      title: 'Review Plan',
      content: <ReviewPlan next={payCrypto || null} prev={prev || null} />,
    },
  ]

  return (
    <Container>
      <StepsContainer>
        <Steps current={current}>
          {steps.map((item, index) => (
            <Step
              onStepClick={() => {
                if (index < current && current != 0) {
                  setSetback(!setback)
                  setCurrent(index)
                }
              }}
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
      </StepsContainer>
      <div className="steps-content">{steps[current].content}</div>
      <Modal isOpen={isOpen}>
        <SubscriptionInvoice
          showModal={(value: boolean) => setIsOpen(value)}
          invoiceData={invoiceData}
          blockData={blockData}
        />
      </Modal>
    </Container>
  )
}

export default UpgradeSteper
