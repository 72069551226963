import { useState, useContext, useEffect } from 'react'
import { Steps, Button } from 'antd'
import DisbursementTree from 'views/dashboard/MyPlan/DisbursementTree'
import PaymentMethod from 'views/dashboard/MyPlan/PaymentMethod'
import ChoosePlanSteper from '../ChoosePlanSteper'
import ErrorAccountChange from 'components/Modal/AccountChangeError'
import PaymentSection from 'views/dashboard/MyPlan/PaymentSection'
import UpdateBenificiaires from 'views/dashboard/MyPlan/UpdateBeneficiaries'
import DisplayBeneficiaries from 'views/dashboard/MyPlan/DisplayBenificiaires'
import usePost from 'hooks/usePost'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import Invoice from 'components/InvoiceModal'
import { LoaderContext } from 'context/loader'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { UpdataDataContext } from 'context/updateBeneficiary'
import { UserContext } from 'context/user'
import { ethers } from 'ethers'
import CPWillFactory from 'artifacts/contracts/CPWillFactoryUpgradeable.sol/CPWillFactoryUpgradeable.json'
import CPWill from 'artifacts/contracts/CPWillUpgradeable.sol/CPWillUpgradeable.json'
import { Container, StepWrapper } from 'styles/views/dashboard/MyPlan/Stepper'
import { useMoralis } from 'react-moralis'
import useGet from 'hooks/useGet'
const { Step } = Steps
import errorName from 'utils/errors/smartContract'
interface IModalProps {
  showModal: (value: boolean) => void
  setHeading: (value: string) => void
}
const StepperContainer: React.FC<IModalProps> = ({ setHeading, showModal }: IModalProps) => {
  const [current, setCurrent] = useState(0)
  const [switchError, setSwitchError] = useState<any>('')
  const [isOpens, setIsOpens] = useState(false)
  const [errorModal, seterrorModal] = useState<string | ''>('')
  const [isOpen, setIsOpen] = useState(false)
  const [invoiceData, setInvoiceData] = useState<any | null>(null)
  const [blockData, setBlockData] = useState<any | null>(null)
  const [willInfo, setWillInfo] = useState<any | null>(null)
  const [disabled, setDisabled] = useState(false)
  const [setback, setSetback] = useState(false)

  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const { user } = useContext(UserContext)
  const { account } = useMoralis()
  const { updateBeneficiariesData } = useContext(UpdataDataContext)
  const { setUptoLoader } = useContext(LoaderContext)
  const { mutateAsync: linkAsync } = usePost()
  const updateBeneficiaryData = updateBeneficiariesData

  const finalCreateData = user?.paymentTransition ? updateBeneficiariesData : beneficiariesData?.data?.beneficary
  const resultFiduciary = finalCreateData?.filter((item: { appointAs: string }) => item?.appointAs === 'Fiduciary')
  const fiduciaryWallet = resultFiduciary[0]?.userWalletId?.toLowerCase()
  const resultBeneficiary = finalCreateData?.filter((item: { appointAs: string }) => item?.appointAs === 'Beneficiary')

  const beneficiaryWallets: any = []
  resultBeneficiary.forEach((element: any) => {
    beneficiaryWallets.push(element?.userWalletId?.toLowerCase())
  })

  const [accountChangeError, showErrorAccountChange] = useState(false)
  const checkAccountChange = async () => {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum)
    const signer = provider.getSigner()
    const signerAddress = await signer.getAddress()
    showErrorAccountChange(!!(signerAddress && validSigner !== signerAddress.toLocaleLowerCase()))
  }

  useEffect(() => {
    checkAccountChange()
  }, [account])

  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  useEffect(() => {
    if (current === 4) setHeading('Choose Payment Method')
    else if (beneficiariesData?.data?.paymentTransition) {
      setHeading('Update My Cryptoplan')
    } else {
      setHeading('Create My Cryptoplan')
    }
  }, [current])
  const steps = [
    {
      title: 'Add Beneficiaries',
      content: beneficiariesData?.data?.paymentTransition ? (
        <UpdateBenificiaires next={next} />
      ) : (
        <DisplayBeneficiaries next={next} />
      ),
    },
    {
      title: 'Disbursement Tree',
      content: (
        <DisbursementTree beneficiariesData={beneficiariesData} beneficiaryData={beneficiariesData?.data?.beneficary} />
      ),
    },
    {
      title: 'Acknowledgement',
      content: <PaymentSection next={next || null} prev={prev} />,
    },
    {
      title: 'Subscription',
      content: <ChoosePlanSteper setDisabled={setDisabled} next={next || null} prev={prev || null} />,
    },
    {
      title: 'Subscription',
      content: <PaymentMethod />,
    },
  ]

  const validSigner: string = user?.userWalletId
  let fiduciaryWalletAddress: Array<string> = []
  let FiduciaryName: string = ''
  const beneficiariesWalletAddress: Array<string> = []
  const beneficiariesAmount: Array<number> = []
  const contractaddress: string = process.env.React_APP_CONTRACT_FACTORY_ADDRESS || ''
  const deployedSigneraddress = process.env.REACT_APP_SIGNER_CONTRACT_ADDRESS || ''

  const { mutateAsync, isSuccess } = usePost()

  beneficiariesData?.data?.beneficary?.forEach((x: any) => {
    if (x.appointAs === 'Fiduciary') {
      fiduciaryWalletAddress.push(x.userWalletId)
      FiduciaryName = x.displayName
    }

    beneficiariesWalletAddress.push(x.userWalletId)
    beneficiariesAmount.push(x.amount * 1000)
  })

  const { data: getAsset } = useGet('get-Asset', 'asset/getAsset', false, {
    enabled: false,
    token: true,
  })

  let tokensArray: Array<any> = []
  // get all assets
  getAsset?.user?.userWallet.forEach((element: any) => {
    tokensArray = [...tokensArray, ...element?.assests]
  })
  // map token to array
  tokensArray = tokensArray.map((e) => e?.tokenAddress)

  // remove duplicate
  tokensArray = tokensArray.filter((item, index) => tokensArray?.indexOf(item) === index)

  const userLink = async () => {
    const payloadLength = finalCreateData?.length - 1

    const finalDataPayload = []
    for (let i = 0; i < finalCreateData?.length; i++) {
      const value = finalCreateData[i]

      const dataPayload = {
        displayName: value?.displayName,
        userWalletId: value?.userWalletId?.toLowerCase(),
        emailId: value?.emailId,
        appointAs: value?.appointAs,
        amount: value?.amount,
      }

      finalDataPayload.push(dataPayload)

      if (i === payloadLength) {
        try {
          const invoiceData = await linkAsync({
            url: 'linkup/createUser',
            payload: finalDataPayload,
            token: true,
          })

          return invoiceData
        } catch (error: any) {
          return { error: error.response.data.message }
        }
      }
    }
  }

  const create = async () => {
    userLink()
    if (typeof (window as any).ethereum !== 'undefined') {
      setUptoLoader(true)
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      const signer = provider.getSigner()
      const signerAddress: string = await signer.getAddress()

      if (validSigner === signerAddress.toLocaleLowerCase()) {
        const contract1 = new ethers.Contract(contractaddress, CPWillFactory.abi, signer)
        try {
          const transaction = await contract1.createCPWILLContractClone(
            fiduciaryWalletAddress,
            beneficiariesWalletAddress,
            beneficiariesAmount,
            tokensArray,
            deployedSigneraddress,
            60,
          )
          const receipt = await transaction.wait()
          const blocknumber = receipt?.blockNumber
          const BlockDetails = await provider.getBlock(blocknumber)
          const will_id = await contract1.getWills(signerAddress)
          const latestContractAddress = will_id[(will_id?.length || 1) - 1]
          const contract2 = new ethers.Contract(latestContractAddress, CPWill.abi, signer)
          const numBeneficiary = await contract2.getNumBeneficiary()

          for (let i = 0; i < receipt?.events?.length; i++) {
            if (receipt?.events[i]?.event === 'ContractCreation') {
              {
                try {
                  await mutateAsync({
                    url: '/notify/event',
                    payload: {
                      message: `CryptoPlan has been created`,
                      contractId: latestContractAddress.toString(),
                      numberofBeneficiary: numBeneficiary.toString(),
                      nameofFiduciary: FiduciaryName,
                      status: receipt.status == 1 ? true : false,
                      type: `contract`,
                      isNotify: `final`,
                      titleName: `Created`,
                      signer: signerAddress,
                      ownerName: user?.userName,
                      userwalletIdFiduciary: fiduciaryWallet,
                      userwalletIdBeneficary: beneficiaryWallets,
                    },
                    token: true,
                  })
                } catch (error: any) {
                  return { error: error.response.data.message }
                }
              }
            }
          }

          eventPayment()
          setInvoiceData(receipt)
          setWillInfo(will_id)
          setBlockData(BlockDetails)
          setUptoLoader(false)
          setIsOpen(true)
        } catch (error: any) {
          setUptoLoader(false)
          setIsOpens(true)
          setSwitchError('')
          seterrorModal(error?.reason ? errorName[error?.reason.split(':')[1].trim() - 1] : error?.message)
          return { error: errorModal }
        }
      } else {
        setUptoLoader(false)
        const accountChangeMessage = `Switch to Primary account ${validSigner}`
        seterrorModal('')
        setSwitchError(accountChangeMessage)
        setIsOpens(true)
      }
    }
  }

  let Addresses: Array<string> = []
  const AddressIdentifier: Array<string> = []
  const addressNotRemoved: Array<string> = []
  const newBeneficiaries: Array<string> = []
  const shares: Array<string> = []
  const newFiduciaries: Array<string> = []
  const fiduciaryNotRemoved: Array<string> = []
  let addTokensCount: number = 0
  let removeTokensCount: number = 0
  let removedBeneficiaryCount: number = 0
  let newBeneficiaryCount: number = 0
  let sharesCount: number = 0
  let newFiduciaryCount: number = 0
  let removedFiduciaryCount: number = 0

  const eventPayment = async () => {
    try {
      await mutateAsync({
        url: '/notify/event',
        payload: {
          message: 'Payment successful',
          status: true,
          type: `transition`,
          titleName: `Payment`,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const update = async () => {
    userLink()
    for (let i = 0; i < updateBeneficiaryData?.length; i++) {
      let addressPresent: boolean = false
      let fiduciaryPresent: boolean = false

      for (let j = 0; j < beneficiariesData?.data?.beneficary?.length; j++) {
        if (updateBeneficiaryData[i]?.userWalletId === beneficiariesData?.data?.beneficary[j]?.userWalletId) {
          addressPresent = true
          addressNotRemoved.push(beneficiariesData?.data?.beneficary[j]?.userWalletId)
        }

        if (
          updateBeneficiaryData[i]?.appointAs === 'Fiduciary' &&
          beneficiariesData?.data?.beneficary[j]?.appointAs === 'Fiduciary' &&
          updateBeneficiaryData[i]?.userWalletId === beneficiariesData?.data?.beneficary[j]?.userWalletId
        ) {
          fiduciaryPresent = true
          fiduciaryNotRemoved.push(beneficiariesData?.data?.beneficary[j]?.userWalletId)
        }
      }

      if (!addressPresent) {
        // newBeneficiaries
        newBeneficiaryCount += 1
        sharesCount += 1
        newBeneficiaries.push(updateBeneficiaryData[i]?.userWalletId?.toString())
        shares.push((updateBeneficiaryData[i]?.amount * 1000)?.toString())
      }

      if (updateBeneficiaryData[i]?.appointAs === 'Fiduciary' && !fiduciaryPresent) {
        // newFiduciaries
        newFiduciaryCount++
        fiduciaryWalletAddress = updateBeneficiaryData[i]?.userWalletId
        FiduciaryName = updateBeneficiaryData[i]?.displayName
        newFiduciaries.push(fiduciaryWalletAddress?.toString())
      }
    }

    // Remove Fiduciaries
    for (let i = 0; i < beneficiariesData?.data?.beneficary?.length; i++) {
      if (
        beneficiariesData?.data?.beneficary[i]?.appointAs === 'Fiduciary' &&
        !fiduciaryNotRemoved.includes(beneficiariesData?.data?.beneficary[i]?.userWalletId)
      ) {
        removedFiduciaryCount += 1
        Addresses.push(beneficiariesData?.data?.beneficary[i]?.userWalletId.toString())
      }
    }

    AddressIdentifier.push(removedFiduciaryCount.toString())
    AddressIdentifier.push(newFiduciaryCount.toString())
    Addresses = Addresses.concat(newFiduciaries)

    // Remove Beneficiaries
    for (let i = 0; i < beneficiariesData?.data?.beneficary?.length; i++) {
      if (!addressNotRemoved.includes(beneficiariesData?.data?.beneficary[i]?.userWalletId)) {
        removedBeneficiaryCount += 1
        Addresses.push(beneficiariesData?.data?.beneficary[i]?.userWalletId?.toString())
      }
    }

    AddressIdentifier.push(removedBeneficiaryCount.toString())
    AddressIdentifier.push(newBeneficiaryCount.toString())
    Addresses = Addresses.concat(newBeneficiaries)
    Addresses = Addresses.concat(newBeneficiaries)

    for (let i = 0; i < updateBeneficiaryData?.length; i++) {
      for (let j = 0; j < beneficiariesData?.data?.beneficary?.length; j++) {
        if (
          updateBeneficiaryData[i]?.userWalletId === beneficiariesData?.data?.beneficary[j]?.userWalletId &&
          updateBeneficiaryData[i]?.amount !== beneficiariesData?.data?.beneficary[j]?.amount
        ) {
          // Shares updated
          Addresses.push(updateBeneficiaryData[i]?.userWalletId)
          shares.push((updateBeneficiaryData[i]?.amount * 1000)?.toString())
          sharesCount += 1
        }
      }
    }

    AddressIdentifier.push(sharesCount.toString())

    if (typeof (window as any).ethereum !== 'undefined') {
      setUptoLoader(true)
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      const signer = provider.getSigner()
      const signerAddress: string = await signer.getAddress()
      if (validSigner === signerAddress.toLocaleLowerCase()) {
        const contract1 = new ethers.Contract(contractaddress, CPWillFactory.abi, signer)
        const will_id = await contract1.getWills(signerAddress)
        const latestContractAddress = will_id[(will_id?.length || 1) - 1]
        const contract2 = new ethers.Contract(latestContractAddress, CPWill.abi, signer)
        const contractToken: Array<string> = await contract2.getAllTokens()
        const contractTokenLowercase: Array<string> = []

        for (let i = 0; i < contractToken.length; i++) {
          contractTokenLowercase.push(contractToken[i].toLocaleLowerCase())
        }

        for (let i = 0; i < contractTokenLowercase.length; i++) {
          if (!tokensArray.includes(contractTokenLowercase[i])) {
            Addresses.push(contractTokenLowercase[i])
            removeTokensCount++
          }
        }

        for (let i = 0; i < tokensArray.length; i++) {
          if (!contractTokenLowercase.includes(tokensArray[i])) {
            Addresses.push(tokensArray[i])
            addTokensCount++
          }
        }

        AddressIdentifier.push(removeTokensCount.toString())
        AddressIdentifier.push(addTokensCount.toString())
        try {
          const transaction = await contract2.update(
            AddressIdentifier,
            Addresses,
            shares,
            '400',
            '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65',
            60,
          )
          const receipt = await transaction.wait()
          const displayName = (displayNameAddress: string) => {
            for (let i = 0; i < updateBeneficiaryData?.length; i++) {
              if (updateBeneficiaryData[i]?.userWalletId === displayNameAddress) {
                return updateBeneficiaryData[i]?.displayName
              }
            }
          }

          for (let i = 0; i < receipt?.events?.length; i++) {
            if (receipt?.events[i]?.event === 'WillFiduciaryChanged') {
              const WillFiduciaryChangedAddress: string = receipt?.events[i]?.args[0]
              const name = displayName(WillFiduciaryChangedAddress)
              await mutateAsync({
                url: '/notify/event',
                payload: {
                  message: `${name} has been added as a Fiduciary`,
                  titleName: `Fiduciary Changed`,
                  type: `contract`,
                },
                token: true,
              })
            } else if (receipt?.events[i]?.event === 'WillBeneficiaryRemoved') {
              const WillBeneficiaryRemovedAddress = receipt?.events[i]?.args[0]
              let name: string = ''
              for (let j = 0; j < beneficiariesData?.data?.beneficary?.length; j++) {
                if (beneficiariesData?.data?.beneficary[j]?.userWalletId === WillBeneficiaryRemovedAddress) {
                  name = beneficiariesData?.data?.beneficary[j]?.displayName
                }
              }

              await mutateAsync({
                url: '/notify/event',
                payload: {
                  message: `${name} has been removed as a beneficiary`,
                  titleName: `Beneficiary Removed`,
                  type: `contract`,
                },
                token: true,
              })
            } else if (receipt?.events[i]?.event === 'WillBeneficiaryAdded') {
              const WillBeneficiaryAddedAddress: string = receipt.events[i]?.args[0]
              const name = displayName(WillBeneficiaryAddedAddress)
              await mutateAsync({
                url: '/notify/event',
                payload: {
                  message: `${name} has been added as a Beneficiary`,
                  titleName: `Beneficiary Added`,
                  type: `contract`,
                },
                token: true,
              })
            } else if (receipt?.events[i]?.event === 'WillUpdatedShares') {
              const WillUpdatedSharesAddress: string = receipt.events[i]?.args[0]
              const name = displayName(WillUpdatedSharesAddress)
              await mutateAsync({
                url: '/notify/event',
                payload: {
                  message: `${name}'s share has been updated`,
                  titleName: `Shares Updated`,
                  type: `contract`,
                },
                token: true,
              })
            }
          }

          const blocknumber = receipt.blockNumber
          const BlockDetails = await provider.getBlock(blocknumber)
          const numBeneficiary = await contract2.getNumBeneficiary()

          await mutateAsync({
            url: '/notify/event',
            payload: {
              message: `Your CryptoPlan has been updated`,
              contractId: latestContractAddress.toString(),
              numberofBeneficiary: numBeneficiary.toString(),
              nameofFiduciary: FiduciaryName,
              status: receipt.status == 1 ? true : false,
              type: `contract`,
              isNotify: `final`,
              titleName: `Updated`,
              signer: signerAddress,
              ownerName: user?.userName,
              userwalletIdFiduciary: fiduciaryWallet,
              userwalletIdBeneficary: beneficiaryWallets,
            },
            token: true,
          })
          eventPayment()
          setInvoiceData(receipt)
          setWillInfo(will_id)
          setBlockData(BlockDetails)
          setUptoLoader(false)
          setIsOpen(true)
        } catch (error: any) {
          setUptoLoader(false)
          setIsOpens(true)
          setSwitchError('')
          seterrorModal(error?.reason ? errorName[error?.reason.split(':')[1].trim() - 1] : error?.message)
          return { error: errorModal }
        }
      } else {
        setUptoLoader(false)
        const accountChangeMessage = `Switch to registered account ${validSigner?.substring(
          0,
          10,
        )}..${validSigner.substring(validSigner?.length - 4)}`
        seterrorModal('')
        setSwitchError(accountChangeMessage)
        setIsOpens(true)
      }
    }
  }

  return (
    <Container>
      <StepWrapper className={current === 4 ? 'no-visible' : ''}>
        <Steps current={current}>
          {steps.map((item, index) => (
            <Step
              onStepClick={() => {
                if (index < current && current != 0) {
                  setSetback(!setback)
                  setCurrent(index)
                }
              }}
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
      </StepWrapper>

      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current === 1 && (
          <Button
            type="primary"
            onClick={() => {
              setSetback(!setback)
              prev()
            }}
            className="back-button"
          >
            Back
          </Button>
        )}

        {current === 4 && (
          <Button
            type="primary"
            onClick={() => {
              setSetback(!setback)
              prev()
            }}
            className="back-button"
          >
            Back
          </Button>
        )}
        {current > 0 && current < steps.length - 3 && (
          <Button
            type="primary"
            disabled={disabled}
            htmlType="submit"
            onClick={() => {
              setSetback(!setback)
              next()
            }}
            className="nextTree-button"
          >
            Next
          </Button>
        )}
        {current > 0 && current < steps.length - 4 && (
          <Button
            type="primary"
            disabled={disabled}
            htmlType="submit"
            onClick={() => {
              setSetback(!setback)
              next()
            }}
            className="next-button"
          >
            Next
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            className="next-button"
            onClick={() => (!updateBeneficiaryData?.length ? create() : update())}
          >
            Pay Now
          </Button>
        )}
      </div>
      <Modal isOpen={isOpens}>
        <ErrorModal
          showModal={(value: boolean) => setIsOpens(value)}
          success={isSuccess}
          errorModal={errorModal}
          switchError={switchError}
        />
      </Modal>
      <Modal isOpen={isOpen}>
        <Invoice
          showModal={(value: boolean) => setIsOpen(value)}
          hidePreviousModal={() => showModal(false)}
          invoiceData={invoiceData}
          blockData={blockData}
          willInfo={willInfo}
        />
      </Modal>
      <Modal isOpen={accountChangeError}>
        <ErrorAccountChange
          showModal={() => {
            showErrorAccountChange(false)
          }}
          success={false}
          errorModal={`Switch to registered account ${validSigner?.substring(0, 10)}..${validSigner.substring(
            validSigner?.length - 4,
          )}`}
        />
      </Modal>
    </Container>
  )
}

export default StepperContainer
