import React from 'react'
import {
  CardContainer,
  TitleContainer,
  Title,
  SubTitle,
  ImgConatiner,
  ImageWrapper,
} from 'styles/components/Cards/Beneficiaries'

export interface ICardProps {
  title?: string
  subtitle?: string
  image?: string
  color?: string
}

const BeneficiariesCard = ({ image, title, subtitle, color }: ICardProps) => (
  <CardContainer color={color} image={image}>
    <ImgConatiner>
      <ImageWrapper src={image} />
    </ImgConatiner>
    <TitleContainer>
      <Title className="benefiaries" color={color}>
        {title}
      </Title>
      <SubTitle>{subtitle}</SubTitle>
    </TitleContainer>
  </CardContainer>
)

export default BeneficiariesCard
