import styled from 'styled-components'
import { theme } from 'theme'

export const Container = styled.div`
  background: #d9dde2;
  width: 100%;
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  min-height: 900px;
  width: 100%;
`
export const WelcomeTextSection = styled.div`
  display: flex;
  gap: 22px;
  align-items: baseline;
  justify-content: center;
  justify-content: space-between;
`
export const TopTextSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 52px;
  line-height: 80px;
  color: #0a0c17;
  text-align: center;
  padding: 20px;
  @media (max-width: 767px) {
    font-size: 11.2vw;
    line-height: 13.333vw;
    text-align: center;
    padding: 13.333vw 0 24vw 0;
  }
`
export const BottomTextSection = styled.div`
  font-family: 'OutfitLight';
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #737b7d;
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 23px;
`
export const ContainerTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex: auto;
`
export const PrimaryWalletSection = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  background: ${theme.primary.light};
  border-radius: 34px;
  @media (max-width: 767px) {
    max-width: 89.333vw;
  }
`
export const PrimaryText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  color: #0a0c17;
  text-align: initial;
`
export const PrimaryWalletContainer = styled.div`
  padding: 28px 46px 28px 46px;
  @media (max-width: 767px) {
    padding: 2.667vw;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #d9dde2;
  position: sticky;
  padding: 10px 10px 10px 10px;
  @media (max-width: 1366px) {
    padding: 10px 10px 0 10px;
  }
`
export const ThemeIcon = styled.img`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    height: 11.467vw;
    width: 11.467vw;
  }
`
export const LogoSection = styled.img`
  @media (max-width: 767px) {
    width: 41.333vw;
  }
`

export const TopSection = styled.div`
  display: flex;
  justify-content: center;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  padding-bottom: 20px;
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 15px 0px 20px 0px;
  cursor: pointer;
  table {
    width: 100%;
    padding: 6px;
    align-items: center;
  }
  th {
    :nth-child(1) {
      width: 147px;
    }
    :nth-child(2) {
      width: 147px;
    }
    :nth-child(3) {
      width: 147px;
    }
    :nth-child(4) {
      width: 147px;
    }
    @media (max-width: 767px) {
      font-size: 3.2vw;
      color: #737b7d;
    }
  }
  td {
    position: relative;
    font-family: 'OutfitMedium';
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.02em;
    padding: 14px;
    text-align: center;
    border-bottom: 1px solid #dfdfdf;
    @media (max-width: 767px) {
      padding: 2.133vw;
      font-size: 3.2vw;
    }
  }
  table tr input {
    padding: 0 10px;
  }
`
export const ButtonSection = styled.div`
  width: 100%;
  max-width: 190px;
  align-items: center;
  padding-top: 30px;
  display: flex;
  margin-left: auto;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 26.4vw;
  }
`
export const Defination = styled.div`
  font-family: 'OutfitBold';
  font-size: 26px;
  margin-top: 10px;
`
export const CryptoLogo = styled.img`
  height: 176px;
  width: 179px;
  text-align: center;

  &.minified {
    height: 38px;
    width: 38px;
  }

  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
