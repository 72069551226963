import { ReactElement, useState, useEffect, useContext, useRef } from 'react'
import { useMoralis, useMoralisWeb3Api } from 'react-moralis'
import { UserContext } from 'context/user'
import Modal from 'components/Modal'
import WalletSectionCard from 'components/Cards/Wallet'
import { useDraggable } from 'react-use-draggable-scroll'
import PlusImage from 'assets/images/plus-image.png'
import CryptoWallet from 'assets/images/cryptowallet-logo.png'
import MetaMask from 'assets/images/meta-mask.png'

import {
  RightSection,
  RightWalletSection,
  TextArea,
  LeftPlusBox,
  BottomBoxSection,
  PlusSection,
  ScrollContainer,
} from 'styles/views/dashboard/MyPlan'
import AddWalletAddressModal from 'components/Modal/AddWalletAddressModal'

const Wallets = (): ReactElement => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref, {
    activeMouseButton: 'Left', // activate rubber band effect
    applyRubberBandEffect: true,
  })

  const [isWalletAddrAdd, setIsWalletAddrAdd] = useState(false)
  const [walletBalances, setWalletBalances] = useState<any>([])
  const { user } = useContext(UserContext)
  const walletBalance: number[] = []
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api()

  const fetchNativeBalance = async (specificAccount: any) => {
    const { balance } = await Web3Api.account.getNativeBalance({
      chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      address: specificAccount,
    })

    const assetValue = parseFloat(Moralis.Units.FromWei(balance, 18))
    walletBalance.push(assetValue)
    setWalletBalances(walletBalance)
  }

  const lengthIterator = user?.userWallet.length

  useEffect(() => {
    callingFetchbalance(user?.userWallet, async (element: any) => {
      await fetchNativeBalance(element?.walletId)
    })
  }, [lengthIterator])

  async function callingFetchbalance(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  const displayData = user?.userWallet
  const walletInfo = localStorage?.getItem('walletconnect') || ''

  return (
    <>
      <RightSection>
        <RightWalletSection>
          <TextArea>Wallets</TextArea>
        </RightWalletSection>
        <BottomBoxSection {...events} ref={ref}>
          <LeftPlusBox onClick={() => setIsWalletAddrAdd(true)}>
            <PlusSection src={PlusImage} />
          </LeftPlusBox>
          <ScrollContainer>
            {displayData?.map((_item: any, index: number) => {
              return (
                <div key={index} className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide">
                  {walletInfo ? (
                    <WalletSectionCard
                      image={JSON.parse(walletInfo)?.peerMeta?.icons[0] || CryptoWallet}
                      title={walletBalances[index]?.toLocaleString() || '0'}
                      subtitle={'USD'}
                      subtext={`${user.userWallet[index].walletId.substring(0, 5)}...${user.userWallet[
                        index
                      ].walletId.substring(
                        user.userWallet[index].walletId.length - 3,
                        user.userWallet[index].walletId.length,
                      )}`}
                    />
                  ) : (
                    <WalletSectionCard
                      image={_item.primaryWalletName === 'MetaMask' ? MetaMask : CryptoWallet}
                      title={walletBalances[index]?.toLocaleString() || '0'}
                      subtitle={'USD'}
                      subtext={`${user.userWallet[index].walletId.substring(0, 5)}...${user.userWallet[
                        index
                      ].walletId.substring(
                        user.userWallet[index].walletId.length - 3,
                        user.userWallet[index].walletId.length,
                      )}`}
                    />
                  )}
                </div>
              )
            })}
          </ScrollContainer>
        </BottomBoxSection>
      </RightSection>
      <Modal isOpen={isWalletAddrAdd}>
        <AddWalletAddressModal showModal={(value: boolean) => setIsWalletAddrAdd(value)} />
      </Modal>
    </>
  )
}

export default Wallets
