import React, { useState } from 'react'
import Heading from 'components/Heading'
import SubHeading from 'components/SubHeading'
import {
  Container,
  Wrapper,
  RightSubSection,
  LeftSection,
  HeadOption,
  RightSection,
} from 'styles/views/landing/MobileHowWeWork'
import MobileSubscriberRoadMap from 'views/landing/MobileSubscriberRoadMap'
import MobileBeneficiaryRoadMap from 'views/landing/MobileBeneficiaryRoadMap'
interface IWorkProps {
  workRef: React.RefObject<HTMLDivElement>
}

const MobileHowWeWork: React.FC<IWorkProps> = ({ workRef }: IWorkProps) => {
  const [subscriberRoadmap, setSubscriberRoadmap] = useState(true)
  const [beneficiaryRoadmap, setBenificiaryRoadmap] = useState(false)

  return (
    <Container ref={workRef}>
      <Wrapper>
        <LeftSection>
          <Heading label="How it Works" />
          <SubHeading label="Create your Cryptoplan on our smart contract engine with only a few clicks" />
        </LeftSection>
        <RightSection>
          <RightSubSection>
            <HeadOption
              active={subscriberRoadmap}
              onClick={() => {
                setSubscriberRoadmap(true)
                setBenificiaryRoadmap(false)
              }}
            >
              For Subscriber
            </HeadOption>

            <HeadOption
              active={beneficiaryRoadmap}
              onClick={() => {
                setSubscriberRoadmap(false)
                setBenificiaryRoadmap(true)
              }}
            >
              For Beneficiary
            </HeadOption>
          </RightSubSection>
        </RightSection>
      </Wrapper>
      {subscriberRoadmap ? (
        <>
          <MobileSubscriberRoadMap />
        </>
      ) : (
        <>
          <MobileBeneficiaryRoadMap />
        </>
      )}
    </Container>
  )
}

export default MobileHowWeWork
