import React, { RefAttributes, FC, useContext, useEffect, useState } from 'react'
import ReactFlow from 'reactflow'
import { TreeData } from 'components/Tree/TreeData'
import 'reactflow/dist/style.css'
import { ReactZoomPanPinchProps, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { Container, TopSection, TextSection, ProfileText } from 'styles/components/Zoomable'
import { UniqueTokensContext } from 'context/uniqueTokens'
import { UserContext } from 'context/user'
import { useMoralisWeb3Api } from 'react-moralis'
import Modal from 'components/Modal'
import AddWalletAddressModal from 'components/Modal/AddWalletAddressModal'

const Zoomable: FC<Omit<ReactZoomPanPinchProps, 'ref'> & RefAttributes<ReactZoomPanPinchRef>> = () => {
  interface ITokenData {
    name: string
    balance: string
  }
  const { uniqueTokensContext, setUniqueTokensContext } = useContext(UniqueTokensContext)

  const Web3Api = useMoralisWeb3Api()
  const { user } = useContext(UserContext)
  const linkedAddresses = user?.userWallet

  const fetchAssetPriceBalance = async () => {
    const tokens: any[] = []
    for (let iterator = 0; iterator < linkedAddresses.length; iterator++) {
      const tokenbalances: any[] = await Web3Api.account.getTokenBalances({
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
        address: linkedAddresses[iterator].walletId, //this holds internally linked address of MM
      })
      tokens.push(...tokenbalances)
    }

    const uniqueToken = [
      ...tokens
        .reduce((map, current) => {
          const { token_address } = current
          const grouped = map.get(token_address)
          if (!grouped) {
            map.set(token_address, { ...current })
          } else {
            map.set(token_address, {
              ...grouped,
              balance: (Number(grouped.balance) + Number(current.balance)).toLocaleString('fullwide', {
                useGrouping: false,
              }),
            })
          }

          return map
        }, new Map())
        .values(),
    ]

    setUniqueTokensContext(uniqueToken)

    //Get asset token price
    const getAssetsPrice: any = []
    let totalassetsvalue: any = 0

    for (let iterator = 0; iterator < uniqueToken.length; iterator++) {
      const assetPrice = await Web3Api.token.getTokenPrice({
        address: uniqueToken[iterator]?.token_address,
        chain: process.env.REACT_APP_WEB3_CHAIN_ID as any,
      })
      const assetPriceUSD: any = assetPrice?.usdPrice
      totalassetsvalue = totalassetsvalue + assetPriceUSD
      getAssetsPrice?.push(assetPriceUSD)
    }
  }

  const [isWalletAddrAdd, setIsWalletAddrAdd] = useState(false)
  const total = TreeData(user, uniqueTokensContext, setIsWalletAddrAdd)

  useEffect(() => {
    if (!uniqueTokensContext.length) fetchAssetPriceBalance()
  }, [user])
  return (
    <React.Fragment>
      <Container>
        <TopSection>
          <TextSection>
            <ProfileText>Disbursement Tree </ProfileText>
          </TextSection>
        </TopSection>
        <div style={{ width: '100%', height: '75vh' }} className="react-flow-wrapper">
          {total != undefined && (
            <ReactFlow
              nodes={total[0]}
              edges={total[1]}
              fitView
              zoomOnDoubleClick={false}
              zoomOnScroll={true}
              preventScrolling={true}
            />
          )}
          <Modal isOpen={isWalletAddrAdd}>
            <AddWalletAddressModal showModal={(value: boolean) => setIsWalletAddrAdd(value)} />
          </Modal>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default Zoomable
