import { ReactElement, useEffect } from 'react'
import useGet from 'hooks/useGet'
import BeneficaryNavBar from 'components/BeneficaryNavBar'
import BeneficarySidebar from 'components/BeneficarySidebar'
import { Container, SecContainer, SidebarSection } from 'styles/pages/Fiduciarydashboard'
import BebeficaryMyPlan from 'views/dashboard/BeneficaryMyPlan'

export const Beneficarydashboard = (): ReactElement => {
  const { data: beneficary, refetch: beneficaryRefetch } = useGet('Benificary', 'beneficary/getBeneficary', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    beneficaryRefetch()
  }, [])

  const name = beneficary?.user && beneficary?.user.length > 0 ? beneficary?.user[0].displayName : ''
  const email = beneficary?.user && beneficary?.user.length > 0 ? beneficary?.user[0].email : ''

  return (
    <Container>
      <BeneficaryNavBar beneificaryName={name} />
      <SecContainer>
        <SidebarSection>
          <BeneficarySidebar />
        </SidebarSection>
        <BebeficaryMyPlan beneificaryName={name} message={email} />
      </SecContainer>
    </Container>
  )
}

export default Beneficarydashboard
