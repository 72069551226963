import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from 'components/FormElements/TextInput'
import { Button } from 'antd'
import Modal from 'components/Modal'
import Otp from 'components/Modal/OtpModal'
import ErrorModal from 'components/ErrorModal'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CancelSign from 'assets/images/vector-cancel.png'
import { UserContext } from 'context/user'
import {
  StyledContainer,
  ImageSection,
  CancelSection,
  TextSection,
  Container,
  Wrapper,
  PrimaryWalletSection,
  Error,
  CrossSection,
  InputContainer,
  ButtonContainer,
} from 'styles/components/Modal/MetamaskModal'
import usePost from 'hooks/usePost'
export interface IModalProps {
  showModal: (value: boolean) => void
}
const schema = yup.object().shape({
  userWalletId: yup
    .string()
    .required('Wallet address is required ')
    .matches(/^\S+$/, 'Space is not allowed ')
    .matches(/^$|[a-zA-Z0-9_]$/, 'Special characters are not allowed ')
    .min(40, 'Wallet Address must be 40-46 characters')
    .max(46, 'Wallet Address must be 40-46 characters'),
})
interface IData {
  userWalletId?: string
}
let userwalletId = ''
const AddWalletModal: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [otpVerifyModal, setOtpVerifyModal] = useState(false)

  const [errorModal, seterrorModal] = useState(false)
  const { user } = useContext(UserContext)
  const { mutateAsync } = usePost()
  const [Selector, setSelector] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange', resolver: yupResolver(schema) })
  const displayData_array = user?.userWallet
  const onSubmit = async (data: IData) => {
    userwalletId = data?.userWalletId as string
    displayData_array.map(async (element: any) => {
      if (userwalletId.toUpperCase() == element.walletId.toUpperCase()) {
        // alert('Cannot link same wallet')
        seterrorModal(true)
      } else {
        try {
          const response = await mutateAsync({
            url: 'verify/otp',
          })
          if (response?.success) {
            setOtpVerifyModal(true)
            setSelector('MetaMask')
          } else {
            alert('Problem in sending OTP!')
          }
        } catch (err) {
          alert(err)
        }
      }
    })
  }

  return (
    <StyledContainer>
      <Container>
        <Wrapper>
          <PrimaryWalletSection>
            <ImageSection>
              <TextSection>Enter your Metamask Wallet</TextSection>
            </ImageSection>
            <CrossSection>
              <CancelSection src={CancelSign} onClick={() => showModal(false)} />
            </CrossSection>
          </PrimaryWalletSection>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <InputContainer>
                <TextInput
                  name={'userWalletId'}
                  className="text"
                  placeholder="Wallet Address "
                  control={control}
                  autoComplete="off"
                />
              </InputContainer>
              <InputContainer>
                <Error>{formErrors?.userWalletId?.message}</Error>
              </InputContainer>
              <ButtonContainer>
                <Button type="primary" className="sub-button" htmlType="submit">
                  Next
                </Button>
              </ButtonContainer>
            </div>
          </form>
        </Wrapper>
      </Container>
      <Modal isOpen={otpVerifyModal}>
        <Otp
          showModal={(value: boolean) => {
            if (value === false) {
              setOtpVerifyModal(value)
              //   setInviteModal(true)
            }
          }}
          userwalletId={userwalletId}
          fromSelector={Selector}
        />
      </Modal>
      <Modal isOpen={errorModal}>
        <ErrorModal
          showModal={(value: boolean) => seterrorModal(value)}
          sameAddError={'Cannot link an Already linked Address'}
          success={true}
        />
      </Modal>
    </StyledContainer>
  )
}

export default AddWalletModal
