import { useState } from 'react'
import Modal from 'components/Modal'
import ContractDetails from 'components/Cards/ContractDetails'
import { ActionButton } from 'styles/components/Status'

export interface IProps {
  eventId?: string
}

const Action: React.FC<IProps> = ({ eventId }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ActionButton label={'View'} onClick={() => setIsOpen(true)} />

      <Modal isOpen={isOpen}>
        <ContractDetails showModal={(value: boolean) => setIsOpen(value)} eventId={eventId} />
      </Modal>
    </>
  )
}

export default Action
