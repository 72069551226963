import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .react-flow {
  }
  .react-flow__container {
  }

  .react-flow__renderer {
  }

  a {
    display: none;
  }
  .react-flow__viewport {
  }
  .Benwrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
    @media (max-width: 1366px) {
      margin: 0px;
    }
  }
  .addwallet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .react-flow__node-input {
    width: 23% !important;
    @media (max-width: 767px) {
      width: 65vw !important;
    }
  }
  .react-flow__node-default {
    width: 23% !important;
    height: 23.5% !important;
    @media (max-width: 767px) {
      height: 14.933vw !important;
      width: 56vw !important;
    }
  }
  .stepperWalletWrapper {
    width: 100%;
  }
  .stepperTreeImage {
    width: 33%;
  }
`
