import React from 'react'
import UpgradeStepper from 'views/dashboard/MyPlan/UpgradeSteper'
import NavBar from 'components/NavBar'
import { MainContainer, Container } from 'styles/views/dashboard/MyPlan/UpgradePlan'

const UpgradePlan = () => (
  <MainContainer>
    <Container>
      <NavBar />
      <UpgradeStepper />
    </Container>
  </MainContainer>
)
export default UpgradePlan
