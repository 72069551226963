import * as React from 'react'
import { ReactElement, useState } from 'react'
import Modal from 'components/Modal'
import AddNote from 'components/Cards/AddNote'
import Notes from 'assets/svg/Notes'
import { NotesContainer } from 'styles/components/Cards/ContractDetails'

const SvgComponent = ({ index }: { index: number }): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <NotesContainer>
      <Notes onClick={() => setIsOpen(true)} />
      <Modal isOpen={isOpen}>
        <AddNote index={index} showModal={(value: boolean) => setIsOpen(value)} />
      </Modal>
    </NotesContainer>
  )
}

export default SvgComponent
