interface IData {
  title: string
  value?: number
  dollar?: string
}

const plan: IData[] = [
  {
    title: 'Lite',
    dollar: '$',
    value: 5.99,
  },
  {
    title: 'Pro',
    dollar: '$',
    value: 9.99,
  },
]
const subsCycle: IData[] = [
  {
    title: 'Monthly',
    dollar: '$',
    value: 9.99,
  },
  {
    title: 'Yearly',
    dollar: '$',
    value: 14.99,
  },
]
export { plan, subsCycle }
