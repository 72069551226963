import React from 'react'
import {
  WalletTableInfo,
  NameSection,
  NameInfo,
  WalletInfo,
  InfoWaleet,
  PercentageSection,
} from 'styles/components/Cards/DashboardBeneficiaries'
export interface ICardProps {
  title: string
  subtitle: string
  image?: string
  subtext: string
  subheading?: string
}

const BenificiariesCards: React.FC<ICardProps> = ({ title, subtitle, subtext, subheading }) => (
  <WalletTableInfo>
    <NameSection>
      <NameInfo>{title}</NameInfo>
      <WalletInfo>{subtitle}</WalletInfo>
    </NameSection>
    <InfoWaleet>{subtext}</InfoWaleet>
    <PercentageSection>{subheading}%</PercentageSection>
  </WalletTableInfo>
)

export default BenificiariesCards
