import styled from 'styled-components'
import { theme } from 'theme'

export const BeneficiariesContainer = styled.div`
  height: 100%;
  padding: 60px 30px 30px 30px;
  @media (max-width: 767px) {
    margin-top: 0.533vw !important;
  }

  @media (max-width: 1366px) {
    margin-top: 50px;
  }
`
export const HeadingText = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 76px;
  color: ${theme.info.light};
  @media (max-width: 1350px) {
    font-size: 60px;
  }

  @media (max-width: 767px) {
    font-family: 'OutfitBold';
    font-size: 8.533vw;
    color: #ffffff;
    width: 100%;
    max-width: 77.933vw;
    padding-top: 0px;
    text-align: left;
  }
`

export const SubHeadingText = styled.div`
  width: 100%;
  max-width: 786px;
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 29px;
  color: ${theme.info.extra_light};
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 1366px) {
    font-size: 19px;
  }
  @media (max-width: 767px) {
    font-family: 'OutfitRegular';
    font-size: 4.267vw;
    line-height: 5.867vw;
    color: #ffffff;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 77.8vw;
    text-align: left;
  }
`

export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0 25px 110px;
  @media (max-width: 1440px) {
    padding: 0 0 25px 75px;
  }
  @media (max-width: 1536px) {
    padding: 0 0 25px 7px;
  }
  @media (max-width: 375px) {
    padding: 0;
  }
`
export const MainWrapper = styled.div`
  display: flex;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0;
    margin-top: 5.333vw;
  }
`
export const CardContainer = styled.div`
  max-width: 1300px;
  width: 100%;
`
export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: space-between;
  @media (max-width: 1350px) {
    grid-template-columns: repeat(3, 300px);
    gap: 20px;
    justify-content: center;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: none;
    gap: 5.333vw;
    justify-content: center;
  }
`
