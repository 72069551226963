import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.7 8.867h-.633v1.266H5.7V8.867Zm7.6 1.266h.633V8.867H13.3v1.266ZM5.7 5.067h-.633v1.266H5.7V5.067Zm2.533 1.266h.634V5.067h-.634v1.266ZM13.3.633l.448-.448L13.562 0H13.3v.633Zm3.8 3.8h.633v-.262l-.185-.186-.448.448Zm-6.967 9.5-.448.449.448-.449Zm-.633.634.284.566.05-.025.046-.035-.38-.506Zm-3.8-4.434h7.6V8.867H5.7v1.266Zm0-3.8h2.533V5.067H5.7v1.266Zm10.133 11.4H3.167V19h12.666v-1.267Zm-13.3-.633V1.9H1.267v15.2h1.266Zm.634-15.833H13.3V0H3.167v1.267Zm13.3 3.166V17.1h1.266V4.433h-1.266Zm-3.615-3.351 3.8 3.8.896-.897-3.8-3.8-.896.897ZM3.167 17.733a.633.633 0 0 1-.634-.633H1.267a1.9 1.9 0 0 0 1.9 1.9v-1.267ZM15.833 19a1.9 1.9 0 0 0 1.9-1.9h-1.266a.633.633 0 0 1-.634.633V19ZM2.533 1.9a.633.633 0 0 1 .634-.633V0a1.9 1.9 0 0 0-1.9 1.9h1.266Zm4.4 12.867c.141-.422.542-.813 1.052-.95.478-.128 1.092-.044 1.7.565l.897-.897c-.912-.912-1.988-1.144-2.925-.892-.903.243-1.643.929-1.925 1.774l1.203.4h-.001Zm2.752-.385c.036.034.069.071.1.11l1-.778a2.45 2.45 0 0 0-.203-.228l-.897.896Zm.1.11c.099.127.076.167.08.14.002-.018.007.01-.069.08-.11.09-.233.163-.367.213-.16.065-.328.11-.499.137-.104.02-.211.024-.317.013-.021-.005.023 0 .089.046a.491.491 0 0 1 .166.545.266.266 0 0 1-.03.06c-.002.002.02-.026.092-.085.144-.117.41-.287.854-.507l-.568-1.133c-.477.238-.836.456-1.081.654a1.744 1.744 0 0 0-.33.338.893.893 0 0 0-.177.555.773.773 0 0 0 .323.593c.143.107.301.152.418.176.237.047.507.034.751-.002.481-.074 1.105-.282 1.529-.667.22-.201.43-.49.474-.868.045-.388-.094-.752-.339-1.064l-1 .776h.001Zm.095.581c.203-.155.423-.285.656-.389l-.503-1.162a4.56 4.56 0 0 0-.913.538l.76 1.013Zm.656-.389c.824-.355 1.56-.168 2.313.19.19.092.375.19.563.292.182.099.374.204.557.297.35.177.783.37 1.231.37v-1.266c-.12 0-.314-.06-.659-.233-.162-.084-.332-.178-.527-.283-.19-.103-.4-.215-.62-.32-.884-.42-2.04-.78-3.36-.21l.502 1.163Z"
      fill="#8C929B"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
