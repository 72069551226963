import React from 'react'

interface IPaymentContext {
  paymentWallet: any
  setPaymentWallet: any
}

const defaultState = {
  paymentWallet: [],
  setPaymentWallet: () => null,
}

const PaymentWalletContext = React.createContext<IPaymentContext>(defaultState)

export { PaymentWalletContext }
