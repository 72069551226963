import styled from 'styled-components'
import Button from 'components/Button'

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 12px 5px 15px;
  background: #ffffff;
  width: 100%;
  height: 510px;
  border-radius: 30px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    height: 91.465vw !important;
  }

  @media (max-width: 1280px) {
    padding: 12px;
    height: 451px;
  }
  @media (max-width: 1366px) {
    padding: 5px 17px 0 8px;
  }
`
export const Container = styled.div`
  display: flex;
`
export const TextWrapper = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  width: 100%;
  max-width: 802px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
  text-align: initial;
`
export const Star = styled.div`
  color: #ef0707;
  font-size: 20px;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 5px;
  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
  }
`

export const NotifyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    font-size: 8px;
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1366px) {
    padding: 5px 2px 0 7px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 83.733vw;
    height: 34.667vw;
  }
`
export const NotifySection = styled.div`
  display: flex;
  flex-direction: column;
`
export const TextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 24px;
  display: flex;
  @media (max-width: 767px) {
    text-align: initial;
    width: 100%;
    max-width: 280px;
  }
`
export const AfterSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px 5px 1px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    border: none;
    font-family: 'OutfitLight';
    font-size: 18px;
    line-height: 29px;
    min-width: 920px;
    text-align: left;

    @media (max-width: 767px) {
      min-width: 300px;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #4473f5;
  }
  @media (max-width: 1280px) {
    padding: 0;
  }
`
export const TermsSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const TermText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 19px;
  line-height: 24px;
  display: flex;
  padding-top: 20px;
  @media (max-width: 1366px) {
    padding-top: 10px;
  }
`
export const TermsContainer = styled.div`
  display: flex;
  align-items: baseline;
  font-family: 'OutfitRegular';
  font-size: 16px;
  line-height: 29px;
  color: #969696;
  text-align: initial;
  gap: 10px;
  padding-top: 3px;
  @media (max-width: 767px) {
    text-align: justify;
  }
`
export const GrrenDot = styled.img`
  height: 7px;
`

export const ButtonSection = styled.div`
  width: 100%;
  max-width: 120px;
`
export const ResetButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  color: #1bb691;
  border: 1px solid #1bb691;
`

export const DiscardButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #000;
`
export const BottomButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 35px;
`
export const LeftButtonBox = styled.div`
  width: 100%;
`
export const RightButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`
export const SaveButton = styled(Button)`
  max-width: 150px;
`

export const ScrollContainer = styled.div`
  height: 450px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1366px) {
    padding: 10px 17px 0 17px;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  .Back-button {
    background: #fff;
    color: #000;
    border-color: #e0e0e0;
    height: 56px;
    margin: 14px;
    width: 100%;
    max-width: 150px;
    border-radius: 16px;
    @media (max-width: 1366px) {
      margin: 14px;
    }
    @media (max-width: 767px) {
      margin: 0;
      width: 21.867vw;
      height: 46px;
      margin-top: 1.333vw;
      margin-bottom: 10px;
    }
  }
`
export const TermsSectionOne = styled.div`
  display: flex;
  flex-direction: column;
  height: 189px;
  overflow-y: scroll;
  @media (max-width: 1366px) {
    height: 200px;
  }
  @media (max-width: 1280px) {
    height: 150px;
  }
`
