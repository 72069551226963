import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const Section = styled.div`
  width: 100%;
  display: flex;
`
export const MainContainer = styled.div`
  width: 100%;
  margin: 0px 25px;
  justify-content: center;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 151px;
`
export const MyPortfolioSection = styled.div`
  width: 100%;
  max-width: 1185px;
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding: 19px 985px 9px 26px;
  gap: 20px;
`
export const MyPortfolio = styled.div`
  font-family: 'OutfitBold';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  padding-bottom: 9px;
`
export const MyWallets = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  color: #737b7d;
  padding-bottom: 9px;
`
export const TableSection = styled.div`
  padding: 10px 10px 17px 10px;
  .ant-table-wrapper {
    border-radius: 24px;
  }
`
