import Grantors from 'assets/images/grantor-image.png'
import Beneficiary from 'assets/images/beneficiary.png'
import Fiduciary from 'assets/images/fiduciary.png'

interface IData {
  id: number
  image: string
  title: string
  subtitle: string
  disabled: boolean
}

const data: IData[] = [
  {
    id: 0,
    image: Grantors,
    title: 'Grantor',
    disabled: false,
    subtitle:
      'Simply create your Cryptoplan with our smart contract engine in a matter of minutes, with only a few clicks',
  },
  {
    id: 1,
    image: Beneficiary,
    title: 'Beneficiary',
    disabled: false,
    subtitle: 'It’s as easy as accepting your grantor’s invitation & connecting your crypto wallet (s)',
  },
  {
    id: 2,
    image: Fiduciary,
    title: 'Fiduciary',
    disabled: false,
    subtitle:
      'The fiduciary role is to be the trusted individual to start the disbursement process. Accept this role by grantor invitation and connecting your wallet.',
  },
]

export default data
