import React, { useContext, useState, useEffect } from 'react'
import CancelSign1 from 'assets/images/vector-cancel.png'

import { useForm } from 'react-hook-form'
import Button from 'components/Button'
import TextInput from 'components/FormElements/TextInput'
import useGet from 'hooks/useGet'
import CryptoJS from 'crypto-js'
import { combine } from 'shamirs-secret-sharing-ts'
import { BigNumber, ethers } from 'ethers'
import CPWillFactory from 'artifacts/contracts/CPWillFactoryUpgradeable.sol/CPWillFactoryUpgradeable.json'
import CPWill from 'artifacts/contracts/CPWillUpgradeable.sol/CPWillUpgradeable.json'
import erc20 from 'artifacts/contracts/erc20.json'
import usePut from 'hooks/usePut'
import { UserContext } from 'context/user'
import ShardsInvoice from 'components/ShardsInvoice'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import { LoaderContext } from 'context/loader'
import errorName from 'utils/errors/smartContract'
import {
  MainContainer,
  TextSection,
  CancelSection,
  TopSection,
  BottomSection,
  ButtonSecton,
  StyledContainer,
  BottomText,
  TextInputSection,
} from 'styles/components/Modal/SecretKeyAllocation'

const contractaddress: string = process.env.React_APP_CONTRACT_FACTORY_ADDRESS || ''

export interface IModalProps {
  showModal: (value: boolean) => void
}
export interface IForm {
  encryptionKey: string
}
export interface IShareProps {
  keys: string
}

export interface IReceipt {
  blockNumber: number
  blockDetails: any
  status: number
}

interface GenericObject {
  [key: string]: any
}
interface IWalletId {
  walletId?: string
}

const SecretKeyAllocation: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const { control, handleSubmit } = useForm<IForm>()
  const [invoiceData, setInvoiceData] = useState<IReceipt>()
  const [errorModal, seterrorModal] = useState<string | ''>('')
  const [blockData, setBlockData] = useState<any | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const { user, setUser } = useContext(UserContext)
  const { setUptoLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePut()

  const { data: getSharts, refetch } = useGet('get-getSharts ', `shart/getSharts`, false, {
    token: true,
  })
  const { data: walletList, refetch: refetchWalletList } = useGet('get-wallet-list ', `/wallet/list`, false, {
    token: true,
  })
  const { mutateAsync: etherAsync } = usePut()

  const withdrawEther = async () => {
    try {
      etherAsync({
        url: 'shart/isEtherWithdrawn',
        payload: { isEtherWithdrawn: true },
        token: true,
      })
    } catch (error: any) {
      return { error: error?.response?.data?.message || 'Something went wrong' }
    }
  }

  const filteredData = walletList?.wallets.slice(1).map((item: IWalletId) => {
    return item?.walletId
  })
  const arr: Array<string> = []
  const linkedAddress: Array<string> = filteredData
  let grantorAddress: string
  class customError extends Error {
    reason: string
    constructor(message: string) {
      super(message)
      this.reason = message
    }
  }

  const helperFunction = async (value: IForm, address: string) => {
    grantorAddress = filteredData[0]
    getSharts.data.sharts.forEach((share: IShareProps, index: number) => {
      arr[index] = CryptoJS.AES.decrypt(share.keys.replaceAll(' ', ''), value.encryptionKey).toString(CryptoJS.enc.Utf8)
    })

    const seedPhrase: string = combine(arr).toString()
    let privateKey: string = ''
    for (let i = 0; i < 150; i++) {
      const mnemonicWallet = ethers.Wallet.fromMnemonic(seedPhrase, `m/44'/60'/0'/0/${i}`)
      if (address.toLocaleLowerCase() === mnemonicWallet.address.toLocaleLowerCase()) {
        privateKey = mnemonicWallet.privateKey.toString()
        break
      }
    }

    const provider = new ethers.providers.Web3Provider((window as any).ethereum)
    const signer = new ethers.Wallet(privateKey, provider)
    const contract1 = new ethers.Contract(contractaddress, CPWillFactory.abi, signer)
    const willId = await contract1.getWills(grantorAddress)
    const latestContractAddress: string = willId[(willId?.length || 1) - 1]
    const contract2 = new ethers.Contract(latestContractAddress, CPWill.abi, signer)
    const txSigner = contract2.connect(signer)
    return { txSigner, provider, signer, contract2, latestContractAddress, privateKey }
  }

  const catchingErrors = (error: any) => {
    //Error from wallet
    if (error.reason !== undefined) {
      if (error.reason.length <= 2) {
        setUptoLoader(false)
        //Transalating Errors
        seterrorModal(errorName[Number(error.reason) - 1])
        setIsOpen(true)
      } else {
        if (error.reason.replaceAll(' ', '').split(':')[1] === '11') {
          setUptoLoader(false)
          seterrorModal('The Redemption period is not met!')
          setIsOpen(true)
        } else {
          setUptoLoader(false)
          seterrorModal(error.reason)
          setIsOpen(true)
        }
      }
    } else {
      setUptoLoader(false)
      seterrorModal(error.message)
      setIsOpen(true)
    }
  }
  const splittingEther = async (value: IForm, address: string) => {
    try {
      setUptoLoader(true)
      const { txSigner, provider, latestContractAddress, privateKey, contract2 } = await helperFunction(value, address)
      const grantorEtherBalance: number = Number(ethers.utils.formatUnits(await provider.getBalance(address), 'ether'))
      const trxGasPrice: any = await provider.getFeeData()
      const wallet = new ethers.Wallet(privateKey, provider)
      const psigner = wallet.connect(provider)
      if (linkedAddress[linkedAddress.length - 1] != address) {
        const forGasEstimatetx = {
          to: latestContractAddress,
          value: ethers.utils.parseEther('0'),
        }
        const gasEstimate = await wallet.estimateGas(forGasEstimatetx)
        const sub: BigNumber = trxGasPrice.maxFeePerGas.mul(parseInt(gasEstimate._hex))
        const convertedSub = ethers.utils.formatUnits(sub, 'wei')
        const subToEther = Number(ethers.utils.formatEther(convertedSub))
        const sendingEther: any = grantorEtherBalance - subToEther
        if (sendingEther > 0) {
          const tx = {
            to: latestContractAddress,
            value: ethers.utils.parseEther(sendingEther.toString()),
            gasPrice: trxGasPrice.maxFeePerGas,
            gasLimit: parseInt(gasEstimate._hex),
          }
          const toContractTx = await psigner.sendTransaction(tx)
          await toContractTx.wait()
        }
      } else {
        const forGasEstimatetx = {
          to: latestContractAddress,
          value: ethers.utils.parseEther('0'),
        }
        const gasEstimate = await wallet.estimateGas(forGasEstimatetx)
        const ethEstimate = await contract2.estimateGas.sendETH(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65', {
          value: ethers.utils.formatUnits(await provider.getBalance(address), 'wei').toString(),
        })
        const sumEstimate = parseInt(gasEstimate._hex) + parseInt(ethEstimate._hex)
        const doublingTheGasprice = trxGasPrice.maxFeePerGas.mul(2)
        const sub = doublingTheGasprice.mul(sumEstimate)
        const convertedSub = ethers.utils.formatUnits(sub, 'wei')
        const subToEther = Number(ethers.utils.formatEther(convertedSub))
        const sendingEther: any = grantorEtherBalance - subToEther
        const tx = {
          to: latestContractAddress,
          value: ethers.utils.parseEther(sendingEther.toString()),
          gasPrice: trxGasPrice.maxFeePerGas,
          gasLimit: parseInt(gasEstimate._hex),
        }
        const toContractTx = await psigner.sendTransaction(tx)
        await toContractTx.wait()
        const finalTransaction = await txSigner.sendETH(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65', {
          gasLimit: parseInt(ethEstimate._hex),
          gasPrice: trxGasPrice.maxFeePerGas,
        })
        const receipt = await finalTransaction.wait()
        await withdrawEther()
        setUptoLoader(false)
        const blockNumber: number = receipt?.blockNumber
        const blockDetails: any = await provider.getBlock(blockNumber)
        setBlockData(blockDetails)
        setInvoiceData(receipt)
        setOpen(true)
      }
    } catch (error: any) {
      catchingErrors(error)
      return false
    }
  }

  const moderatorForAll = async (value: IForm) => {
    for (let i = 0; i < linkedAddress.length; i++) {
      if (user?.isWithdrawn) {
        const decider = await splittingEther(value, linkedAddress[i])
        if (decider === false) {
          break
        }
      } else {
        if (user?.isRedeemed) {
          const decider = await withdraw(value, linkedAddress[i])
          if (decider === false) {
            break
          }
        } else {
          const decider = await redeem(value, linkedAddress[i])
          if (decider === false) {
            break
          }
        }
      }
    }
  }

  const splittingEtherForAll = async (value: IForm) => {
    if (value) {
      // to fix the UI freeze issue
      setTimeout(async () => {
        await moderatorForAll(value)
      }, 600)
    }
  }

  const withdraw = async (value: IForm, address: string) => {
    try {
      setUptoLoader(true)
      const { txSigner, provider } = await helperFunction(value, address)
      const transactionGasPrice = (await provider.getFeeData()).gasPrice
      const transactionGasLimit = await txSigner.estimateGas.withdraw(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65')
      const transaction: GenericObject = await txSigner.withdraw(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65', {
        gasLimit: transactionGasLimit,
        gasPrice: transactionGasPrice,
      })
      const receipt: IReceipt = await Promise.resolve(transaction.wait())
      if (linkedAddress[linkedAddress.length - 1] === address) {
        const blockNumber: number = receipt?.blockNumber
        const blockDetails: any = await provider.getBlock(blockNumber)
        await updatingTokenFlag()
        setUptoLoader(false)
        setBlockData(blockDetails)
        setInvoiceData(receipt)
        setOpen(true)
      }
    } catch (error: any) {
      catchingErrors(error)
      return false
    }
  }

  const updatingTokenFlag = async () => {
    const { data } = await mutateAsync({
      url: 'shart/isWithdrawn',
      payload: {
        isWithdrawn: true,
      },
      token: true,
    })
    setUser((prev: any) => ({ ...prev, isWithdrawn: data?.isWithdrawn }))
  }
  const withdrawForAll = async (value: IForm) => {
    if (value) {
      // to fix the UI freeze issue
      setTimeout(async () => {
        await moderatorForAll(value)
      }, 600)
    }
  }

  const redeem = async (value: IForm, address: string) => {
    try {
      setUptoLoader(true)
      const { txSigner, signer, provider, contract2 } = await helperFunction(value, address)
      const tokenArray: Array<string> = await contract2.getAllTokens()
      if (tokenArray.length === 0) {
        await updatingRedeemFlag()
        await updatingTokenFlag()
        throw new customError('Since the will has no tokens, you can only disburse ether!')
      }

      for (let i = 0; i < tokenArray.length; i++) {
        const tokenContract: ethers.Contract = new ethers.Contract(tokenArray[i], erc20.abi, signer)
        const bal = Number(ethers.utils.formatUnits(await tokenContract.balanceOf(address), 'wei'))
        const checkIfApproved = ethers.utils.formatUnits(
          await tokenContract.connect(signer).allowance(address, contract2.address),
          'wei',
        )
        if (bal != 0) {
          if (Number(checkIfApproved) < bal) {
            const gasPrice = (await provider.getFeeData()).gasPrice //in wei
            const gasLimit = await tokenContract.estimateGas.approve(contract2.address, bal.toString())
            const approve: any = await tokenContract
              .connect(signer)
              .approve(contract2.address, bal.toString(), { gasLimit: gasLimit, gasPrice: gasPrice })
            await approve.wait()
          }
        }
      }

      if (linkedAddress[linkedAddress.length - 1] === address) {
        const gasPriceRedeem = (await provider.getFeeData()).gasPrice
        const gasLimitRedeem = await txSigner.estimateGas.redeem(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65', {
          gasPrice: gasPriceRedeem,
        })
        const transaction: GenericObject = await txSigner.redeem(1, '0x15d34AAf54267DB7D7c367839AAf71A00a2C6A65', {
          gasPrice: gasPriceRedeem,
          gasLimit: gasLimitRedeem,
        })
        const receipt: IReceipt = await Promise.resolve(transaction.wait())
        const blockNumber: number = receipt.blockNumber
        const BlockDetails: any = await provider.getBlock(blockNumber)
        await updatingRedeemFlag()
        setBlockData(BlockDetails)
        setInvoiceData(receipt)
        setUptoLoader(false)
        setOpen(true)
      }
    } catch (error: any) {
      catchingErrors(error)
      return false
    }
  }

  const updatingRedeemFlag = async () => {
    const { data } = await mutateAsync({
      url: '/shart/isRedeemed',
      payload: {
        isRedeemed: true,
      },
      token: true,
    })
    setUser((prev: any) => ({ ...prev, isRedeemed: data?.isRedeemed }))
  }
  const redeemForAll = async (value: IForm) => {
    if (value) {
      // to fix the UI freeze issue
      setTimeout(async () => {
        await moderatorForAll(value)
      }, 600)
    }
  }

  const onSubmit = async (value: IForm) => {
    setUptoLoader(true)
    if (!getSharts) refetch()
    if (user?.isWithdrawn) {
      splittingEtherForAll(value)
    } else {
      if (user?.isRedeemed) {
        withdrawForAll(value)
      } else {
        redeemForAll(value)
      }
    }
  }

  useEffect(() => {
    refetch()
    refetchWalletList()
  }, [])

  return (
    <StyledContainer>
      <MainContainer>
        <TopSection>
          <CancelSection src={CancelSign1} onClick={() => showModal(false)} />
        </TopSection>

        <BottomSection>
          <TextSection>The secret key has been transferred!</TextSection>
          <BottomText>
            Secret key has been transferred to your cryptoplan , you shall be able to click the button disburse
          </BottomText>
        </BottomSection>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputSection>
              <TextInput type="text" name="encryptionKey" placeholder="Enter encryption Key" control={control} />
            </TextInputSection>
            <ButtonSecton>
              <Button label="Submit " variant="outline" type="submit" />
            </ButtonSecton>
          </form>
        </div>
      </MainContainer>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} errorModal={errorModal} success={false} />
      </Modal>
      <Modal isOpen={open}>
        <ShardsInvoice showModal={(value: boolean) => setOpen(value)} invoiceData={invoiceData} blockData={blockData} />
      </Modal>
    </StyledContainer>
  )
}

export default SecretKeyAllocation
