import { useNavigate } from 'react-router-dom'
import { Container, Wrapper, WrapperLogoSection, FooterLogo, FootMenu, MenuItem } from 'styles/views/landing/Footer'
import Logo from 'assets/images/crypto-logo2.png'

interface IHeaderProps {
  scrollView(refName: string): void
}

const data = [
  {
    title: 'Home',
  },
  {
    title: 'Features',
  },
  {
    title: window?.innerWidth <= 767 ? 'How it Works' : 'How It Works',
  },
  {
    title: 'FAQs',
  },
  {
    title: 'Our Solution',
  },
  {
    title: 'Privacy Policy',
  },
  {
    title: 'Terms & Conditions',
  },
]

export const ContactUs = ({ scrollView }: IHeaderProps) => {
  const navigate = useNavigate()

  return (
    <Container>
      <Wrapper>
        <FootMenu>
          {data?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (item?.title == 'Our Solution') navigate('/oursolution')
                else scrollView(item.title)
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </FootMenu>
        <WrapperLogoSection>
          <FooterLogo src={Logo} />
        </WrapperLogoSection>
      </Wrapper>
    </Container>
  )
}

export default ContactUs
