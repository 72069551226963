import React from 'react'
import { CardContainer, ImgConatiner, TitleContainer, Title, SubTitle } from 'styles/components/Cards/KeyFeature'
export interface ICardProps {
  title: string
  subtitle: string
  image?: string
}

const KeyFeatCard: React.FC<ICardProps> = ({ image, title, subtitle }) => (
  <CardContainer>
    <ImgConatiner>{image}</ImgConatiner>
    <TitleContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </TitleContainer>
  </CardContainer>
)

export default KeyFeatCard
