import { useState, useContext } from 'react'
import Modal from 'components/Modal'
import RemoveWalletModal from 'components/Cards/RemoveWalletModal'
import PaymentModal from 'components/Modal/PaymentModal'
import { UserContext } from 'context/user'
import {
  ShowWalletSection,
  RadioButtonSection,
  WalletImage,
  WalletName,
  WalletCount,
  BlankSection,
} from 'styles/components/Cards/AddPayments'

export interface ICardProps {
  image: string
  title: string
  subtitle: string
  defination: string
}
const PaymentCard = ({ image, title, defination }: ICardProps) => {
  const [selected, setSelected] = useState('Crypto Wallet')
  const { user } = useContext(UserContext)
  const handleChange = (event: any) => {
    setSelected(event.target.value)
  }
  const [isRemove, setIsRemove] = useState(false)
  const [isResend, setResendInvite] = useState(false)

  return (
    <ShowWalletSection>
      <RadioButtonSection>
        <input type="radio" name={'id'} checked={selected === title} onChange={handleChange} value={title} />
        <WalletImage src={image} />
        <WalletName>{title}</WalletName>
      </RadioButtonSection>
      <WalletCount>{`${user?.userWalletId?.substring(0, 10)}..${user?.userWalletId?.substring(
        user?.userWalletId?.length - 4,
      )}`}</WalletCount>
      <BlankSection>{defination}</BlankSection>

      <Modal isOpen={isRemove}>
        <RemoveWalletModal
          showModal={(value: boolean) => setIsRemove(value)}
          removeBenificiary={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      </Modal>
      <Modal isOpen={isResend}>
        <PaymentModal
          showModal={(value: boolean) => setResendInvite(value)}
          getResponse={function (): void {
            throw new Error('Function not implemented.')
          }}
          image={''}
        />
      </Modal>
    </ShowWalletSection>
  )
}

export default PaymentCard
