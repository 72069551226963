import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  height: 42px !important;
  .ant-input-affix-wrapper {
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #73777b;
    opacity: 0.5;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 34.667vw;
    }
  }
`
export const Container = styled.div`
  width: 100%;
  max-width: 244px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
`
