import React, { useContext, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import CancelSign from 'assets/images/vector-cancel.png'
import NumberFormat from 'react-number-format'
import usePost from 'hooks/usePost'
import { UserContext } from 'context/user'
import { LoaderContext } from 'context/loader'
import Button from 'components/Button'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  StyledContainer,
  ImageSection,
  CancelSection,
  TextSection,
  Container,
  Wrapper,
  PrimaryWalletSection,
  CrossSection,
  ButtonContainer,
  ButtonSection,
  InputContainer,
} from 'styles/components/Modal/Otp'

export interface IModalProps {
  showModal: (value: boolean) => void
  userwalletId: string
  fromSelector: string
}
interface IData {
  token?: number
}
const Otp: React.FC<IModalProps> = ({ showModal, userwalletId, fromSelector }: IModalProps) => {
  const { setLoader } = useContext(LoaderContext)
  const [callReload, setCallReload] = useState(false)

  const { control, handleSubmit } = useForm<IData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { mutateAsync, isLoading } = usePost()
  const { fetchUser } = useContext(UserContext)
  const formData = async (token: IData) => {
    try {
      const response = await mutateAsync({
        url: 'otp/check',
        payload: {
          token: token.token,
          walletId: userwalletId,
          primaryWalletName: fromSelector,
        },
      })
      if (response?.success) {
        fetchUser()
        toast('Your Wallet has been linked')
        setCallReload(false)
        showModal(false)
      } else {
        localStorage.removeItem('walletconnect')
        alert('Problem in sending OTP!')
      }
    } catch (err) {
      alert(err)
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (callReload) {
      timer = setTimeout(() => {
        clearTimeout(timer)
        window.location.reload()
      }, 150000)
    }
  }, [callReload])

  useEffect(() => {
    if (isLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading])

  return (
    <StyledContainer>
      <Container>
        <Wrapper>
          <CrossSection>
            <CancelSection src={CancelSign} onClick={() => showModal(false)} />
          </CrossSection>
          <PrimaryWalletSection>
            <ImageSection>
              <TextSection>Enter Your OTP</TextSection>
            </ImageSection>
          </PrimaryWalletSection>
          <form onSubmit={handleSubmit(formData)}>
            <InputContainer>
              <Controller
                render={({ field }) => (
                  <NumberFormat {...field} className="text11" placeholder="Enter OTP" type={'password'} />
                )}
                control={control}
                name="token"
              />
            </InputContainer>
            <ButtonSection>
              <ButtonContainer>
                <Button label="Submit" variant="contained" type="submit">
                  Submit
                </Button>
                <ToastContainer />
              </ButtonContainer>
            </ButtonSection>
          </form>
        </Wrapper>
      </Container>
    </StyledContainer>
  )
}

export default Otp
