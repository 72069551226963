import React from 'react'

interface ISubscriptionDataContext {
  subscription: any
  setSubscription: any
}

const defaultState = {
  subscription: {},
  setSubscription: () => null,
}

const SubscriptionDataContext = React.createContext<ISubscriptionDataContext>(defaultState)

export { SubscriptionDataContext }
