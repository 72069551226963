import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 33px 26px 10px 44px;
  @media (max-width: 767px) {
    padding: 4.267vw 6.933vw 0 6.733vw;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 595px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  align-items: center;
  margin: auto;
  @media (max-width: 767px) {
    max-width: 93.333vw;
  }
`
export const ImageSection = styled.img`
  height: 20px;
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
`

export const ModalHeading = styled.div`
  font-family: 'OutfitRegular';
  font-size: 32px;
  line-height: 40px;
  color: #0a0c17;
  @media (max-width: 767px) {
    font-size: 5.333vw;
  }
`

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px;
  gap: 15px;
  @media (max-width: 767px) {
    padding: 4vw;
  }
`
