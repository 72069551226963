import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import usePatch from 'hooks/usePatch'
import Modal from 'components/Modal'
import TextArea from 'components/FormElements/TextArea'
import ErrorModal from 'components/ErrorModal'
import { LoaderContext } from 'context/loader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import CancelSign from 'assets/images/vector-cancel.png'
import Button from 'components/Button'

import {
  MainContainer,
  ButtonSection,
  Wrapper,
  TopSection,
  LeftText,
  CancelSection,
  UserDetails,
  USername,
  WalletAdress,
  LabelContainer,
  LabelText,
  InputContainer,
  Error,
  InputWrapper,
} from 'styles/components/Cards/AddNote'

export interface IModalProps {
  showModal: (value: boolean) => void
  index: number
}
interface IData {
  emailId: string
  notes: string
}

const CommingSoon: React.FC<IModalProps> = ({ showModal, index }) => {
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const details = beneficiariesData?.data?.beneficary[index]
  const [isOpen, setIsOpen] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const schema = yup.object().shape({
    emailId: yup
      .string()
      .required('Email is required')
      .matches(/^\S/, 'First character cannot be Space ')
      .email('Please enter a valid Email')
      .max(255),
    notes: yup.string().required('Notes is Required'),
  })
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<IData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const { mutateAsync, isLoading } = usePost()
  const { mutateAsync: updateAsync, isLoading: updateLoading } = usePatch()

  const {
    data: viewData,
    refetch: getViewDataRefetch,
    isLoading: dataLoading,
    isError,
    error,
  } = useGet('getViewData', 'data/getViewData', false, {
    enabled: false,
    token: true,
  })

  useEffect(() => {
    if (dataLoading) setLoader(true)
    else setLoader(false)
  }, [dataLoading])

  const eventHit = viewData?.eventHit?.eventHit
  const onSubmit = async (values: IData) => {
    for (let j = 0; j < eventHit?.length; j++) {
      const eventHitData = eventHit[j]

      const benfeciaryHistroys = eventHitData?.benfeciaryHistroys

      for (let i = 0; i < benfeciaryHistroys?.length; i++) {
        const benfeciaryHistroysData = benfeciaryHistroys[i]

        const payload = {
          email: values?.emailId,
          message: values?.notes,
          emailCollectionSet: benfeciaryHistroysData?.dataId,
        }

        const updateNotePayload = {
          email: values?.emailId,
          message: values?.notes,
          userData: getNoteData?.userGet?.userData,
        }
        try {
          if (!getNoteError) {
            const updateNote = await updateAsync({
              url: 'collectSet/set',
              payload: updateNotePayload,
              token: true,
            })
            showModal(false)
            return updateNote
          } else {
            const noteDataPost = await mutateAsync({
              url: 'noteData/note ',
              payload: payload,
              token: true,
            })
            showModal(false)
            return noteDataPost
          }
        } catch (error: any) {
          return { error: error?.response?.data?.message }
        }
      }
    }
  }

  useEffect(() => {
    if (isError) {
      setIsOpen(true)
    }
  }, [isError, error])

  const {
    data: getNoteData,
    error: getNoteError,
    isFetching: getLoading,
    refetch,
  } = useGet('get-noteData ', `/noteData/get?email=${details?.emailId}`, false, {
    token: true,
  })
  useEffect(() => {
    if (!getNoteError) {
      setValue('emailId', getNoteData?.userGet?.email)
      setValue('notes', getNoteData?.userGet?.message)
    } else {
      setValue('emailId', details?.emailId)
      setValue('notes', '')
    }
  }, [getNoteData, getNoteError, dataLoading])

  useEffect(() => {
    refetch()
    getViewDataRefetch()
    setValue('emailId', '')
    setValue('notes', '')
  }, [])
  useEffect(() => {
    if (isLoading || updateLoading || getLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, updateLoading, getLoading])

  return (
    <MainContainer>
      <Wrapper>
        <TopSection>
          <LeftText>Add Note</LeftText>
          <CancelSection src={CancelSign} onClick={() => showModal(false)} />
        </TopSection>
        <UserDetails>
          <USername>{details?.displayName}</USername>
          <WalletAdress>{details?.userWalletId}</WalletAdress>
        </UserDetails>
        <form onSubmit={handleSubmit(onSubmit)} className="one">
          <InputContainer>
            <LabelContainer>
              <LabelText>Email ID </LabelText>
              <InputWrapper>{`${details?.emailId}`}</InputWrapper>
            </LabelContainer>
            <LabelContainer>
              <LabelText>Write Personalized Note </LabelText>
              <TextArea name={'notes'} className="textone" control={control} autoComplete="off" />
              <Error>{formErrors?.notes?.message}</Error>
            </LabelContainer>
          </InputContainer>
          <ButtonSection>
            <Button label="Cancel " variant="outline" type="button" onClick={() => showModal(false)} />
            <Button label="Save " variant="contained" type="submit" />
          </ButtonSection>
        </form>
      </Wrapper>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} error={error} success={false} />
      </Modal>
    </MainContainer>
  )
}

export default CommingSoon
