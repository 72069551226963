import * as React from 'react'
import { SVGProps } from 'react'

const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={33} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.25 32.917a2.968 2.968 0 0 1-2.177-.905 2.97 2.97 0 0 1-.906-2.179V14.417c0-.848.302-1.574.906-2.179a2.968 2.968 0 0 1 2.177-.905h1.542V8.25c0-2.133.752-3.95 2.255-5.454C8.55 1.293 10.367.542 12.5.542c2.133 0 3.951.751 5.455 2.254 1.502 1.503 2.254 3.321 2.254 5.454v3.083h1.541c.848 0 1.574.302 2.179.905.603.605.905 1.33.905 2.179v15.416c0 .848-.302 1.575-.905 2.179a2.973 2.973 0 0 1-2.179.905H3.25Zm9.25-7.709c.848 0 1.574-.301 2.179-.904.603-.605.905-1.331.905-2.179 0-.848-.302-1.574-.905-2.178a2.973 2.973 0 0 0-2.179-.905c-.848 0-1.573.301-2.177.905a2.97 2.97 0 0 0-.906 2.178c0 .848.302 1.574.906 2.178a2.968 2.968 0 0 0 2.177.905ZM7.875 11.333h9.25V8.25c0-1.285-.45-2.377-1.349-3.276-.899-.9-1.991-1.349-3.276-1.349-1.284 0-2.376.45-3.276 1.349-.899.9-1.349 1.991-1.349 3.276v3.083Z"
      fill="#2677E9"
      className="icon"
    />
  </svg>
)

export default Lock
