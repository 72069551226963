import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const MainContainer = styled.div`
  width: 100%;
  margin: 0 24px;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    margin: 0;
  }
`
export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
`
export const NoDataContainer = styled.div`
  width: 100%;
`

export const LeftBottomSection = styled.div`
  width: 100%;
  height: auto;
`
export const DashbordContentSection = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 25px;
`
export const BalanceSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 24px;
  line-height: 80px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 10px;
`
export const BalanceDetails = styled.div`
  border-right: 1px solid #dfdfdf;
  padding-right: 25px;
  display: flex;
  gap: 10px;
`
export const WalletDetails = styled.div`
  padding-left: 35px;
  display: flex;
  gap: 10px;
`
export const SelectContainer = styled.div`
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 6px 6px 13px;
`
export const BalanceImageSection = styled.img``
export const WalletImageSection = styled.img``
export const Details = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #292a34;
`
export const MoneyDetails = styled.div`
  font-family: 'OutfitMedium';
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #0a0c17;
`
export const TableContainer = styled.div`
  width: 100%;
  padding: 6px;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-bottom: 4vw;
    width: 100%;
    max-width: 90.667vw;
    overflow-x: scroll;
  }
  table {
    width: 100%;
    padding: 6px;

    tr {
      border-bottom: 1px solid #e7e7e7;
      font-family: 'OutfitRegular';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      height: 100px;

      td {
        text-align: center;
      }
      :nth-child(3) {
        align-items: center;
      }
    }
    tr:first-child {
      color: #737b7d;
    }
    th {
      text-align: center;
      padding: 18px;
      font-family: 'OutfitMedium';
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.02em;
      background: #ffffff;
      border: none;

      @media (max-width: 1366px) {
        padding: 10px 10px 10px 19px;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell)
        :not([colspan])::before {
        background-color: #fafafa;
      }
      :nth-child(1) {
        width: 20%;
      }

      td {
        position: relative;
        font-family: 'OutfitMedium';
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #4d64e4;
        padding: 20px;
        background-color: blue;
        text-align: left;
        @media (max-width: 1440px) {
          height: 11vh;
        }
      }
    }
  }
`
export const MainBalanceSection = styled.div``
export const ViewAllBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 7px;
  cursor: pointer;
`
export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 10px 10px 10px 10px;
  align-items: baseline;
`
export const LeftSectionFilter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 10px 10px 10px;
  .ant-select-item-option-content {
    text-align: center;
  }
`
export const RightSectionFilter = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`
export const SearchBox = styled.div`
  max-width: 330px;
`
export const DropSection = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  .css-1s2u09g-control {
    min-width: 430px;
    max-width: 100%;
    height: 40px;
    overflow-y: scroll;
    border-radius: 10px;
    align-items: flex-start !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .css-1pahdxg-control {
    min-width: 430px;
    max-width: 100%;
    height: 40px;
    overflow-y: scroll;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1rhbuit-multiValue {
    background: rgba(38, 119, 233, 0.2);
    border-radius: 6px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    align-items: flex-start !important;
  }
`
export const NetworkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`
export const DropImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const NetworkText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  display: flex;
  padding: 5px;
  gap: 10px;
`
export const FilterBy = styled.div`
  display: flex;
  width: 100%;
  max-width: 75px;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
`
export const FilterImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const RightDrop = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 70px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 7px;
  cursor: pointer;
`
export const ViewSection = styled.div`
  width: 100%;
  max-width: 70px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
`
export const FilterBox = styled.img``
export const FilterView = styled.img``

export const IncContainer = styled.div`
  padding-left: 10px;
`

export const ImageWrapper = styled.img`
  height: 50px;
  @media (max-width: 767px) {
    height: 35px;
  }
`
export const Minus = styled.div`
  background: rgba(255, 56, 56, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 15px;
  color: #ff3535;
  width: 100%;
  max-width: 80px;
`
export const Plus = styled.div`
  background: rgba(0, 159, 66, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 15px;
  color: #009f42;
  width: 100%;
  max-width: 80px;
`

export const Action = styled.div`
  position: absolute;
  width: 100%;
  max-width: 120px;
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  z-index: 2;
`
export const DotSection = styled.div`
  position: relative;
`

export const FilterContainer = styled.div`
  position: absolute;
  right: 100px;
  bottom: 280px;
  width: 175px;
  height: 128px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  @media (max-width: 1366px) {
    bottom: 180px;
  }
`
export const NetworkImage = styled.img`
  height: 21px;
  width: 21px;
  margin: 0px 4px;
`
export const NetworkPara = styled.div`
  font-family: 'OutfitRegular';
  font-size: 12px;
`
export const ValueText = styled.div`
  font-family: 'OutfitBold';
  font-size: 12px;
  line-height: 33px;
`
export const RestFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`
export const ResetImage = styled.img`
  height: 15px;
  cursor: pointer;
`
export const TextReset = styled.div`
  font-family: 'Outfit';
  cursor: pointer;
  font-size: 12px;
  line-height: 33px;
  color: #009f42;
`
export const SortRows = styled.div`
  position: absolute;
  text-align: center;
  background: #fff;
  top: 40px;
  padding: 10px 0px;
  z-index: 1;
  max-width: 119px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
`
export const SelectWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`
export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const HeadingSection = styled.div`
  width: 100%;
  max-width: 800px;
  font-family: 'OutfitRegular';
  font-size: 24px;
  padding: 21px 11px 21px 11px;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 1440px) {
    padding: 20px 3px 22px 3px;
  }
`
export const DefinitionSection = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 26px 37px 26px 10px;
  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 26px 10px 25px 10px;
  }
`
export const CryptoLogo = styled.img`
  height: 200px;
  width: 196px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`
export const Section = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
    margin-left: 5.333vw;
  }
`
export const ValueSection = styled.div`
  display: flex;
  justify-content: center;
`
