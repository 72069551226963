interface IData {
  title: string
  path?: string
}

const data: IData[] = [
  {
    title: 'Home',
    path: '/dashboard',
  },
  {
    title: 'Features',
    path: '/keyfeat',
  },
  {
    title: 'How it Works',
  },
  {
    title: 'FAQs',
  },
  {
    title: 'Contact Us',
  },
  {
    title: 'Our Solution',
  },
]

export default data
