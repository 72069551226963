import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import usePost from 'hooks/usePost'
import { login } from 'utils'
import MetamasakModal from 'components/Modal/MetamaskModal'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import CancelSign from 'assets/images/vector-cancel.png'
import { StyledContainer, ImageSection, CancelSection, TextSection } from 'styles/views/landing/SignIn'
import { useMoralis } from 'react-moralis'
import SignUpCard from 'components/Cards/SignUp'
import { Container, Wrapper, PrimaryWalletSection, CardSection } from 'styles/views/landing/SignUp'
import data from 'views/landing/SignUp/data'
import { UserContext } from 'context/user'
import Moralis from 'moralis-v1'

interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

const Signin: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [errorModal, seterrorModal] = useState<string | ''>('')
  const role = localStorage.getItem('signRole')
  const [open, setOpen] = useState(false)
  const { authenticate } = useMoralis()
  const { mutateAsync } = usePost()
  const { fetchUser } = useContext(UserContext)
  const signingMessage = process.env.REACT_APP_SECRET_MESSAGE || ''
  const walletLogin = async (provider: 'metamask' | 'walletconnect' = 'metamask') => {
    await Moralis.start({
      serverUrl: process.env.REACT_APP_MORALIS_SERVER_URL,
      appId: process.env.REACT_APP_MORALIS_APP_ID,
    })
    const ethersProvider = await Moralis.enableWeb3({
      provider,
    })
    const signer = ethersProvider.getSigner()
    const account = await signer.getAddress()
    const { message } = await Moralis.Cloud.run('requestMessage', {
      address: account,
      chain: process.env.REACT_APP_WALLET_CONNECT_ID,
      network: 'evm',
    })
    const user = await authenticate({
      signingMessage: message,
      throwOnError: true,
    })
    const authData = user?.get('authData')
    const { id, data, signature } = authData.moralisEth
    const tx = data

    try {
      if (data.includes(signingMessage)) {
        const response = await mutateAsync({
          url: 'auth/login-or-register',
          payload: { walletId: id, userRole: role || 'Grantor', tx, signature },
        })

        if (response?.token) {
          login(response?.token)
          fetchUser()
          navigate('/dashboard')
        } else return response?.data
      }
    } catch (error: any) {
      seterrorModal(error.response.data.message)
      setIsOpen(true)
      return { error: errorModal }
    }
  }

  return (
    <StyledContainer>
      <Container>
        <Container>
          <Wrapper>
            <PrimaryWalletSection>
              <ImageSection>
                <TextSection>Connect Wallet</TextSection>
                <CancelSection src={CancelSign} onClick={() => showModal(false, 'signIn')} />
              </ImageSection>

              <CardSection onClick={() => setOpen(true)}>
                <SignUpCard key={data[0].id} image={data[0].image} title={data[0].title} />
              </CardSection>

              <CardSection onClick={() => walletLogin('walletconnect')}>
                <SignUpCard key={''} image={data[1].image} title={data[1].title} />
              </CardSection>
            </PrimaryWalletSection>
          </Wrapper>
        </Container>
      </Container>
      <Modal isOpen={open}>
        <MetamasakModal showModal={(value: boolean) => setOpen(value)} />
      </Modal>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} errorModal={errorModal} success={false} />
      </Modal>
    </StyledContainer>
  )
}

export default Signin
