interface IData {
  title: string
}

const data: IData[] = [
  {
    title: 'Connect multiple wallets to disburse from all EVM chains',
  },
  {
    title: 'Capture a 360 degree view of your Cryptoplan across your entire portfolio',
  },
  {
    title: 'Visualize the percentage of assets that will be disbursed to your beneficiaries',
  },
  {
    title: 'Ensure nobody can gain access to your portfolio through our proprietary multi-layer security protocol',
  },
]

export default data
