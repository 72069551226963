import { useContext, useEffect, useState } from 'react'
import ReactFlow from 'react-flow-renderer'
import 'react-flow-renderer/dist/style.css'
import 'react-flow-renderer/dist/theme-default.css'
import { UserContext } from 'context/user'
import { dumbData } from './stepperTreeData'
import { Container } from 'styles/components/SteperTree'
import { LoaderContext } from 'context/loader'
import Modal from 'components/Modal'
import AddWalletAddressModal from 'components/Modal/AddWalletAddressModal'

function SteperTree({ data }: any) {
  interface ITokenData {
    name: string
    balance: string
  }
  const [isWalletAddrAdd, setIsWalletAddrAdd] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (data.length == 0) setLoader(true)
    else setLoader(false)
  }, [data])

  const total = dumbData(user, data, setIsWalletAddrAdd)

  return (
    <Container>
      {data != undefined && data.length >= 1 && (
        <ReactFlow nodes={total[0]} edges={total[1]} fitView preventScrolling={true} minZoom={0.5} maxZoom={0.5} />
      )}
      <Modal isOpen={isWalletAddrAdd}>
        <AddWalletAddressModal showModal={(value: boolean) => setIsWalletAddrAdd(value)} />
      </Modal>
    </Container>
  )
}

export default SteperTree
