import { useState, useEffect, useContext } from 'react'
import Tick from 'assets/svg/Tick'
import { Button } from 'antd'
import usePost from 'hooks/usePost'
import usePatch from 'hooks/usePatch'
import useGet from 'hooks/useGet'
import { plan, subsCycle } from 'views/dashboard/MyPlan/ChoosePlan/data'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { LoaderContext } from 'context/loader'
import { UpdataDataContext } from 'context/updateBeneficiary'
import {
  Container,
  Wrapper,
  HeaddigText,
  PlanContainer,
  PlanCard,
  HeadText,
  MidTextWrapper,
  MidText,
  PlanWrapper,
  Plan,
  PlanDuration,
  Billed,
  Yearly,
  ContainerButton,
} from 'styles/views/dashboard/MyPlan/ChoosePlanSteper'

const ChoosePlanSteper = ({ prev, next }: any) => {
  const [check, setCheck] = useState<number | null>(null)
  const [cycle, setCycle] = useState<number | null>(null)

  const { beneficiariesData } = useContext(BeneficiaryDataContext)

  const { updateBeneficiariesData } = useContext(UpdataDataContext)
  const { setLoader } = useContext(LoaderContext)

  const beneficiaryData = beneficiariesData?.data?.beneficary

  const { mutateAsync, isLoading } = usePost()
  const { mutateAsync: updateAsync, isLoading: updateLoading } = usePatch()

  const handleClick = (index: number) => {
    setCheck(index)
  }

  const handleCycle = (index: number) => {
    setCycle(index)
  }

  const { data: subscribe, isLoading: linkLoading } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    const subscribeData = subscribe?.subscriptionData?.subscription

    if (subscribeData?.length) {
      const i = subscribeData?.length - 1
      if (updateBeneficiariesData?.length <= 3) {
        setCheck(0)
        setCycle(subsCycle.findIndex((item: any) => item.title === subscribeData[i]?.subscriptionCycle))
      } else {
        setCheck(1)
        setCycle(subsCycle.findIndex((item: any) => item.title === subscribeData[i]?.subscriptionCycle))
      }
    } else if (updateBeneficiariesData?.length < 4) {
      setCheck(0)
      setCycle(1)
    } else {
      setCheck(1)
      setCycle(1)
    }
  }, [subscribe, beneficiaryData, updateBeneficiariesData])

  const handleOptionClick = () => {
    prev()
  }

  const prevOptionClick = () => {
    next()
  }

  const eventPayment = async (value: string) => {
    try {
      await mutateAsync({
        url: '/notify/event',
        payload: {
          message: value === 'create' ? 'Subscription active' : 'Subscription updated',
          status: true,
          type: `subscribe`,
          titleName: `Subscription`,
        },
        token: true,
      })
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  const handleNext = async () => {
    const payload = {
      subscription: 'Crypto',
      subscriptionAmount: cycle !== null && subsCycle[cycle].value,
      subscriptionPlan: check !== null && plan[check].title,
      subscriptionCycle: cycle !== null && subsCycle[cycle].title,
    }

    try {
      const subscription = await mutateAsync({
        url: 'subscription/addSubscription',
        payload: payload,
        token: true,
      })
      prevOptionClick()
      eventPayment('create')
      return subscription
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading || linkLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading || linkLoading])

  const updatePlan = async () => {
    const updatePayload = {
      id: subscribe?.subscriptionData?.subscription[0]?.id,
      subscription: 'Crypto',
      subscriptionAmount: cycle !== null && subsCycle[cycle].value,
      subscriptionPlan: check !== null && plan[check].title,
      subscriptionCycle: cycle !== null && subsCycle[cycle].title,
    }
    try {
      const updateSubscription = await updateAsync({
        url: '/subscription/updateSubscription',
        payload: updatePayload,
        token: true,
      })
      prevOptionClick()
      eventPayment('update')

      return updateSubscription
    } catch (error: any) {
      return { error: error.response.data.message }
    }
  }

  useEffect(() => {
    if (isLoading || linkLoading || updateLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading || updateLoading || linkLoading])
  return (
    <Container>
      <Wrapper>
        <div>
          <HeaddigText>Choose your plan</HeaddigText>

          <PlanContainer>
            {plan.map((item, index) => {
              return (
                <PlanCard
                  key={index}
                  onClick={() => {
                    handleClick(index)
                  }}
                  planCheck={beneficiaryData?.length >= 0}
                  className={check === index ? 'Chosed' : 'not'}
                >
                  <HeadText className={check === index ? 'Chosed' : 'not'}>
                    {item.title} {check === index && <Tick />}
                  </HeadText>
                  <MidTextWrapper>
                    <MidText className={check === index ? 'Chosed' : 'not'}>
                      {item.title === 'Lite' ? 'Up to 3 Beneficiaries' : ' 4+ Beneficiaries'}
                    </MidText>
                  </MidTextWrapper>
                  <PlanWrapper>
                    <Plan className={check === index ? 'Chosed' : 'not'}>{item.dollar}</Plan>
                    <Plan className={check === index ? 'Chosed' : 'not'}>{item.value}</Plan>
                    <PlanDuration className={check === index ? 'Chosed' : 'not'}>/Month</PlanDuration>
                  </PlanWrapper>
                </PlanCard>
              )
            })}
          </PlanContainer>
        </div>
        <div>
          <HeaddigText>Choose your subscription cycle</HeaddigText>
          <PlanContainer>
            {subsCycle.map((item, index) => {
              return (
                <PlanCard
                  key={index}
                  onClick={() => {
                    handleCycle(index)
                  }}
                  className={cycle === index ? 'Chosed' : 'not'}
                >
                  <HeadText className={cycle === index ? 'Chosed' : 'not'}>
                    {item.title}

                    {cycle === index && <Tick />}
                  </HeadText>
                  <PlanWrapper>
                    <Plan className={cycle === index ? 'Chosed' : 'not'}> {item.dollar}</Plan>
                    <Plan className={cycle === index ? 'Chosed' : 'not'}>{item.value}</Plan>
                    <PlanDuration className={cycle === index ? 'Chosed' : 'not'}>
                      {item.title === 'Yearly' ? '/Year' : '/Month'}
                    </PlanDuration>
                  </PlanWrapper>

                  {item.title === 'Yearly' ? (
                    <Billed className="Chosed">10% Off</Billed>
                  ) : (
                    <Yearly className={cycle === index ? 'Chosed' : 'not'}>Billed Monthly</Yearly>
                  )}
                </PlanCard>
              )
            })}
          </PlanContainer>
        </div>
      </Wrapper>
      <ContainerButton>
        <Button
          type="primary"
          onClick={beneficiaryData?.length ? handleOptionClick : handleNext}
          className="Back-button"
        >
          Back
        </Button>

        <Button
          type="primary"
          onClick={subscribe?.subscriptionData?.subscription?.length ? updatePlan : handleNext}
          className="next-button"
        >
          Next
        </Button>
      </ContainerButton>
    </Container>
  )
}

export default ChoosePlanSteper
