import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={39} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={-0.5} y={0.5} width={39} height={38} rx={19} transform="matrix(-1 0 0 1 39 0)" stroke="#CCC" />
    <path
      d="M16.5 16.5a.583.583 0 0 1 .583-.583h5.834a.583.583 0 0 1 0 1.166h-5.834a.583.583 0 0 1-.583-.583Zm0 2.333a.583.583 0 0 1 .583-.583h5.834a.583.583 0 1 1 0 1.167h-5.834a.583.583 0 0 1-.583-.584Zm.583 1.75a.583.583 0 0 0 0 1.167h5.834a.583.583 0 0 0 0-1.167h-5.834ZM16.5 23.5a.583.583 0 0 1 .583-.583h2.334a.583.583 0 0 1 0 1.166h-2.334a.583.583 0 0 1-.583-.583Z"
      fill="#009F42"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.167 15.333a1.75 1.75 0 0 1 1.75-1.75h8.166a1.75 1.75 0 0 1 1.75 1.75v9.334a1.75 1.75 0 0 1-1.75 1.75h-8.166a1.75 1.75 0 0 1-1.75-1.75v-9.334Zm1.75-.583h8.166a.583.583 0 0 1 .584.583v9.334a.583.583 0 0 1-.584.583h-8.166a.583.583 0 0 1-.584-.583v-9.334a.583.583 0 0 1 .584-.583Z"
      fill="#009F42"
    />
  </svg>
)

export default SvgComponent
