import { ReactElement, useContext } from 'react'
import Button from 'components/Button'
import useGet from 'hooks/useGet'
import { UserContext } from 'context/user'
import MetamaskWallet from 'assets/images/meta-mask.png'
import {
  MainContainer,
  Container,
  TextSection,
  PaidSection,
  LeftSection,
  RightSection,
  TopLeftText,
  BottamLeftText,
  ProPlan,
  PlanText,
  WalletValue,
  WalletText,
  TexInfoSection,
  TexSection,
  TaxText,
  AmmountText,
  TotalAmount,
  Chooseplan,
  TopText,
  OptionText,
  ButtonContainer,
  ButtonSection,
} from 'styles/views/dashboard/Subscricptions/ReviewPlan'

const ReviewPlan = ({ prev, next }: any): ReactElement => {
  const { user } = useContext(UserContext)

  const { data: subscribe } = useGet('get-subscribe', 'subscription/getSubscription', false, {
    enabled: true,
    token: true,
  })

  const lastElement = subscribe?.subscriptionData?.subscription?.length
    ? subscribe?.subscriptionData?.subscription[subscribe?.subscriptionData?.subscription?.length - 1]
    : {}

  return (
    <MainContainer>
      <Chooseplan>
        <TopText>Review Plan </TopText>
        <OptionText></OptionText>
      </Chooseplan>
      <Container>
        <TextSection>Payment Method</TextSection>
        <PaidSection>
          <LeftSection>
            <TopLeftText>{lastElement?.subscription}</TopLeftText>
            <BottamLeftText>{user?.emailId}</BottamLeftText>
          </LeftSection>
          <RightSection src={MetamaskWallet} />
        </PaidSection>
        <ProPlan>
          <PlanText>{lastElement?.subscriptionPlan} Plan</PlanText>
          <WalletValue>
            <WalletText>${lastElement?.subscriptionAmount}</WalletText>
          </WalletValue>
        </ProPlan>
        <TexInfoSection>
          <TexSection>
            <TaxText></TaxText>
            <AmmountText></AmmountText>
          </TexSection>
          <TexSection>
            <TaxText></TaxText>
            <AmmountText></AmmountText>
          </TexSection>
          <TexSection>
            <TaxText>Subtotal </TaxText>
            <TotalAmount>
              ${lastElement?.subscriptionAmount}/{lastElement?.subscriptionCycle}
            </TotalAmount>
          </TexSection>
        </TexInfoSection>
      </Container>
      <ButtonContainer>
        <ButtonSection>
          <Button label="Previous" variant="outline" onClick={prev} />
          <Button label="Pay Now" variant="contained" onClick={next} />
        </ButtonSection>
      </ButtonContainer>
    </MainContainer>
  )
}

export default ReviewPlan
