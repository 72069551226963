import styled from 'styled-components'

export interface IProps {
  label: string
}

export const FilterContainer = styled.select`
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 6px 10px 13px;
`
export const FilterShortSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 15px;
`
export const RestFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`
export const TextReset = styled.div`
  font-family: 'Outfit';
  cursor: pointer;
  font-size: 12px;
  line-height: 33px;
  color: #009f42;
`
export const ResetImage = styled.img`
  height: 15px;
  cursor: pointer;
`
export const FilterBy = styled.select`
  display: flex;
  gap: 10px;
`
export const FilterImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const TableWrapper = styled.div`
  width: 100%;
  padding: 6px;
  @media (max-width: 767px) {
    .ant-table {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  td.ant-table-column-sort {
    background-color: #fff;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
  }
  .center {
    text-align: center;
  }

  table {
    width: 100%;
    padding: 6px;
    tr {
      height: 71px;
      border-bottom: 1px solid #e7e7e7;
      :last-child {
        border: none;
      }
    }
    th {
      text-align: left;
      padding: 22px;
      font-family: 'OutfitMedium';
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #737b7d;
      font-weight: 500;
      @media (max-width: 1440px) {
        padding: 5px;
      }
      :nth-child(5) {
        text-align: left;
        padding-left: 50px;
        @media only screen and (max-width: 900px) {
          padding-left: 25px;
        }
      }
      :nth-child(6) {
        text-align: left;
        padding-left: 50px;
      }
    }
    td {
      font-family: 'OutfitMedium';
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0a0c17;
      padding: 20px;
      :first-child {
        text-align: center;
      }

      @media (max-width: 1366px) {
        padding: 30px 10px 10px 10px;
      }
      :nth-child(5) {
        padding-left: 30px;
        @media only screen and (max-width: 900px) {
          padding-left: 1px;
        }
      }
      :nth-child(1) {
        text-align: inherit;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 11px;
        padding: 30px 6px 10px 6px;
      }
    }
  }
`

export const Short = styled.select`
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 6px 10px 13px;
`
export const FilterSection = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  max-width: 99px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 6px 10px 13px;
  cursor: pointer;
`
export const Filter = styled.div``

export const FilterLogo = styled.img`
  height: 10px;
`
export const FilterText = styled.div`
  font-family: 'OutfitRegular';
  font-size: 14px;
`
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  z-index: 3;
  text-align: left;
  background: #fff;
  position: absolute;
  min-width: 175px;
  width: 100%;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  right: 0px;
  top: 40px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 2.667vw;
    padding: 2.667vw 5.333vw;
    z-index: 3;
    text-align: left;
    background: #fff;
    position: absolute;
    min-width: 46.667vw;
    width: 100%;
    box-shadow: 0px 3.733vw 6.4vw rgb(0 0 0 / 9%);
    border-radius: 2.667vw;
    right: -10.667vw;
    top: 10.667vw;
  }
`
export const FilterLabel = styled.div`
  font-family: 'OutfitSemiBold';
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.2px;
  color: #000000;
`
export const FilterCheckText = styled.div`
  display: flex;
  gap: 5px;
  font-family: 'OutfitRegular';
  font-size: 12px;
  line-height: 24px;
`
export const SortWrapper = styled.div`
  z-index: 1;
  position: absolute;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 99px;
  width: 100%;
  text-align: left;
  top: 40px;
  left: 0;
  padding: 10px 20px;
`
export const SortText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`
export const NoBeneficiaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const BeneficiarieImage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`

export const CryptoLogo = styled.img`
  height: 176px;
  width: 196px;
  text-align: center;
  @media (max-width: 1366px) {
    height: 160px;
    width: 156px;
  }
`

export const TextSextion = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeadingSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 80px;
  padding: 21px 11px 21px 11px;
  text-align: center;
  @media (max-width: 1440px) {
    padding: 20px 3px 22px 3px;
  }
`

export const DefinitionSection = styled.div`
  width: 100%;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #737b7d;
  margin-left: 26px;
  padding: 26px 37px 26px 10px;
  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 26px 10px 25px 10px;
  }
`

export const MainContainer = styled.div`
  gap: 17px;
  display: flex;
  align-items: center;
`
export const Container = styled.div`
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
  border-radius: 30px;
  background: #ffc9a242;
  color: #fea41e;
  display: flex;
  justify-content: center;
`
export const PanddingImage = styled.img`
  height: 23px;
  cursor: pointer;
`
export const MainPending = styled.div`
  display: flex;
  gap: 15px;
`
export const PendingContainer = styled.div`
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
  border-radius: 30px;
  background: #ffc9a242;
  color: #fea41e;
`

export const AcceptedContainer = styled.div`
  background: #009f4221;
  color: #009f42;
  border-radius: 30px;
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`
