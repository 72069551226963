import React, { useState, useContext } from 'react'
import MobileUpdateCrypto from 'views/dashboard/MobileUpdateCrypto'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import Modal from 'components/Modal'
import NavBar from 'components/NavBar'
import Button from 'components/Button'
import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  HeadingArea,
  ButtonBox,
  TopBox,
  TextContainer,
  Section,
  TableContent,
} from 'styles/views/dashboard/SmartContract'

import SmartContract from 'views/dashboard/SmartContract/SmartContractContent/index'
import MobileCreatePlan from '../MyPlan/MobileCreatePlan'

const MobileSmartContracts = () => {
  const [isCreateMobilePlanOpen, setIsCreatePlanOpen] = useState(false)
  const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState(false)
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const setMobilePlanModal = (value: boolean) => {
    setIsCreatePlanOpen(value)
  }
  const setUpdatePlanModal = (value: boolean) => {
    setIsUpdatePlanOpen(value)
  }

  return (
    <Wrapper>
      <NavBar />
      <Section>
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Smart Contract</TextArea>
                <HeadingArea></HeadingArea>
              </TextContainer>
              <ButtonBox>
                {beneficiariesData?.data?.paymentTransition === true ? (
                  <Button label="Update My Cryptoplan" variant="contained" onClick={() => setUpdatePlanModal(true)} />
                ) : (
                  <Button label="Create My Cryptoplan" variant="contained" onClick={() => setMobilePlanModal(true)} />
                )}
              </ButtonBox>
            </TopBox>
            <TableContent>
              <SmartContract />
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
      <Modal isOpen={isCreateMobilePlanOpen}>
        <MobileCreatePlan showModal={(value: boolean) => setMobilePlanModal(value)} />
      </Modal>
      <Modal isOpen={isUpdatePlanOpen}>
        <MobileUpdateCrypto showModal={(value: boolean) => setIsUpdatePlanOpen(value)} />
      </Modal>
    </Wrapper>
  )
}

export default MobileSmartContracts
