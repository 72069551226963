import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
`
export const Actions = styled.div`
  padding: 10px 5px;
  position: absolute;
  z-index: 1;
  right: 20px;
  max-width: 119px;
  min-width: 119px;
  width: 100%;
  background: #fff;
  text-align: left;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  align-items: center;
  text-align: center;
  cursor: pointer;
`
export const ActionText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`
