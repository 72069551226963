import styled from 'styled-components'
import { theme } from 'theme'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const NavContainer = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`
export const TableContent = styled.div`
  padding: 10px 10px 45px 10px;
  @media only screen and (max-width: 1280px) {
    padding: 10px 10px 110px 10px;
  }

  .ant-tabs-nav {
    padding: 0 30px;
    @media (max-width: 767px) {
      padding: 0;
    }
    @media only screen and (max-width: 900px) {
      padding: 0 8px;
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 8px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
    max-width: 90.667vw;
    padding: 2.667vw 2.667vw 22.133vw 2.667vw;
  }
`
export const Section = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 767px) {
    padding: 10px !important;
  }
`
export const MainContainer = styled.div`
  background: #f1f5fb;
  width: 100%;
  margin: 0 24px;
  justify-content: center;
  margin-bottom: 10px;

  @media only screen and (max-width: 1230px) {
    width: 100%;
    max-width: 88vw;
  }
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
`
export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
  @media (max-width: 767px) {
    min-height: 147.733vw;
  }
  @media only screen and (max-width: 900px) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`
export const TextArea = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #0a0c17;
`
export const HeadingArea = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`

export const TopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 35px;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ShortContainer = styled.input`
  width: 100%;
  max-width: 225px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 8px 6px 6px 13px;
`
export const FilterContainer = styled.select`
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 6px 6px 13px;
`
export const FilterShortSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 15px;
`
export const ShortImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const FilterBy = styled.select`
  display: flex;
  gap: 10px;
`
export const FilterImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const TableWrapper = styled.div`
  padding: 20px;
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #fafafa;
  }
`
export const LastSection = styled.div``
