import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={36}
      height={36}
    >
      <path
        d="M31.334 24V11.5L20.917 5.64m-5.833 0L4.667 11.5V24m2.5 3.907L18 34l6.667-3.75 4.167-2.344"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 14.625 13 16.083v5.834l2.5 1.458 2.5 1.458 2.5-1.458 2.5-1.459v-5.833l-2.5-1.458-2.5-1.459-2.5 1.459Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 13.167V7.333M23 21.5l5.834 3.333M13 21.5l-5.833 3.333"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.333a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM4.667 29a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM31.334 29a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#a)">
      <path d="M-2-1h40v40H-2V-1Z" fill="#2677E9" className="icon" />
    </g>
  </svg>
)

export default SvgComponent
