import React, { useContext, useEffect } from 'react'
import { LoaderContext } from 'context/loader'
import { UpdataDataContext } from 'context/updateBeneficiary'
import SteperTree from 'components/SteperTree'
import { Container } from 'styles/views/dashboard/MyPlan/DisbursementTree'
import useGet from 'hooks/useGet'

const DisbursementTree = ({ beneficiariesData, beneficiaryData }: any) => {
  const { updateBeneficiariesData } = useContext(UpdataDataContext)
  const { loader, setLoader } = useContext(LoaderContext)
  const { data: beneficiaryStatus, isLoading: linkLoading } = useGet('get-status', '/get/getStatus', false, {
    enabled: true,
    token: true,
  })

  useEffect(() => {
    if (linkLoading) setLoader(true)
    else setLoader(false)
  }, [linkLoading])

  const data = beneficiariesData?.data?.paymentTransition
    ? updateBeneficiariesData.length === 0
      ? beneficiaryStatus.users
      : updateBeneficiariesData
    : beneficiaryData

  useEffect(() => {
    if (data.length == 0) setLoader(true)
    else setLoader(false)
  }, [data])
  return <Container>{!loader && <SteperTree data={data} />}</Container>
}

export default DisbursementTree
