import { Tabs } from 'antd'
import MyDetails from 'views/dashboard/FiduciarySettings/MyDetails'
import FiduciaryRecoverSecretPhrase from 'views/dashboard/FiduciaryRecoverSecretPhrase/index'
import FiduciarySidebar from 'components/FiduciarySidebar'
import FiduciaryNavBar from 'components/FiduciaryNavBar'
import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  HeadingArea,
  TopBox,
  TextContainer,
  Section,
  TableContent,
  NavContainer,
} from 'styles/views/dashboard/Settings'

const { TabPane } = Tabs

const FiduciarySetting = () => {
  return (
    <Wrapper>
      <FiduciaryNavBar />
      <Section>
        <NavContainer>
          <FiduciarySidebar />
        </NavContainer>
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Settings</TextArea>
                <HeadingArea></HeadingArea>
              </TextContainer>
            </TopBox>
            <TableContent>
              <Tabs defaultActiveKey="1">
                <TabPane tab="My Details" key="1">
                  <MyDetails />
                </TabPane>

                <TabPane tab="Recover Secret Phrase" key="2">
                  <FiduciaryRecoverSecretPhrase />
                </TabPane>
              </Tabs>
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default FiduciarySetting
