import styled from 'styled-components'

export const MainContainer = styled.div``
export const TypeInformation = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`
export const TypeText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2677e9;
`
export const CoinUpImage = styled.img`
  height: 16px;
`
export const StatusText = styled.div`
  border: 1px solid #ff3535;
  color: #ff3535;
  border-radius: 30px;
  font-family: 'OutFitSemiBold';
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 3px 10px;
`
