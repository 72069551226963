import React, { ReactElement, useEffect, useState, useContext } from 'react'
import Modal from 'components/Modal'
import AddSecretOtp from 'components/Modal/AddSecretOtp'
import SecretKeyAllocation from 'components/Modal/SecretKeyAllocation'
import useGet from 'hooks/useGet'
import Table from 'components/Table'
import SerectKey from 'components/Secretkey'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PdfViewer from 'components/Modal/ShardsViewer'
import { columns, iColumns } from 'views/dashboard/FiduciaryRecoverSecretPhrase/data'
import { UserContext } from 'context/user'
import Button from 'components/Button'
import { Progress } from 'antd'
import { MainContainer, Container } from 'styles/components/Status'
import { NextButton } from 'styles/pages/WelcomePlan'
import {
  TableWrapper,
  TextSection,
  TopTable,
  LastContainer,
  ButtonSection,
  TextArea,
  Lefttopsection,
  FiduciaryBottomTable,
} from 'styles/views/dashboard/FiduciaryRecoverPharase'

interface IData {
  key: React.Key
  nickName: string
  secretKey: ReactElement
  appointedAs: string
}

const RecoverSecretPhrase = () => {
  const [first, setfirst] = useState(false)
  const [open, setOpen] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isOpen, openSet] = useState(false)
  const { user } = useContext(UserContext)
  const verifyOtp = user?.isOTPVerified
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const notify = () =>
    toast(`Note: Please copy your shards responsibly, as once logged out won't be able to retreive it.`)

  const handleOpenModal = () => {
    setModalIsOpen(true)
    notify()
  }

  const { data: getlinkup, refetch: linkupFetch } = useGet('get-linkup', 'getlinkup/getuserLink', false, {
    enabled: true,
    token: true,
  })

  const iData: IData[] = [
    {
      key: '1',
      nickName:
        getlinkup?.linkupFiducary && getlinkup?.linkupFiducary.length > 0
          ? getlinkup?.linkupFiducary[0]?.displayName
          : '',
      secretKey: <SerectKey />,
      appointedAs:
        getlinkup?.linkupFiducary && getlinkup?.linkupFiducary.length > 0
          ? getlinkup?.linkupFiducary[0]?.appointAs
          : '',
    },
  ]

  const { data: getShartsForView } = useGet('shart-getShartsForView', 'shart/getShartsForView', false, {
    enabled: true,
    token: true,
  })

  const filteredFiduciary = getShartsForView?.data?.userData.filter(
    (item: { role: string }) => item?.role[0] === 'Fiduciary',
  )

  const filtered = filteredFiduciary?.some((item: { keys: string }) => item?.keys === null)

  useEffect(() => {
    if (getShartsForView) {
      const data = getShartsForView?.data?.userData?.filter(
        (item: any) => item && item?.keys !== null && item.keys !== '',
      )
      setPercent((data.length * 100) / user?.beneficiaryCount)
    } else setPercent(0)
  }, [getShartsForView])

  const fiduciaryData = []

  for (let i = 0; i < filteredFiduciary?.length; i++) {
    const element = filteredFiduciary[i]

    const shartkeys = `${element?.keys?.substring(0, 5)}......${element?.keys?.substring(element?.keys.length - 4)}`

    const joinedFiduciary = element?.joinedKeys ? 'Success' : 'Pending'

    if (element?.keys !== null) {
      fiduciaryData.push({
        key: i,
        nickName: element?.userName,
        appointerEmail: shartkeys,
        appointedAs: element?.role,
        status: (
          <MainContainer>
            <Container label={joinedFiduciary} />
          </MainContainer>
        ),
      })
    }
  }

  const filteredObjects = getShartsForView?.data?.userData.filter(
    (item: { role: string }) => item?.role[0] === 'Beneficiary',
  )

  const tableData = []

  for (let i = 0; i < filteredObjects?.length; i++) {
    const element = filteredObjects[i]

    const shortkeys = `${element?.keys?.substring(0, 5)}......${element?.keys?.substring(element?.keys.length - 4)}`

    const joined = element?.joinedKeys ? 'Success' : 'Pending'

    if (element?.keys !== null) {
      tableData.push({
        key: i,
        nickName: element?.userName,
        appointerEmail: shortkeys,
        appointedAs: element?.role,
        status: (
          <MainContainer>
            <Container label={joined} />
          </MainContainer>
        ),
      })
    }
  }

  const label: string = user?.isWithdrawn ? 'Disburse Ether' : user?.isRedeemed ? 'Disburse Assets' : 'Redeem'

  const disable =
    label !== 'Redeem' &&
    (user?.isEtherWithdrawn || user?.contractStatus === 'Active' || user?.contractStatus === 'Terminated')

  useEffect(() => {
    linkupFetch()
    setOpen(true)
  }, [])

  return (
    <>
      {first || verifyOtp ? (
        <>
          <TableWrapper>
            <LastContainer>
              <Lefttopsection>
                <TextArea>CP holds one shard as a backup</TextArea>

                <Progress
                  percent={percent}
                  strokeColor={{
                    '20%': '#11630dde',
                  }}
                />
              </Lefttopsection>
              {window.innerWidth <= 768 && (
                <div>
                  <Button label="Preview shards" variant="contained" type="submit" onClick={handleOpenModal} />
                  <ToastContainer />
                </div>
              )}{' '}
              <ButtonSection>
                <NextButton
                  label={label}
                  variant="contained"
                  type="button"
                  disabled={disable}
                  onClick={() => openSet(true)}
                />
              </ButtonSection>
            </LastContainer>
            <TextSection>Secret Key Recover Status</TextSection>
            <TopTable>
              <Table data={filtered ? iData : fiduciaryData} columns={filtered ? iColumns : columns} />
            </TopTable>
          </TableWrapper>
          <TableWrapper>
            <TextSection>Confirmation by Others</TextSection>
            <FiduciaryBottomTable>
              <Table data={tableData} columns={columns} />
            </FiduciaryBottomTable>
          </TableWrapper>
          <Modal isOpen={isOpen}>
            <SecretKeyAllocation showModal={(value: boolean) => openSet(value)} />
          </Modal>
        </>
      ) : (
        <>
          <Modal isOpen={open}>
            <AddSecretOtp showModal={(value: boolean) => setOpen(value)} firsts={setfirst} />
          </Modal>
        </>
      )}
      <Modal isOpen={modalIsOpen}>
        <PdfViewer showModal={(value: boolean) => setModalIsOpen(value)} />
      </Modal>
    </>
  )
}

export default RecoverSecretPhrase
