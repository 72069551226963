import styled from 'styled-components'
import Button from 'components/Button'
import { theme } from 'theme'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
  /* box-shadow: 0px 24px 48px rgb(22 29 36 / 39%); */
`
export const TableContent = styled.div`
  padding: 10px 10px 17px 10px;
  .ant-tabs-tab {
    padding-left: 15px;
  }
`
export const Section = styled.div`
  width: 100%;
  display: flex;
`
export const MainContainer = styled.div`
  background: #f1f5fb;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin: 04.8vw !important;
  }
  @media (max-width: 900px) {
    width: 100%;
    max-width: 589px;
  }
  @media (max-width: 1024px) {
    margin: 0;
  }
`
export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
  @media (max-width: 767px) {
    width: 100%;
    max-width: 89.6vw;
  }
`
export const TextArea = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #0a0c17;
`
export const HeadingArea = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const Create = styled(Button)``

export const ButtonBox = styled.div`
  display: flex;
  gap: 10px;
  .sc-bdvvtL {
    padding: 15px;
  }
  :hover {
    opacity: 0.5;
  }
`
export const TopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 0 25px;
  @media (max-width: 767px) {
    padding: 25px 12px 0 12px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ShortContainer = styled.input`
  width: 100%;
  max-width: 225px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 8px 6px 6px 13px;
`
export const FilterContainer = styled.select`
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 6px 6px 13px;
`
export const FilterShortSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 15px;
`
export const ShortImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const FilterBy = styled.select`
  display: flex;
  gap: 10px;
`
export const FilterImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const TableWrapper = styled.div`
  padding: 20px;
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #fafafa;
  }
`

export const Invite = styled(Button)`
  background-color: #1bb691;
  width: 130px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 19.467vw;
    font-size: 3.2vw;
    margin-left: 2.667vw;
  }
`

export const CreateButton = styled(Button)`
  background: #4473f5;
  width: 180px;
  border-radius: 16px;
  @media (max-width: 767px) {
    width: 23.667vw;
    font-size: 2.933vw;
  }
`
