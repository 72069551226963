import React, { useState, useEffect, useContext } from 'react'
import MobileSteper from 'views/dashboard/MyPlan/MobileSteper'
import HeaderLogo from 'assets/images/planhader-image.png'
import { IBeneficiaryData } from 'views/dashboard/MyPlan'
import {
  Container,
  Wrapper,
  HeadingSection,
  LeftTextSection,
  LeftImageSection,
  DataFlowSection,
} from 'styles/views/dashboard/MyPlan/CreatePlan'
import { UserContext } from 'context/user'
import { ethers } from 'ethers'
import ErrorModal from 'components/ErrorModal'
import Modal from 'components/Modal'

interface IModalProps {
  showModal: (value: boolean) => void
  benificiariesData?: IBeneficiaryData[]
}

let signerAddress: string = ''

const MobileCreatePlan: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [heading, setHeading] = useState('Create My Heading')
  const { user } = useContext(UserContext)
  const validSigner: string = user?.userWalletId
  const [accountChangeError, showAccountChangeError] = useState(false)

  const getSignerAddress = async () => {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum)
    const signer = provider.getSigner()
    signerAddress = await signer.getAddress()
    showAccountChangeError(!!(signerAddress && validSigner !== signerAddress.toLocaleLowerCase()))
  }

  useEffect(() => {
    getSignerAddress()
  }, [])

  return (
    <Container>
      <Wrapper>
        <HeadingSection>
          <LeftTextSection>{heading}</LeftTextSection>
          <LeftImageSection src={HeaderLogo} onClick={() => showModal(false)} />
        </HeadingSection>
        <DataFlowSection>
          <MobileSteper showModal={showModal} setHeading={setHeading} />
        </DataFlowSection>
      </Wrapper>
      <Modal isOpen={accountChangeError}>
        <ErrorModal
          showModal={() => {
            showModal(false)
            showAccountChangeError(false)
          }}
          success={false}
          errorModal={`Switch to registered account ${validSigner}`}
        />
      </Modal>
    </Container>
  )
}

export default MobileCreatePlan
