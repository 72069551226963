import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #f1f5fb;
  width: 100%;
  min-height: 100vh;
`
export const Section = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const MainContainer = styled.div`
  background: #f1f5fb;
  width: 100%;
  max-width: 84vw;
  margin: 0 15px;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    max-width: 79vw;
  }
  @media (max-width: 767px) {
    max-width: 95vw !important;
    margin: 0 1.8vw !important;
  }
  @media only screen and (max-width: 820px) {
    max-width: 73vw;
  }
`
export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);

  @media (max-width: 767px) {
    width: 100%;
    max-width: 95.467vw;
    margin-bottom: 5.333vw;
  }
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 26px 0px 26px;
  align-items: center;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProfileText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 80px;
  @media (max-width: 767px) {
    font-size: 4.533vw;
  }
`
export const ProfileDeff = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 151px;
  @media (max-width: 767px) {
    max-width: 26.667vw;
  }
`
export const MyPortfolioSection = styled.div`
  width: 100%;
  max-width: 1185px;
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding: 19px 985px 9px 26px;
  gap: 20px;
`
export const MyPortfolio = styled.div`
  font-family: 'OutfitBold';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  padding-bottom: 9px;
`
export const MyWallets = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  color: #737b7d;
  padding-bottom: 9px;
`
export const TableSection = styled.div`
  padding: 10px 10px 17px 10px;
  z-index: 1;

  .ant-table-wrapper {
    border-radius: 24px;
  }
  .ant-tabs-nav-wrap {
    padding: 10px 10px 10px 19px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    z-index: 0;
  }
`
export const TableBorder = styled.div`
  border-bottom: 1px solid #d9d9d9;
  z-index: 2;
`
