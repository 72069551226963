import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1019px;
  background: #ffffff;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  @media (max-width: 767px) {
    max-width: 92vw !important;
  }
  @media (max-width: 1350px) {
    max-width: 953px;
  }
`
export const HeadingSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 40px;
  @media (max-width: 1366px) {
    padding: 16px 46px 4px 36px;
  }
`

export const LeftTextSection = styled.div`
  font-family: 'OutfitRegular';
  font-size: 28px;
  line-height: 35px;
  align-items: center;
  color: #0a0c17;
`

export const LeftImageSection = styled.img`
  height: 22px;
  cursor: pointer;
`
export const DataFlowSection = styled.div`
  padding: 5px 0;
  text-align: center;
  border-radius: 2px;
  @media (max-width: 1280px) {
    padding: 0;
  }
`
export const AddBeneficiaries = styled.div`
  display: flex;
  align-items: center;
`
export const NumberSection = styled.div`
  font-family: 'OutfitLightSemi Bold';
  font-size: 28px;
  line-height: 80px;
  align-items: center;
  color: #2677e9;
`
export const TextBenefiSection = styled.div`
  font-family: 'Outfit';
  font-size: 14px;
  line-height: 80px;
  align-items: center;
  color: #2677e9;
`
