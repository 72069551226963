import React from 'react'

interface IBeneficiaryDataContext {
  updateBeneficiariesData: any
  setUpdateBeneficiariesData: any
}

const defaultState = {
  updateBeneficiariesData: [],
  setUpdateBeneficiariesData: () => null,
}

const UpdataDataContext = React.createContext<IBeneficiaryDataContext>(defaultState)

export { UpdataDataContext }
