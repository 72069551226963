import React from 'react'
import CrossLogo from 'assets/images/grayx-logo.png'
import PaypalCheckout from 'components/PaypalCheckout'
import { MainContainer, Wrapper, Container, HeadText, Cross } from 'styles/components/Modal/PaymentModal'

interface IModalProps {
  showModal: (value: boolean) => void
  showAnotherModal?: (value: boolean) => void
  data?: any
  getResponse?: () => void
  image: string
}
interface IData {
  displayName: string
  userWalletId: string
  emailId: string
  amount: number
  appointAs: any
  beneficiaryId: string
}

const PaymentModal: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  return (
    <MainContainer>
      <Wrapper>
        <Container>
          <HeadText>Add Paypal Wallet</HeadText>
          <Cross src={CrossLogo} alt="Close" onClick={() => showModal(!true)} />
        </Container>
        <PaypalCheckout />
      </Wrapper>
    </MainContainer>
  )
}

export default PaymentModal
