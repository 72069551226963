import Button from 'components/Button'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  @media (max-width: 767px) {
    padding: 0px 5px;
  }
  @media only screen and (max-width: 900px) {
    padding: 0px 10px;
  }
`
export const HeadText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 40px;
`
export const TextArea = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737b7d;
`
export const WalletButton = styled(Button)`
  max-width: 150px;
  background: rgba(68, 115, 245, 0.13);
  border: 1px solid #a7cbff;
  color: #4473f5;
`
export const ContractButton = styled(Button)`
  width: 100%;
  max-width: 150px;
  background: rgba(68, 115, 245, 0.13);
  border: 1px solid #a7cbff;
  color: red;
`

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 25px 0 35px 0;
  border-bottom: 1px solid #dfdfdf;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 90.667vw;
    overflow-x: scroll;
    border-bottom: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const LabelText = styled.div`
  font-family: 'OutfitMedium';
  min-width: 210px;
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
  @media (max-width: 767px) {
    min-width: 27.867vw;
    font-size: 3.733vw;
  }
`
export const LabelTextPhone = styled.div`
  font-family: 'OutfitMedium';
  min-width: 210px;
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
  @media (max-width: 767px) {
    min-width: 27.867vw;
    font-size: 3.55vw;
  }
`
export const EmailText = styled(LabelText)`
  color: #4473f5;
  @media (max-width: 767px) {
    font-size: 3.467vw;
  }
`
export const WalletLogoSection = styled.img`
  height: 30px;
`
export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const MetaImage = styled.div`
  background: rgba(187, 187, 187, 0.3);
  border-radius: 25px;
  width: 55px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 7px 0px;
  border-bottom: 1px solid #dfdfdf;
  @media (max-width: 767px) {
    border-bottom: none;
  }
`
export const SubDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
`
export const NameContainer = styled.div`
  display: flex;
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 13px 2px 0 0;

    ::placeholder {
      color: #737b7d;
    }
    :focus {
      outline: none;
      box-shadow: none;
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 20vw;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .text11 {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`
export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  width: 15vw;
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 13px 2px 0 0;

    ::placeholder {
      color: #737b7d;
    }
    :focus {
      outline: none;
      box-shadow: none;
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 20vw;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .text11 {
    border: none;
    border-bottom: 1px solid #dfdfdf;
  }
`
export const LabelContainerPhone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 13px 2px 0 0;

    ::placeholder {
      color: #737b7d;
    }
    :focus {
      outline: none;
      box-shadow: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .text11 {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    @media (max-width: 767px) {
      width: 100%;
      max-width: 28vw;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 150px;
  }
`
export const AddEmail = styled.div`
  display: flex;
  font-family: 'OutfitSemiBold';
  font-size: 14px;
  line-height: 24px;
  color: #2677e9;
  gap: 5px;
  cursor: pointer;
`
export const SubWalletWrapper = styled.div``
export const WalletAddress = styled.div`
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
`
export const Wallet = styled.div`
  font-family: 'OutfitRegular';
  font-size: 20px;
  line-height: 25px;
  color: #0a0c17;
`
export const BottomButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 35px;
  @media (max-width: 767px) {
    padding: 0;
  }
`
export const LeftButtonBox = styled.div`
  width: 100%;
`
export const RightButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 10px;
  }
`
export const ResetButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  color: #1bb691;
  border: 1px solid #1bb691;
`
export const DiscardButton = styled(Button)`
  max-width: 150px;
  background: #fff;
  border: 1px solid #dfdfdf;
  color: #000;
`
export const SaveButton = styled(Button)`
  max-width: 150px;

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
  opacity: ${(props) => (props.type === 'button' ? 0.5 : 1)};
`

export const Error = styled.div`
  color: #d65e5e;
  padding: 5px 0px;
  font-size: 12px;
  text-align: left;
`
export const SecondaryEmailText = styled(LabelText)`
  color: #4473f5;
  display: flex;
  gap: 5px;
`
export const SecondaryImg = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`
export const EmailLabelContainer = styled.div`
  color: #4473f5;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  @media (max-width: 767px) {
    gap: 2px;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1366px) {
    max-width: 393px;
  }
`
export const SecndEmailLabel = styled.div`
  color: #4473f5;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  @media (max-width: 1366px) {
    width: 100%;
    max-width: 425px;
    text-align: center;
    align-items: center;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }
`
export const CopySection = styled.div`
  font-family: 'OutfitSemiBold';
  display: flex;
  gap: 8px;
  align-items: center;
`
export const LabelEmail = styled.div`
  font-family: 'OutfitMedium';
  min-width: 210px;
  font-size: 16px;
  line-height: 24px;
  color: #0a0c17;
  @media (max-width: 1366px) {
    text-align: left;
  }
  @media (max-width: 767px) {
    min-width: 37.6vw;
  }
`
