import styled from 'styled-components'
import { theme } from 'theme'

export const EthBox = styled.div`
  width: 100%;
  max-width: 116px;
  height: 251px;
  background: linear-gradient(180deg, #eff4ff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 8px 40px 29px -16px rgba(207, 207, 207, 0.5);
  border-radius: 40px;
  color: #0a0c17;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 39px;
  margin-left: 37px;
  cursor: pointer;
  :hover {
    box-shadow: 5px 20px 50px -16px rgba(103, 146, 255, 0.5);
    border-radius: 40px;
    color: ${theme.info.light};
    background: linear-gradient(180deg, #4675f7 0%, #1b49cc 100%);
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 25.789vw;
    margin-left: 2.133vw;
  }
`
export const EthImageSection = styled.img`
  height: 63px;
  margin: 13px;
`
export const EthTextSection = styled.div`
  text-align: center;
`
export const EthDemoSection = styled.div`
  text-align: center;
`
export const EthFirstText = styled.div`
  font-family: 'OutfitBold';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
`
export const EthSecndText = styled.div`
  font-family: 'OutfitBold';
  font-size: 12px;
  line-height: 22px;
`
