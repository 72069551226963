import styled from 'styled-components'

export const Container = styled.div`
  background: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 24px 48px rgb(22 29 36 / 39%);
`
export const TopSection = styled.div`
  display: flex;

  gap: 20px;
  padding: 18px 26px 25px 26px;
  align-items: flex-start;
  @media (max-width: 1300px) {
    padding: 10px 26px 0px 26px;
  }
  button {
    border: 1px solid #b8d5ff;
    border-radius: 9px;
    color: #2677e9;
    background: white;
    width: 41px;
    height: 41px;
    cursor: pointer;
  }
  .tools {
    display: flex;
    gap: 5px;
  }
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProfileText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
`
export const ProfileDeff = styled.div`
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #737d77;
`
export const TreeButton = styled.div`
  @media (max-width: 767px) {
    display: none !important;
  }
`
