import React, { useState } from 'react'
import usePost from 'hooks/usePost'
import MetamaskModal from 'components/Modal/MetamaskModal'
import Modal from 'components/Modal'
import CancelSign from 'assets/images/vector-cancel.png'
import { StyledContainer, ImageSection, CancelSection, TextSection } from 'styles/views/landing/SignIn'
import { useMoralis } from 'react-moralis'
import SignUpCard from 'components/Cards/SignUp'
import { Container, Wrapper, PrimaryWalletSection, CardSection } from 'styles/views/landing/SignUp'
import data from 'views/landing/SignUp/data'
import Otp from '../OtpModal'

interface IModalProps {
  showModal: (value: boolean, type: string) => void
}

const AddWalletAddressModal: React.FC<IModalProps> = ({ showModal }: IModalProps) => {
  const [openMetamaskModal, setOpenMetamaskModal] = useState<boolean>(false)
  const [otpVerifyModal, setOtpVerifyModal] = useState<boolean>(false)
  const [userwalletIdwalletconnect, setuserwalletIdwalletconnect] = useState('')
  const [Selector, setSelector] = useState('')
  const { authenticate } = useMoralis()
  const { mutateAsync } = usePost()
  const signingMessage = process.env.REACT_APP_SECRET_MESSAGE || ''

  const getProviderDetails = (provider: 'wallet-connect' | 'metamask'): any => {
    if (provider === 'wallet-connect')
      return {
        provider: 'walletconnect',
        chainId: process.env.REACT_APP_WALLET_CONNECT_ID,
        signingMessage,
      }
    else
      return {
        signingMessage,
      }
  }
  const walletLogin = async () => {
    const providerDetails = getProviderDetails('wallet-connect')
    const authData = (await authenticate(providerDetails))?.get('authData')
    const { id } = authData.moralisEth
    setuserwalletIdwalletconnect(id)
    try {
      const response = await mutateAsync({
        url: 'verify/otp',
      })
      if (response?.success) {
        setOtpVerifyModal(true)
        setSelector('WalletConnect')
      } else {
        throw new Error('Problem in sending OTP!')
      }
    } catch (err) {
      alert(err)
    }
  }

  return (
    <StyledContainer>
      <Container>
        <Container>
          <Wrapper>
            <PrimaryWalletSection>
              <ImageSection>
                <TextSection>Connect Wallet</TextSection>
                <CancelSection src={CancelSign} onClick={() => showModal(false, 'signIn')} />
              </ImageSection>

              <CardSection onClick={() => setOpenMetamaskModal(true)}>
                <SignUpCard key={data[0].id} image={data[0].image} title={data[0].title} />
              </CardSection>

              <CardSection onClick={() => walletLogin()}>
                <SignUpCard key={''} image={data[1].image} title={data[1].title} />
              </CardSection>
            </PrimaryWalletSection>
          </Wrapper>
        </Container>
      </Container>
      <Modal isOpen={openMetamaskModal}>
        <MetamaskModal showModal={(value: boolean) => setOpenMetamaskModal(value)} />
      </Modal>
      <Modal isOpen={otpVerifyModal}>
        <Otp
          showModal={(value: boolean) => {
            setOtpVerifyModal(value)
            showModal(false, 'signIn')
          }}
          userwalletId={userwalletIdwalletconnect}
          fromSelector={Selector}
        />
      </Modal>
    </StyledContainer>
  )
}

export default AddWalletAddressModal
