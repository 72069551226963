import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'components/Button'
import CancelSign from 'assets/images/black-x.png'
import TextInput from 'components/FormElements/TextInput'
import Modal from 'components/Modal'
import ErrorModal from 'components/ErrorModal'
import { LoaderContext } from 'context/loader'
import { useNavigate } from 'react-router-dom'
import { BeneficarydashboardRoute, FiduciaryMyPlanRoute } from 'constants/routes'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import {
  MainContainer,
  TopSection,
  TextSection,
  BottomSection,
  ButtonSecton,
  StyledContainer,
  BottomText,
  CancelSection,
  InputField,
} from 'styles/components/Modal/AddSecretOtp'

export interface IModalProps {
  showModal: (value: boolean) => void
  firsts: any
}

const AddSecretOtp: React.FC<IModalProps> = ({ showModal, firsts }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [errorModal, seterrorModal] = useState<string | ''>('')
  const { control, handleSubmit, formState, reset } = useForm()
  const { mutateAsync: otpChecker, isLoading } = usePost()
  const { mutateAsync: otpCheckermobile, isLoading: otpcheckermobileLoading } = usePost()
  const { setLoader } = useContext(LoaderContext)
  const { mutateAsync: otpStatus } = usePost()
  const navigate = useNavigate()
  const [downloadedData, setDownloadedData] = useState('')

  const verifyFlag = async (payload: boolean) => {
    await otpStatus({
      url: 'otp/otpStatus',
      payload: {
        isOTPVerified: payload,
      },
      token: true,
    })
  }

  const mobileOtpVerify = async (data: any) => {
    try {
      const response: any = await otpCheckermobile({
        url: 'otp/otpVerify_v2',
        payload: data,
        token: true,
      })
      if (response) {
        const payload = response?.data ? true : false
        verifyFlag(payload)
        showModal(false)
        firsts(true)
        localStorage.setItem('shards', response?.data?.shards)
      }
    } catch (error: any) {
      seterrorModal(error.response.data.message)
      setIsOpen(true)
      return {
        error: errorModal,
      }
    }
  }

  const webOtpVerify = async (data: any) => {
    try {
      const response: any = await otpChecker({
        url: '/otpverifys/otpverify',
        payload: data,
        token: true,
      })
      if (response) {
        const payload = response?.data ? true : false
        setDownloadedData(response?.data)
        verifyFlag(payload)
        showModal(false)
        firsts(true)
      }
    } catch (error: any) {
      seterrorModal(error.response.data.message)
      setIsOpen(true)
      return {
        error: errorModal,
      }
    }
  }

  const onSubmit = async (data: any) => {
    if (window.innerWidth <= 768) {
      mobileOtpVerify(data)
    } else {
      webOtpVerify(data)
    }
  }

  const { refetch: getViewDataRefetch } = useGet('otp', '/otpShart/otpShart', false, {
    enabled: true,
    token: true,
  })

  const { data: fiduciaryView, refetch: viewRefetch } = useGet('view-fiduciary', 'getView/getFiduciaryView', false, {
    enabled: true,
    token: true,
  })

  const { data: getBenificaryView, refetch } = useGet('get-Benificary', 'getBenificary/getBenificaryView', false, {
    enabled: true,
    token: true,
  })

  const ownerName =
    fiduciaryView?.fiducarydetail && fiduciaryView?.fiducarydetail.length > 0
      ? fiduciaryView?.fiducarydetail[0]?.ownerName
      : '' || (getBenificaryView?.beneificaryDetail && getBenificaryView?.beneificaryDetail.length > 0)
      ? getBenificaryView?.beneificaryDetail[0]?.ownerName
      : ''

  useEffect(() => {
    if (downloadedData) {
      const linkSource = `data:application/pdf;base64,${downloadedData}`
      const downloadLink = document.createElement('a')
      const fileName = 'Shards.pdf'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }, [downloadedData])

  useEffect(() => {
    refetch()
    getViewDataRefetch()
    viewRefetch()
  }, [])

  useEffect(() => {
    if (isLoading || otpcheckermobileLoading) setLoader(true)
    else setLoader(false)
  }, [isLoading, otpcheckermobileLoading])

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ token: '' })
    }
  }, [formState, reset])

  return (
    <StyledContainer>
      <MainContainer onSubmit={handleSubmit(onSubmit)}>
        <TopSection>
          <CancelSection
            src={CancelSign}
            onClick={() => {
              location.pathname === '/fiduciarysetting'
                ? navigate(FiduciaryMyPlanRoute.path)
                : navigate(BeneficarydashboardRoute.path)
            }}
          />
        </TopSection>
        <BottomSection>
          <TextSection>
            You are appointed as a {location.pathname === '/fiduciarysetting' ? 'fiduciary' : 'beneficiary'} for{' '}
            {ownerName}
          </TextSection>
          <BottomText>Please enter the OTP you received</BottomText>
          <InputField>
            <TextInput type="text" name="token" className="IdSection" placeholder="Enter OTP" control={control} />
          </InputField>
        </BottomSection>
        <ButtonSecton>
          <Button label="Submit " variant="contained" type="submit" />
        </ButtonSecton>
      </MainContainer>
      <Modal isOpen={isOpen}>
        <ErrorModal showModal={(value: boolean) => setIsOpen(value)} errorModal={errorModal} success={false} />
      </Modal>
    </StyledContainer>
  )
}

export default AddSecretOtp
