interface IStatus {
  title: string
}

export const iColumns = [
  {
    title: 'NickName',
    dataIndex: 'nickName',
  },

  {
    title: 'Appointed As',
    dataIndex: 'appointedAs',
  },
  {
    title: 'Secret Key',
    dataIndex: 'secretKey',
  },
]

export const columns = [
  {
    title: 'Nickname',
    dataIndex: 'nickName',
  },
  {
    title: 'Appointed As',
    dataIndex: 'appointedAs',
  },
  {
    title: 'Secret Key',
    dataIndex: 'appointerEmail',
  },

  {
    title: 'Status',
    dataIndex: 'status',
  },
]
