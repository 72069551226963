import styled from 'styled-components'

export const MainContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 220px;
  right: 13px;
  top: 58px;
  background: #ffffff;
  padding: 11px;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 11px;
  z-index: 2;
  margin-top: 7px;
  @media (max-width: 1440px) {
    right: 7px;
  }
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const EmailWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const EmailImg = styled.img`
  width: 11px;
  height: 15px;
  cursor: pointer;
`
export const UserImg = styled.img`
  width: 19px;
  height: 19px;
  cursor: pointer;
`
export const EmailText = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2677e9;
  cursor: pointer;
`
export const UserText = styled.div`
  font-family: 'Outfitregular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`
export const BalanceWrapper = styled.div`
  width: 100%;
  max-width: 215px;
  height: 70px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 16px;
`
export const BalanceText = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #737b7d;
  cursor: pointer;
`
export const BalanceNumber = styled.div`
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #1bb691;
  cursor: pointer;
`
export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e4e4e4;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.02em;
  padding: 7px 0;
  cursor: pointer;
`

export const WalletImg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`
export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'OutfitRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.02em;
  padding: 7px 0;
  cursor: pointer;
`
