import styled from 'styled-components'
import { theme } from 'theme'
import Button from 'components/Button'

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 6px 6px 13px;
  cursor: pointer;
`
export const FilterShortSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 15px;
`
export const ShortContainer = styled.input`
  width: 100%;
  max-width: 225px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 8px 6px 6px 13px;
`

export const FilterBy = styled.select`
  display: flex;
  gap: 10px;
`
export const FilterImage = styled.img`
  height: 10px;
  margin: 6px;
`
export const TableWrapper = styled.div`
  padding: 20px;
  @media (max-width: 767px) {
    .ant-table {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .ant-table-thead {
    background-color: ${theme.primary.light};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: #fafafa;
  }
  .ant-table-cell {
    padding: 16px 46px;
    text-align: center;
    font-family: 'OutfitMedium';
    font-size: 14px;
  }
  .sc-hilbJV .hurACc {
  }
  th {
    :nth-child(3) {
      text-align: left;
      padding-left: 80px;
    }
  }
  td {
    :nth-child(2) {
      padding-left: 10px;
    }
  }
`
export const SortWrapper = styled.div`
  z-index: 1;
  position: absolute;
  background: #fff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 99px;
  width: 100%;
  text-align: left;
  right: 30px;
  top: 48px;
  padding: 10px 20px;
`
export const SortText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 12px;
  line-height: 24px;
`
export const ShortImage = styled.img`
  height: 10px;
  margin: 6px;
`

export interface IProps {
  label: string
}

export const MainContainer = styled.div`
  gap: 17px;
  display: flex;
  justify-content: start;
  align-items: center;
`
export const PendingContainer = styled.div`
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
  border-radius: 30px;
  background: #ffc9a242;
  color: #fea41e;
`
export const PanddingImage = styled.img`
  height: 23px;
`
export const ActionButton = styled(Button)`
  background: #fff;
  color: #2677e9;
  border: 1px solid #2677e9;
  border-radius: 16px;
  width: 122px;
`
export const ResendButton = styled(Button)`
  background: #fff;
  color: #2677e9;
  border: 1px solid #2677e9;
  border-radius: 16px;
  width: 122px;
`
export const StopButton = styled(Button)`
  display: none;
  background: #ff3535;
  border-radius: 16px;
  width: 122px;
  color: #fff;
`
export const IdStatus = styled.div`
  color: #2677e9;
`

export const AcceptedContainer = styled.div`
  background: #009f4221;
  color: #009f42;
  border-radius: 30px;
  width: 100%;
  max-width: 120px;
  padding: 10px 0;
`

export const MainPending = styled.div`
  display: flex;
  gap: 15px;
`
export const Pendingimg = styled.img`
  height: 35px;
  padding-top: 10px;
  cursor: pointer;
`
export const Container = styled.div`
  min-height: 50vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Image = styled.img`
  min-height: 30vh;
  @media (max-width: 767px) {
    min-height: 21vh;
  }
`

export const TextMessage = styled.div`
  font-family: 'OutfitMedium';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.1px;
`
