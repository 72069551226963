import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MainContainer } from 'styles/components/PageNotFound'

const PageNotFound = () => {
  const navigate = useNavigate()
  const goTo = () => {
    if (location.pathname === '/') navigate('/dashbaord')
    else {
      navigate('/')
    }
  }

  return (
    <div>
      <div onClick={goTo}>Back</div>
      <MainContainer>
        <div>Error</div>
        <div>Page Not Found</div>
      </MainContainer>
    </div>
  )
}

export default PageNotFound
