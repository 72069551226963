import React from 'react'
import FirstMetamask from 'assets/images/first-Metamask.png'
import Middpowerfull from 'assets/images/oursolution-midd.png'
import SecndMidd from 'assets/images/Secndlogo-midd.png'
import OpenZapline from 'assets/images/openzapline-midd.png'
import Web3apis from 'assets/images/wb3-apis.png'
import RediusRed from 'assets/images/redis-red.png'
import ShardsLogo from 'assets/images/shard-logo.png'
import ShardsManager from 'assets/images/shards-manager.png'

import {
  RoadMap,
  OurSolutionBox,
  SolutionMiddleSection,
  SolutionLastBox,
  SolutionTopSection,
  TopLeftSection,
  ToprightSection,
  ImageCintainer,
  TextSection,
  BottomLastBox,
  MiddFirstSection,
  MiddImageCintainer,
  MiddSecSection,
  MiddLastSection,
  MiddSecndImg,
  MiddZapSection,
  ZapDetails,
  BackgrundBrack,
  BackgrundBrackMetamask,
  ZapDetailsLast,
  Backgrundhide,
  MiddleSection,
  TextWrapper,
  ScalableFirstSection,
  MobileMap,
  BackgrundBrackGreen,
  BackgrundBrackBlue,
  BackgrundGreenBlue,
  BackgrundToEncrypt,
} from 'styles/views/landing/OurSolution'
import MobileOurSolution from 'views/landing/MobileOurSolution'
// import Paath from 'assets/svg/Paath'
// import Solutionpath from 'assets/svg/Solutionpath'

const OurSolution = () => {
  return (
    <RoadMap>
      <OurSolutionBox>
        <SolutionTopSection>
          <TopLeftSection>
            {/* <ImageBlueBaclground> */}
            <BackgrundBrackMetamask>
              <ImageCintainer src={FirstMetamask} />
            </BackgrundBrackMetamask>
            {/* </ImageBlueBaclground> */}
            <TextSection>Secured Authentication with MetaMask & WalletConnect</TextSection>
          </TopLeftSection>
          <ToprightSection>Our Solution</ToprightSection>
        </SolutionTopSection>
        <SolutionMiddleSection>
          <ZapDetails>
            <MiddFirstSection>
              <BackgrundBrack>
                <MiddImageCintainer src={Middpowerfull} />
              </BackgrundBrack>
              <TextSection>Powerful SDK for cloud functions</TextSection>
            </MiddFirstSection>
            <MiddSecSection>
              <BackgrundBrackBlue>
                <MiddSecndImg src={SecndMidd} />
              </BackgrundBrackBlue>
              <TextSection>Available on Trusted EVM chains</TextSection>
            </MiddSecSection>
            <MiddLastSection>
              <BackgrundBrackGreen>
                <MiddZapSection src={OpenZapline} />
              </BackgrundBrackGreen>
              <TextSection>Battle tested framework for smart Contract </TextSection>
            </MiddLastSection>
          </ZapDetails>
        </SolutionMiddleSection>
        <SolutionLastBox>
          <ScalableFirstSection>
            <Backgrundhide>
              <MiddImageCintainer src={Web3apis} />
            </Backgrundhide>
            <TextSection>Scalable Web3 APIs</TextSection>
          </ScalableFirstSection>
          <ZapDetailsLast>
            <MiddleSection>
              <BackgrundGreenBlue>
                <MiddSecndImg src={RediusRed} />
              </BackgrundGreenBlue>
            </MiddleSection>
            <MiddLastSection>
              <BackgrundToEncrypt>
                <MiddZapSection src={ShardsLogo} />
              </BackgrundToEncrypt>
            </MiddLastSection>
            <MiddLastSection>
              <BackgrundBrackGreen>
                <MiddZapSection src={ShardsManager} />
              </BackgrundBrackGreen>
            </MiddLastSection>
          </ZapDetailsLast>
          <TextWrapper>
            <TextSection>To ingest, process and analyse real-time data with sub milli-second latency</TextSection>
            <TextSection>To encrypt &amp; decrypt shards</TextSection>
            <TextSection>Shard Manager</TextSection>
          </TextWrapper>
        </SolutionLastBox>
        <BottomLastBox />
      </OurSolutionBox>
      <MobileMap>
        <MobileOurSolution />
      </MobileMap>
    </RoadMap>
  )
}

export default OurSolution
