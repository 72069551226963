export const previousYear = (totalList: any) => {
  const res = totalList.filter((item: any) => {
    const itemDate = item.createDate.split('T')[0]?.split('.')[0]
    const today = new Date().toISOString().slice(0, 10)
    const itemYear = itemDate.split('-')[0]
    if (itemYear + 1 == today.split('-')[0]) return item
  })
  return res
}

export const currentYear = (totalList: any) => {
  const res = totalList.filter((item: any) => {
    const itemDate = item.createDate.split('T')[0]?.split('.')[0]
    const today = new Date().toISOString().slice(0, 10)
    const itemYear = itemDate.split('-')[0]
    if (itemYear == today.split('-')[0]) return item
  })
  return res
}

export const quaterYear = (totalList: any) => {
  const res = totalList.filter((item: any) => {
    const itemDate = item.createDate.split('T')[0]?.split('.')[0]
    const today = new Date().toISOString().slice(0, 10)
    const itemYear = itemDate.split('-')[1]
    if (itemYear + 3 >= today.split('-')[1]) return item
  })
  return res
}

export const semiYear = (totalList: any) => {
  const res = totalList.filter((item: any) => {
    const itemDate = item.createDate.split('T')[0]?.split('.')[0]
    const today = new Date().toISOString().slice(0, 10)
    const itemYear = itemDate.split('-')[1]
    if (itemYear + 6 >= today.split('-')[1]) return item
  })
  return res
}
