import styled from 'styled-components'

export const MainContainer = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 18.667vw;
    /* background: linear-gradient(125.17deg, #4473f5 0%, #50c6be 89.38%); */
  }
`
export const HeadingWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Heading = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    font-weight: 700;
    font-size: 8vw;
    line-height: 88px;
    color: #ffffff;
  }
`
export const Container = styled.div`
  position: relative;
`
export const AuthenticationWrapper = styled.div`
  display: flex;
`
export const AuthentcationImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    top: -12vw;
    left: 4.8vw;
  }
`
export const Image = styled.img`
  @media (max-width: 767px) {
    width: 27.467vw;
    height: 27.467vw;
  }
`
export const TextWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    top: -2.667vw;
    left: 36.8vw;
    max-width: 41.6vw;
    width: 100%;
  }
`
export const SDKWrapper = styled.div`
  display: flex;
`
export const SDKImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    top: 33.067vw;
    left: 50.4vw;
  }
`
export const SDKText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    top: 61.067vw;
    left: 6.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const ZapText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    bottom: 243.067vw;
    left: 6.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const First = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    bottom: 186.067vw;
    left: 25.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const FirstText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    bottom: 131.067vw;
    left: 8.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const SecText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    bottom: 80.067vw;
    left: 29.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const LastText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    bottom: 31.067vw;
    left: 9.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const AwelText = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 4.533vw;
    color: #ffffff;
    top: 117.067vw;
    left: 32.067vw;
    max-width: 41.6vw;
    width: 100%;
    text-align: right;
  }
`
export const ZapWrapper = styled.div`
  display: flex;
`
export const FirstSection = styled.div`
  display: flex;
`
export const SecndSection = styled.div`
  display: flex;
`
export const ThredSection = styled.div`
  display: flex;
`
export const FourthSection = styled.div`
  display: flex;
`
export const TrustWrapper = styled.div`
  display: flex;
`

export const TrustImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    top: 86.667vw;
    left: 10.4vw;
  }
`
export const ImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    top: 142.133vw;
    left: 49.333vw;
  }
`
export const Wrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    bottom: 205.133vw;
    left: 8.533vw;
  }
`
export const ScaleImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    bottom: 148.8vw;
    left: 48vw;
  }
`
export const ScalableImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    bottom: 94.933vw;
    left: 10.933vw;
  }
`
export const BottomImageWrapper = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    bottom: 40vw;
    left: 49.333vw;
  }
`
