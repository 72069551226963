import styled from 'styled-components'
import Button from 'components/Button'
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f1f5fb;
  min-height: 100vh;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 576px;
`
export const WelcomeText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 52px;
  line-height: 80px;
  @media (max-width: 767px) {
    font-family: 'OutfitMedium';
    font-size: 11.2vw;
    display: flex;
    align-items: center;
    text-align: center;
  }
`
export const WelcomeProfile = styled.div`
  width: 100%;
  max-width: 443px;
  font-family: 'OutfitLight';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`
export const SelectSection = styled.div`
  font-family: 'OutfitMedium';
  font-size: 22px;
  line-height: 28px;
  padding: 35px 10px 35px 10px;
  @media (max-width: 1440px) {
    padding: 23px 10px 30px 10px;
  }
  @media (max-width: 1366px) {
    padding: 20px 10px 20px 10px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 133.333vw;
    padding: 4.8vw 2.667vw 9.6vw 2.667vw;
  }
`
export const Grantor = styled.div`
  width: 100%;
  max-width: 298px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 46px 91px 28px 91px;

  @media (max-width: 1440px) {
    padding: 25px 91px 28px 91px;
  }
  @media (max-width: 1366px) {
    padding: 15px 91px 15px 91px;
  }
  @media (max-width: 767px) {
    padding: 20vw 24vw 7.2vw 24vw;
  }
`
export const GrantorId = styled.img``
export const GrantorText = styled.div``
export const HeadingText = styled.div`
  font-family: 'OutfitMedium';
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  padding: 10px 89px 16px 89px;
  @media (max-width: 1440px) {
    padding: 0 89px 16px 89px;
  }
  @media (max-width: 1366px) {
    padding: 0 89px 0 89px;
  }
  @media (max-width: 767px) {
    padding: 0 23.467vw 4vw 23.467vw;
  }
`
export const SubHadding = styled.div`
  font-family: 'OutfitMedium';
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  min-height: 145px;
  color: #737b7d;
  padding: 16px 12px 49px 12px;
  @media (max-width: 1366px) {
    padding: 16px 43px 22px 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 11.2vw 12.8vw 7.733vw;
  }
`

interface IMainContainer {
  select: boolean
  disabled: boolean
}

export const MainContainer = styled.div<IMainContainer>`
  width: 100%;
  max-width: 298px;
  background: #ffffff;
  border-radius: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border: ${(props) => (props.select ? '1px solid #4473f5' : 'none')};

  ${(props) => (props.disabled ? 'filter: grayscale(1);cursor: not-allowed;' : '')};

  @media (max-width: 1440px) {
    max-width: 291px;
  }
  @media (max-width: 1366px) {
    max-width: 291px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 78.933vw;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 400px;
  padding: 30px 0px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 58.667vw;
  }
`
export const UserOptionCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-top: 1.333vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 78.933vw;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #f1f5fb;
  position: sticky;
  padding: 10px 10px 10px 10px;
  @media (max-width: 1366px) {
    padding: 10px 10px 0 10px;
  }
  @media (max-width: 767px) {
    padding: 8.267vw 6.4vw 5.333vw 9.333vw;
  }
`
export const LogoSection = styled.img`
  @media (max-width: 767px) {
    width: 41.333vw;
    height: 10.133vw;
  }
`
export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 248px;
  width: 100%;
`
export const ThemeIcon = styled.img`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 767px) {
    width: 11.467vw;
    height: 11.467vw;
  }
`
export const SectionButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-right: 920px;
  @media (max-width: 1366px) {
    justify-content: end;
    margin-right: 450px;
  }
  @media (max-width: 1440px) {
    justify-content: end;
    margin-right: 450px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 85.333vw;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`
export const CancelButton = styled(Button)`
  border: 1px solid #cbd1d8;
  color: #000;
  width: 150px;
`
export const NextButton = styled(Button)`
  border: 1px solid #4473f5;
  color: #fff;
  width: 150px;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
export const StopButton = styled(Button)`
  border: 1px solid #4473f5;
  color: #fff;
  width: 150px;
  background: #ff3535;
  border: none;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
