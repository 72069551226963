import styled from 'styled-components'
import { theme } from 'theme'

export const Container = styled.div`
  width: 100%;
  background: #040d20;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 35px 60px;
  @media (max-width: 1350px) {
    gap: 75px;
    padding: 26px;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: auto;
    padding: 10.133vw 2.667vw 10.667vw 1.333vw;
    gap: 8vw;
  }
`

export const WrapperLogoSection = styled.div`
  @media (max-width: 767px) {
    order: 1;
    grid-row-end: none;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 270px;
    padding-left: 6.667vw;
  }
`

export const FooterLogo = styled.img`
  max-width: 350px;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const FootMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 1350px) {
    gap: 5px 20px;
  }

  @media (max-width: 767px) {
    display: grid;
    justify-content: flex-start;
    padding-left: 6.667vw;
    gap: 3.867vw;
  }
`

export const MenuItem = styled.div`
  font-family: 'OutfitRegular';
  font-size: 16px;
  color: ${theme.info.light};
  cursor: pointer;
  :hover,
  :active {
    color: ${theme.secondary.main};
  }
  @media (max-width: 1000px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 600;
  }
`
