import FirstWallet from 'assets/images/firstwallet-image.png'

interface IData {
  id: number
  image: string
  title: string
  subtitle: string
}

export interface IColumns {
  id: number
  image: string
  title: string
  subtitle: string
}

export const columns = [
  {
    id: 0,
    image: FirstWallet,
    title: 'Crypto Wallet',
    subtitle: '1 Wallet(s) Connected',
  },
]
export default { columns }
