import { getBranches, getWalletDetails, getBeneficiaryArray, getFiduciaryArray } from 'components/Tree/treeHelpers'
import { UserStatus, UserWallet } from 'styles/components/DisbursementTree'

export const dumbData = (user: any, fidBen: any, setIsWalletAddrAdd: any) => {
  const walletIndexes = user?.userWallet.map((item: any, i: number) => {
    if (item) return i + 2
  })

  const initialNodes = [
    {
      id: '1',
      layer: 1,
      type: 'input',
      style: {
        background: '#4473F5',
        border: '8px solid #FFFFFF',
        borderRadius: '30px',
      },
      parent: [...walletIndexes, walletIndexes.length + 2],
      data: {
        label: (
          <div className="Benwrapper">
            <div style={{ color: '#ffffff', fontSize: '24px', lineHeight: '24px', fontFamily: 'OutfitSemiBold' }}>
              {user.userName}
            </div>
            <UserStatus fontSize="18px">Owner</UserStatus>
            <div className="wallet">
              <UserWallet padding="10px" fontSize="18px">
                {user?.userWallet?.length} Wallet
              </UserWallet>
            </div>
          </div>
        ),
      },
      position: { x: 300, y: -140 },
    },
  ]

  const totalWallets = getWalletDetails(user, initialNodes, setIsWalletAddrAdd)
  const nodesWithWallet = [...initialNodes, ...totalWallets]

  const beneficary = getBeneficiaryArray(fidBen, nodesWithWallet)
  const fidData = getFiduciaryArray(fidBen, nodesWithWallet, beneficary)

  const totalData = fidData ? [...fidData, ...beneficary] : fidData
  const branches = getBranches(totalData, 2, 'stepper')
  const totalBranches = branches.reduce((previousValue: any, currentValue: any) => {
    return [...previousValue, ...currentValue]
  })
  return [totalData, totalBranches]
}
