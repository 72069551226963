import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.381 12.581a.688.688 0 0 1-.962-.138 4.434 4.434 0 0 0-3.575-1.787.688.688 0 1 1 0-1.375 2.063 2.063 0 1 0-2.028-2.449.686.686 0 1 1-1.35-.258 3.438 3.438 0 1 1 5.698 3.18 5.826 5.826 0 0 1 2.355 1.865.688.688 0 0 1-.138.962Zm-4.812 5.68a.697.697 0 0 1-.318.92.8.8 0 0 1-.301.069.696.696 0 0 1-.619-.387 4.82 4.82 0 0 0-8.662 0 .688.688 0 0 1-.92.318.696.696 0 0 1-.318-.92 6.196 6.196 0 0 1 3.06-2.955 4.125 4.125 0 1 1 5.018 0 6.196 6.196 0 0 1 3.06 2.956ZM11 14.782a2.75 2.75 0 1 0-2.75-2.75 2.759 2.759 0 0 0 2.75 2.75ZM5.844 9.97a.688.688 0 0 0-.688-.688 2.063 2.063 0 1 1 2.028-2.449.687.687 0 1 0 1.35-.258 3.438 3.438 0 1 0-5.698 3.18A5.826 5.826 0 0 0 .48 11.619a.687.687 0 1 0 1.1.825 4.434 4.434 0 0 1 3.575-1.788.688.688 0 0 0 .688-.687Z"
      fill="#737B7D"
      className="sideIcon"
    />
  </svg>
)

export default SvgComponent
