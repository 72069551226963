import { ReactElement } from 'react'
import { IdStatus, StatusActive } from 'styles/components/Status'

interface IStatus {
  title: string
}
interface IData {
  key: React.Key
  contractname: string
  contractid: ReactElement
  contractdate: string
  appointedas: string
  status: ReactElement
  action: ReactElement
}

export const data: IData[] = [
  {
    key: '1',
    contractname: 'David John',
    contractid: <IdStatus>DCFK028FSD339</IdStatus>,
    contractdate: '05/12/2021',
    appointedas: 'Fiduciary',
    status: <StatusActive>Active</StatusActive>,
    action: <IdStatus>Start Contract</IdStatus>,
  },
]

export const columns = [
  {
    title: 'Owner’s Name',
    dataIndex: 'contractname',
  },
  {
    title: 'Contract ID',
    dataIndex: 'contractid',
  },
  {
    title: 'Contract Date',
    dataIndex: 'contractdate',
  },
  {
    title: 'Appointed As',
    dataIndex: 'appointedas',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
]
export default { data, columns }
