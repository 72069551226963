import TreeWallet from 'assets/images/tree-wallet.png'

export const getXAxisPosition = () => {
  const selectedQueryData = window.document.querySelector('.react-flow-wrapper')
  const xAxisCoordinate = selectedQueryData?.getBoundingClientRect()
  return xAxisCoordinate?.width
}

export const getYAxisPosition = () => {
  const selectedQueryData = window.document.querySelector('#root')
  const yAxisCoordinate = selectedQueryData?.getBoundingClientRect()
  return yAxisCoordinate?.height
}

export const shrinkWalletId = (data: any) => {
  return `${data.userWalletId.slice(0, 4)}...${data.userWalletId.slice(
    data.userWalletId.length - 3,
    data.userWalletId.length,
  )}`
}

export const getBranches = (outputNodes: any, animatedNodeId: number, type: string) => {
  return outputNodes
    ?.filter((item: any) => {
      return item?.parent?.length > 0
    })
    .map((item: any) => {
      return item?.parent?.map((subItem: []) => {
        return {
          id: `e${item.id}-${subItem}`,
          source: type == 'tree' ? (item.id == 1 ? item.id : `${subItem}`) : item.id,
          target: type == 'tree' ? (item.id == 1 ? `${subItem}` : item.id) : `${subItem}`,
          type: 'smoothstep',
          animated: item.layer == animatedNodeId ? true : false,
        }
      })
    })
}

export const getWalletDetails = (user: any, initialNodes: any, setIsWalletAddrAdd: any) => {
  const walletLength = user?.userWallet?.length + 1
  const walletLeftHalf = walletLength / 2
  const walletStartingPoint =
    walletLength % 2 == 0 ? 300 - ((walletLeftHalf - 1) * 250 + 125) : 300 - (walletLeftHalf - 0.5) * 250
  const walletParents = [walletLength + 2]
  // creating initialWallet as a seprate Node for now 'cause data for old accounts doen't have walletId present in user.userWallet array
  const initialWallet = [
    {
      id: '2',
      layer: 2,
      parent: walletParents,
      style: {
        border: '0px solid rgba(38, 119, 233, 0.15)',
        borderRadius: '10px',
      },
      data: {
        label: (
          <div className="stepperWalletWrapper">
            <img src={TreeWallet} alt="img" className="stepperTreeImage" />
            <div className="currency">Wallet 1</div>
          </div>
        ),
      },
      position: { x: walletStartingPoint + 0 * 250, y: 120 },
    },
  ]

  const dynamicWallets = user?.userWallet
    ?.filter((item: any) => user?.userWalletId !== item.walletId)
    .map((item: any, i: number) => {
      return {
        id: `${initialNodes.length + 2 + i}`,
        layer: 2,
        parent: walletParents,
        style: {
          border: '0px solid rgba(38, 119, 233, 0.15)',
          borderRadius: '10px',
        },
        data: {
          label: (
            <div key={item} className="stepperWalletWrapper">
              <img src={TreeWallet} alt="img" className="stepperTreeImage" />
              <div className="currency">{`Wallet ${i + 2}`}</div>
            </div>
          ),
        },
        position: { x: walletStartingPoint + (i + 1) * 250, y: 120 },
      }
    })

  return [
    ...initialWallet,
    ...dynamicWallets,
    ...[
      {
        id: `${initialNodes.length + 2 + dynamicWallets.length}`,
        layer: 2,
        style: {
          border: '0px solid rgba(38, 119, 233, 0.15)',
          borderRadius: '10px',
        },
        data: {
          label: (
            <div className="addwallet">
              <button
                onClick={() => setIsWalletAddrAdd(true)}
                style={{
                  background: '#4473F5',
                  color: '#FFFFFF',
                  fontSize: '30px',
                  borderRadius: '50px',
                  border: 'none',
                  width: '60px',
                  height: '60px',
                  textAlign: 'center',
                }}
              >
                +
              </button>
            </div>
          ),
        },
        position: { x: walletStartingPoint + (-1 + walletLength) * 250, y: 120 },
      },
    ],
  ]
}

export const getBeneficiaryArray = (fidBen: any, nodesWithWallet: any) => {
  const beneficiaryLength = fidBen?.length - 1
  const LeftHalf = beneficiaryLength / 2
  const SPoint = beneficiaryLength % 2 == 0 ? 300 - ((LeftHalf - 1) * 250 + 115) : 300 - (LeftHalf - 0.5) * 250
  return fidBen
    .filter((item: any) => item.appointAs == 'Beneficiary')
    .map((item: any, i: number) => {
      return {
        id: `${nodesWithWallet.length + 2 + i}`,
        layer: 4,
        style: {
          border: '8px solid #CEFFF9',
          borderRadius: '20px',
        },
        data: {
          label: (
            <div className="Benwrapper">
              <div className="percentage">
                <div className="name">{`${item ? `${item.displayName.slice(0, 6)}...` : 'Kristin'}`}</div>
                <div className="amount">{`${item ? `${item.amount}%` : ' 05%'}`}</div>
              </div>
              <div style={{ color: '#2677E9', fontSize: '18px', lineHeight: '24px' }} className="Responsibility">
                {`${item ? item.appointAs : 'Beneficiary'}`}
              </div>
            </div>
          ),
        },
        position: { x: SPoint + i * 250, y: 450 },
      }
    })
}

export const getFiduciaryArray = (fidBen: any, nodesWithWallet: any, beneficary: any) => {
  const fidParents = beneficary.map((item: any) => item.id)

  const fiduciary = fidBen.find((item: any) => item.appointAs == 'Fiduciary')
  return fiduciary
    ? [
        ...nodesWithWallet,
        {
          id: `${nodesWithWallet.length + 1}`,
          parent: fidParents,
          layer: 3,
          style: {
            background: '#1BB691',
            border: '8px solid #FFFFFF',
            borderRadius: '20px',
            minHeight: '25%',
          },
          data: {
            label: (
              <div className="Benwrapper">
                <div className="percentage">
                  <div style={{ color: '#ffffff' }} className="name">
                    {`${fiduciary ? fiduciary.displayName : 'Jessica 50%'}`}
                  </div>
                  <div className="amount1">{`${fiduciary ? `${fiduciary.amount}%` : ' 05%'}`}</div>
                </div>
                <div style={{ color: 'rgba(255, 255, 255, 0.65)', fontSize: '18px' }} className="Responsibility">
                  {`${fiduciary ? fiduciary.appointAs : 'Fiduciary'}`}
                </div>
              </div>
            ),
          },
          position: { x: 300, y: 270 },
        },
      ]
    : nodesWithWallet
}
