import { Tabs } from 'antd'
import SettingsAcknowledgement from 'views/dashboard/Settings/Acknowledgement'
import MyDetails from './MyDetails'
import Notifications from 'views/dashboard/Settings/Notifications'
import Billing from 'views/dashboard/Settings/Billing'
import SideBar from 'components/SideBar'
import NavBar from 'components/NavBar'
import {
  Wrapper,
  MainContainer,
  Container,
  TextArea,
  HeadingArea,
  TopBox,
  TextContainer,
  Section,
  TableContent,
} from 'styles/views/dashboard/Settings'
import ManageWills from './ManageWills'
import ManageAccount from './ManageAccount'

const { TabPane } = Tabs

const Settings = () => {
  return (
    <Wrapper>
      <NavBar />
      <Section>
        <SideBar />
        <MainContainer>
          <Container>
            <TopBox>
              <TextContainer>
                <TextArea>Settings</TextArea>
                <HeadingArea></HeadingArea>
              </TextContainer>
            </TopBox>
            <TableContent>
              <Tabs defaultActiveKey="1">
                <TabPane tab="My Details" key="1">
                  <MyDetails />
                </TabPane>
                <TabPane tab="Acknowledgement" key="2">
                  <SettingsAcknowledgement />
                </TabPane>
                <TabPane tab="Billing" key="3">
                  <Billing />
                </TabPane>
                <TabPane tab="Notifications" key="4">
                  <Notifications />
                </TabPane>
                <TabPane tab="Manage will(s)" key="5">
                  <ManageWills
                    showModal={function (): void {
                      ;('')
                    }}
                  />
                </TabPane>
                <TabPane tab="Manage account" key="6">
                  <ManageAccount />
                </TabPane>
              </Tabs>
            </TableContent>
          </Container>
        </MainContainer>
      </Section>
    </Wrapper>
  )
}

export default Settings
