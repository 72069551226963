import { useState } from 'react'

import Heading from 'components/Heading'
import data from 'views/landing/FAQ/data'
import InfoIcon from 'assets/svg/Info'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import Modal from 'components/Modal'
import {
  FAQContainer,
  Wrapper,
  HeadWrapper,
  CustomSubHeading,
  CardsWrapper,
  FAQCard,
  CardDetials,
  CardTitleText,
  CardPara,
  LearnText,
  ImageScetion,
  DownIcone,
  ContentWrapper,
} from 'styles/views/landing/FAQ'
import FaqModal from 'components/Modal/FaqModal'

interface IFAQProps {
  faqRef: React.RefObject<HTMLDivElement>
}
interface IDataProps {
  description: string
  label: string
  link: string
  panel: string
}

const FAQ: React.FC<IFAQProps> = ({ faqRef }: IFAQProps) => {
  const [toggle, setToggle] = useState(true)
  const [open, setOpen] = useState<boolean>(false)
  const [detail, setDetail] = useState<IDataProps>()
  const isOpen = () => setToggle(true)
  const isClose = () => setToggle(false)
  const [selectIndex, setSelectIndex] = useState<number | null>(null)

  return (
    <FAQContainer ref={faqRef}>
      <Wrapper>
        <HeadWrapper>
          <Heading label="FAQs" />
          <CustomSubHeading></CustomSubHeading>
        </HeadWrapper>
        <CardsWrapper>
          {data.map((item: IDataProps, index: number) => {
            return (
              <FAQCard key={index}>
                <ImageScetion>
                  <InfoIcon className="LogoFaq" />
                  <DownIcone>
                    {toggle && selectIndex == index ? (
                      <DownOutlined
                        onClick={() => {
                          isClose()
                          setSelectIndex(null)
                        }}
                      />
                    ) : (
                      <RightOutlined
                        onClick={() => {
                          isOpen()
                          setSelectIndex(index)
                        }}
                      />
                    )}
                  </DownIcone>
                </ImageScetion>
                <CardDetials
                  onClick={() => {
                    if (window?.innerWidth <= 767) {
                      setSelectIndex(index)
                      setToggle(!toggle)
                    }
                  }}
                >
                  <CardTitleText>{item.panel}</CardTitleText>
                  <ContentWrapper>
                    <CardPara>{item.label}</CardPara>
                    <LearnText
                      onClick={() => {
                        setDetail(item)
                        setOpen(true)
                      }}
                    >
                      {item.link}
                    </LearnText>
                  </ContentWrapper>
                  {toggle && selectIndex == index ? (
                    <div>
                      <CardPara>{item.description}</CardPara>
                    </div>
                  ) : null}
                </CardDetials>
              </FAQCard>
            )
          })}
        </CardsWrapper>
      </Wrapper>
      <Modal isOpen={open}>
        <FaqModal showModal={(value: boolean) => setOpen(value)} allDetail={detail} />
      </Modal>
    </FAQContainer>
  )
}

export default FAQ
