import { ReactElement } from 'react'

interface IStatus {
  title: string
}
interface IData {
  key: React.Key
  contractname: string
  contractdate: string
  contractId: string
  appointedas: string
  status: ReactElement
}

export const columns = [
  {
    title: 'Owner’s Name',
    dataIndex: 'contractname',
  },
  {
    title: 'Contract Id',
    dataIndex: 'contractId',
  },
  {
    title: 'Contract Date',
    dataIndex: 'contractdate',
  },
  {
    title: 'Appointed As',
    dataIndex: 'appointedas',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
]
export default { columns }
