import React, { useState, useContext } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import TextInput from 'components/FormElements/TextInput'
import { split } from 'shamirs-secret-sharing-ts'
import CryptoJS from 'crypto-js'
import ConfirmationModal from 'components/ConfrmationModal'
import { Checkbox, Tooltip } from 'antd'
import Modal from 'components/Modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { seckeySchema } from 'utils/schema/updateBenficiary'
import { BeneficiaryDataContext } from 'context/beneficiariesData'
import { Select } from 'antd'
import CancelSign from 'assets/images/black-x.png'
import Help from 'assets/images/help.png'
import {
  MainContainer,
  Container,
  LogoSection,
  HeadingText,
  Wrapper,
  TopText,
  BottomText,
  AdressDetails,
  TableWrapper,
  CrossSection,
  NotifySection,
  TextContainer,
  TextSection,
  AfterSection,
  InputSubWrapper,
  LabelText,
  ButtonSave,
  RightButtonBox,
  HelpContainer,
  HelpSection,
} from 'styles/components/Cards/GeneratedKeyDetails'

export interface IModalProps {
  showModal: (value: boolean) => void
  eventId?: string
}

interface IDataProps {
  abc: boolean
  xyz: boolean
  numberOfShards: number
  numberOfThreshold: number
  seckey: string
  shareKey: string
}

const text = (
  <>
    <div style={{ color: 'red', fontSize: '14px' }}>
      *Shards are generated for your wallet’s secret phrase using Shamir Secret.
    </div>
    <div style={{ color: 'red', fontSize: '14px' }}>
      *Please consider CP’s recommendations when creating your encryption key.
    </div>
    <div style={{ color: 'red', fontSize: '14px' }}>
      *When sharing your encryption key make sure your recipients are trusted contacts.
    </div>
    <div style={{ color: 'red', fontSize: '14px' }}>
      *Cryptoplan never has access to your secret phrase, shards, or your encryption key.
    </div>
    <div style={{ color: 'red', fontSize: '14px' }}>
      *Grantor is solely reponsible for sharing the encryption to their fiduciary.
    </div>
  </>
)

const GenerateKeyDetails: React.FC<IModalProps> = ({ showModal }) => {
  const { beneficiariesData } = useContext(BeneficiaryDataContext)
  const [shards, setShards] = useState<Array<string>>()
  const [isCreateInviteOpen, setIsCreateInviteOpen] = useState(false)
  const setInviteModal = (value: boolean) => {
    setIsCreateInviteOpen(value)
  }

  const beneficiaryData = beneficiariesData?.data?.beneficary

  const encryptedShards: Array<string> = []
  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<IDataProps>({ mode: 'onChange', reValidateMode: 'onChange', resolver: yupResolver(seckeySchema) })

  const watchData: any = useWatch({ control })

  const flterData: any = Object.values(watchData)

  const countShares = flterData.filter((value: boolean | string | number) => value === true).length + 1
  const countThreshold =
    flterData.filter((value: boolean | string | number) => value === true).length < 2
      ? 2
      : flterData.filter((value: boolean | string | number) => value === true).length

  const optionsShards = [{ value: countShares, label: countShares }]
  const optionsThreshold = [{ value: countThreshold, label: countThreshold }]

  const getShares = (value: IDataProps) => {
    const secret = Buffer.from(value.seckey) //secret key
    const shares = split(secret, {
      shares: countShares,
      threshold: countThreshold,
    })
    shares.forEach((share: Uint8Array, index: number, arr: string[]) => {
      const shards = Buffer.from(share).toString('hex')
      arr[index] = CryptoJS.AES.encrypt(shards, value.shareKey).toString()
      encryptedShards.push(arr[index])
      setShards(encryptedShards)
    })
  }

  const handleNo = () => {
    setInviteModal(false)
  }

  const submit = async (data: IDataProps) => {
    getShares(data)
  }

  const value: IDataProps[] = []

  value.push(watchData)

  const isEmpty = value.every((item) => Object.keys(item).length === 0)
  const hasFilled = value.some((item) =>
    Object.values(item).some((value) => typeof value === 'undefined' || value === '' || value === false),
  )

  return (
    <MainContainer>
      <Wrapper>
        <form onSubmit={handleSubmit(submit)}>
          <AdressDetails>
            <Container>
              <HeadingText>
                <TopText>Generate Unique Key Shares</TopText>
                <BottomText></BottomText>
              </HeadingText>
              <CrossSection>
                <LogoSection src={CancelSign} onClick={() => showModal(false)} />
              </CrossSection>
            </Container>
          </AdressDetails>
          <TableWrapper>
            {beneficiaryData?.map((item: any, index: number) => (
              <div key={index}>
                <div>
                  <Controller
                    control={control}
                    name={item.displayName}
                    render={({ field }) => (
                      <Checkbox {...field}>
                        <LabelText>{item.displayName}</LabelText>
                      </Checkbox>
                    )}
                  />
                </div>
              </div>
            ))}
          </TableWrapper>
          <TableWrapper>
            <InputSubWrapper>
              <LabelText>Please Enter Your Seed Phrase.</LabelText>
              <TextInput
                type="password"
                name="seckey"
                className="text"
                placeholder="Enter your seed phrase here"
                control={control}
              />
              <div>{formErrors?.seckey?.message}</div>
            </InputSubWrapper>

            <NotifySection>
              <TextContainer>
                <TextSection>Set Shares</TextSection>
              </TextContainer>
              <AfterSection>
                <Controller
                  control={control}
                  name="numberOfShards"
                  render={({ field }) => <Select {...field} options={optionsShards} placeholder="Please select one" />}
                />
              </AfterSection>
            </NotifySection>
            <NotifySection>
              <TextContainer>
                <TextSection>Set Threshold</TextSection>
              </TextContainer>
              <AfterSection>
                <Controller
                  control={control}
                  name="numberOfThreshold"
                  render={({ field }) => (
                    <Select {...field} options={optionsThreshold} placeholder="Please select one" />
                  )}
                />
              </AfterSection>
            </NotifySection>
            <InputSubWrapper>
              <HelpContainer>
                <LabelText>Encryption Key</LabelText>
                <Tooltip placement="rightTop" title={text} color={'#e2eaff'} overlayStyle={{ maxWidth: '500px' }}>
                  <HelpSection src={Help} />
                </Tooltip>
              </HelpContainer>

              <TextInput name="shareKey" className="text" type="password" control={control} />
              <div>{formErrors?.shareKey?.message}</div>
            </InputSubWrapper>
          </TableWrapper>

          <RightButtonBox>
            <div>
              <ButtonSave
                label="Generate Shards"
                variant="contained"
                type="submit"
                disabled={hasFilled || isEmpty}
                onClick={() => {
                  setInviteModal(true)
                }}
              />
              <Modal isOpen={isCreateInviteOpen}>
                <ConfirmationModal
                  showModal={(value: boolean) => setInviteModal(value)}
                  onNo={handleNo}
                  encryptedShards={shards}
                  hidePreviousModal={showModal}
                />
              </Modal>
            </div>
          </RightButtonBox>
        </form>
      </Wrapper>
    </MainContainer>
  )
}

export default GenerateKeyDetails
