import React from 'react'

interface ILoaderContext {
  loader: boolean
  setLoader: any
  uptoLoader: boolean
  setUptoLoader: any
}

const defaultState = {
  loader: false,
  setLoader: () => null,
  uptoLoader: false,
  setUptoLoader: () => null,
}

const LoaderContext = React.createContext<ILoaderContext>(defaultState)

export { LoaderContext }
